import cn from 'classnames';
import { Input } from 'components/common';
import { CloseIcon } from 'components/common/Modal';
import { Label } from 'components/fields';
import { CopyURLButton } from 'components/shared/CopyURLButton';
import { ErrorSvg } from 'components/svgs';
import { noop } from 'components/utils';
import React from 'react';
import DatePicker from 'react-datepicker';

import { Text } from '@components/common';

interface Props {
  onDelete: (v: RepoSession) => void;
  session?: RepoSession;
  index: number;
  checkForConflicts: (index: number) => boolean;
  onChange: (session: RepoSession, date: Date | null) => void;
  duration: number;
  cancelEnabled?: boolean;
  createSession?: (data: Partial<RepoSession>) => void;
  isDraft?: boolean;
}

export const Session: React.FC<Props> = ({
  cancelEnabled,
  onChange,
  duration,
  onDelete,
  session,
  index,
  checkForConflicts,
  createSession,
  isDraft
}) => {
  const hasConflicts = checkForConflicts(index);

  const scheduledCount =
    session?.participations?.filter((p) => p.status === 'booked' || p.status === 'completed').length || 0;

  const handleChange = async (session, date) => {
    date.setMilliseconds(0);
    if (!session) {
      createSession?.({ session_at: date });
    } else {
      onChange(session, date);
    }
  };
  return (
    <div
      className={cn(
        'relative w-full p-4 border border-gray-200 rounded-md',
        hasConflicts || !session ? 'border-red-600' : 'pb-7'
      )}
    >
      {cancelEnabled && session && (
        <div className='absolute top-0 right-0 flex items-center h-4 mt-4 mr-4'>
          <button
            onClick={() => onDelete(session)}
            aria-label='Close panel'
            className='hover:text-indigo-500 text-gray-700'
          >
            <CloseIcon className='w-4 h-4' />
          </button>
        </div>
      )}

      <Text h='300' color='gray-500' className='mb-4'>
        SESSION #{index + 1}
      </Text>
      <div className='full flex space-x-6'>
        <div className='w-1/2'>
          <Label className='mb-2'>When</Label>
          <DatePicker
            placeholderText='Select date'
            className='xx-select-date'
            customInput={<Input size='full' />}
            selected={session?.session_at}
            onChange={(date) => handleChange(session, date)}
            onFocus={noop}
            onBlur={noop}
            showTimeSelect
            minDate={new Date()}
            timeIntervals={15}
            dateFormat='Pp'
          />
        </div>
        {!isDraft && (
          <div className='w-1/2'>
            <Label className='mb-5'>Participants</Label>
            <Text h='400'>{scheduledCount} scheduled</Text>
          </div>
        )}
      </div>
      {!isDraft && (
        <>
          <Label className='mt-4 mb-3'>Call link</Label>
          <div className='flex items-center space-x-2'>
            {session?.join_url ? (
              <>
                <Text h='400'>{session?.join_url}</Text>
                <CopyURLButton noTippy text={session?.join_url || ''} link icon='link' />
              </>
            ) : (
              <Text h='400'>Not generated yet.</Text>
            )}
          </div>
        </>
      )}
      {hasConflicts && (
        <Text color='red-600' h='400' className='flex items-center mt-4'>
          <ErrorSvg className='mr-2' />
          Session dates or times conflict with another session.
        </Text>
      )}
      {!session?.session_at && (
        <Text color='red-600' h='400' className='flex items-center mt-4'>
          <ErrorSvg className='mr-2' />
          Please select a date and time for your session.
        </Text>
      )}
    </div>
  );
};
