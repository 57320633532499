import * as React from 'react';
import { useCallback } from 'react';

import {
  ModeratorsNeedCalendarAlert,
  ModeratorsNeedZoomSettingChangeAlert,
  ModeratorsNeedConferencingAlert,
  NeedsSlotsAlert,
  UnderParticipantLimitAlert,
  InvalidCallLocationAlert,
  NoScreenerQuestionsAlert,
  NoSurveyQuestionsAlert
} from './components';

type Props = {
  study: Study;
  emailCheck: EmailCheck;
  onStartPolling?: () => void;
};

const COMPONENTS: Record<BookabilityRule, BookabilityRuleAlertComponent> = {
  moderators_need_calendar: ModeratorsNeedCalendarAlert,
  moderators_need_conferencing: ModeratorsNeedConferencingAlert,
  moderators_need_zoom_setting_change: ModeratorsNeedZoomSettingChangeAlert,
  needs_slots: NeedsSlotsAlert,
  under_participant_limit: UnderParticipantLimitAlert,
  invalid_call_location: InvalidCallLocationAlert,
  no_screener_questions: NoScreenerQuestionsAlert,
  no_survey_questions: NoSurveyQuestionsAlert
};

export const EmailCheckAlerts: React.FC<Props> = ({ study, emailCheck, onStartPolling }) => {
  const rules = Object.keys(emailCheck);

  const renderRule = useCallback(
    (rule: BookabilityRule) => {
      const Component = COMPONENTS[rule];
      const ruleCase = emailCheck[rule];
      return (
        ruleCase &&
        Component && (
          <Component
            key={rule}
            study={study}
            rule={rule}
            level={ruleCase.level}
            extra={ruleCase.extra}
            onClickCta={onStartPolling}
          />
        )
      );
    },
    [emailCheck]
  );

  if (!rules?.length) {
    return null;
  }

  return <div className='flex flex-col mb-4 space-y-4'>{rules.map(renderRule)}</div>;
};
