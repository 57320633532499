import { FormGroup, Label, PanelWrapper } from '../shared';

import { noop } from 'components/utils';
import React, { useState } from 'react';

import { Alert, SelectOption, Text } from '@components/common';
import { CalendarTypeToggle } from '../NylasCalendar/CalendarTypeToggle';
import { CallModerators } from '../NylasCalendar/CallModerators';
import { Moderator } from '../NylasCalendar/CallModerators/Moderator';
import { UseStudyUsersHook } from '../NylasCalendar/hooks/useStudyUsers';
import { ModerationStyleDropdown } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/ModerationStyleDropdown';
import { ConfirmChangeModal } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/ConfirmChangeModal';
import { api } from '@api/reduxApi';

type Data = {
  icon: React.ReactElement;
  description: string;
};

type RenderSelected = <T extends string>(option: SelectOption<T, Data>) => React.ReactNode;

const renderSelected: RenderSelected = (option) => (
  <div className='flex items-center space-x-2'>
    {option.data?.icon} <span>{option.label}</span>
  </div>
);
interface Props {
  study: Study;
  onBack: () => void;
  readOnly?: boolean;
  onSave: (study: { id: number } & Partial<Study>) => void;
  onClose: () => void;
  studyUsersHook: UseStudyUsersHook;
}

export const AttendeesPanel: React.FC<Props> = ({
  study,
  onSave,
  onBack,
  readOnly,
  onClose,
  studyUsersHook
}) => {
  const [confirmValue, setConfirmValue] = useState<NylasCalendar | null>();

  const [updateStudy] = api.useUpdateStudyMutation();

  const isDraft = study.state === 'draft';

  const anyPhantoms = studyUsersHook.allStudyUsers?.map((m) => m.teamUser)?.some((u) => u?.is_phantom);

  const moderator = studyUsersHook.allStudyUsers?.find((m) => m.studyUser.role === 'moderator');

  const confirmCalendarChange = async (cal: NylasCalendar) => {
    if (isDraft) {
      await updateStudy({ id: study.id, calendar_id: cal.internal_id, nylas_calendar_id: cal.id });
      studyUsersHook.fetchStudyUsers();
    } else {
      setConfirmValue(cal);
    }
  };

  return (
    <PanelWrapper title='Attendees' onClose={onClose}>
      {study.style === 'video_call' && (
        <FormGroup>
          <Label tooltip='Determines how calls will be moderated and the resulting availability for calls.'>
            Scheduling style
          </Label>
          <ModerationStyleDropdown
            renderSelected={renderSelected}
            study={study}
            onSave={async (v) => {
              await updateStudy(v);
              studyUsersHook.fetchStudyUsers();
            }}
          />
        </FormGroup>
      )}
      {study.moderation_style === 'single' && moderator && (
        <FormGroup>
          <Label tooltip='Who will be moderating the call with the participant. Moderators will be on calendar invites with participants that schedule.'>
            Moderator
          </Label>
          <Moderator
            type='moderator'
            disabled={!!readOnly}
            user={moderator.teamUser}
            isOwner
            requireCalendar
            onClickRemove={noop}
            calendarId={study.nylas_calendar_id || study.owner?.default_nylas_calendar_id}
            onChangeCalendar={confirmCalendarChange}
            onClickSwitchToCalendly={study.state === 'draft' ? onBack : undefined}
            loading={studyUsersHook.calendarState.loading}
            error={studyUsersHook.calendarState.error}
            calendars={studyUsersHook.calendarState.calendars[moderator.teamUser.id]}
          />
          {moderator.teamUser.nylas_invalid && (
            <div className='pt-3'>
              <Alert type='error'>
                {moderator.teamUser.name} must re-authorize their {moderator.teamUser.nylas_provider || 'connected'}{' '}
                calendar.
              </Alert>
            </div>
          )}
          {!study.focus_group && (
            <div className='pt-3'>
              <CalendarTypeToggle
                readOnly={readOnly}
                type={study.nylas_calendar_type}
                onChange={(type) => onSave({ id: study.id, nylas_calendar_type: type })}
              />
            </div>
          )}
        </FormGroup>
      )}

      {(study.moderation_style === 'collective' || study.moderation_style === 'round_robin') && study.owner && (
        <FormGroup>
          <Label tooltip='Who will be moderating the call with the participant. Moderators will be on calendar invites with participants that schedule.'>
            Moderators
          </Label>
          <div className='pb-4'>
            <CallModerators studyUsersHook={studyUsersHook} study={study} type='moderator' requireCalendar />
          </div>
          <div className='py-3'>
            <CalendarTypeToggle
              readOnly={readOnly}
              type={study.nylas_calendar_type}
              onChange={(type) => onSave({ id: study.id, nylas_calendar_type: type })}
            />
          </div>
        </FormGroup>
      )}

      <FormGroup>
        <Label tooltip='Who will be invited to listen in.  Observers will receive internal calendar invites separate from the moderator and participant calendar invites.'>
          Observers
        </Label>

        <CallModerators
          disabled={readOnly}
          initialColorIndex={1}
          type='observer'
          requireCalendar={false}
          studyUsersHook={studyUsersHook}
          study={study}
        />
        {anyPhantoms && (
          <Text color='gray-500' h='200'>
            *Some calendars can not be shown.
          </Text>
        )}
      </FormGroup>
      {confirmValue && (
        <ConfirmChangeModal
          onCancel={() => setConfirmValue(null)}
          onConfirm={async () => {
            setConfirmValue(null);
            await updateStudy({ id: study.id, calendar_id: confirmValue.internal_id, nylas_calendar_id: confirmValue.id });
            studyUsersHook.fetchStudyUsers();
          }}
        />
      )}
    </PanelWrapper>
  );
};
