import { Text } from 'components/common';
import * as React from 'react';
import { useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useDebouncedCallback } from 'use-debounce';

import { removeListDecorators, uid, without } from '@components/utils';

import { getDefaultOperator, isArrayAnswer } from '../components/IdealAnswers/utils';
import { IdealAnswerStar } from './IdealAnswerStar';
import { XSVG } from './shared';

const ListItem: React.FC = (props) => (
  <li {...props} className='group relative flex items-start space-x-2 text-gray-700' />
);
const Interactable: React.FC<any> = ({ className = '', ...props }) => (
  <div
    {...props}
    className={[
      className,
      props.readOnly ? '' : 'hover:border-gray-400 focus:border-indigo-600 focus:outline-none',
      'rounded-xl flex w-full border border-transparent justify-items-stretch'
    ].join(' ')}
  />
);
const InteractableInner: React.FC = ({ children }) => (
  <div className='flex flex-row items-center flex-grow px-2 py-1'>{children}</div>
);
const InteractableDeleteButton: React.FC<{ onClick: React.MouseEventHandler }> = ({ onClick }) => (
  <button
    name='delete_option'
    className='group-hover:flex focus:outline-none items-center justify-center hidden w-10 border-l border-gray-200'
    onClick={onClick}
  >
    <XSVG className='text-gray-500' />
  </button>
);

interface OptionWithId {
  id: number;
  value: string;
}
interface OptionsComponentProps extends QuestionBodyProps {
  bulletClass: string;
}
export const OptionsComponent: React.FC<OptionsComponentProps> = ({
  readOnly = false,
  bulletClass,
  config,
  question: q,
  onChange
}) => {
  const [options, setOptions] = useState<OptionWithId[] | undefined>(q.options?.map((value) => ({ value, id: uid() })));
  const { hasIdealAnswers } = config || {};

  const save = useDebouncedCallback((newOptions: OptionWithId[]) => {
    onChange({
      ...q,
      options: newOptions.map((o) => o.value)
    });
  }, 100);

  const getHtmlId = (o: OptionWithId) => `field-${q.id}-option-${o.id}`;

  const focus = (o: OptionWithId) => document.getElementById(getHtmlId(o))?.focus();

  const handleClickInsert = (pos = options?.length || 0) => {
    const emptyOption = { id: uid(), value: '' };
    const opts = options || [];
    const newOptions = [...opts.slice(0, pos), emptyOption, ...opts.slice(pos)];

    setOptions(newOptions);

    setTimeout(() => focus(emptyOption), 1);
  };

  const idealAnswerArray = isArrayAnswer(q);
  // TODO: this needs to consider what type of field it is
  const idealAnswerValue = idealAnswerArray ? (q.ideal_answer || {}).value || [] : (q.ideal_answer || {}).value;
  function toggleIdealAnswer(option: string) {
    let value;
    if (idealAnswerArray) {
      value = idealAnswerValue.includes(option) ? without(idealAnswerValue, option) : [...idealAnswerValue, option];
    } else {
      value = idealAnswerValue === option ? null : option;
    }

    onChange({
      ...q,
      ideal_answer: {
        operator: getDefaultOperator(q),
        value
      }
    });
  }

  function optionIsAnIdealAnswer(val: string): boolean {
    if (idealAnswerArray) {
      return idealAnswerValue.includes(val);
    } else {
      return idealAnswerValue === val;
    }
  }

  const onChangeMultipleOptions = (newValue: string, options: OptionWithId[], currentId: number) => {
    const newValuesArray = newValue.split('\n').map((v) => removeListDecorators(v));

    const newOptionsArray = newValuesArray.map((o) => ({ id: uid(), value: o }));

    const current = options.find(({ id }) => id === currentId) as OptionWithId;

    const newOptions = [
      ...options.slice(0, options.indexOf(current)),
      ...newOptionsArray,
      ...options.slice(options.indexOf(current) + 1)
    ];

    setOptions(newOptions);

    if (newValue !== '') {
      save.callback(newOptions);
    }
  };
  const onOptionChange = (newValue: string, options: OptionWithId[], optionId: number) => {
    const newOptions = options.map((o) => ({
      id: o.id,
      value: o.id === optionId ? newValue : o.value
    }));

    setOptions(newOptions);

    if (newValue !== '') {
      save.callback(newOptions);
    }
  };

  return (
    <ul className='space-y-2'>
      {options?.map((option, idx) => (
        <ListItem key={option.id}>
          <div className={`w-4 h-4 border border-gray-400 mt-1.5 ${bulletClass}`} />
          {hasIdealAnswers && (
            <IdealAnswerStar
              mt='1.5'
              ideal={optionIsAnIdealAnswer(option.value)}
              onClick={() => toggleIdealAnswer(option.value)}
            />
          )}

          <Interactable readOnly={readOnly}>
            <InteractableInner>
              {readOnly ? (
                <Text>{option.value}</Text>
              ) : (
                <TextareaAutosize
                  onFocus={(e) => e.target.select()}
                  id={getHtmlId(option)}
                  className={`${
                    readOnly ? '' : 'focus:outline-none focus:border-0 focus:ring-0'
                  } w-full p-0 border-0 resize-none`}
                  minRows={0}
                  value={option.value}
                  disabled={readOnly}
                  onPaste={
                    readOnly
                      ? undefined
                      : (e) => {
                          const newValue = e.clipboardData.getData('text');

                          if (newValue.includes('\n')) {
                            onChangeMultipleOptions(newValue, options, option.id);
                          } else {
                            onOptionChange(e.currentTarget.value, options, option.id);
                          }
                        }
                  }
                  onChange={
                    readOnly
                      ? undefined
                      : (e) => {
                          onOptionChange(e.currentTarget.value, options, option.id);
                        }
                  }
                  onBlur={
                    readOnly
                      ? undefined
                      : (e) => {
                          // delete this option if empty
                          if (option.value.trim() === '') {
                            const newOptions = without(options, option);
                            setOptions(newOptions);
                            save.callback(newOptions);
                          }
                        }
                  }
                  onKeyDown={(e) => {
                    // enter key saves and focuses the next option
                    if (e.key === 'Enter' && !readOnly) {
                      e.preventDefault();
                      const nextOption = options[idx + 1];
                      if (!option.value) {
                        focus(nextOption);
                      } else if (nextOption?.value) {
                        handleClickInsert(idx + 1);
                      } else {
                        e.currentTarget.blur();
                        handleClickInsert();
                      }
                    }
                  }}
                />
              )}
            </InteractableInner>

            {!readOnly && (
              <InteractableDeleteButton
                onClick={() => {
                  const newOptions = without(options, option);
                  setOptions(newOptions);
                  save.callback(newOptions);
                }}
              />
            )}
          </Interactable>
        </ListItem>
      ))}

      {!readOnly && (
        <ListItem>
          <div className={`w-4 h-4 border border-gray-400 mt-1.5 ${bulletClass}`} />

          <Interactable readOnly={readOnly}>
            <InteractableInner>
              <button
                className='focus:outline-none cursor-text text-gray-500'
                onClick={() => handleClickInsert()}
              >
                Enter option{!q.other && ' or'}
              </button>
              {!q.other && (
                <span>
                  &nbsp;
                  <button className='focus:outline-none' onClick={() => onChange({ ...q, other: true })}>
                    <span className='hover:underline text-indigo-600'>add “Other”</span>
                  </button>
                </span>
              )}
            </InteractableInner>
          </Interactable>
        </ListItem>
      )}

      {q.other && (
        <ListItem>
          <div className={`w-4 h-4 border border-gray-400 mt-1.5 ${bulletClass}`} />
          <Interactable readOnly={readOnly}>
            <InteractableInner>
              <Text>Other</Text>
            </InteractableInner>
            {!readOnly && <InteractableDeleteButton onClick={() => onChange({ ...q, other: false })} />}
          </Interactable>
        </ListItem>
      )}
    </ul>
  );
};

export const Checkboxes: QuestionCardBodyComponent = (props) => {
  return <OptionsComponent bulletClass='rounded-sm' {...props} />;
};
