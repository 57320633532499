import { RestrictedAction } from 'components/shared/RestrictedAction';
import { useFeature } from 'hooks/useFeature';
import { usePlan } from '@hooks/usePlan';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { SlideOut } from '@components/common';
import { useDisabledFeatures } from '@hooks/useDisabledFeatures';
import { useUser } from '@hooks/useUser';
import { useToaster } from '@stores/toaster';

import { StudyButton } from '../StudyButton';

interface ButtonsProps {
  teamId?: number | null;
  onClose?: () => void;
  externalCandidatesDefaulted?: boolean;
}

type Props = ButtonsProps & { isVisible: boolean };

export const NewRecruitingStudySlideout = ({ teamId, isVisible, onClose }: Props): ReactElement | null => {
  return <NewStudySlideout teamId={teamId} isVisible={isVisible} onClose={onClose} externalCandidatesDefaulted />;
};

export const NewStudyButtons = ({
  teamId,
  onClose,
  externalCandidatesDefaulted = false
}: ButtonsProps): ReactElement | null => {
  const user = useUser();
  const navigate = useNavigate();
  const showToast = useToaster();

  const [createStudy] = api.useCreateStudyMutation();

  const { hasFeature } = usePlan();

  const focusGroupsEnabled = useFeature('focus_groups');
  const unmoderatedTestsEnabled = hasFeature('unmoderated');

  const disabledFeatures = useDisabledFeatures();

  const handleOnClick = async (style: Study['style'], focus_group = false, continuous = false) => {
    const external_candidates_enabled: boolean = externalCandidatesDefaulted;
    const has_screener: boolean = externalCandidatesDefaulted;

    try {
      const { id } = await createStudy({
        style,
        owner_id: user.id,
        focus_group,
        continuous,
        external_candidates_enabled,
        has_screener,
        team_id: teamId
      }).unwrap();
      navigate(`/studies/${id}`);
      onClose?.();
    } catch {
      showToast({
        heading: 'Something went wrong',
        icon: 'error',
        text: 'We couldn’t create the study. Please try again later.'
      });
    }
  };

  return (
    <>
      <StudyButton kind='video_call' className='mb-4' onClick={() => handleOnClick('video_call')} />
      {focusGroupsEnabled && (
        <RestrictedAction feature='focus_groups'>
          {({ may, can, show }) => (
            <StudyButton
              kind='focus_group'
              className='mb-4'
              onClick={() => {
                if (!may || !can) {
                  show?.();
                  return;
                }
                handleOnClick('video_call', true, false);
              }}
            />
          )}
        </RestrictedAction>
      )}

      {!disabledFeatures.survey && (
        <StudyButton kind='survey' className='mb-4' onClick={() => handleOnClick('survey')} />
      )}

      {unmoderatedTestsEnabled && !disabledFeatures.unmoderated_test && (
        <StudyButton kind='unmoderated_test' className='mb-4' onClick={() => handleOnClick('unmoderated_test')} />
      )}

      {!disabledFeatures.online_task && (
        <StudyButton kind='online_task' className='mb-4' onClick={() => handleOnClick('online_task')} />
      )}

      {!externalCandidatesDefaulted && <StudyButton kind='panel' onClick={() => handleOnClick('panel')} />}
    </>
  );
};

export const NewStudySlideout = ({
  teamId,
  isVisible,
  onClose,
  externalCandidatesDefaulted = false
}: Props): ReactElement | null => {
  if (!isVisible) {
    return null;
  }

  return (
    <section>
      <SlideOut title='Create new study' subtitle='Choose a research method to run with internal or external candidates.' onClose={onClose}>
        <div className='p-6 border-t border-gray-200'>
          <NewStudyButtons
            teamId={teamId}
            onClose={onClose}
            externalCandidatesDefaulted={externalCandidatesDefaulted}
          />
        </div>
      </SlideOut>
    </section>
  );
};
