import * as React from 'react';
import { Basic } from '@components/shared/Skeleton';
import { Text } from '@components/common';

export const Skeleton: React.FC = () => {
  return (
    <div className='desktop:p-10 h-full flex flex-col p-4 bg-white border border-gray-200 rounded-lg'>
      <div className='space-y-4 tablet:space-y-0'>
        <div className='flex flex-col tablet:flex-row tablet:justify-between items-center space-y-4 tablet:space-y-0'>
          <Text className='text-center' h='800'>
            Candidate segments
          </Text>
          <Basic h='5' width={205} height={40} />
        </div>
        <Basic mb='4' h='5' />
        <table className='w-full mt-6 min-w-4xl overflow-auto'>
          <thead>
            <tr>
              <th className='pl-0 text-sm text-left'>
                <Basic h='5' width={45} height={20} />
              </th>
              <th className='p-3 text-sm text-left'>
                <Basic h='5' width={55} height={20} />
              </th>
              <th className='p-3 text-sm text-left'>
                <Basic h='5' width={60} height={20} />
              </th>
              <th></th>
            </tr>
          </thead>

          <tbody className='divide-y divide-gray-200'>
            <tr className='group text-sm text-gray-700'>
              <td className='p-3 pl-0'>
                <Basic h='5' width={65} height={20} />
              </td>
              <td className='p-3'>
                <Basic h='5' width={70} height={20} />
              </td>
              <td className='p-3'>
                <Basic h='5' width={150} height={20} />
              </td>
              <td className='p-3'></td>
              <td className='p-3 flex space-x-4 justify-end'>
                <Basic h='5' width={20} height={20} />
                <Basic h='5' width={20} height={20} />
              </td>
            </tr>
            <tr className='group text-sm text-gray-700'>
              <td className='p-3 pl-0'>
                <Basic h='5' width={65} height={20} />
              </td>
              <td className='p-3'>
                <Basic h='5' width={70} height={20} />
              </td>
              <td className='p-3'>
                <Basic h='5' width={150} height={20} />
              </td>
              <td className='p-3'></td>
              <td className='p-3 flex space-x-4 justify-end'>
                <Basic h='5' width={20} height={20} />
                <Basic h='5' width={20} height={20} />
              </td>
            </tr>
            <tr className='group text-sm text-gray-700'>
              <td className='p-3 pl-0'>
                <Basic h='5' width={65} height={20} />
              </td>
              <td className='p-3'>
                <Basic h='5' width={70} height={20} />
              </td>
              <td className='p-3'>
                <Basic h='5' width={150} height={20} />
              </td>
              <td className='p-3'></td>
              <td className='p-3 flex space-x-4 justify-end'>
                <Basic h='5' width={20} height={20} />
                <Basic h='5' width={20} height={20} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
