import classNames from 'classnames';
import * as React from 'react';

import { NextIcon, PrevIcon } from '../svgs';

interface Props {
  disabled: boolean;
  onClick: () => void;
  type: 'prev' | 'next';
}

export const ArrowButton: React.FC<Props> = ({ disabled, onClick, type }) => {
  const Icon = {
    prev: PrevIcon,
    next: NextIcon
  }[type];

  const buttonClassname = classNames({
    'text-gray-500': disabled,
    'text-indigo-600 cursor-pointer hover:border-indigo-600': !disabled,
    'rounded-full flex items-center justify-center border border-gray-200 h-10 w-10': true
  });

  const onArrowClick = () => {
    !disabled && onClick();
  };

  return (
    <button data-testid={type} disabled={disabled} onClick={onArrowClick} className={buttonClassname}>
      <Icon />
    </button>
  );
};
