import * as React from 'react';

type CellWrapperProps = {
  onClick?: () => void;
  wrapperClass?: string;
  position?: string;
};

export const CellWrapper: React.FC<CellWrapperProps> = ({ children, onClick, position = 'center', wrapperClass }) => (
  <div onClick={onClick} className={`h400 w-full h-full px-2 flex items-${position} ${wrapperClass}`}>
    {children}
  </div>
);
