import { api } from 'api/reduxApi';
import { useToaster } from 'components/stores/toaster';
import * as React from 'react';
import { useEffect, useState, MouseEventHandler, useMemo } from 'react';

// components
import { Card, Input, Select, SelectOption, Text, Toggle, TryItPopUp } from '@components/common';
import { SelectDropdown } from '@components/shared/SelectDropdown';
import { blurOnEnter, ChangeFn, getChangeFn } from '@components/utils';
// hooks
// libs
import Tippy from '@tippyjs/react';
import { track } from '@components/tracking';

import { StudyScreenerBuilder } from '../components/StudyScreenerBuilder';
import { FormGroup, Helper, Label } from './Plan/components/IncventivesOptions/IncentivesOptions';

import { ExternalLinkSVG, ErrorStateSVG } from '@components/svgs';
import { useDebouncedCallback } from 'use-debounce';

import { useCandidateAttrs } from 'hooks/useCandidateAttrs';

const PLATFORM_OPTIONS: SelectOption[] = [
  {
    label: 'Formsort',
    value: 'Formsort'
  },
  {
    label: 'SurveyMonkey',
    value: 'SurveyMonkey'
  },
  {
    label: 'Google Form',
    value: 'Google Form'
  },
  {
    value: 'Qualtrics',
    label: 'Qualtrics'
  },
  {
    value: 'other',
    label: 'Other'
  }
];

export const ExternalSurvey: React.FC<{ onSave: (study: Study) => void; study: Study }> = ({ onSave, study }) => {
  const [link, setLink] = useState(study.external_url || '');
  const [linkWithAttrs, setLinkWithAttrs] = useState<string>();
  const [clientID, setClientID] = useState(study.external_platform_settings?.client_id);
  const [flowID, setFlowID] = useState(study.external_platform_settings?.flow_id);
  const [variantID, setVariantID] = useState(study.external_platform_settings?.variant_id);
  const [externalSurveyAttrs, setExternalSurveyAttrs] = useState(study.external_survey_attrs || []);

  const limitedEditing = study.has_requested_any_participants;

  const showToast = useToaster();

  const change = getChangeFn<Study>(study, onSave) as ChangeFn;

  const changeExternalSettings = getChangeFn<Study>(study.external_platform_settings, (v) =>
    change('external_platform_settings', v)
  ) as ChangeFn;

  const debounced = useDebouncedCallback<ChangeFn>(changeExternalSettings, 500);
  const debouncedChange: ChangeFn = debounced.callback;

  const onBlur = () => change?.('external_url', link);

  const { data: qualtricsSurveys, isError } = api.useGetStudyQualtricsSurveysQuery(study.id);
  const qualtricsSurveysOptions = (qualtricsSurveys || []).map(({ url, name, has_token }) => ({
    label: has_token ? name : `${name} -- "gq_token" field missing`,
    value: url,
    disabled: !has_token
  }));

  const { candidateAttrs } = useCandidateAttrs();

  const externalSurveyAttrsOptions = candidateAttrs.map(({ name, label }) => ({
    label: label,
    value: name
  }));

  const changeExternalSurveyAttrs = (attrs) => {
    const external_survey_attrs = attrs.map(({ value }) => value);
    setExternalSurveyAttrs(external_survey_attrs);
    change?.('external_survey_attrs', external_survey_attrs);
  };

  const selectedExternalSurveyAttrs = useMemo(
    () => externalSurveyAttrs.map((attr) => ({ value: attr, label: attr })),
    [externalSurveyAttrs]
  );

  useEffect(() => {
    if (link && selectedExternalSurveyAttrs.length) {
      setLinkWithAttrs(
        `${link}${link.includes('?') ? '&' : '?'}gq_token=[value]&${selectedExternalSurveyAttrs
          .map(({ value }) => `gq_${value}=[value]`)
          .join('&')}`
      );
    } else {
      setLinkWithAttrs(undefined);
    }
  }, [link, selectedExternalSurveyAttrs]);

  let qualtricsState: 'not_connected' | 'with_surveys' | 'without_surveys';
  if (study.qualtrics_needed) {
    qualtricsState = 'not_connected';
  } else if (qualtricsSurveysOptions.length) {
    qualtricsState = 'with_surveys';
  } else {
    qualtricsState = 'without_surveys';
  }

  const changeLink = (value) => {
    setLink(value);
    change?.('external_url', value);
  };

  const changePlatform = (value) => {
    if (value === 'Qualtrics' && qualtricsState === 'not_connected') {
      track('clicked_qualtrics_connect_from_survey_platform_dropdown');
      window.open('/user/connected_accounts', '_blank', 'noopener');
    } else if (value === 'Qualtrics' && qualtricsState === 'without_surveys') {
      track('clicked_qualtrics_no_recent_surveys_from_survey_platform_dropdown');
    } else {
      change?.('external_platform', value);
    }
  };

  useEffect(() => {
    if (isError) {
      showToast({
        heading: 'Failed to get Qualtrics surveys',
        text: 'Please check your Account Integration to Qualtrics.',
        icon: 'error'
      });
    }
  }, [isError]);

  return (
    <Card className='max-w-3xl px-12 py-8 mx-auto'>
      <FormGroup>
        <Label>Platform</Label>
        <Helper>Set the platform being used for additional functionality such as webhooks and integrations.</Helper>
        <Select
          className='xx-external-platform max-w-sm'
          value={study.external_platform || ''}
          disabled={!PLATFORM_OPTIONS.length || limitedEditing}
          options={PLATFORM_OPTIONS}
          onChange={changePlatform}
          renderLabel={(option) => {
            if (option.value === 'Qualtrics' && qualtricsState === 'not_connected') {
              return (
                <span className='flex flex-row items-center w-full'>
                  <span className=''>{option.label}</span>
                  <span className='ml-auto'>{<ExternalLinkSVG />}</span>
                  <span className='ml-2'>Connect</span>
                </span>
              );
            } else if (option.value === 'Qualtrics' && qualtricsState === 'without_surveys') {
              return (
                <span className='flex flex-row items-center w-full'>
                  <span className=''>{option.label}</span>
                  <span className='ml-auto'>{<ErrorStateSVG />}</span>
                  <span className='ml-2'>(No recent surveys found)</span>
                </span>
              );
            } else {
              return <>{option.label}</>;
            }
          }}
        ></Select>
      </FormGroup>
      {study.external_platform === 'Formsort' && (
        <>
          <FormGroup>
            <Label>Client ID</Label>
            <Helper>What is the client id of this flow?</Helper>
            <Input
              value={clientID}
              disabled={limitedEditing}
              onChange={(v) => {
                setClientID(v);
                debouncedChange?.('client_id', v);
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Flow ID</Label>
            <Helper>What is the flow id of this flow?</Helper>
            <Input
              value={flowID}
              disabled={limitedEditing}
              onChange={(v) => {
                setFlowID(v);
                debouncedChange?.('flow_id', v);
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Variant ID</Label>
            <Helper>What is the variant of this flow?</Helper>
            <Input
              value={variantID}
              disabled={limitedEditing}
              onChange={(v) => {
                setVariantID(v);
                debouncedChange?.('variant_id', v);
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Formsort external variable</Label>
            <Helper>To reconcile invited participations please add the following external variable.</Helper>
            <Text>gq_token</Text>
          </FormGroup>
          <FormGroup>
            <Label>Formsort webhook</Label>
            <Helper>For deep integration, set up the following webhook in Formsort.</Helper>
            <Text>
              https://{window.location.hostname}/formsort/studies/{study.id}/webhook
            </Text>
          </FormGroup>
        </>
      )}
      {study.external_platform && study.external_platform !== 'Qualtrics' && (
        <>
          <FormGroup>
            <Label>Survey link</Label>
            <Helper>This will be shared with candidates you invite to the study.</Helper>
            <input
              type='text'
              className='w-96 px-4 py-2 border border-gray-200'
              name='external_url'
              value={link}
              disabled={limitedEditing}
              onChange={(e) => setLink(e.currentTarget.value)}
              onBlur={onBlur}
              placeholder='e.g. UsabilityHub.com/xyz'
              {...blurOnEnter()}
            />
            <Tippy content='View survey'>
              <a
                href={link}
                target='_blank'
                className={`ml-4 ${link ? 'cursor-pointer' : 'cursor-not-allowed text-gray-300'}`}
              >
                <ExternalLinkSVG className='inline' />
              </a>
            </Tippy>
          </FormGroup>
        </>
      )}
      {study.external_platform && study.external_platform === 'Qualtrics' && (
        <>
          <FormGroup>
            <Label>Survey</Label>
            <Helper>
              This will be shared with candidates you invite to the study. IMPORTANT: In order to track participant
              status, you must have an{' '}
              <a
                href='https://www.qualtrics.com/support/survey-platform/survey-module/survey-flow/standard-elements/embedded-data/#CreatingAnEmbeddedDataElement'
                target='_blank'
              >
                embedded data field
              </a>{' '}
              called "gq_token" included in your survey flow.
            </Helper>
            <div className='flex items-center'>
              <div className='w-96'>
                <Select
                  className='border border-gray-200'
                  value={link}
                  disabled={limitedEditing}
                  options={qualtricsSurveysOptions}
                  onChange={changeLink}
                />
              </div>
              <Tippy content='View survey'>
                <a
                  href={link}
                  target='_blank'
                  className={`ml-4 ${link ? 'cursor-pointer' : 'cursor-not-allowed text-gray-300'}`}
                >
                  <ExternalLinkSVG className='inline' />
                </a>
              </Tippy>
            </div>
          </FormGroup>
        </>
      )}
      {study.external_platform && (
        <FormGroup>
          <Label>Embed Candidate Attributes</Label>
          <Helper>You may also select attributes to pass as URL parameters for use as embedded data fields.</Helper>
          <div className='w-96'>
            <SelectDropdown
              multi
              keepSelectedOptions
              value={selectedExternalSurveyAttrs}
              options={externalSurveyAttrsOptions}
              onChange={changeExternalSurveyAttrs}
            />
          </div>
          {linkWithAttrs && (
            <div className='mt-2'>
              <Text className='text-sm'>Example: {linkWithAttrs}</Text>
            </div>
          )}
        </FormGroup>
      )}
    </Card>
  );
};

export const Survey: React.FC<PageProps> = ({ survey_screener, study, onSave }) => {
  const change = getChangeFn<Study>(study, onSave);

  const [onGQ, setOnGQ] = useState<boolean>(study.on_gq);
  
  const handleNotOnGQToggle = (notOnGQ: boolean) => {
    setOnGQ(!notOnGQ);
    change?.('on_gq', !notOnGQ);
    change?.('maximum_as_started_in_minutes', notOnGQ ? null : 60);
  }
  
  const handleOnConnectQualtrics: MouseEventHandler<HTMLButtonElement> = () => {
    window.open('/user/connected_accounts', '_blank', 'noopener');
  };

  return (
    <div className='relative'>
      {['survey', 'panel'].includes(study.style) && (
        <div className='flex px-8'>
          {study.style === 'survey' && (
            <div className='tablet:top-8 tablet:left-12 absolute top-0 left-0 z-10'>
              <div className='flex items-center justify-end py-1 space-x-2'>
                <Toggle on={!onGQ} onToggle={handleNotOnGQToggle} className='xx-external' />
                <span className='text-gray-600'>Use my own survey link</span>
              </div>
              {study.qualtrics_needed && (
                <TryItPopUp
                  popupKey={'qualtrics'}
                  title={'Try the Qualtrics integration'}
                  description={'Now you can bring your Qualtrics surveys into Great Question.'}
                  onConfirm={handleOnConnectQualtrics}
                />
              )}
            </div>
          )}

          <div id='pre-publish-survey-actions' className='tablet:top-8 tablet:right-12 absolute top-0 right-0 z-10' />
        </div>
      )}
      <div className='relative'>
        {!onGQ && (
          <div className='px-page tablet:py-20 py-12'>
            <ExternalSurvey study={study} onSave={onSave} />
          </div>
        )}

        {onGQ && survey_screener && (
          <StudyScreenerBuilder
            screener={{ ...survey_screener, style: study.style, project_id: study.id }}
            builderTitle
            page='studyBuilder'
            onSave={onSave}
            study={study}
          />
        )}
      </div>
    </div>
  );
};
