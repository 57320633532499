import pluralize from 'pluralize';
import * as React from 'react';
import { useState } from 'react';

import { bulkParticipationsAction, sendStudyMessage } from '@api/queries';
import { Loading } from '@components/common';
import { useToaster } from '@stores/toaster';

import { UncontactableAlert } from './components/alerts';
import { StudyMessageSlideOut } from './StudyMessageSlideOut';
import { SendMessageParams } from './types';
import { filterContactable } from './utils/filters';

interface Props {
  study: Study;
  participations: Participation[];
  event: StudyMessageEvent;
  title: string;
  cta: string;
  onSuccess?: () => void;
  onClose: () => void;
}

const MESSAGES = {
  ad_hoc: 'ad hoc message',
  started_reminder: 'a reminder',
  booked_reminder: 'a reminder',
  invited_reminder: 'a reminder',
  reschedule_request: 'reschedule requests'
};

export const GenericMessageSlideOut: React.FC<Props> = ({
  study,
  participations,
  title,
  cta,
  event,
  onSuccess,
  onClose
}) => {
  const showToast = useToaster();
  const [loading, setLoading] = useState<boolean>();
  const contactable = filterContactable(study.comms_medium, participations);
  const count = participations.length;

  async function handleSend({ message, sender }: SendMessageParams) {
    setLoading(true);

    const ids = participations.map((p) => p.id);
    if (participations.length > 0) {
      await bulkParticipationsAction(study.id, { action: event as ParticipationAction, ids, fields: [] });
    }
    const resp = await sendStudyMessage({ studyId: study.id, id: message.id }, { participation_ids: ids, sender });
    setLoading(false);
    if (resp) {
      onSuccess?.();

      showToast({
        heading: `${pluralize('Email', resp.participation_ids.length)} sent!`,
        text: `${pluralize('candidates', resp.participation_ids.length, true)} sent ${MESSAGES[event]}.`,
        icon: 'success'
      });
    } else {
      showToast({
        heading: 'An error occurred!',
        text: 'Please try again later.',
        icon: 'error'
      });
    }
  }

  return (
    <StudyMessageSlideOut
      customizable
      title={title}
      study={study}
      cta={cta}
      event={event}
      inviteableCount={count}
      previewCandidate={participations[0]?.customer}
      previewParticipation={participations[0]}
      onClose={onClose}
      onSend={handleSend}
    >
      {loading && <Loading absolute />}
      <UncontactableAlert diff={count - contactable.length} medium={study.comms_medium} total={count} />
    </StudyMessageSlideOut>
  );
};
