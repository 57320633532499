import classNames from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { Button, RadioGroup, Spinner } from '@components/common';
import { EmptyBubbleSVG, FailedBubbleSVG, GreenCheckBubbleSVG, PencilSVG } from '@components/svgs';
import { REQUEST_STATE_OPTIONS } from '@components/StudiesApp/components/StudyPublished/pages/ExternalCandidatesRequest/constants';
import { useRecruitingRequest } from '@components/StudiesApp/components/StudyDraft/hooks/useRecruitingRequest';
import { useState } from 'react';

type Props = PublishStepItem & { noBorder: boolean; studyId?: number };

const GreenCheckRowItem: React.FC<Props> = ({ studyId, id, noBorder, heading, subheading, href, ctaText, state }) => {
  const { request, handleChange } = useRecruitingRequest({
    studyId: studyId as number,
    skip: id !== 'recruiting' || !studyId
  });

  const [requestState, setRequestState] = useState<ExternalCandidatesState>(request?.state as ExternalCandidatesState);

  const onChange = (state: ExternalCandidatesState) => {
    if (!request) return;
    setRequestState(state);
    handleChange({ ...request, state });
  };

  return (
    <div
      className={classNames(`flex items-center py-6 space-x-6 xx-publish-item xx-publish-item-${state}`, {
        'border-b border-gray-200': !noBorder
      })}
    >
      {state === 'completed' && <GreenCheckBubbleSVG className='mt-1' />}
      {state === 'failed' && <FailedBubbleSVG className='mt-1' />}
      {state === 'loading' && <Spinner className='w-4 h-4' />}
      {state === null && <EmptyBubbleSVG className='mt-1 text-gray-400' />}

      <div className='flex-1'>
        <span className='h500-bold block'>{heading}</span>
        {typeof subheading === 'string' ? <span className='h500 block'>{subheading}</span> : subheading}
        {id === 'recruiting' && request && (
          <div className='mt-4'>
            <RadioGroup<ExternalCandidatesState>
              options={REQUEST_STATE_OPTIONS}
              border={false}
              selectedColor='none'
              name='requestState'
              disabled={request.been_published}
              onChange={onChange}
              selected={requestState || (request.state as ExternalCandidatesState)}
            />
          </div>
        )}
      </div>

      {(state === 'completed' || !ctaText) && (
        <Link
          to={href}
          className='hover:text-indigo-600 hover:border-indigo-600 focus:ring-2 focus:ring-indigo-500 flex items-center justify-center w-10 h-10 text-gray-900 bg-white border border-gray-200 rounded-full'
        >
          <PencilSVG />
        </Link>
      )}
      {state !== 'completed' && ctaText && (
        <Link to={href}>
          <Button>{ctaText}</Button>
        </Link>
      )}
    </div>
  );
};
export { GreenCheckRowItem, Props as GreenCheckRowItemProps };
