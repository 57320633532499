import * as React from 'react';

export const PrevIcon: React.FC = () => (
  <svg
    className='fill-current'
    width='11'
    height='18'
    viewBox='0 0 11 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M8.90688 18L11 15.885L4.20108 9L11 2.115L8.90688 0L0 9L8.90688 18Z' fill='currentColor' />
  </svg>
);

export const NextIcon: React.FC = () => (
  <svg
    className='fill-current'
    width='11'
    height='18'
    viewBox='0 0 11 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M2.09312 0L0 2.115L6.79892 9L0 15.885L2.09312 18L11 9L2.09312 0Z' fill='currentColor' />
  </svg>
);
