import { Basic } from 'components/shared/Skeleton';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { Alerts } from '../components/Alerts';
import { useUser } from '@hooks/useUser';
import { usePermission } from '@hooks/usePermission';
import { BackgroundTasks } from '@components/shared/BackgroundTasks';

import { api } from '@api/reduxApi';
import { useGetStudyBackgroundTasksQuery } from '../api';
import { Card, CardTitle, Text, Button, SimpleConfirmationModal, TippyOrNot } from '@components/common';
import Tippy from '@tippyjs/react';
import { StepTitle } from './shared';
import { moneyFormat, humanize } from '@components/utils';

import { ExternalCandidatesSVG, ErrorSvg } from '@components/svgs';
import { useParticipationsStatus, STATUS_LABEL } from '../hooks/useParticipationsStatus';
import { ChangeIncentiveModal, useStudyFunder } from '@components/Wallet';

interface Props {
  study: Study;
}

const Metric: React.FC<{ title: string; value: number }> = ({ title, value }) => {
  return (
    <div className='w-40 px-6 mt-4'>
      <Text h='400'>{title}</Text>
      <div className='flex'>
        <Text h='400' className='mt-1.5'>
          {value}
        </Text>
      </div>
    </div>
  );
};

const Metrics: React.FC<{
  study: Study;
  request: ExternalCandidatesRequest;
  onPublish: (e?: React.MouseEvent) => void;
  onRevertToDraft: (e?: React.MouseEvent) => void;
  onPause: (e?: React.MouseEvent) => void;
  onUnpause: (e?: React.MouseEvent) => void;
  onClose: (e?: React.MouseEvent) => void;
}> = ({ study, request, onPublish, onRevertToDraft, onPause, onUnpause, onClose }) => {
  const maximum = request.attrs.maximum_candidates || 0;

  const status = useParticipationsStatus(study);

  const studyIsActive = study.state === 'active';

  const requestValid = (request.valid_request as boolean) && (request.valid_study as boolean) && studyIsActive;
  const requestErrors = [
    ...(request.request_errors || []),
    ...(request.study_errors || []),
    ...(studyIsActive ? [] : ['Study is not Active'])
  ].join(', ');

  const partitcipationCounts = status.reduce<Record<ParticipationStatus, number>>((acc, { status }) => {
    const count = request.participations?.filter((p) => p.status === status).length ?? 0;
    acc[status] = count;

    return acc;
  }, {} as Record<ParticipationStatus, number>);

  const [confirmationModal, setConfirmationModal] = useState<'close' | null>(null);

  const StateBadge: React.FC<{ state: string; paused: boolean; valid: boolean }> = ({ state, paused, valid }) => {
    let copy: string = state;
    let color: string = 'indigo';
    switch (state) {
      case 'draft':
        copy = 'draft';
        color = 'yellow';
        break;
      case 'submitted_publish':
        if (valid) {
          copy = 'publishing';
          color = 'indigo';
        } else {
          copy = 'unable to publish';
          color = 'red';
        }
        break;
      case 'published':
        if (paused) {
          copy = 'paused';
          color = 'yellow';
        } else {
          copy = 'active';
          color = 'indigo';
        }
        break;
      case 'submitted_close':
        copy = 'closing';
        color = 'green';
        break;
      case 'closed':
        copy = 'closed';
        color = 'green';
        break;
    }

    return <div className={`px-1 text-xs text-${color}-700 bg-${color}-50 font-bold uppercase`}>{copy}</div>;
  };

  return (
    <Card className='mb-4'>
      <div className='flex flex-row items-center justify-between'>
        <CardTitle size='base'>{request.attrs.public_title}</CardTitle>
        <div className='flex flex-row items-center space-x-3'>
          <Button icon='pencil' href={`/studies/${request.project_id}/recruitment_requests/${request.id}`}>
            Edit
          </Button>
          {request.state === 'draft' && (
            <TippyOrNot show={!requestValid && requestErrors.length > 0} content={requestErrors}>
              <Button icon='promo' onClick={onPublish} disabled={!requestValid}>
                Publish
              </Button>
            </TippyOrNot>
          )}
          {request.state === 'submitted_publish' && (
            <Tippy content='Stop Publishing and return the request to Draft'>
              <Button icon='noCircle' onClick={onRevertToDraft}>
                Return to Draft
              </Button>
            </Tippy>
          )}
          {request.state === 'published' && !request.paused && (
            <Tippy content='Temporarily pause recruiting new participants'>
              <Button icon='pause' onClick={onPause}>
                Pause
              </Button>
            </Tippy>
          )}
          {request.state === 'published' && request.paused && (
            <Tippy content='Start recruiting new participants again'>
              <Button icon='play' onClick={onUnpause}>
                Unpause
              </Button>
            </Tippy>
          )}
          {request.state === 'published' && (
            <Tippy content='Permanently stop recruiting participants'>
              <Button icon='noCircle' onClick={() => setConfirmationModal('close')}>
                Close
              </Button>
            </Tippy>
          )}
        </div>
        {confirmationModal && confirmationModal === 'close' && (
          <SimpleConfirmationModal
            action='*Permanently Close* this request'
            onConfirm={() => {
              onClose();
              setConfirmationModal(null);
            }}
            onCancel={() => setConfirmationModal(null)}
          />
        )}
      </div>
      <div className='flex flex-row items-center mt-2 space-x-2'>
        <TippyOrNot show={!requestValid && requestErrors.length > 0} content={requestErrors}>
          <StateBadge state={request.state || 'draft'} paused={request.paused || false} valid={requestValid} />
        </TippyOrNot>
        <div className='border-2 border-gray-200 rounded-full' />
        <span className='text-sm text-gray-500'>{request.platform_name}</span>
        <div className='border-2 border-gray-200 rounded-full' />
        <span className='text-sm text-gray-500 uppercase'>{request.attrs.market_type}</span>
      </div>
      <div className='flex flex-wrap -ml-6 divide-x divide-gray-200'>
        <Metric title='Limit' value={maximum} />
        {Object.keys(partitcipationCounts).map((status) => (
          <Metric title={humanize(STATUS_LABEL[status] || status)} value={partitcipationCounts[status]} />
        ))}
      </div>
    </Card>
  );
};

const ZDS: React.FC<{ study: Study }> = ({ study }) => {
  const costPerB2BParticipant = moneyFormat(study.external_fee_per_b2b_participant_in_cents / 100, 0);
  const costPerB2CParticipant = moneyFormat(study.external_fee_per_b2c_participant_in_cents / 100, 0);

  return (
    <div className='flex'>
      <div className='w-9/12'>
        <Card>
          <div className='flex flex-col items-center justify-center'>
            <ExternalCandidatesSVG className='w-20 h-20 text-gray-500' />
            <span className='mt-4 font-bold text-gray-700'>Recruit external participants</span>
            <span className='mt-4 text-sm text-gray-500'>
              Submit a request to our panel providers and get participants that fit.
            </span>
            <div className='flex flex-row items-center mt-6 space-x-3'>
              <Button
                icon='newTab'
                href='https://greatquestion.co/support/studies/external-participant-recruitment'
                target='_blank'
              >
                Learn more
              </Button>
              <Button primary href={`/studies/${study.id}/recruitment_requests/new`}>
                Create request
              </Button>
            </div>
          </div>
          <div className='pt-6 mt-6 border-t border-gray-200'>
            <span className='mt-4 font-bold text-gray-700'>How it works</span>
            <div className='items-top flex flex-row mt-6'>
              <div className='w-1/12'>
                <div className='w-8 p-1 text-center border border-gray-200 rounded-lg'>1</div>
              </div>
              <div className='w-11/12'>
                <div className='text-sm text-gray-700'>Submit recruitment request form</div>
                <div className='mt-1 text-sm text-gray-500'>
                  Specify the details of who you’re looking to participate in research and our recruiting partners will
                  do the rest.
                </div>
              </div>
            </div>
            <div className='items-top flex flex-row mt-6'>
              <div className='w-1/12'>
                <div className='w-8 p-1 text-center border border-gray-200 rounded-lg'>2</div>
              </div>
              <div className='w-11/12'>
                <div className='text-sm text-gray-700'>Screen candidates</div>
                <div className='mt-1 text-sm text-gray-500'>
                  You will review the candidates provided by our partners and decide who to invite.
                </div>
              </div>
            </div>
            <div className='items-top flex flex-row mt-6'>
              <div className='w-1/12'>
                <div className='w-8 p-1 text-center border border-gray-200 rounded-lg'>3</div>
              </div>
              <div className='w-11/12'>
                <div className='text-sm text-gray-700'>Conduct the research</div>
                <div className='mt-1 text-sm text-gray-500'>
                  Just like you normally do in Great Question, you’ll be able to conduct the research with the
                  participants.
                </div>
              </div>
            </div>
            <div className='items-top flex flex-row mt-6'>
              <div className='w-1/12'>
                <div className='w-8 p-1 text-center border border-gray-200 rounded-lg'>4</div>
              </div>
              <div className='w-11/12'>
                <div className='text-sm text-gray-700'>Pay incentives and recruiting fees</div>
                <div className='mt-1 text-sm text-gray-500'>
                  You’ll send the incentives to these participants, and pay a separate fee to our partners for each
                  participant. The fees will vary by partner.
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div className='w-3/12 ml-6'>
        <span className='text-sm font-bold text-gray-500'>Our partner</span>
        <div className='p-4 mt-4 border border-gray-200 rounded'>
          <div className='flex justify-center'>
            <img src='/temp-assets/candidates/respondent.png' alt='respondent-logo' />
          </div>
          <div className='mt-4'>
            <span className='text-xs leading-snug text-gray-700'>
              Leading research participant recruitment platform.
            </span>
            <span className='text-xs text-gray-700'>
              <ul className='pl-4 list-disc'>
                <li>3M+ participants</li>
                <li>30 minute median time to first match</li>
                <li>Less than 5% no-show rate</li>
                <li>${costPerB2BParticipant} fee per B2B participant, plus incentives</li>
                <li>${costPerB2CParticipant} fee per B2C participant, plus incentives</li>
              </ul>
            </span>
          </div>
          <div className='mt-4'>
            <Button icon='newTab' className='w-full' href='https://www.respondent.io/' target='_blank'>
              Visit website
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Skeleton = () => {
  return (
    <div className='relative p-6 mb-4 bg-white border border-gray-200 rounded'>
      <Basic h='10' width={250} />
      <div className='flex flex-row mt-6 -ml-6 divide-x divide-gray-200'>
        <div className='flex flex-col flex-grow px-6 space-y-4'>
          <Basic h='8' width={136} />
          <Basic h='8' width={110} />
        </div>
        <div className='flex flex-col flex-grow px-6 space-y-4'>
          <Basic h='8' width={110} />
          <Basic h='8' width={60} />
        </div>
        <div className='flex flex-col flex-grow px-6 space-y-4'>
          <Basic h='8' width={120} />
          <Basic h='8' width={50} />
        </div>
        <div className='flex flex-col flex-grow px-6 space-y-4'>
          <Basic h='8' width={100} />
          <Basic h='8' width={50} />
        </div>
      </div>
    </div>
  );
};

export const ExternalCandidatesRequests: React.FC<{ study: Study }> = ({ study }) => {
  const user = useUser();
  const canUpdate = usePermission<Study>('updateStudy')(study);

  const [fundModalOpen, setFundModalOpen] = useState(false);

  const funder = useStudyFunder({ study });

  const {
    data: externalCandidatesRequests,
    isLoading,
    isSuccess,
    refetch: refetchRequests
  } = api.useGetExternalCandidatesRequestsQuery(study.id);
  const [publishExternalCandidatesRequest, { isSuccess: publishIsSuccess }] =
    api.usePublishExternalCandidatesRequestMutation();
  const [revertToDraftExternalCandidatesRequest] =
    api.useCreateExternalCandidatesRequestSubmissionsRevertToDraftMutation();
  const [pauseExternalCandidatesRequest, { data: pauseBackgroundTask }] =
    api.useCreateExternalCandidatesRequestPauseMutation();
  const [unpauseExternalCandidatesRequest, { data: unpauseBackgroundTask }] =
    api.useCreateExternalCandidatesRequestUnpauseMutation();
  const [closeExternalCandidatesRequest, { isSuccess: closeIsSuccess }] =
    api.useCloseExternalCandidatesRequestMutation();
  const { data: slots, refetch: refetchSlots } = api.useGetStudySlotsBalanceQuery(study.id, { skip: !study.id });

  const { data: initialBackgroundTasksData, refetch: refetchBackgroundTasksData } = useGetStudyBackgroundTasksQuery({
    objectId: study.id
  });
  const initialBackgroundTasks: BackgroundTask[] = initialBackgroundTasksData || [];
  const [backgroundTasks, setBackgroundTasks] = useState<BackgroundTask[]>(initialBackgroundTasks);

  useEffect(() => {
    if (
      study.state !== 'closed' &&
      !study.can_delay_funding &&
      study.funding.usd_outstanding > 0 &&
      study.funding.recruitment_fees > 0
    ) {
      setFundModalOpen(true);
    }
  }, [study]);

  useEffect(() => {
    if (publishIsSuccess) {
      refetchRequests();
      refetchBackgroundTasksData();
    }
  }, [publishIsSuccess]);

  useEffect(() => {
    if (closeIsSuccess) {
      refetchRequests();
      refetchBackgroundTasksData();
    }
  }, [closeIsSuccess]);

  useEffect(() => {
    refetchRequests();
    refetchSlots();
  }, [study, backgroundTasks]);

  useEffect(() => {
    if (pauseBackgroundTask) setBackgroundTasks([...backgroundTasks, pauseBackgroundTask]);
  }, [pauseBackgroundTask]);

  useEffect(() => {
    if (unpauseBackgroundTask) setBackgroundTasks([...backgroundTasks, unpauseBackgroundTask]);
  }, [unpauseBackgroundTask]);

  function handlePublish(studyId, externalCandidatesRequestId) {
    publishExternalCandidatesRequest({ studyId, externalCandidatesRequestId });
  }

  function handleRevertToDraft(studyId, externalCandidatesRequestId) {
    revertToDraftExternalCandidatesRequest({ studyId, externalCandidatesRequestId });
  }

  function handlePause(studyId, externalCandidatesRequestId) {
    pauseExternalCandidatesRequest({ studyId, externalCandidatesRequestId });
  }

  function handleUnpause(studyId, externalCandidatesRequestId) {
    unpauseExternalCandidatesRequest({ studyId, externalCandidatesRequestId });
  }

  function handleClose(studyId, externalCandidatesRequestId) {
    closeExternalCandidatesRequest({ studyId, externalCandidatesRequestId });
  }

  return (
    <div className='px-page py-gutter relative'>
      <Alerts
        slots={slots}
        user={user}
        study={study}
        openFundModal={() => setFundModalOpen(true)}
        canAction={true}
        keys={['must_fund']}
      />

      <BackgroundTasks
        onFinished={() => {
          refetchRequests();
          refetchSlots();
        }}
        setBackgroundTasks={setBackgroundTasks}
        backgroundTasks={backgroundTasks}
        params={{ objectId: study.id }}
        backgroundTasksQuery={useGetStudyBackgroundTasksQuery}
      />

      <div className='flex flex-row items-center justify-between pb-6'>
        <StepTitle>Recruitment requests</StepTitle>
        {isSuccess && !!externalCandidatesRequests?.length && (
          <Button primary className='ml-8' href={`/studies/${study.id}/recruitment_requests/new`}>
            New Request
          </Button>
        )}
      </div>
      {isLoading ? (
        <Skeleton />
      ) : (
        !!externalCandidatesRequests?.length &&
        externalCandidatesRequests?.map((request) => (
          <Metrics
            key={`externalCandidatesRequest-metrics-${request.id}`}
            study={study}
            request={request}
            onPublish={(e) => handlePublish(request.project_id, request.id)}
            onRevertToDraft={(e) => handleRevertToDraft(request.project_id, request.id)}
            onPause={(e) => handlePause(request.project_id, request.id)}
            onUnpause={(e) => handleUnpause(request.project_id, request.id)}
            onClose={(e) => handleClose(request.project_id, request.id)}
          />
        ))
      )}
      {isSuccess && !externalCandidatesRequests?.length && <ZDS study={study} />}

      {fundModalOpen && (
        <ChangeIncentiveModal
          limitCount={study.maximum_slots}
          study={study}
          funder={funder}
          onCancel={() => setFundModalOpen(false)}
          onConfirm={() => setFundModalOpen(false)}
        />
      )}
    </div>
  );
};
