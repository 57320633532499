import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { Document, Page } from 'react-pdf';
import * as React from 'react';
import { useDeviceType } from '@hooks/useDeviceType';
import { useState } from 'react';

interface Props {
  url: string;
  width: number;
}

export const PDFView: React.FC<Props> = ({ url, width }) => {
  const [numPages, setNumPages] = useState<number>(1);

  const { isMobile, isTablet } = useDeviceType();

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const panningInteractionHandler = (instance, state) => {
    instance.setup.panning.disabled = state?.positionX >= 0 && state?.positionY >= 0 && state?.scale <= 1;
  };
  return (
    <TransformWrapper
      initialScale={1}
      initialPositionX={0}
      initialPositionY={0}
      disabled={!isMobile && !isTablet}
      panning={{ disabled: true }}
      onZoomStop={(ref) => {
        panningInteractionHandler(ref.instance, ref.state);
      }}
      onPanning={(ref) => {
        panningInteractionHandler(ref.instance, ref.state);
      }}
      onInit={(ref) => {
        ref.instance.setup.panning.disabled = true;
      }}
    >
      <TransformComponent wrapperClass='pdf-zoom-wrapper'>
        <Document
          className='w-full max-w-5xl divide-y divide-gray-700'
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(Array(numPages).keys()).map((page) => (
            <Page key={page} pageNumber={page + 1} width={width} renderAnnotationLayer />
          ))}
        </Document>
      </TransformComponent>
    </TransformWrapper>
  );
};
