import { track } from 'components/tracking';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button, Loading, Select, SelectOption, SlideOut, Text, Tooltip } from '@components/common';
import { ExternalLinkIcon } from '@components/CustomerImports/steps/UploadStep';
import { PlansProvider } from '@components/PlansApp/components/PlansProvider';
import { usePlans } from '@components/PlansApp/hooks/usePlans';
import { ExternalCandidatesRequestsProvider } from '@components/StudiesApp/components/StudyPublished/pages/ExternalCandidatesRequest/utils/ExternalCandidatesRequestsProvider';
import { moneyFormat } from '@components/utils';
import { useAccount } from '@hooks/useAccount';
import { usePlan } from '@hooks/usePlan';
import { CardElement, Elements, useElements } from '@stripe/react-stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js';

import { useProcessStudyCheckoutMutation } from '../api';
import { stripePromise } from '../utils/stripePromise';
import { CardDetails } from './CardDetails';
import { Group } from './Group';
import { Label } from './Label';
import { StripeProvider } from './StripeProvider';

interface Props {
  study: Study;
  stripe: any;
  candidatesRequest: ExternalCandidatesRequest | null;
  card?: CardPaymentMethod;
  onClose: () => void;
  onSuccess: () => void;
}

const StudyCheckoutSlideout: React.FC<Props> = ({
  study,
  stripe,
  candidatesRequest,
  card,
  onClose,
  onSuccess
}) => {
  const studyId: number = study.id;
  const b2bFeeInCents: number = study.external_fee_per_b2b_participant_in_cents;
  const b2cFeeInCents: number = study.external_fee_per_b2c_participant_in_cents;
  const participantLimit: number = study.participant_limit || 10;
  const incentive: number = study.incentive || 50;
  const fundedInCents: number = study.funding.usd_unissued;

  const allPlans = usePlans();
  const currentPlan = usePlan();

  const hasRequest: boolean = study.external_candidates_enabled && !!candidatesRequest;
  const requiredFeatures: PlanFeature[] = hasRequest ? ['external_recruitment', 'incentives'] : ['incentives'];
  const plans = allPlans.filter((plan) => plan.amount > 0 && plan.interval === 'month' && plan.self_serve);

  const hasFeatures = requiredFeatures.every((feature) => currentPlan.features[feature]);
  const [plan, setPlan] = useState<Plan | null>(hasFeatures ? null : plans[0]);
  const planOptions: SelectOption<string, { amount: number }>[] = plans.map((plan) => ({
    label: `${plan.name} Plan`,
    value: plan.id.toString(),
    data: { amount: plan.amount }
  }));
  const elements = useElements();
  const [processCheckout, { isSuccess, isError, isLoading, error: processError }] = useProcessStudyCheckoutMutation();

  const [error, setError] = useState<string | null>(null);
  const totalIncentive = incentive * participantLimit;

  const recruitmentLimit = hasRequest
    ? (candidatesRequest?.attrs.maximum_candidates || 0)
    : 0;
  const recruitmentFee = hasRequest
    ? candidatesRequest?.attrs.market_type === 'b2b'
      ? b2bFeeInCents / 100
      : b2cFeeInCents / 100
    : 0;
  const totalFees = recruitmentLimit * recruitmentFee;
  const planAmount = plan ? plan.amount / 100 : 0;

  const dueToday = totalIncentive + totalFees + planAmount - fundedInCents / 100;

  useEffect(() => {
    track('viewed_study_checkout', { has_recruit: hasRequest });
  }, []);

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError && processError) {
      setError((processError as any).data);
    }
  }, [isError, processError]);

  async function handleNewCard() {
    if (!elements) {
      return;
    }
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement)
    });
    if (error) {
      setError(error);
      return;
    }
    handleCheckout({ payment_method_id: paymentMethod.id });
  }

  async function handleCheckout(params?: { payment_method_id?: string }) {
    processCheckout({
      study_id: studyId,
      payment_method_id: params?.payment_method_id,
      plan_id: plan?.id,
      incentive: incentive,
      participant_limit: participantLimit,
      amount: dueToday * 100
    });
  }

  async function handleConfirm() {
    if (!card) {
      handleNewCard();
    } else {
      handleCheckout({ payment_method_id: card.processor_id });
    }
  }

  function setPlanById(id: string) {
    const plan = plans.find((plan) => plan.id.toString() === id);
    if (plan) {
      setPlan(plan);
    }
  }
  return (
    <>
      {isLoading && <Loading />}
      <SlideOut
        size='2xl'
        title='Checkout'
        onClose={onClose}
        renderFooter={() => (
          <>
            <Button primary onClick={handleConfirm} disabled={isLoading}>
              Confirm & Pay
            </Button>
            {error && <Text color='red-600'>{error}</Text>}
          </>
        )}
      >
        {hasRequest && (
          <Group>
            <Label>Recruitment</Label>
            <Text>{candidatesRequest?.attrs.market_type.toUpperCase()} Participants</Text>
          </Group>
        )}
        {!hasFeatures && (
          <Group>
            <div className='flex flex-row items-center justify-between mb-4'>
              <Label mb='0'>Subscription</Label>
              <a
                className=' flex flex-row items-center text-sm font-medium text-indigo-600'
                href='/plans'
                target='_blank'
              >
                Compare plans
                <ExternalLinkIcon className='ml-2 text-indigo-600' />
              </a>
            </div>
            <Select
              name='plan'
              options={planOptions}
              value={plan ? plan.id.toString() : ''}
              onChange={setPlanById}
              renderLabel={(option) => (
                <div className='flex flex-row justify-between w-full'>
                  <span>{option.label}</span>
                  <span>${option.data.amount / 100}/month</span>
                </div>
              )}
            />
          </Group>
        )}
        <Group>
          <Label>Payment details</Label>
          {card ? <CardDetails card={card} /> : <CardElement />}
        </Group>
        <Group>
          <Label>Cost</Label>
          <div className='flex flex-row items-center justify-between pb-4'>
            <div className='flex items-center space-x-2'>
              <Text>Incentive funding</Text>
              <Tooltip>
                {participantLimit} candidates at ${incentive} each
              </Tooltip>
            </div>
            <Text>${moneyFormat(totalIncentive)}</Text>
          </div>
          {totalFees > 0 && (
            <div className='flex flex-row items-center justify-between pb-4'>
              <div className='flex items-center space-x-2'>
                <Text>Recruitment fees</Text>
                <Tooltip>
                  {recruitmentLimit} candidates at ${recruitmentFee} each
                </Tooltip>
              </div>
              <Text>${moneyFormat(totalFees)}</Text>
            </div>
          )}
          {fundedInCents > 0 && (
            <div className='flex flex-row items-center justify-between pb-4'>
              <Text>Existing funds</Text>
              <Text>- ${moneyFormat(fundedInCents / 100)}</Text>
            </div>
          )}
          {!hasFeatures && (
            <div className='flex flex-row items-center justify-between pb-6'>
              <Text>Subscription</Text>
              <Text>${moneyFormat(planAmount)}</Text>
            </div>
          )}
          <div className='py-3 border-t border-b border-gray-200'>
            <div className='flex flex-row items-center justify-between'>
              <Text>Total due today</Text>
              <Text bold>${moneyFormat(dueToday)}</Text>
            </div>
            {plan && (
              <Text color='gray-500' h='400' mt='1'>
                You will then be charged ${moneyFormat(planAmount)} per {plan.interval} for your {plan.name} plan.
              </Text>
            )}
          </div>
        </Group>
      </SlideOut>
    </>
  );
};

export const StudyCheckoutInline: React.FC<{ study: Study }> = ({ study }) => {
  const [search, setSearch] = useSearchParams();
  const [show, setShow] = useState(!!search.get('checkout'));

  if (!show) {
    return null;
  }
  function handleSuccess() {
    window.location.href = `/studies/${study.id}`;
  }
  function handleClose() {
    setShow(false);
    setSearch({});
  }
  return <StudyCheckout study={study} onClose={handleClose} onSuccess={handleSuccess} />;
};

export const StudyCheckout: React.FC<{ study: Study; onSuccess: () => void; onClose: () => void }> = ({
  study,
  onClose,
  onSuccess
}) => {
  const {
    account: { cards }
  } = useAccount();
  const defaultCard = cards.find((card) => card.is_default);
  const options: StripeElementsOptions = {
    mode: 'setup',
    currency: 'usd',
    appearance: {
      theme: 'flat'
    }
  };
  return (
    <PlansProvider>
      <Elements stripe={stripePromise} options={options}>
        <StripeProvider>
          {({ stripe }) => (
            <ExternalCandidatesRequestsProvider studyId={study.id}>
              {({ requests }) => (
                <StudyCheckoutSlideout
                  stripe={stripe}
                  study={study}
                  candidatesRequest={requests[0]}
                  card={defaultCard}
                  onClose={onClose}
                  onSuccess={onSuccess}
                />
              )}
            </ExternalCandidatesRequestsProvider>
          )}
        </StripeProvider>
      </Elements>
    </PlansProvider>
  );
};
