import pluralize from 'pluralize';
import * as React from 'react';

import { Text } from '@components/common';
import { usePermission } from '@hooks/usePermission';
import { usePlan } from '@hooks/usePlan';

export const SeatInfo: React.FC = () => {
  const { hasQuota, getQuota, costs: planCosts, interval } = usePlan();
  const canUseSeat = usePermission('canUseSeat')();
  const canBuySeat = usePermission('canBuySeat')();

  if (!canUseSeat) {
    return (
      <Text h='400' light className='mb-6'>
        You have <span className='font-bold'>unlimited</span> observer seats available. Please contact your admin to
        invite creators or admins
      </Text>
    );
  }
  if (hasQuota('seats')) {
    return (
      <Text h='400' light className='mb-6'>
        You have <span className='font-bold'>{getQuota('seats')}</span>{' '}
        {pluralize('remaining creator seats', getQuota('seats'))} and <span className='font-bold'>unlimited</span>{' '}
        observer seats available.
      </Text>
    );
  }
  if (canBuySeat && planCosts.seat) {
    return (
      <Text h='400' light className='mb-6'>
        You have <span className='font-bold'>unlimited</span> observer seats available. Each new admin or creator seat
        will cost{' '}
        <span className='font-bold'>
          ${planCosts.seat}/{interval}.
        </span>
      </Text>
    );
  }

  return (
    <Text h='400' light className='mb-6'>
      You have <span className='font-bold'>unlimited</span> observer seats available. Please upgrade for additional
      admin or creator seats.
    </Text>
  );
};
