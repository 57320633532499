import * as React from 'react';

import { Radio, Tooltip } from '@components/common';

interface Props {
  readOnly?: boolean;
  disabled?: boolean;
  type: Study['nylas_calendar_type'];
  onChange: (type: Study['nylas_calendar_type']) => void;
}
export const CalendarTypeToggle: React.FC<Props> = ({ disabled, readOnly, type, onChange }) => (
  <div className='flex items-center space-x-1'>
    <Radio
      disabled={readOnly || disabled}
      type='checkbox'
      checked={type === 'user'}
      name='cal_type'
      onChange={() => onChange(type === 'user' ? 'virtual' : 'user')}
      label='Check for Moderator conflicts'
    >
      <Tooltip>
        When checked, we’ll ensure participants can’t schedule time over your existing events unless marked as Free.
      </Tooltip>
    </Radio>
  </div>
);
