import * as React from 'react';
import { useState } from 'react';

import { Button, Input } from '@components/common';
import { uid, without } from '@components/utils';

type Attrs = CustomerImport['attrs'];

type FormValue = { attr: string; value: string; _id: number };

const toFormValues = (attrs: Attrs): FormValue[] =>
  Object.keys(attrs).map((attr) => ({ attr, value: attrs[attr], _id: uid() }));

const toAttrs = (values: FormValue[]): Attrs | null =>
  values.length === 0
    ? null
    : values.reduce((acc, cur) => {
        acc[cur.attr] = cur.value;
        return acc;
      }, {} as Attrs);

const focusAndScrollToId = (id: string) => {
  const element = document.getElementById(id) as HTMLInputElement;
  if (element) {
    element.focus();
    element.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
  }
};

interface Props {
  disabled?: boolean;
  value: Attrs | null;
  onChange: (value: Attrs) => void;
}
export const AdditionalAttrsForm: React.FC<Props> = ({ disabled, value, onChange }) => {
  const [values, setValues] = useState<FormValue[]>(value ? toFormValues(value) : []);

  const handleClickAdd = () => {
    const _id = uid();
    setValues([...values, { _id, attr: '', value: '' }]);
    setImmediate(() => focusAndScrollToId(`attr-${_id}`));
  };

  const onChangeValue = (_id: number, data: Partial<FormValue>) => {
    const value = values.find((v) => v._id === _id);

    if (data.attr === '') {
      setValues(without(values, value) as any);
    } else {
      const newValue = { ...value, ...data };
      setValues(values?.map((v) => (v._id === _id ? newValue : v)) as any);
    }
  };

  const save = () => {
    const valToAttr = toAttrs(values);
    valToAttr && onChange(valToAttr);
  };

  return (
    <div className='pb-4 space-y-4'>
      {values.map(({ attr, value, _id }, i) => (
        <div key={_id} className='flex space-x-2'>
          <Input
            id={`attr-${_id}`}
            name={`attrs[${i}][name]`}
            ariaLabel={`attrs[${i}][name]`}
            disabled={disabled}
            onChange={(attr) => onChangeValue(_id, { attr })}
            onBlur={save}
            placeholder='Attribute'
            value={attr}
          />
          <Input
            name={`attrs[${i}][value]`}
            ariaLabel={`attrs[${i}][value]`}
            disabled={disabled}
            onChange={(value) => onChangeValue(_id, { value })}
            value={value}
            onBlur={save}
            placeholder='Value'
          />
        </div>
      ))}
      <div className=''>
        <Button aria-label='Add attribute' icon='plus' onClick={handleClickAdd}>
          Add attribute
        </Button>
      </div>
    </div>
  );
};
