import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
const tokenEl = document.getElementById('sentry-token');
if (tokenEl) {
  Sentry.init({
    dsn: tokenEl.content,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
  });
}
