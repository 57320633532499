import React, { useEffect } from 'react';

import { Popper, Text } from '@components/common';

import type { Props as PopperProps } from '@components/common/Popper';

interface Props {
  children: PopperProps['children'];
  placement?: PopperProps['placement'];
  content: string;
  appendToNode?: boolean;
  isDisabled?: boolean;
}

const Content = ({ updatePopper, content }: { updatePopper: () => void; content: string }) => {
  useEffect(updatePopper, [content]);

  return (
    <div className='flex items-center px-2 py-1 leading-none bg-gray-700 rounded-lg'>
      <Text h='200' className='text-white whitespace-pre'>
        {content}
      </Text>
    </div>
  );
};

export const Tooltip: React.FC<Props> = ({ placement = 'top', content, children, appendToNode, isDisabled }) => {
  if (!content) return children;

  return (
    <Popper
      event='hover'
      placement={placement}
      offset={[0, 6]}
      zIndex={50}
      content={({ updatePopper }) => <Content content={content} updatePopper={updatePopper} />}
      renderOnBodyRoot={!appendToNode}
      isDisabled={isDisabled}
    >
      {children}
    </Popper>
  );
};
