import cn from 'classnames';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';

import { Alert, Button, Input, Select, TippyOrNot, Toggle, Tooltip } from '@components/common';
import { ChangeFn, without } from '@components/utils';
import { useDisabledFeatures } from '@hooks/useDisabledFeatures';
import { usePlan } from '@hooks/usePlan';

import { useStudyPublishValidator } from '../../../../hooks/useStudyPublishValidator';
import {
  INCENTIVE_FIELDS,
  INCENTIVE_METHOD_OPTIONS,
  INCENTIVE_OPTIONS,
  INCENTIVE_TITLE_PLACEHOLDERS,
  REWARD_PRODUCT_ICONS
} from '../../constants';
import { InputWithDropdown } from '../InputWithDropdown';
import { useDebouncedCallback } from 'use-debounce';
import { useEffect } from 'react';

export const FormGroup: React.FC<{ className?: string }> = ({ children, className }) => (
  <div className={cn('mb-6', className)}>{children}</div>
);
export const Label: React.FC<{ htmlFor?: string }> = ({ htmlFor, children }) => (
  <label htmlFor={htmlFor} className='h500-bold block mb-2'>
    {children}
  </label>
);
export const Helper: React.FC = ({ children }) => (
  <span className='h400 block mb-3 -mt-1 text-gray-500'>{children}</span>
);

interface Props {
  study: Study;
  change: ChangeFn;
  setOptions: (v: PlanOption[]) => void;
  options: PlanOption[];
  canChangeIncentive: boolean;
  handleSave: () => void;
  setModal: (v: 'add' | 'remove' | 'change' | 'unfund' | 'invalid' | null) => void;
}

export const IncentivesOptions: React.FC<Props> = ({
  study,
  canChangeIncentive,
  change,
  setOptions,
  options,
  handleSave,
  setModal
}) => {
  const [incentiveTitle, setIncentiveTitle] = React.useState<string>(study.incentive_title || '');
  const [incentiveAmount, setIncentiveAmount] = React.useState<number>(study.incentive || 0);
  const [incentiveCoupons, setIncentiveCoupons] = React.useState<string>(study.incentive_coupons || '');
  const [incentiveInstructions, setIncentiveInstructions] = React.useState<string>(study.incentive_instructions || '');
  const [incentiveMethod, setIncentiveMethod] = React.useState<Study['incentive_method']>(study.incentive_method || '');
  const debounced = useDebouncedCallback(change, 300);
  const debouncedChange: ChangeFn = debounced.callback;

  const method = incentiveMethod || study.incentive_method;
  const fields = method ? INCENTIVE_FIELDS[method] : [];

  const { items } = useStudyPublishValidator({ study });
  const validator = items.find((item) => item.id === 'plan');
  const validatorError = validator?.failedKeys?.includes('incentives');

  const { hasFeature } = usePlan();
  const hasIncentives = hasFeature('incentives');
  const navigate = useNavigate();

  const incentiveRequiredForRecruit = options.includes('external_candidates');
  const disabledFeatures = useDisabledFeatures();

  const defaultIncentiveMethod = React.useMemo(() => {
    if (disabledFeatures.incentives) {
      return 'manual';
    }

    if (incentiveRequiredForRecruit) {
      return 'tremendous';
    } else {
      return method ?? 'tremendous';
    }
  }, [incentiveRequiredForRecruit, method, disabledFeatures.incentives]);

  useEffect(() => {
    setIncentiveAmount(study.incentive || 0);
  }, [options.length, study.incentive_method]);

  const toggleIncentives = (v: boolean) => {
    if (study.state === 'draft') {
      v ? setOptions([...options, 'incentive']) : setOptions(without(options, 'incentive'));
      return;
    }

    if (!v) {
      setModal(study.incentive_method === 'tremendous' ? 'unfund' : 'remove');
    } else {
      setModal('add');
    }
  };

  const handleIncentiveMethodChange = (value) => {
    if (value === 'money') {
      setIncentiveMethod('manual');
      change?.('incentive_method', 'manual');
    } else {
      setIncentiveMethod(value);
      change?.('incentive_method', value);
    }
  };

  const isMoney = ['tremendous', 'manual'].includes(method || '');

  return (
    <div className='py-6 border-b border-gray-200'>
      <div className='flex justify-between'>
        <label htmlFor='incentives-toggle' className='h500-bold flex items-center'>
          <span className='mr-1'>Incentives</span>
          <Tooltip>
            Once candidates have joined your study incentives cannot be changed in order to ensure a great, consistent
            participant experience.
          </Tooltip>
        </label>

        <TippyOrNot content='Incentives are required to use external recruitment' show={incentiveRequiredForRecruit}>
          <Toggle
            id='incentives-toggle'
            testId='incentives-toggle'
            disabled={!canChangeIncentive || incentiveRequiredForRecruit}
            on={options.includes('incentive')}
            onToggle={toggleIncentives}
            customOnClick={study.state !== 'draft' && canChangeIncentive ? toggleIncentives : undefined}
          />
        </TippyOrNot>
      </div>
      <span className='h400 block mb-2 text-gray-500'>
        Set the incentive that participants will get for joining your research.
      </span>

      {options.includes('incentive') &&
        (study.state !== 'draft' ? (
          <Button className='mt-4' medium onClick={() => navigate(`/studies/${study.id}/incentives`)}>
            Manage incentives
          </Button>
        ) : (
          <>
            <div className='mb-2'>
              {!hasIncentives && study.incentive_method === 'tremendous' && !options.includes('external_candidates') && (
                <Alert type='warning' className='mt-4'>
                  Paying incentives through Great Question is only available on paid accounts.
                  <a className='font-bold text-gray-700 underline' href='/plans'>
                    View plans
                  </a>
                </Alert>
              )}
            </div>
            <div className='flex flex-col'>
              <div className='flex-1 mb-3 space-x-4'>
                <div className='flex-1'>
                  <Select
                    disabled={!canChangeIncentive || incentiveRequiredForRecruit}
                    overflowClass='visible'
                    options={INCENTIVE_OPTIONS}
                    onChange={handleIncentiveMethodChange}
                    value={isMoney ? 'money' : method || ''}
                  />
                </div>
              </div>
              <div className='tablet:flex-1' />
              {fields.includes('amount') && (
                <>
                  <div className='tablet:flex-row tablet:space-y-0 tablet:space-x-4 flex flex-col space-y-3'>
                    <div className='flex-1'>
                      <InputWithDropdown
                        disabled={!canChangeIncentive}
                        amount={incentiveAmount}
                        currency={study.currency}
                        disableCurrency={study.state !== 'draft' || incentiveRequiredForRecruit}
                        setCurrency={(v) => change?.('currency', v)}
                        setAmount={(v) => {
                          setIncentiveAmount(v);
                          debouncedChange?.('incentive', v);
                        }}
                        onEnter={handleSave}
                        onBlur={handleSave}
                        error={validatorError}
                      />
                    </div>
                    <div className='flex-1'>
                      <Select
                        overflowClass='visible'
                        options={INCENTIVE_METHOD_OPTIONS}
                        onChange={(v) => {
                          setIncentiveMethod(v as Study['incentive_method']);
                          change?.('incentive_method', v as Study['incentive_method']);
                        }}
                        disabled={!canChangeIncentive || incentiveRequiredForRecruit || disabledFeatures.incentives}
                        value={defaultIncentiveMethod}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            {fields.includes('title') && (
              <div className='mb-6'>
                <label className='h500-bold block' htmlFor='incentive_title'>
                  Incentive title
                </label>
                <span className='h400 block mb-2 text-gray-500'>This will be displayed to participants.</span>
                <Input
                  disabled={!canChangeIncentive}
                  placeholder={method ? INCENTIVE_TITLE_PLACEHOLDERS[method] : ''}
                  onChange={(v) => {
                    setIncentiveTitle(v);
                    debouncedChange('incentive_title', v);
                  }}
                  value={incentiveTitle}
                  size='2xl'
                  name='incentive_title'
                  error={validator?.state === 'failed'}
                />
              </div>
            )}
            {fields.includes('coupons') && (
              <div className='mb-6'>
                <label className='h500-bold block' htmlFor='incentive_coupons'>
                  Coupon codes
                </label>
                <span className='h400 block mb-2 text-gray-500'>
                  Include 1 code per person to be sent out after participating.
                </span>

                <TextareaAutosize
                  name='incentive_coupons'
                  maxRows={6}
                  disabled={!canChangeIncentive}
                  placeholder='Enter coupons separated by commas or new lines.'
                  onChange={(e) => {
                    setIncentiveCoupons(e.currentTarget.value);
                    debouncedChange('incentive_coupons', e.currentTarget.value);
                  }}
                  className='focus:border-indigo-500 focus:ring-indigo-500 h400 block w-full p-3 placeholder-gray-400 transition duration-150 ease-in-out border-gray-200 rounded-md'
                  value={incentiveCoupons}
                />
              </div>
            )}

            {fields.includes('instructions') && (
              <div>
                <label className='h500-bold block' htmlFor='incentive_instructions'>
                  Redemption instructions
                </label>
                <span className='h400 block mb-2 text-gray-500'>
                  Include details or links that participants will need to redeem their incentive.
                </span>

                <TextareaAutosize
                  disabled={!canChangeIncentive}
                  name='incentive_instructions'
                  maxRows={6}
                  placeholder='Add instructions or links.'
                  onChange={(e) => {
                    setIncentiveInstructions(e.currentTarget.value);
                    debouncedChange('incentive_instructions', e.currentTarget.value);
                  }}
                  className='focus:border-indigo-500 focus:ring-indigo-500 h400 block w-full p-3 placeholder-gray-400 transition duration-150 ease-in-out border-gray-200 rounded-md'
                  value={incentiveInstructions}
                />
              </div>
            )}
            {method === 'tremendous' && (
              <div className='flex items-center mt-2 space-x-2'>
                {Object.values(REWARD_PRODUCT_ICONS).map((src) => (
                  <img alt='' key={src} src={src} width='50' height='30' />
                ))}
                <span className='h400 pl-1 font-normal text-gray-500'>and 859 other ways.</span>
              </div>
            )}
          </>
        ))}
    </div>
  );
};
