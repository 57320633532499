export function getUnmappedColumns({ headers, hidden_columns, mapping }: CustomerImport): number[] {
  const errors: number[] = [];
  for (let i = 0; i < headers.length; i++) {
    if (!mapping[i] && !hidden_columns.includes(headers[i])) {
      errors.push(i);
    }
  }
  return errors;
}

export const isMissingUniqueId = ({
  hidden_columns,
  headers,
  id_attr,
  mapping
}: CustomerImport): string | undefined => {
  const hiddenIndexes: number[] = hidden_columns.map((c) => headers.findIndex((h) => h === c)) || [];
  const uniqueIdIndicies: number[] = new Array(mapping.length)
    .fill(0)
    .map((_, i) => i)
    .filter((i) => mapping[i] === id_attr);
  const uniqueIdsHidden: boolean = hiddenIndexes.length > 0 && uniqueIdIndicies.every((i) => hiddenIndexes.includes(i));

  if (!mapping.includes(id_attr) || uniqueIdsHidden) {
    return id_attr.replace('extra:', '');
  }
};

export const hasMultipleUniqueIds = ({ id_attr, mapping }: CustomerImport) => {
  return id_attr.startsWith('extra:') && mapping.includes('email');
};
