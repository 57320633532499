import * as React from 'react';

import { Text } from '@components/common';
import { GlobeSVG } from '@components/svgs';
import { findCurrentTimeZoneName } from '@components/utils/tz';

export const TimezoneIndicator: React.FC<{ timezone?: jstz.TimeZone }> = ({ timezone }) => (
  <div className='flex items-center mt-2 space-x-2'>
    <GlobeSVG />
    <Text h='400'>{timezone ? timezone.name() : findCurrentTimeZoneName()}</Text>
  </div>
);
