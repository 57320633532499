import * as React from 'react';

import { Button, SlideOut } from '@components/common';

import { useEffect, useState } from 'react';
import { TextEditorWrapper } from '@components/shared/TextEditorWrapper';
import { useGetDocumentQuery } from '@components/shared/Tiptap/api';
import { api } from '@api/reduxApi';
import { createDocument, updateDocument } from '@api/queries';
import { useTiptapFromDocumentId } from '@components/shared/Tiptap';
import { SearchInput } from '@components/GQSurveyBuilder/QuestionBank/components';
import { TemplateCard } from '@components/TemplatesApp/components/TemplateCard';
import { useSearch } from '@hooks/useSearch';
import { usePermission } from '@hooks/usePermission';

interface Props {
  study: Study;
}

export const TemplateSettings: React.FC<Props> = ({ study }) => {
  const [open, setOpen] = useState(false);

  const { data: interviewTemplates = [], isLoading } = api.useGetInterviewTemplatesQuery();
  const [updateStudy] = api.useUpdateStudyMutation();

  const canEdit = usePermission<Study>('updateStudy')(study);

  const [readOnly, setReadOnly] = useState(false);
  const [chosenTemplate, setChosenTemplate] = useState<Pick<InterviewTemplate, 'title' | 'document_id'> | null>();
  const [search, setSearch] = useState('');

  const { results: templates } = useSearch<InterviewTemplate>(interviewTemplates, ['title'], search, [
    interviewTemplates
  ]);

  const { data: templateDocument } = useGetDocumentQuery(chosenTemplate?.document_id as number, {
    skip: !chosenTemplate?.document_id
  });

  const chooseTemplate = async (template: InterviewTemplate) => {
    setOpen(false);
    setReadOnly(true);
    setChosenTemplate(template);
    updateStudy({ ...study, template_study_id: template.id });
  };

  async function customizeTemplate() {
    if (!tiptap.editor) return;

    setReadOnly(false);
    await Promise.allSettled([
      updateStudy({ ...study, template_study_id: null }),
      updateDocument(study.custom_interview_script_document_id || undefined, tiptap.editor.state.doc.toJSON())
    ]);

    if (study.custom_interview_script_document_id) {
      setChosenTemplate({ title: 'Custom script', document_id: study.custom_interview_script_document_id });
    }
  }

  const tiptap = useTiptapFromDocumentId({
    readonly: !canEdit,
    documentId: study?.custom_interview_script_document_id as number,
    localDoc: templateDocument?.doc,
    scrollToTop: false,
    editorClass: 'h-full flex flex-col',
    config: {
      image: { enable: true },
      link: { enable: true },
      highlight: { enable: false },
      artifacts: false,
      templates: false,
      placeholder:
        'Use this space to craft your questions, plan, or just a cheat sheet to use during interviews. Choose a template or start typing...'
    }
  });

  async function newDocument() {
    const [, document] = await createDocument();

    updateStudy({ ...study, custom_interview_script_document_id: document.id });

    if (!study.template_study_id) {
      setChosenTemplate({
        title: 'Choose a template or start typing',
        document_id: document.id
      });
      setReadOnly(false);
    }
  }

  useEffect(() => {
    if (!study.custom_interview_script_document_id) {
      newDocument();
    }

    if (!isLoading && study.template_study_id && interviewTemplates) {
      const template = interviewTemplates.find((t) => t.id === study.template_study_id);

      if (template) {
        chooseTemplate(template);
      }
    }
  }, [isLoading]);
  return (
    <>
      <TextEditorWrapper
        renderButtons={() =>
          canEdit ? (
            <Button medium icon='template' data-testid='templates' onClick={() => setOpen(!open)}>
              Templates
            </Button>
          ) : null
        }
        readOnly={readOnly}
        customizeTemplate={customizeTemplate}
        title='Interview guide'
        subtitle='Craft the questions that you want to ask in your interviews.'
        tiptap={tiptap}
      />
      {open && (
        <SlideOut size='md' nonBlocking onClose={() => setOpen(false)} zIndex='50' title='Interview templates'>
          <SearchInput onChange={setSearch} value={search} />
          <div className='p-6 pt-0 space-y-4 overflow-auto'>
            {templates?.map((t, i) => (
              <TemplateCard<InterviewTemplate>
                allowUpdate={false}
                className='m-3'
                template={t}
                key={i}
                kind='InterviewTemplate'
                useTemplateButton
                onTemplateUse={chooseTemplate}
              />
            ))}
          </div>
        </SlideOut>
      )}
    </>
  );
};
