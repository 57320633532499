import * as React from 'react';
import { useState } from 'react';

import { XSVG } from '@components/GQSurveyBuilder/questions/shared';
import { InfoCircleIcon } from '@components/svgs';

type AlertType = 'info' | 'warning' | 'error' | 'success' | 'promo';

interface Props {
  Icon?: React.FC<any>;
  className?: string;
  position?: 'top' | 'center';
  border?: boolean;
  heading?: string;
  bg?: string;
  type?: AlertType;
  cta?: React.ReactNode;
  noIcon?: boolean;
  hideable?: boolean;
}

const CheckCircleIcon = ({ className = '' }) => {
  return (
    <svg
      className={`stroke-current ${className}`}
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.5 12.1211L7.74583 15.3083C7.83102 15.4356 7.94537 15.5407 8.07936 15.6148C8.21335 15.6889 8.36311 15.73 8.51617 15.7346C8.66924 15.7391 8.82118 15.7071 8.95936 15.6411C9.09753 15.5751 9.21795 15.477 9.31058 15.3551L16.5 6.259'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.6875 10.9991C0.6875 13.7341 1.77399 16.3572 3.70796 18.2911C5.64193 20.2251 8.26496 21.3116 11 21.3116C13.735 21.3116 16.3581 20.2251 18.292 18.2911C20.226 16.3572 21.3125 13.7341 21.3125 10.9991C21.3125 8.26404 20.226 5.64101 18.292 3.70705C16.3581 1.77308 13.735 0.686584 11 0.686584C8.26496 0.686584 5.64193 1.77308 3.70796 3.70705C1.77399 5.64101 0.6875 8.26404 0.6875 10.9991V10.9991Z'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const AlertTriangleIcon = ({ className = '' }) => {
  return (
    <svg
      className={`stroke-current ${className}`}
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11 17.1875C10.932 17.1875 10.8656 17.2077 10.809 17.2454C10.7525 17.2832 10.7084 17.3369 10.6824 17.3997C10.6564 17.4625 10.6496 17.5316 10.6629 17.5983C10.6761 17.665 10.7089 17.7262 10.7569 17.7743C10.805 17.8224 10.8663 17.8551 10.9329 17.8684C10.9996 17.8817 11.0687 17.8749 11.1315 17.8488C11.1944 17.8228 11.248 17.7788 11.2858 17.7222C11.3236 17.6657 11.3437 17.5992 11.3437 17.5312C11.3437 17.4401 11.3075 17.3526 11.2431 17.2882C11.1786 17.2237 11.0912 17.1875 11 17.1875Z'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M11 14.4375V7.5625' stroke='currentColor' strokeWidth='1.375' strokeLinecap='round' />
      <path
        d='M12.4859 1.61333C12.3495 1.33559 12.138 1.10165 11.8753 0.938044C11.6127 0.774437 11.3094 0.687714 11 0.687714C10.6906 0.687714 10.3873 0.774437 10.1247 0.938044C9.86205 1.10165 9.65052 1.33559 9.51409 1.61333L0.830509 19.3032C0.725981 19.5158 0.677367 19.7516 0.689257 19.9882C0.701146 20.2248 0.773146 20.4546 0.898459 20.6556C1.02377 20.8567 1.19827 21.0226 1.40547 21.1375C1.61267 21.2524 1.84574 21.3127 2.08268 21.3125H19.9173C20.1543 21.3127 20.3874 21.2524 20.5946 21.1375C20.8018 21.0226 20.9762 20.8567 21.1016 20.6556C21.2269 20.4546 21.2989 20.2248 21.3108 19.9882C21.3226 19.7516 21.274 19.5158 21.1695 19.3032L12.4859 1.61333Z'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
export const AdvertisingMegaphoneIcon: React.FC<{ className?: string }> = ({ className = '' }) => {
  return (
    <svg
      className={`stroke-current ${className}`}
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.87101 11.7333L3.28168 10.6562L2.32285 8.14091C2.254 7.95948 2.24919 7.75998 2.3092 7.57544C2.36922 7.3909 2.49045 7.23239 2.65285 7.12616L4.90601 5.65491L5.6091 3.05707C5.65989 2.86973 5.77323 2.70539 5.9303 2.59134C6.08737 2.47728 6.27873 2.42038 6.4726 2.43007L9.16026 2.56666L11.2558 0.875407C11.407 0.753456 11.5954 0.686951 11.7897 0.686951C11.984 0.686951 12.1724 0.753456 12.3237 0.875407L14.4192 2.56666L17.1068 2.43282C17.3007 2.42313 17.4921 2.48003 17.6491 2.59409C17.8062 2.70814 17.9195 2.87248 17.9703 3.05982L18.6734 5.65766L20.9266 7.12891C21.0891 7.23502 21.2105 7.39353 21.2705 7.57811C21.3305 7.76269 21.3256 7.96225 21.2566 8.14366L20.2987 10.659L21.2566 13.1734C21.3257 13.355 21.3306 13.5547 21.2706 13.7394C21.2106 13.9241 21.0892 14.0828 20.9266 14.1891L18.6734 15.6557L17.9667 18.2545C17.9162 18.4421 17.803 18.6067 17.6459 18.721C17.4887 18.8353 17.2972 18.8922 17.1032 18.8824L14.4155 18.7486L12.32 20.4362'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.6875 18.1216L2.2055 20.5132'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.08228 7.46808L14.8518 16.5577'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.5485 16.0793L1.90213 20.0356L0.990967 18.6001L9.3858 7.94659L14.5485 16.0793Z'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.63464 19.1803L5.43764 20.4142C5.57042 20.6318 5.74628 20.8201 5.95444 20.9673C6.16259 21.1146 6.39865 21.2177 6.64811 21.2704C6.89757 21.3231 7.15517 21.3243 7.4051 21.2738C7.65502 21.2234 7.892 21.1224 8.10148 20.977C8.52973 20.6688 8.82398 20.2086 8.92401 19.6905C9.02404 19.1725 8.92228 18.6358 8.63956 18.1903L8.49014 17.974'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const COLORS: Record<AlertType, string> = {
  info: 'bg-gray-50',
  warning: 'bg-yellow-50',
  error: 'bg-red-50',
  success: 'bg-green-50',
  promo: 'bg-indigo-50'
};

const ACCENT_COLORS: Record<AlertType, string> = {
  info: 'gray-700',
  warning: 'yellow-600',
  error: 'red-600',
  success: 'green-600',
  promo: 'indigo-600'
};

const ICONS: Record<AlertType, React.FC<{ className?: string }>> = {
  info: InfoCircleIcon,
  warning: AlertTriangleIcon,
  error: AlertTriangleIcon,
  success: CheckCircleIcon,
  promo: AdvertisingMegaphoneIcon
};

export const AlertHeading: React.FC = ({ children }) => <h3 className='h500-bold pb-2'>{children}</h3>;
export const AlertMessage: React.FC = ({ children }) => <div className='h400'>{children}</div>;
export const AlertLink: React.FC<{ href: string }> = ({ href, children }) => (
  <a className='ml-2 font-bold text-gray-700 underline' href={href}>
    {children}
  </a>
);

const POSITIONS = {
  center: 'center',
  top: 'start',
  bottom: 'end'
};

export const Alert: React.FC<Props> = ({
  Icon: customIcon,
  noIcon = false,
  children,
  heading,
  cta,
  bg = '',
  border = false,
  position = 'center',
  type = 'info',
  className = '',
  hideable = false
}) => {
  const [hidden, setHidden] = useState<boolean>(false);

  const Icon = customIcon || ICONS[type];
  const accentColor = ACCENT_COLORS[type];
  const color = COLORS[type];

  if (hidden) {
    return null;
  }

  return (
    <div className={`rounded ${className} ${bg ? `bg-${bg}` : color} flex border-l-4 border-${accentColor} relative`}>
      {hideable && <XSVG onClick={() => setHidden(true)} className='top-2 right-2 absolute' />}
      <div className={border ? 'w-full rounded p-4 border-t border-b border-r border-gray-200' : 'p-4 w-full'}>
        <div className={`flex items-${POSITIONS[position]} w-full`}>
          {!noIcon && (
            <div className='flex items-center flex-shrink-0'>
              <Icon className={`text-${accentColor}` + ' w-6 h-6'} />
            </div>
          )}
          <div className='h400 flex flex-col items-start justify-between flex-1 ml-4'>
            {heading ? (
              <>
                <AlertHeading>{heading}</AlertHeading>
                <AlertMessage>{children}</AlertMessage>
              </>
            ) : (
              children
            )}
          </div>
          <div className='flex-0'>{cta || ''}</div>
        </div>
      </div>
    </div>
  );
};
