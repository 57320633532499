import { useToaster } from 'components/stores/toaster';
import pluralize from 'pluralize';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';

import { api } from '@api/reduxApi';
import { Alert, Button, Loading } from '@components/common';
import { DropdownItem } from '@components/common/DropdownCombobox';
import SearchSelector from '@components/shared/SearchSelector';
import { skipToken } from '@reduxjs/toolkit/dist/query';

import { FilterQuery } from './types';

interface Props {
  teamId?: number | null;
  studies: Study[];
  selectedIds: number[];
  onFinished: (backgroundTask: BackgroundTask) => void;
  onClose: () => void;
  allSelected?: boolean;
  selectedCount?: number;
  query?: FilterQuery;
}

const AllCandidatesWarning = () => (
  <Alert className='mt-6' type='warning'>
    All selected candidates have already been added to this study.
  </Alert>
);

export const studiesToOptions = (studies: Study[]): DropdownItem[] => {
  const isActiveFn = (study: Study): boolean => ['pending', 'active'].includes(study.state);

  return studies
    .filter((s) => s.style !== 'panel' && isActiveFn(s))
    .map((study) => {
      const { title, id } = study;
      return {
        label: title,
        value: id.toString()
      };
    });
};

const ShortlistToStudy: React.FC<Props> = ({
  teamId,
  selectedIds,
  studies,
  allSelected,
  selectedCount,
  query,
  onClose,
  onFinished
}) => {
  const [selectedStudyId, setSelectedStudyId] = useState<number | undefined>();

  const {
    data: study,
    isLoading: studyLoading,
    isError: studyError
  } = api.useGetStudyQuery(selectedStudyId ?? skipToken);

  const [count, setCount] = useState<number>(0);

  const candidateParams = allSelected ? { query } : { ids: selectedIds };

  const [bulkShortlist, { isLoading: shortlistLoading }] = api.useBulkShortlistMutation();
  const [shortlistIds, { isSuccess }] = api.useGetShortlistSlideOutCountsMutation();
  const { data: canAccess } = api.useCanAccessCandidatesQuery(
    { candidates: candidateParams, team_id: study?.team_id },
    {
      skip: !study
    }
  );
  const wantToShortlistCount = allSelected ? selectedCount : selectedIds.length;
  const showToast = useToaster();

  useEffect(() => {
    if (studyError) {
      showToast({
        heading: 'Something went wrong!',
        text: 'Please try again later.',
        icon: 'error'
      });
    }
  }, [studyError]);

  const handleStudySelect = async (value: string): Promise<void> => {
    setSelectedStudyId(parseInt(value));
  };

  async function onSubmit(e) {
    if (selectedIds && study) {
      e.preventDefault();

      const resp = await bulkShortlist({
        studyId: study.id,
        ...(allSelected ? { query } : { customer_ids: selectedIds })
      }).unwrap();

      if (resp) {
        const addedCount = resp.count;
        const selectedCount = selectedIds.length;
        const text = `${
          addedCount === 0 ? 'No Candidates' : pluralize('Candidates', addedCount, true)
        } will be added to study. This might take a while.`;
        showToast({
          icon: 'success',
          heading: 'Successfully added!',
          text: text,
          action: {
            text: 'View study',
            href: `/studies/${study.id}`
          }
        });
        onFinished(resp.background_task);
      }
    }
  }

  useEffect(() => {
    if (!study) {
      setCount(0);
      return;
    }

    shortlistIds({
      studyId: study.id,
      ...(allSelected ? { query } : { candidateIds: selectedIds })
    })
      .unwrap()
      .then((data) => {
        setCount(data.shortlistable_count);
      });

    setCount(0);
  }, [study, wantToShortlistCount]);

  const studyOptions = studiesToOptions(studies.filter((s) => !teamId || s.team_id === teamId));

  const loading = shortlistLoading || studyLoading;

  return (
    <div>
      {loading && <Loading />}
      <p className='mb-2 text-sm text-gray-700'>Candidates will be added to the study, but not contacted yet.</p>
      <form onSubmit={onSubmit}>
        {selectedIds && canAccess !== false && (
          <Alert>
            <span>{pluralize('Candidates', wantToShortlistCount, true)} selected</span>
          </Alert>
        )}
        {canAccess === false && (
          <Alert type='error'>
            Your account settings prevent you from shortlisting some of the selected candidates that belong to a
            different team.
          </Alert>
        )}
        <div className='form-group mt-4'>
          <SearchSelector
            inputClassName='xx-study-dropdown'
            defaultSelected={study?.id.toString()}
            options={studyOptions}
            onSelect={handleStudySelect}
          />
        </div>
        {study && count === 0 && isSuccess && <AllCandidatesWarning />}
        {study && count > 0 && (
          <span>
            Shortlisting{' '}
            <span className='font-semibold'>
              {count}
              {wantToShortlistCount !== count ? '/' + wantToShortlistCount : ''}
            </span>{' '}
            {pluralize('selected candidates', count)}.
          </span>
        )}
        <div className='form-group flex justify-between mt-3'>
          <Button disabled={!study || count <= 0 || !canAccess} primary>
            Shortlist
          </Button>
        </div>
      </form>
    </div>
  );
};
export default ShortlistToStudy;
