import cn from 'classnames';
import { Button, ButtonProps } from 'components/common/Button';
import React from 'react';

interface Props {
  buttonProps?: ButtonProps;
  enabled?: boolean;
  className?: string;
}

export const ButtonHoverOverlay: React.FC<Props> = ({ enabled, buttonProps, className, children }) => (
  <div className='group relative w-full flex-1'>
    {enabled && (
      <div className={cn('absolute inset-0', className)}>
        <div className='group-hover:block absolute inset-0 hidden bg-black opacity-25'></div>
        <div className='absolute inset-0 flex items-center justify-center'>
          <Button {...buttonProps} className='group-hover:flex hidden' />
        </div>
      </div>
    )}
    {children}
  </div>
);
