import { createPortal } from 'react-dom';

export const Portal = ({ id, children }) => {
  const node = document.getElementById(id);

  if (!node) {
    return null;
  }

  return createPortal(children, node);
};
