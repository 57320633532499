import React, { SVGProps } from 'react';

export const StarSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M7.42526 0.699959L9.37709 4.56688L13.1338 4.93904C13.2232 4.94647 13.3086 4.97938 13.3799 5.03385C13.4512 5.08833 13.5055 5.1621 13.5361 5.24643C13.5668 5.33076 13.5727 5.42212 13.5531 5.50969C13.5335 5.59726 13.4892 5.67737 13.4254 5.74054L10.3338 8.80479L11.48 12.9686C11.5035 13.0569 11.5009 13.1502 11.4726 13.2371C11.4444 13.3239 11.3916 13.4008 11.3207 13.4584C11.2497 13.5161 11.1637 13.552 11.0728 13.5618C10.982 13.5717 10.8902 13.5551 10.8086 13.514L7.00001 11.6281L3.19667 13.5117C3.11503 13.5528 3.02327 13.5694 2.93243 13.5595C2.84158 13.5496 2.75552 13.5137 2.6846 13.4561C2.61367 13.3985 2.5609 13.3216 2.53263 13.2347C2.50436 13.1478 2.5018 13.0546 2.52526 12.9663L3.67151 8.80246L0.577505 5.73821C0.513766 5.67504 0.469462 5.59493 0.44984 5.50736C0.430217 5.41979 0.436098 5.32843 0.466786 5.2441C0.497474 5.15977 0.551684 5.086 0.622997 5.03152C0.69431 4.97704 0.77974 4.94414 0.869172 4.93671L4.62584 4.56454L6.57475 0.699959C6.61484 0.621676 6.67575 0.555975 6.75078 0.510097C6.82582 0.464218 6.91206 0.439941 7.00001 0.439941C7.08795 0.439941 7.17419 0.464218 7.24923 0.510097C7.32426 0.555975 7.38517 0.621676 7.42526 0.699959V0.699959Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
