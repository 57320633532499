import { CandidateSlideOut } from '@components/CandidatesApp/CandidatesIndex/types';
import { StudySlideOut } from '@components/StudiesApp/types';
import * as React from 'react';
import { useState } from 'react';
import { useAccount } from '@hooks/useAccount';
import { GenerateCandidatesModal } from '@components/Sample/GenerateCandidatesModal';
import { Button, DropdownLink, Popper } from '@components/common';
import { AddPeopleSVG, ExternalCandidatesSVG, ImportSVG, LinkSVG } from '@components/svgs';
import { RestrictedButton } from '@components/shared/RestrictedButton';
import { useDisabledFeatures } from '@hooks/useDisabledFeatures';
import { useGetEnabledIntegrationsQuery } from './api';
import { useCreateIntegrationJobMutation } from '../../shared/IntegrationStatus/api';
import { humanize } from '@components/utils';

interface Props {
  teamId?: number | null;
  setSlideOut: (slideOut: CandidateSlideOut | StudySlideOut) => void;
  component: string;
  openPanelStudyModal?: () => void;
}

export const AddCustomers: React.FC<Props> = ({ teamId, component, setSlideOut, openPanelStudyModal }) => {
  const [showSample, setShowSample] = useState<boolean>();
  const { data: enabledIntegrations } = useGetEnabledIntegrationsQuery();
  const [createJob] = useCreateIntegrationJobMutation();

  const {
    account: { sandbox }
  } = useAccount();

  const disabledFeatures = useDisabledFeatures();

  return (
    <>
      {showSample && <GenerateCandidatesModal onClose={() => setShowSample(false)} />}
      <Popper
        zIndex={50}
        closeOnClickOutside
        closeOnEscape
        placement='bottom-start'
        offset={[0, 8]}
        content={({ closePopper }) => (
          <div
            className='ring-1 ring-black ring-opacity-5 w-60 py-2 bg-white border border-gray-200 rounded-md shadow-lg'
            role='menu'
            aria-orientation='vertical'
            aria-labelledby='options-menu'
          >
            {sandbox && (
              <DropdownLink
                trackEvent='clicked_add_candidates_dropdown'
                trackProps={{ page: 'candidates', component, link: 'Generate demo data' }}
                onClick={() => {
                  setShowSample(true);
                  closePopper();
                }}
                className='xx-add-manual text-indigo-600'
              >
                <AddPeopleSVG className='inline mr-2' />
                Generate demo data
              </DropdownLink>
            )}
            <DropdownLink
              aria-label='Add manually'
              trackEvent='clicked_add_candidates_dropdown'
              trackProps={{ page: 'candidates', component, link: 'Add manually' }}
              onClick={() => {
                setSlideOut('AddCandidate');
                closePopper();
              }}
              className='xx-add-manual'
            >
              <AddPeopleSVG className='inline mr-2' />
              Add manually
            </DropdownLink>
            <DropdownLink
              aria-label='Invite via link'
              trackEvent='clicked_add_candidates_dropdown'
              trackProps={{ page: 'candidates', component, link: 'Invite via link' }}
              onClick={() => {
                openPanelStudyModal?.();
                closePopper();
              }}
            >
              <LinkSVG className='inline mr-2' />
              Invite via link
            </DropdownLink>
            <DropdownLink
              aria-label='Import from CSV'
              trackEvent='clicked_add_candidates_dropdown'
              trackProps={{ page: 'candidates', component, link: 'Import from CSV' }}
              spa
              onClick={() => {
                closePopper();
              }}
              href={`/customer_imports/new${teamId ? `?team_id=${teamId}` : ''}`}
            >
              <ImportSVG className='inline mr-2' />
              Import from CSV
            </DropdownLink>
            {enabledIntegrations &&
              enabledIntegrations.map((integration) => (
                <DropdownLink
                  key={integration}
                  aria-label={`Import from ${humanize(integration)}`}
                  trackEvent='clicked_add_candidates_dropdown'
                  trackProps={{ page: 'candidates', component, link: `Import from ${humanize(integration)}` }}
                  onClick={() => {
                    createJob({ provider: integration, job: 'pull' });
                    closePopper();
                  }}
                >
                  <ImportSVG className='inline mr-2' />
                  Import from {humanize(integration)}
                </DropdownLink>
              ))}
            {!disabledFeatures.external_recruitment && (
              <RestrictedButton limit='studies' permission='createStudy' action='New study'>
                <DropdownLink
                  aria-label='Recruit external candidates'
                  trackEvent='clicked_add_candidates_dropdown'
                  trackProps={{ page: 'candidates', component, link: 'Recruit external candidates' }}
                  onClick={() => {
                    setSlideOut('NewRecruitingStudySlideout');
                    closePopper();
                  }}
                >
                  <ExternalCandidatesSVG className='inline mr-2' />
                  Recruit external candidates
                </DropdownLink>
              </RestrictedButton>
            )}
          </div>
        )}
      >
        <Button aria-label='Add Candidates' medium primary iconSuffix='caretDown' className='w-auto'>
          Add Candidates
        </Button>
      </Popper>
    </>
  );
};
