import { compact } from 'components/utils';
import { useEffect, useMemo, useState } from 'react';

import { getAllUserCalendars } from '@api/queries';
import { api } from '@api/reduxApi';
import { useAccount } from '@hooks/useAccount';

interface State {
  loading: boolean;
  error?: boolean;
  calendars: { [userId: number]: NylasCalendar[] };
}

interface Params {
  studyId: number;
  ownerId: number;
  isRoundRobin: boolean;
}
interface Hook {
  loading: boolean;
  team: GQAccount['team'];
  allStudyUsers: { teamUser: TeamUser; studyUser: StudyUser }[] | null;
  calendarState: State;
  fetchStudyUsers: () => void;
}

export const useStudyUsers = (params: Params): Hook => {
  const [error, setError] = useState(false);
  const [calendars, setCalendars] = useState<State['calendars']>({});

  const {
    data: studyUsers,
    refetch: refetchStudyUsers,
    isLoading: loading
  } = api.useGetStudyUsersQuery(params.studyId);

  const calendarState = useMemo<State>(
    () => ({
      loading,
      error,
      calendars
    }),
    [loading, error, calendars]
  );

  const {
    account: { team }
  } = useAccount();

  useEffect(() => {
    getAllUserCalendars()
      .then((data) => {
        if (data) {
          setCalendars(data);
        }
      })
      .catch(() => {
        setError(true);
      });
  }, []);

  const getAllStudyUsers = (studyUsers) => {
    const mapped = studyUsers.map((studyUser) => {
      const teamUser = team.find((u) => u.id === studyUser.user_id);

      if (!teamUser) return false;

      return { teamUser, studyUser };
    });

    return (compact(mapped) || []) as Hook['allStudyUsers'];
  };

  const allStudyUsers = useMemo(() => {
    return studyUsers ? getAllStudyUsers(studyUsers) : null;
  }, [studyUsers, team]);

  return {
    loading,
    fetchStudyUsers: refetchStudyUsers as () => void,
    team: params.isRoundRobin ? team : team.filter((u) => u.id !== params.ownerId),
    allStudyUsers,
    calendarState
  };
};

export { Hook as UseStudyUsersHook, Params as UseStudyUsersParams };
