import { createContext, useContext } from 'react';
import * as React from 'react';
import { CalendarSettings } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/GQCalendar';

interface Context {
  studyUserColors: Record<number, UserColor | undefined>;
  calSettings?: CalendarSettings | null;
  setCalSettings?: (v: CalendarSettings | null) => void;
}

export const GQCalendarContext = createContext<Context>({} as Context);

export const useGQCalendarContext = () => {
  const context = useContext<Context>(GQCalendarContext);

  if (context === undefined) throw new Error();

  return context;
};

export const GQCalendarProvider: React.FC<{
  value: Context;
}> = ({ children, value }) => {
  return <GQCalendarContext.Provider value={value}>{children}</GQCalendarContext.Provider>;
};
