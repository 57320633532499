import cn from 'classnames';
import { Pill } from 'components/common';
import { format } from 'date-fns';
import { useDayzed } from 'dayzed';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ChevronLeftSVG, ChevronRightSVG } from '@components/svgs';

import { DateButton } from './DateButton';

const weekdayNamesShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export type CalendarProps = {
  handleDateSelected: any;
  handleOffsetChanged: any;
  selectedDate: Date;
  timeslots?: Timeslots;
  calendarOffset: number;
  wrapperRef: React.MutableRefObject<HTMLDivElement | null>;
  setWrapperHeight: any;
  nextMonthCount: number;
};

export const Calendar: React.FC<CalendarProps> = ({
  handleDateSelected,
  handleOffsetChanged,
  selectedDate,
  timeslots,
  calendarOffset,
  wrapperRef,
  setWrapperHeight,
  nextMonthCount,
}) => {
  const { t } = useTranslation('Calendar');
  const todayKey = format(new Date(), 'yyyy-MM-dd');
  const { calendars, getBackProps, getForwardProps, getDateProps } = useDayzed({
    selected: selectedDate,
    onDateSelected: handleDateSelected,
    offset: calendarOffset,
    onOffsetChanged: handleOffsetChanged,
    showOutsideDays: true
  });

  useEffect(() => {
    if (calendars.length && wrapperRef.current) {
      setWrapperHeight(wrapperRef.current.offsetHeight);
    }
  }, [calendars, wrapperRef]);

  return (
    <>
      {calendars.map((calendar) => (
        <div key={`${calendar.month}${calendar.year}`}>
          <h4 className='mb-6'>{t('title')}</h4>
          <div className='flex items-center'>
            <button
              type='button'
              className='focus:relative focus:outline-none tablet:w-9 tablet:px-2 tablet:hover:bg-gray-50 flex items-center justify-center py-2 pl-3 pr-4 text-black bg-white'
              {...getBackProps({ calendars })}
            >
              <span className='sr-only'>Previous month</span>
              <ChevronLeftSVG className='w-3 h-3' aria-hidden='true' viewBox='0 0 20 20' />
            </button>
            <span className='flex items-center justify-center flex-auto font-semibold text-center text-gray-900'>
              <span className={cn({ 'ml-auto': nextMonthCount > 0 })}>
                {t(`months.${calendar.month}`)} {calendar.year}
              </span>
              {nextMonthCount > 0 && (
                <Pill color='green' className='ml-auto'>
                  <span className='sr-only'>
                    {nextMonthCount} timeslots in {t(`months.${calendar.month + 1}`)}
                  </span>
                  +{nextMonthCount}
                </Pill>
              )}
            </span>
            <button
              type='button'
              className='focus:relative focus:outline-none tablet:w-9 tablet:px-2 tablet:hover:bg-gray-50 flex items-center justify-center py-2 pl-4 pr-3 text-black bg-white'
              {...getForwardProps({ calendars })}
            >
              <span className='sr-only'>Next month</span>
              <ChevronRightSVG className='w-3 h-3' aria-hidden='true' viewBox='0 0 20 20' />
            </button>
          </div>

          <div key={`${calendar.month}${calendar.year}`}>
            <div className='grid grid-cols-7 mt-4 text-xs leading-6 text-center text-gray-500'>
              {weekdayNamesShort.map((weekday) => (
                <div key={`${calendar.month}${calendar.year}${weekday}`}>{t(`monthsShort.${weekday}`)}</div>
              ))}
            </div>
            <div className='grid grid-cols-7 mt-2 text-sm'>
              {calendar.weeks.map((week, weekIndex) =>
                week.map((dateObj, index) => {
                  const key = `${calendar.month}${calendar.year}${weekIndex}${index}`;
                  return (
                    <DateButton
                      key={key}
                      todayKey={todayKey}
                      dateObj={dateObj}
                      timeslots={timeslots}
                      weekIndex={weekIndex}
                      getDateProps={getDateProps}
                    />
                  );
                })
              )}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
