import cn from 'classnames';
import { useLocalStorage } from 'hooks/useLocalStorage';
import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { BetaFlag } from '@components/common';
import { StudyTab, StudyTabKey } from '@components/StudiesApp/components/StudyPublished/helpers/buildTabs';
import { AddToInsightSVG, CalendarSVG, CandidatesSVG, ChevronRightSVG, DataSVG, EmailSVG, ExternalCandidatesSVG, HideTabsSVG, IncentivesSVG, InterviewGuideSVG, LightBulbSVG, LightningSVG, MultipleActionsSVG, OnlineTaskSVG, OverviewSVG, PagesSVG, PlanSVG, ScreenerResultsSVG, ScreenerSVG, ShowTabsSVG, StatsSVG, SurveysSVG, SynthesisSVG, TagsSVG } from '@components/svgs';
import { BellSVG } from '@components/svgs/BellSVG';
import Tippy from '@tippyjs/react';

interface Props {
  current: StudyTabKey;
  tabs: StudyTab[];
  onSelect?: (tab: string) => void;
  onClick?: (tab: string) => void;
  labels: Record<StudyTabKey, string>;
  customTabPaddings?: string;
  withRepo?: boolean;
}

const TAB_ICONS: Record<StudyTabKey, React.FC> = {
  participants: CandidatesSVG,
  recruitment_requests: ExternalCandidatesSVG,
  interviews: MultipleActionsSVG,
  stats: StatsSVG,
  plan: PlanSVG,
  calendar: CalendarSVG,
  task: OnlineTaskSVG,
  survey: SurveysSVG,
  test: AddToInsightSVG,
  screener: ScreenerSVG,
  incentives: IncentivesSVG,
  emails: EmailSVG,
  pages: PagesSVG,
  report: AddToInsightSVG,
  overview: OverviewSVG,
  repository: DataSVG,
  insights: LightBulbSVG,
  tags: TagsSVG,
  synthesis: SynthesisSVG,
  automation: LightningSVG,
  notifications: BellSVG,
  interview_guide: InterviewGuideSVG,
  screener_responses: ScreenerResultsSVG
};

type TabLinkProps = {
  to: string;
  isActive: boolean;
  name: string;
  icon: React.FC<{ className?: string }>;
  isBeta?: boolean;
  label: string;
  disabled?: boolean;
  disabledReason?: string;
};

interface TabSections {
  setup?: boolean;
  execution?: boolean;
  results?: boolean;
}

const TabLink: React.FC<TabLinkProps> = (props) => {
  if (props.disabled) {
    return <DisabledTabLink {...props} />;
  } else {
    const { label, to, isActive, icon: Icon, isBeta } = props;

    return (
      <Link
        to={to}
        role='tab'
        className={cn(
          { 'bg-indigo-50 text-indigo-600': isActive },
          'flex space-x-3 items-center text-gray-700 w-full focus:outline-none focus:text-indigo-600 hover:text-indigo-600 group px-4 py-1.5 text-sm font-medium relative'
        )}
      >
        <Icon className='w-3.5 h-3.5' />
        <span>{label}</span>
        {isBeta && <BetaFlag />}
      </Link>
    );
  }
};

const DisabledTabLink: React.FC<TabLinkProps> = ({ label, icon: Icon, disabledReason, isBeta }) => (
  <Tippy content={disabledReason}>
    <div className='flex space-x-3 items-center text-gray-700 w-full focus:outline-none group px-4 py-1.5 text-sm font-medium relative opacity-50 cursor-not-allowed'>
      <Icon className='w-3.5 h-3.5' />
      <span>{label}</span>
      {isBeta && <BetaFlag />}
    </div>
  </Tippy>
);

export const StudyTabs = (props: Props): React.ReactElement => {
  const { current, tabs, labels } = props;

  const tabsToBeShown = tabs.filter((tab) => tab.disabled !== true || tab.disabled_reason);

  const executionTabs = tabsToBeShown.filter((tab) =>
    [
      'participants',
      'screener_responses',
      'recruitment_requests',
      'stats',
      'interviews',
      'interview_guide',
      'reports'
    ].includes(tab.key)
  );

  const resultsTabs = tabsToBeShown.filter((tab) => ['tags', 'synthesis', 'repository'].includes(tab.key));

  const setupTabs = tabsToBeShown.filter((tab) =>
    ['plan', 'incentives', 'screener', 'survey', 'test', 'task', 'calendar', 'emails', 'pages'].includes(tab.key)
  );

  const [showTabs, setShowTabs] = useState(true);
  const [hiddenTabs, setHiddenTabs] = useLocalStorage<TabSections>('study-hidden-tabs');

  if (!showTabs) {
    return (
      <button
        className='tablet:absolute desktop:top-3 tablet:top-2 tablet:left-2 desktop:left-6 tablet:m-0 tablet:self-start focus:outline-none hover:text-indigo-600 z-10 self-end mt-3 ml-6 mr-4'
        onClick={() => setShowTabs(true)}
      >
        <ShowTabsSVG />
      </button>
    );
  }

  return (
    <div className='tablet:w-60 tablet:flex-shrink-0 flex flex-col w-full h-screen-with-fixed-header bg-white overflow-y-auto border-r border-gray-200'>
      <div className='relative flex items-center justify-between flex-shrink-0'>
        <nav className='flex flex-col items-start flex-1 pb-3'>
          <button
            className='tablet:mr-2 focus:outline-none hover:text-indigo-600 self-end mt-3 mb-1 mr-4'
            onClick={() => setShowTabs(false)}
          >
            <HideTabsSVG />
          </button>
          <TabLink
            label={labels['overview']}
            to='overview'
            icon={TAB_ICONS.overview}
            name='Overview'
            isActive={current === 'overview'}
          />
          {!!setupTabs.length && (
            <>
              <button
                aria-label='Setup'
                className='focus:outline-none no-outline w-full px-4 py-1 mt-4 text-xs font-bold text-left text-gray-500'
                onClick={() => setHiddenTabs({ ...hiddenTabs, setup: !hiddenTabs?.setup })}
              >
                Setup
                <ChevronRightSVG
                  className={cn('inline-block ml-2 h-2.5 w-2.5 transform transition ease-in-out duration-200', {
                    ['rotate-90']: !hiddenTabs?.setup
                  })}
                />
              </button>

              {!hiddenTabs?.setup &&
                setupTabs.map((tab) => (
                  <TabLink
                    label={labels[tab.key]}
                    key={tab.key}
                    to={tab.key}
                    icon={TAB_ICONS[tab.key]}
                    name={tab.key}
                    isActive={current === tab.key}
                    disabled={tab.disabled}
                    disabledReason={tab.disabled_reason}
                  />
                ))}
            </>
          )}

          {!!executionTabs.length && (
            <>
              <button
                aria-label='Execution'
                className='focus:outline-none no-outline w-full px-4 py-1 mt-4 text-xs font-bold text-left text-gray-500'
                onClick={() => setHiddenTabs({ ...hiddenTabs, execution: !hiddenTabs?.execution })}
              >
                Execution
                <ChevronRightSVG
                  className={cn('inline-block ml-2 h-2.5 w-2.5 transform transition ease-in-out duration-200', {
                    ['rotate-90']: !hiddenTabs?.execution
                  })}
                />
              </button>
              {!hiddenTabs?.execution &&
                executionTabs.map((tab) => (
                  <TabLink
                    label={labels[tab.key]}
                    key={tab.key}
                    to={tab.key}
                    icon={TAB_ICONS[tab.key]}
                    name={tab.key}
                    disabled={tab.disabled}
                    disabledReason={tab.disabled_reason}
                    isActive={current === tab.key}
                  />
                ))}
            </>
          )}
          {!!resultsTabs.length && (
            <>
              <button
                aria-label='Results'
                className='focus:outline-none no-outline w-full px-4 py-1 mt-4 text-xs font-bold text-left text-gray-500'
                onClick={() => setHiddenTabs({ ...hiddenTabs, results: !hiddenTabs?.results })}
              >
                Results
                <ChevronRightSVG
                  className={cn('inline-block ml-2 h-2.5 w-2.5 transform transition ease-in-out duration-200', {
                    ['rotate-90']: !hiddenTabs?.results
                  })}
                />
              </button>
              {!hiddenTabs?.results &&
                resultsTabs.map((tab) => (
                  <TabLink
                    label={labels[tab.key]}
                    key={tab.key}
                    to={tab.key}
                    icon={TAB_ICONS[tab.key]}
                    name={tab.key}
                    disabled={tab.disabled}
                    disabledReason={tab.disabled_reason}
                    isActive={current === tab.key}
                  />
                ))}
            </>
          )}

          <div className='self-stretch m-4 border-b border-gray-200' />
          <TabLink
            label={labels['automation']}
            to='automation'
            icon={TAB_ICONS['automation']}
            name='automations'
            isActive={current === 'automation'}
          />
          <TabLink
            label={labels['notifications']}
            to='notifications'
            icon={TAB_ICONS['notifications']}
            name='notifications'
            isActive={current === 'notifications'}
          />
        </nav>
      </div>
    </div>
  );
};
