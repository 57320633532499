import React, { ChangeEventHandler, forwardRef, HTMLAttributes, useContext, useMemo, useState } from 'react';
import { Count } from 'components/RepositoryApp/hits/artifacts/Count';
import pluralize from 'pluralize';

import { api } from '@api/reduxApi';
import { Text, Button } from '@components/common';
import { buildRepoFilterDefs } from '@components/RepositoryApp/filters/buildRepoFilterDefs';
import { useAlgoliaFilters } from '@components/RepositoryApp/hooks/useAlgoliaFilters';
import { TYPE_FILTERS } from '@components/RepositoryApp/constants';
import { ArtifactHit } from '@components/RepositoryApp/types';
import { algoliaProxyAdapter, AlgoliaProxyConfig, AlgoliaData, Search, useSearch } from '@components/shared/Search';
import { SearchInput } from '@components/shared/SearchInput';
import { AnyAllToggle, SegmentActions, TableFilters, useTableFilters } from '@components/shared/TableFilters';
import { CloseSVG } from '@components/svgs';

import { useHighlightReelsContext } from '../../../hooks/useHighlightReelsContext';
import { Clip } from './Clip';
import { PreviewContentSlideout } from '@components/RepositoryApp/components/PreviewContentSlideout';

type HitsProps = {
  selectedHits: string[];
  setSelectedHits: (hits: string[]) => void;
};

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  studyId?: number | null;
}

const Hits = ({ selectedHits, setSelectedHits }: HitsProps) => {
  const { artifactIds } = useHighlightReelsContext();
  const { data } = useSearch<AlgoliaData>();
  const { hits = [] } = data ?? {};

  return (
    <>
      {hits.map(
        (hit) =>
          !artifactIds.includes(hit.objectID) && (
            <Clip
              key={hit.objectID}
              hit={hit as ArtifactHit}
              selectedHits={selectedHits}
              setSelectedHits={setSelectedHits}
              className='mb-6'
            />
          )
      )}
    </>
  );
};

const SelectAllCheckbox = ({ selectedHits, setSelectedHits }: HitsProps) => {
  const { artifactIds } = useHighlightReelsContext();
  const { data } = useSearch<AlgoliaData>();
  const { hits = [] } = data ?? {};
  const availableHitIds = hits.map((hit) => hit.objectID).filter((hit) => !artifactIds.includes(hit));

  const onCheckboxChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.target.checked ? setSelectedHits(availableHitIds) : setSelectedHits([]);
  };

  if (!availableHitIds.length) return null;

  return (
    <input
      type='checkbox'
      className='mr-4'
      onChange={onCheckboxChange}
      checked={selectedHits.length === availableHitIds.length}
    />
  );
};

export const Clips = forwardRef<HTMLDivElement, Props>(({ studyId, ...rest }, ref) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedHits, setSelectedHits] = useState<string[]>([]);

  const { reelPreviewProps, clipForDetails, setClipForDetails, setShowClips, setIsDirty, artifactIds, setArtifactIds } =
    useHighlightReelsContext();

  const definitions = useMemo(() => {
    return buildRepoFilterDefs(studyId).filter((f) => f.id !== 'artifact_type');
  }, [studyId]);

  const filtersHook = useTableFilters<ArtifactHit>({
    definitions,
    trackKey: 'highlight_reel_clips'
  });

  const algoliaFilters = useAlgoliaFilters(filtersHook, {
    extraFilter: studyId
      ? `${TYPE_FILTERS.highlights} AND study_ids:${studyId} AND has_video:true`
      : `${TYPE_FILTERS.highlights} AND has_video:true`
  });

  const sortIndex = 'created_at';
  const [fetch] = api.useLazyGetRepoArtifactsQuery();
  const algoliaProxyConfig = useMemo<AlgoliaProxyConfig>(
    () => ({
      fetch,
      sortBy: sortIndex,
      hitsPerPage: 100
    }),
    [fetch, sortIndex]
  );

  const getCount = (n: number) => (n === 1 ? n : Math.max(0, n - artifactIds.length));

  const onAddToHighlightReel = () => {
    setArtifactIds([...artifactIds, ...selectedHits]);
    setSelectedHits([]);
    setIsDirty?.(true);
  };

  return (
    <section ref={ref} {...rest}>
      <Search<AlgoliaData, AlgoliaProxyConfig>
        query={searchValue}
        filters={algoliaFilters}
        adapter={algoliaProxyAdapter}
        config={algoliaProxyConfig}
        infiniteResults
      >
        {({ setData }) => {
          return (
            <>
              {clipForDetails && (
                <PreviewContentSlideout
                  setData={setData}
                  reelPreviewProps={reelPreviewProps}
                  artifact={clipForDetails}
                  closeSlideout={() => setClipForDetails?.(null)}
                />
              )}
              <div className='p-6 pb-4 border-b border-gray-200'>
                <div className='flex items-center justify-between mb-4'>
                  <Text bold>Available highlights</Text>
                  <button
                    className='focus:outline-none'
                    onClick={() => setShowClips?.(false)}
                    aria-label='Close highlights'
                  >
                    <CloseSVG />
                  </button>
                </div>
                <SearchInput placeholder='Search for highlights' value={searchValue} onSearch={setSearchValue} />
                <div className='flex items-center'>
                  <div className='flex-1'>
                    <TableFilters<ArtifactHit> hook={filtersHook} />
                  </div>
                  <div className='flex py-3 pl-3 space-x-3 border-l border-gray-200'>
                    <AnyAllToggle value={filtersHook.op} onChange={filtersHook.setOp} />
                    <SegmentActions onClear={filtersHook.clearFilters} anyFilters={filtersHook.filters.length > 0} />
                  </div>
                </div>
              </div>
              <div className='flex justify-between px-6 py-4 space-x-6 border-b border-gray-200'>
                <Count
                  className='h-10 my-px'
                  render={(n) => (
                    <div className='flex items-center h-full'>
                      <SelectAllCheckbox setSelectedHits={setSelectedHits} selectedHits={selectedHits} />
                      {getCount(n)} {pluralize('highlight', getCount(n))}
                    </div>
                  )}
                />
                {selectedHits.length > 0 && (
                  <Button onClick={onAddToHighlightReel} icon='plus'>
                    Add to reel
                  </Button>
                )}
              </div>
              <div className='desktop:overflow-auto'>
                <div className='px-6 pt-6'>
                  <Hits selectedHits={selectedHits} setSelectedHits={setSelectedHits} />
                </div>
              </div>
            </>
          );
        }}
      </Search>
    </section>
  );
});
