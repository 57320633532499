import cn from 'classnames';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';

import { Button, DropdownCombobox, Modal, Textarea } from '@components/common';
import { MultiselectCombobox } from '@components/shared/MultiselectCombobox';
import { useOnClickOutside } from '@components/utils';

import { isEmpty } from '../helpers';
import { useTableContext } from '../hooks';
import * as Icons from '../icons';
import { CellProps, GetKeyOf } from '../types';

export const Cell = <D extends Record<string, any>, O extends string = string>(props: CellProps<D, O>) => {
  const { type = 'text', options = [], table, row, column, cell, getValue, render } = props;
  const { editableColumns = [], errors, stickyColumns = [] } = useTableContext<D>();

  const initialValue = getValue();
  const isEditable = editableColumns.includes(column.id as GetKeyOf<D, 'extra'>);
  const hasError = !!errors.find(({ rowIndex, columnId }) => rowIndex === row.index && columnId === column.id);

  const [value, setValue] = useState(initialValue);
  const [editMode, setEditMode] = useState<boolean>(false);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleOnBlur = () => {
    setEditMode(false);
    table.options.meta?.updateCellData?.({ rowIndex: row.index, columnId: column.id, value });
  };

  const handleCellUpdate = (newValue: any) => {
    setEditMode(false);
    setValue(newValue);
    table.options.meta?.updateCellData?.({ rowIndex: row.index, columnId: column.id, value: newValue });
  };

  const itemsToChoose = options.map((v) => ({ value: v, label: v }));

  const renderEdit = useCallback(() => {
    switch (type) {
      case 'text':
        return itemsToChoose.length ? (
          <DropdownCombobox
            onClickOutside={() => setEditMode(false)}
            items={itemsToChoose}
            selectedItem={{ label: value, value: value }}
            placeholder='Enter…'
            onSelect={(selected) => handleCellUpdate(selected?.value)}
            fullWidth
            allowCreate
            submitOnEnter
            submitLastValue
            autoFocus
          />
        ) : (
          <input
            type={type}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={handleOnBlur}
            placeholder='Enter…'
            autoFocus
          />
        );
      case 'number':
      case 'url':
        return (
          <input
            type={type}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={handleOnBlur}
            placeholder='Enter…'
            autoFocus
          />
        );
      case 'datetime':
        return (
          <DatePicker
            onChange={handleCellUpdate}
            value={format(new Date(value), 'PP')}
            placeholderText='Enter…'
            autoFocus
          />
        );
      case 'multiple_choice':
        return (
          <MultiselectCombobox
            value={value}
            options={itemsToChoose}
            onSave={handleCellUpdate}
            withInput
            canCreate
            rightDropdown
          />
        );
      case 'boolean':
        return (
          <div>
            <label>
              <input type='radio' value='0' checked={!!value} onChange={() => handleCellUpdate(true)} />
              Yes
            </label>
            <label>
              <input type='radio' value='1' checked={!value} onChange={() => handleCellUpdate(false)} />
              No
            </label>
            <button onClick={() => handleCellUpdate(undefined)}>Clear</button>
          </div>
        );
      case 'free_text':
        return (
          <Modal
            title={`Edit ${cell.column.id} for ${row.original.name}`}
            size='md'
            onClose={() => setEditMode(false)}
            renderFooter={() => (
              <>
                <Button onClick={() => setEditMode(false)}>Cancel</Button>
                <Button primary onClick={() => handleCellUpdate(textareaRef.current?.value)}>
                  Save
                </Button>
              </>
            )}
          >
            <Textarea defaultValue={value} ref={textareaRef} />
          </Modal>
        );
      default:
        return null;
    }
  }, [value]);

  const renderValue = useCallback(() => {
    if (value == null || value === '') return '-';

    if (render) return render(props);

    switch (type) {
      case 'datetime':
        return format(new Date(value), 'PP');
      case 'multiple_choice':
        return value.join(', ');
      case 'boolean':
        return value ? (
          <Icons.Check role='img' className='text-green-600' />
        ) : (
          <Icons.Close role='img' className='text-red-600' />
        );
      default:
        return value;
    }
  }, [value]);

  return (
    <div
      className={cn('h400 w-full h-11 flex px-2 bg-white border-b border-gray-200 items-center', {
        'text-red-600': hasError,
        'cursor-pointer hover:bg-indigo-50': isEditable && !editMode
      })}
      onClick={() => !editMode && setEditMode(isEditable)}
    >
      {!isEditable || !editMode ? (
        <span className='w-full truncate'>{renderValue()}</span>
      ) : (
        <div ref={wrapperRef}>{renderEdit()}</div>
      )}
    </div>
  );
};
