import { useStudyActionOptions } from 'components/StudiesApp/hooks/useStudyActionOptions';
import { useTransitionStudy } from 'components/StudiesApp/hooks/useTransitionStudy';
import * as React from 'react';
import { useState } from 'react';

import { Loading, PopperDropdown, PopperDropdownLink } from '@components/common';
import { CloseStudyModal } from '@components/StudiesApp/components/modals/CloseStudyModal';
import {
    ArchiveSVG, CompleteSVG, DuplicateSVG, PauseSVG, SolidKebabSVG, TrashSVG, UndoSVG
} from '@components/svgs';
import { usePermission } from '@hooks/usePermission';
import { useUser } from '@hooks/useUser';
import { useToaster } from '@stores/toaster';

import {
    ArchiveStudyModal, DeleteStudyModal, DuplicateStudyModal, ReactivateStudyModal
} from '../../../components/modals';
import * as toasts from '../../../toasts';

type Props = {
  study: Study;
};

export const OptionsDropdown: React.FC<Props> = ({ study }) => {
  const [reactivateModalOpen, setReactivateModalOpen] = useState(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [closeModalOpen, setCloseModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const showToast = useToaster();

  const options = useStudyActionOptions(study);

  const user = useUser();

  const canEdit = usePermission<Study>('updateStudy')(study);

  const { transition: pauseStudy } = useTransitionStudy({
    id: study.id,
    transition: 'pause',
    onSuccess: () => {
      showToast(toasts.successPause());
      setLoading(false);
    },
    onError: () => {
      showToast(toasts.failedPause());
      setLoading(false);
    }
  });
  const { transition: unpauseStudy } = useTransitionStudy({
    id: study.id,
    transition: 'unpause',
    onSuccess: () => {
      showToast(toasts.successUnpause());
      setLoading(false);
    },
    onError: () => {
      showToast(toasts.failedUnpause());
      setLoading(false);
    }
  });

  const onReactivateClick = () => {
    setReactivateModalOpen(true);
  };

  const onDuplicateClick = () => {
    setDuplicateModalOpen(true);
  };

  const onDeleteClick = () => {
    setDeleteModalOpen(true);
  };

  const onArchiveClick = () => {
    setArchiveModalOpen(true);
  };

  const onCloseClick = () => {
    setCloseModalOpen(true);
  };

  const onPauseClick = async () => {
    setLoading(true);
    pauseStudy();
  };

  const onUnpauseClick = async () => {
    setLoading(true);
    unpauseStudy();
  };

  const onStudyCloseComplete = () => {
    setCloseModalOpen(false);
  };

  if (loading) {
    return <Loading absolute />;
  }

  return (
    <div className='w-full'>
      <ReactivateStudyModal
        id={study.id}
        title={study.title}
        open={reactivateModalOpen}
        onClose={() => setReactivateModalOpen(false)}
      />
      <DuplicateStudyModal
        initialStudy={study}
        open={duplicateModalOpen}
        onClose={() => setDuplicateModalOpen(false)}
        initialOwner={user}
      />
      <ArchiveStudyModal
        id={study.id}
        title={study.title}
        open={archiveModalOpen}
        onClose={() => setArchiveModalOpen(false)}
      />
      <DeleteStudyModal
        id={study.id}
        title={study.title}
        style={study.style}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      />
      {closeModalOpen && (
        <CloseStudyModal study={study} onClose={() => setCloseModalOpen(false)} onComplete={onStudyCloseComplete} />
      )}

      {options.length > 0 && (
        <PopperDropdown
          text
          hideCaret
          name='more'
          buttonClassName='border-none rounded-full flex-shrink-0 pt-2 pr-2 pb-2 pl-2'
          renderIcon={() => <SolidKebabSVG className='w-4 h-4 flex-shrink-0' />}
        >
          {options.includes('reactivate') && (
            <PopperDropdownLink disabled={!canEdit} onClick={onReactivateClick}>
              <UndoSVG className='inline mr-1 -mt-1' />
              Reactivate…
            </PopperDropdownLink>
          )}
          {options.includes('duplicate') && (
            <PopperDropdownLink disabled={!canEdit} onClick={onDuplicateClick}>
              <DuplicateSVG className='inline mr-1 -mt-1' />
              Duplicate
            </PopperDropdownLink>
          )}
          {options.includes('pause') && (
            <PopperDropdownLink disabled={!canEdit} onClick={onPauseClick}>
              <PauseSVG className='inline mr-1 -mt-1' />
              Pause
            </PopperDropdownLink>
          )}
          {options.includes('unpause') && (
            <PopperDropdownLink disabled={!canEdit} onClick={onUnpauseClick}>
              <PauseSVG className='inline mr-1 -mt-1' />
              Unpause
            </PopperDropdownLink>
          )}
          {options.includes('close') && (
            <PopperDropdownLink disabled={!canEdit} onClick={onCloseClick}>
              <CompleteSVG className='inline mr-1 -mt-1' />
              Close
            </PopperDropdownLink>
          )}
          {options.includes('archive') && (
            <PopperDropdownLink disabled={!canEdit} onClick={onArchiveClick}>
              <ArchiveSVG className='inline mr-1 -mt-1' />
              Archive
            </PopperDropdownLink>
          )}
          {options.includes('delete') && (
            <PopperDropdownLink disabled={!canEdit} color='red-600' hoverColor='red-700' onClick={onDeleteClick}>
              <TrashSVG className='inline mr-1 -mt-1' />
              Delete
            </PopperDropdownLink>
          )}
        </PopperDropdown>
      )}
    </div>
  );
};
