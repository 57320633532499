import * as React from 'react';
import { useState } from 'react';
import { AddTeamIcon, UserAvatars } from '@components/shared/UserAvatars';
import { StudyUsersModal } from './components';

interface Props {
  study: Study;
}

export const StudyUsers: React.FC<Props> = ({ study }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div
        onClick={() => setShowModal(true)}
        className='xx-study-users cursor-pointer group hover:bg-gray-50 h-10 border border-gray-200 rounded-md flex items-center'
      >
        <div className='border-r h-10 border-gray-200 px-3 py-2'>
          <div role='button'>
            <UserAvatars truncate={3} user_ids={study.user_ids} size='md' />
          </div>
        </div>
        <div className='bg-white group-hover:bg-gray-50 text-white rounded-full px-2 py-3'>
          <AddTeamIcon className='text-gray-700 h-3.5 w-3.5' />
        </div>
      </div>
      {showModal && <StudyUsersModal study={study} onClose={() => setShowModal(false)} />}
    </>
  );
};
