import * as React from 'react';
// hooks
import { useRef, useState } from 'react';
// libs
import { Row } from 'react-table';

// components
import { Button, Input, Text } from '@components/common';
import { toPositiveNumber, useOnClickOutside } from '@components/utils';
import { ParticipationTableItem } from '@components/StudiesApp/components/StudyPublished/ParticipationTable/helpers/buildParticipantsColumns';
import { api as reduxApi } from '@api/reduxApi';
import { useFeature } from '@hooks/useFeature';

type SubsetType = 'top' | 'random';

interface Subset {
  number: string;
  type: SubsetType;
}

export type SelectSubset = (
  subset: Subset,
  rows: Row<Candidate | ParticipationTableItem>[],
  toggleRowSelected: (rowId: string, set?: boolean) => void,
  setIds?: (id: number) => void
) => void;

interface Props extends ParticipantsFilterQuery {
  onSelectSubset: SelectSubset;
  rows: Row<Candidate>[];
  subsetButtonIsHidden: boolean;
  toggleRowSelected: (rowId: string, set?: boolean) => void;
  setIds?: (id: number) => void;
  studyId?: number;
  currentStatus?: ParticipationStatus;
  setSelectedIds?: (v: number[]) => void;
}

export const SelectSubsetDropdown: React.FC<Props> = ({
  onSelectSubset,
  rows,
  subsetButtonIsHidden,
  toggleRowSelected,
  setIds,
  studyId,
  sort,
  sort_desc,
  currentStatus,
  filters,
  op,
  searchQuery,
  setSelectedIds
}) => {
  const serverSideParticipations = useFeature('serverside_participations');

  const [subset, setSubset] = useState<Subset>({ number: '', type: 'top' });
  const [visible, setVisible] = useState(false);

  const [getSubset] = reduxApi.useLazyGetParticipationsPagedQuery();

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    if (visible) {
      setVisible(false);
    }
  });

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    e.stopPropagation();
    e.preventDefault();

    if (!visible) {
      setVisible(true);
    }
  };

  const onToggleSubsetType = (v: SubsetType) => {
    setSubset({ ...subset, type: v });
  };

  const onSelect = async () => {
    if (!serverSideParticipations) {
      onSelectSubset(subset, rows, toggleRowSelected, setIds);
      setVisible(false);
      return;
    }

    try {
      const { data } = await getSubset({
        study_id: studyId || 1,
        fields: ['id'],
        page: 1,
        query: {
          op,
          sort: subset.type === 'top' ? sort : 'random',
          sort_desc,
          filters: [`status is ${currentStatus}`, ...(filters || [])],
          searchQuery
        },
        items: +subset.number || 1
      }).unwrap();

      setSelectedIds?.(data?.map(({ id }) => id));
    } catch {
      console.log('error');
    }

    setVisible(false);
  };

  const handleEnter = (e): void => {
    if (e.key === 'Enter') {
      onSelect();
    }
  };

  const onSubsetNumberChange = (value: string): void =>
    setSubset({
      ...subset,
      number: toPositiveNumber(value).toString()
    });

  if (subsetButtonIsHidden) return null;

  return (
    <div ref={ref} className='inline-block overflow-visible'>
      <Button small link onClick={handleClick} className='mx-4'>
        Select subset
      </Button>

      {visible && (
        <div className='origin-top-0 top-12 absolute z-40 rounded-md shadow-lg'>
          <div className='ring-1 ring-black ring-opacity-5 relative p-4 text-left bg-white border border-gray-200 rounded-md'>
            <Text h='400' bold className='mb-4'>
              Select a specific number
            </Text>

            <div>
              <div className='relative'>
                <Input
                  id='amount'
                  autoFocus
                  className='mb-6'
                  type='number'
                  value={subset.number}
                  onChange={onSubsetNumberChange}
                  onKeyDown={handleEnter}
                />
                <Text className='top-2 right-8 absolute text-gray-500'>candidates</Text>
              </div>
              <div className='flex items-center mb-4'>
                <Input
                  type='radio'
                  id='top'
                  value='top'
                  className='rounded-full'
                  onChange={onToggleSubsetType}
                  checked={subset.type === 'top'}
                />
                <label htmlFor='top'>
                  <Text h='400' className='ml-2'>
                    Start at the top of the list
                  </Text>
                </label>
              </div>
              <div className='flex items-center mb-4'>
                <Input
                  type='radio'
                  id='random'
                  value='random'
                  className='rounded-full'
                  onChange={onToggleSubsetType}
                  checked={subset.type === 'random'}
                />
                <label htmlFor='random'>
                  <Text h='400' className='ml-2'>
                    Select in random order
                  </Text>
                </label>
              </div>
            </div>
            <Button primary className='mt-4' onClick={onSelect}>
              Select
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
