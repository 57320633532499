import { useCollectionView } from 'components/stores/view';
import { GroupBySVG, LayoutSVG, ResetSVG, SortBySVG } from 'components/svgs';
import React from 'react';
import { useState } from 'react';

import { Button, Dropdown, Modal, Toggle } from '@components/common';
import { useDeviceType } from '@hooks/useDeviceType';
import { usePermission } from '@hooks/usePermission';
import { Toast, useToaster } from '@stores/toaster';
import { noop } from '@components/utils';

interface Props {
  renderLayout?: () => React.ReactNode;
  renderGroupBy?: () => React.ReactNode;
  renderSortBy?: () => React.ReactNode;
  renderToggle?: () => React.ReactNode;
  resetView?: () => void;
}

const successReset = (): Toast => ({
  heading: 'View updated',
  icon: 'success',
  text: `We've reset to your default view.`
});

const successSet = (): Toast => ({
  heading: 'Default view saved',
  icon: 'success',
  text: `We've saved the view as the default.`
});

const ConfirmModal: React.FC<{ title: string; message: string; onConfirm?: () => void; onCancel?: () => void }> = ({
  title,
  message,
  onCancel,
  onConfirm
}) => {
  return (
    <Modal
      onClose={onCancel}
      title={title}
      renderFooter={() => (
        <>
          <Button onClick={onCancel}>Cancel</Button>
          <Button primary onClick={onConfirm}>
            Confirm
          </Button>
        </>
      )}
    >
      {message}
    </Modal>
  );
};

const DefaultViewControls: React.FC<{ resetView?: () => void; setViewDisabled?: boolean; setView?: () => void }> = ({
  resetView,
  setView,
  setViewDisabled
}) => {
  const isAdmin = usePermission('isAdmin')();
  const viewHook = useCollectionView();
  if (!viewHook) return null;
  return (
    <>
      <div className='flex px-4 py-2 space-x-4 border-t border-gray-200'>
        <div className='flex w-1/2'>
          {resetView && (
            <div
              onClick={resetView}
              className='whitespace-nowrap flex items-center space-x-2 text-indigo-600 cursor-pointer'
            >
              <ResetSVG />
              <span className='text-sm'>Reset to default</span>
            </div>
          )}
        </div>
        {isAdmin && setView && (
          <div className='w-1/2'>
            <Button medium className='whitespace-nowrap' onClick={setView} disabled={setViewDisabled}>
              Set view as default
            </Button>
          </div>
        )}
      </div>
      {isAdmin && (
        <div className='px-4 text-xs text-gray-500'>
          Default views will also include any filters you currently have set.
        </div>
      )}
    </>
  );
};

export const View: React.FC<Props> = ({ renderLayout, renderToggle, renderGroupBy, renderSortBy }) => {
  const [open, setOpen] = useState<boolean>();
  const [confirmOpen, setConfirmOpen] = useState<boolean>();
  const { isMobile } = useDeviceType();
  const toggleMenu = () => setOpen(!open);
  const showToast = useToaster();

  const viewHook = useCollectionView();

  const closeMenu = () => setOpen(false);

  return (
    <>
      {confirmOpen && (
        <ConfirmModal
          title='Save view as default'
          message='Saving the current view as default will make it default for everyone on your account.'
          onCancel={() => {
            setConfirmOpen(false);
            setOpen(true);
          }}
          onConfirm={() => {
            viewHook.setAsDefault?.();
            showToast(successSet());
            setConfirmOpen(false);
            setOpen(true);
          }}
        />
      )}
      <Dropdown
        medium
        position={isMobile ? 'center' : 'right'}
        dropdownWidth='auto'
        icon='view'
        className='xx-send-menu bg-white'
        text='View'
        isOpen={open}
        onClick={toggleMenu}
        onClose={closeMenu}
      >
        <div className='w-80 px-4 py-2 space-y-3'>
          {renderLayout && (
            <div className='flex flex-1'>
              <div className='whitespace-nowrap flex items-center w-1/2 text-sm font-bold'>
                <LayoutSVG className='mr-2' />
                Layout
              </div>
              <div className='w-1/2'>{renderLayout()}</div>
            </div>
          )}
          {renderGroupBy && (
            <div className='flex flex-1'>
              <div className='whitespace-nowrap flex items-center w-1/2 text-sm font-bold'>
                <GroupBySVG className='mr-2' />
                Group by
              </div>
              <div className='w-1/2'>{renderGroupBy()}</div>
            </div>
          )}
          {renderSortBy && (
            <div className='flex flex-1'>
              <div className='whitespace-nowrap flex items-center w-1/2 text-sm font-bold'>
                <SortBySVG className='mr-2' />
                Sort by
              </div>
              <div className='w-1/2'>{renderSortBy()}</div>
            </div>
          )}
          {renderToggle?.()}
        </div>
        {viewHook && (viewHook.reset || viewHook.setAsDefault) && (
          <DefaultViewControls
            resetView={
              viewHook.reset
                ? () => {
                    viewHook.reset?.();
                    showToast(successReset()); // move to provider?
                  }
                : undefined
            }
            setView={() => setConfirmOpen(true)}
            setViewDisabled={viewHook.isDefault}
          />
        )}
      </Dropdown>
    </>
  );
};
