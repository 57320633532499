import { Transaction } from 'prosemirror-state';
import { ReplaceStep } from 'prosemirror-transform';
import { findChildrenByMark } from 'prosemirror-utils';
import { useRef } from 'react';

import { EditorOptions, NodeWithPos, Range } from '@tiptap/core';

import { useBulkUpdateHighlightMutation } from '../api';
import * as Queries from '../helpers/queries';
import { getHighlightContent } from '@components/shared/Tiptap/utils';

interface Params {
  documentId?: number;
}

interface UseHighlightSync {
  isLoading: boolean;
  isError: boolean;
  capture: EditorOptions['onUpdate'];
  sync: EditorOptions['onUpdate'];
}

export const useHighlightSync = ({ documentId }: Params): UseHighlightSync => {
  const [bulkUpdate, { isLoading, isError }] = useBulkUpdateHighlightMutation();
  const transactionsRef = useRef<Transaction[]>([]);

  const getRange = () => {
    const steps = transactionsRef.current
      .map(({ steps }) => steps[0] as ReplaceStep | undefined)
      .filter(Boolean) as ReplaceStep[];

    return steps.reduce(
      (acc, { from, to }) => ({
        from: Math.min(acc.from ?? from, from),
        to: Math.max(acc.to ?? to, to)
      }),
      {} as Range
    );
  };

  return {
    isLoading,
    isError,
    capture: ({ transaction }) => {
      transactionsRef.current.push(transaction);
    },
    sync: ({ editor }) => {
      if (!editor.state.schema.marks.highlight) return;
      const marks = findChildrenByMark(editor.state.doc, editor.state.schema.marks.highlight);
      const highlights: { id: number; text: string; from?: number; to?: number }[] = [];
      const range = getRange();

      for (const { node, pos } of marks) {
        if (pos >= range.from) {
          const mark = node.marks.find((mark) => mark.type === editor.state.schema.marks.highlight);

          if (mark) {
            const { highlightId } = mark.attrs;

            if (highlightId) {
              const marksById = Queries.findMarksByHighlightId(editor.state, highlightId);
              const [firstMark] = marksById;
              const lastMark = [...marksById].pop() as NodeWithPos;

              const from = firstMark.pos;
              const to = lastMark.pos + lastMark.node.nodeSize;
              const text = Queries.getTextSelection(editor, { from, to });
              const content = getHighlightContent({ editor, from, to });

              const newHighlight: {
                id: number;
                text: string;
                from?: number;
                to?: number;
                content: HighlightContentElement[];
              } = {
                id: highlightId,
                text,
                content
              };

              const tWordMarks = Queries.getMarksInRange(editor, { from, to }, 'transcript_word');

              if (tWordMarks.length > 0) {
                newHighlight.from = tWordMarks[0].attrs.start_ts;
                newHighlight.to = tWordMarks[tWordMarks.length - 1].attrs.end_ts;
              }

              highlights.push(newHighlight);
            }
          }

          break;
        }
      }

      if (highlights.length && documentId) {
        bulkUpdate({ documentId, highlights });
      }

      transactionsRef.current = [];
    }
  };
};
