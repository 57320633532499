import { CopyURLButton } from '@components/shared/CopyURLButton';
import { usePermission } from '@hooks/usePermission';
// hooks
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useGetWhoamiQuery } from '@components/stores/whoami';

// api
import { api } from '@api/reduxApi';
import { Button } from '@components/common';
import { Checkbox, FormGroup, Input, Label, Textarea } from '@components/fields';
// utils
import { compact } from '@components/utils';
import { useToaster } from '@stores/toaster';

// components
import * as toasts from '../../../../toasts';
import { BrandLogo } from './BrandLogo';
import { checkAnyValuesChanged, getDefaultValues, SIDEBAR_ITEM_LABELS } from './EditSignupPage';
import { PreviewSchedulingPage } from './PreviewSchedulingPage/PreviewSchedulingPage';

// this is 95% a dupe of EditSignupPage
// TODO:should really be refactored into a EditableAssetPage
export const EditSchedulingPage: React.FC<PageProps> = ({ landing_page, onSave, study, setSaving }) => {
  const [edit, setEdit] = useState(false);
  const canUpdate = usePermission<Study>('updateStudy')(study);

  const showToast = useToaster();

  const { register, handleSubmit, errors, reset } = useForm<Partial<LandingPage>>({
    defaultValues: getDefaultValues(landing_page as any)
  });

  const [updateStudyLandingPage] = api.useUpdateStudyLandingPageMutation();

  const onSubmit = async (data: Partial<LandingPage>) => {
    const dataNotChanged = !checkAnyValuesChanged(data, landing_page as any) || Object.keys(data).length === 0;

    if (dataNotChanged) {
      setEdit(false);
      return;
    }

    setSaving(true);

    try {
      const resp = await updateStudyLandingPage({ study_id: study.id, ...data }).unwrap();

      setEdit(false);
      onSave({ id: study.id, slug: resp.slug }); // is there another way to refresh the study?
      reset(getDefaultValues(resp)); // why this? To set new values as default
      // https://react-hook-form.com/kr/v6/api/
      // Important: defaultValues is cached at the first render within the custom hook.
      // If you want to reset the defaultValues, you should use the reset api.
    } catch (_) {
      showToast(toasts.failedUpdate());
    }

    setSaving(false);
  };

  const { data: whoami } = useGetWhoamiQuery();
  const host = whoami?.account_public_domain.base_url;
  const publicUrl = host + '/' + study.public_path;

  return (
    <div
      className={compact([
        'w-full bg-white max-w-4xl p-8 relative mx-auto border border-gray-200',
        edit && 'rounded-md',
        !edit && 'shadow-lg'
      ]).join(' ')}
    >
      <div className='flex pb-8 space-x-3'>
        <div className='flex-grow'>
          <BrandLogo study={study} className='h-8' edit={edit} />
        </div>

        {!edit && (
          <>
            {study.state !== 'pending' && (
              <CopyURLButton disabled={!study.slug} icon='link' className='mr-2' text={publicUrl} />
            )}
            <Button iconSuffix='externalLink' disabled={!study.slug} href={`${publicUrl}/direct`} target='_blank'>
              View
            </Button>
            <Button aria-label='Edit' iconSuffix='pencil' onClick={() => setEdit(true)} disabled={!canUpdate}>
              Edit
            </Button>
          </>
        )}

        {edit && (
          <>
            <Button onClick={() => setEdit(false)}>Cancel</Button>
            <Button aria-label='Save changes' type='submit' primary onClick={handleSubmit(onSubmit)}>
              Save changes
            </Button>
          </>
        )}
      </div>

      {!edit && <PreviewSchedulingPage study={study} landing_page={landing_page as any} />}

      {edit && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label labelFor='booking_page_title'>Headline</Label>
            <Input name='booking_page_title' id='booking_page_title' errors={errors} register={register} />
          </FormGroup>
          <FormGroup>
            <Label>Personal Note</Label>
            <Textarea name='personal_note' placeholder='' register={register} />
          </FormGroup>
          <FormGroup>
            <Label className='mb-4'>{landing_page?.sidebar_title}</Label>
            {landing_page?._default_sidebar_items.map(
              (item, i) =>
                !(!study.has_incentive && i === 2) && (
                  <Checkbox
                    key={item}
                    className='mb-3 text-gray-700'
                    name='sidebar_items'
                    value={i.toString()}
                    label={`${SIDEBAR_ITEM_LABELS[i]} (${item})`}
                    register={register}
                  />
                )
            )}
          </FormGroup>
          <FormGroup>
            <Label className='mb-4'>{landing_page?.sidebar_subtitle}</Label>
            {landing_page?._default_requirements.map((item, i) => (
              <Checkbox
                key={item}
                className='mb-3 text-gray-700'
                name='requirements'
                value={item}
                label={item}
                register={register}
              />
            ))}
          </FormGroup>
        </form>
      )}
    </div>
  );
};
