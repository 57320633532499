import * as React from 'react';
import { useState } from 'react';

import { Dropdown, DropdownLink } from '@components/common';
import { RestrictedButton } from '@components/shared/RestrictedButton';

import { StudySlideOut } from '@components/StudiesApp/types';
import { CandidateSlideOut } from './types';
import { AddCustomers } from './AddCustomers';

interface PageHeaderProps {
  teamId?: number | null;
  panelStudy?: boolean;
  setSlideOut: (slideOut: CandidateSlideOut | StudySlideOut) => void;
  selectedIds: number[];
  allSelected?: boolean;
  openPanelStudyModal: () => void;
}



interface SendMenuProps {
  ids: number[];
  panelStudy?: boolean;
  setSlideOut: (slideOut: CandidateSlideOut) => void;
}

const SendMenu: React.FC<SendMenuProps> = ({ ids, setSlideOut, panelStudy }) => {
  const [open, setOpen] = useState<boolean>();

  const showSlideOut = (slideOut) => {
    setSlideOut(slideOut);
    setOpen(false);
  };

  const toggleMenu = () => setOpen(!open);

  const closeMenu = () => setOpen(false);

  const showInviteSlideOut = () => showSlideOut('InviteSlideOut');

  const showScreenerSlideOut = () => showSlideOut('ScreenerSlideOut');

  const showPanelInviteSlideOut = () => showSlideOut('PanelInviteSlideOut');

  return (
    <Dropdown
      medium
      className='xx-send-menu'
      primary
      text='Compose'
      icon='composeEmail'
      isOpen={open}
      onClick={toggleMenu}
      onClose={closeMenu}
    >
      <DropdownLink className='xx-bulk-invite border-b' onClick={showInviteSlideOut}>
        Participation invite
      </DropdownLink>
      <DropdownLink className='xx-bulk-screener border-b' onClick={showScreenerSlideOut}>
        Screener invite
      </DropdownLink>
      {panelStudy && (
        <DropdownLink className='xx-bulk-panel border-b' onClick={showPanelInviteSlideOut}>
          Panel opt-in form
        </DropdownLink>
      )}
      <DropdownLink disabled>Emails won't be sent yet</DropdownLink>
    </Dropdown>
  );
};

export const PageHeader: React.FC<PageHeaderProps> = ({
  teamId,
  panelStudy,
  selectedIds,
  allSelected,
  setSlideOut,
  openPanelStudyModal
}) => {
  return (
    <div className='inline-flex items-center space-x-3'>
      {selectedIds.length === 0 && !allSelected ? (
        <RestrictedButton
          buttonProps={{ medium: true }}
          action='Add candidates'
          permission='addCandidate'
          limit='candidates'
        >
          <AddCustomers
            teamId={teamId}
            component='candidates_index_header'
            setSlideOut={setSlideOut}
            openPanelStudyModal={openPanelStudyModal}
          />
        </RestrictedButton>
      ) : (
        <RestrictedButton action='Add candidates' permission='addCandidate'>
          <SendMenu ids={selectedIds} panelStudy={panelStudy} setSlideOut={setSlideOut} />
        </RestrictedButton>
      )}
    </div>
  );
};
