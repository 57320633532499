import 'i18n/translations';

import * as React from 'react';
import { Provider } from 'react-redux';

import { store as reduxStore } from '@api/reduxApi';
import { Loading } from '@components/common';
import { Context, SentryProvider } from '@components/providers';

import { AppErroredPage } from './AppErroredPage';
import { useGetWhoamiQuery } from './stores/whoami';
import { noop } from './utils';

export const AppLoading: React.FC<{ blockOnLoad: boolean }> = ({ blockOnLoad }) =>
  blockOnLoad ? (
    <div className='fixed inset-0 z-50 bg-white'>
      <Loading absolute bg='transparent' />
    </div>
  ) : (
    <Loading absolute bg='transparent' />
  );

const Whoami: React.FC<{
  blockOnLoad?: boolean;
  hideLoader?: boolean;
  children: (data: { whoami: Whoami }) => React.ReactElement;
}> = ({ blockOnLoad = false, hideLoader = false, children }) => {
  const { data: whoami, isLoading, isError } = useGetWhoamiQuery();

  if (isLoading && !hideLoader) {
    return <AppLoading blockOnLoad={blockOnLoad} />;
  }

  if (isError) {
    return (
      <AppErroredPage
        eventId={null}
        error={{ name: 'Loading whoami failed', message: 'Got an error fetching whoami' }}
        componentStack={null}
        resetError={noop}
      />
    );
  }
  if (!whoami) {
    return null;
  }

  return children({ whoami: whoami as Whoami });
};

export const AppLoader: React.FC<{ blockOnLoad?: boolean; hideLoader?: boolean }> = ({
  hideLoader = false,
  blockOnLoad = false,
  children
}) => {
  return (
    <Provider store={reduxStore}>
      <Whoami blockOnLoad={blockOnLoad} hideLoader={hideLoader}>
        {({ whoami }) => (
          <Context whoami={whoami}>
            <SentryProvider whoami={whoami} renderComponent={(props) => <AppErroredPage {...props} />}>
              {children}
            </SentryProvider>
          </Context>
        )}
      </Whoami>
    </Provider>
  );
};
