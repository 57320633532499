import cn from 'classnames';
import * as React from 'react';
import { TippyOrNot, Avatar, Text, Pill, AvatarFromId } from '@components/common';
import { CheckMarkSVG, ErrorSvg } from '@components/svgs';
import { PillProps } from 'components/common/Pill';

type Props = {
  user: TeamUser;
  index: number;
  highlightedIndex: number;
  getItemProps: any;
  showCurrentlySelected?: TeamUser;
};

type ErrorPresentation = {
  disable: boolean;
  message: string;
  pill: PillProps | { error: boolean };
};

type ErrorDisplayMapping = Record<TeamUserError, ErrorPresentation>;

const errorsDisplayMapping: ErrorDisplayMapping = {
  not_on_team: {
    disable: true,
    message: 'This user is not part of your team. Please invite them to join your team first.',
    pill: {
      color: 'blue',
      children: 'Not on team'
    }
  },
  already_belongs_to_study: {
    disable: true,
    message: 'This user is already added to the study.',
    pill: {
      color: 'blue',
      children: 'Already added'
    }
  },
  deactivated: {
    disable: true,
    message:
      'This user has been deactivated from your account. In order to add them, please re-activate their account in Settings > Members.',
    pill: {
      color: 'orange',
      children: 'Deactivated'
    }
  },
  invalid_calendar: {
    disable: false,
    message: `There's an issue with this user's calendar connection. Please remind them to resolve the issue in order to prevent scheduling issues.`,
    pill: {
      error: true
    }
  },
  invalid_email_integration: {
    disable: true,
    message: 'In order to add this user as a sender, please ask them to connect their Google or Microsoft account.',
    pill: {
      color: 'gray',
      children: 'Disconnected'
    }
  },
  no_account_level_permission: {
    disable: true,
    message: "This user hasn't granted permissions to allow others to send emails from their account.",
    pill: {
      color: 'gray',
      children: 'Unavailable'
    }
  },
  observer: {
    disable: true,
    message: 'In order to add this user as an email sender, an admin will need to upgrade them to a creator seat.',
    pill: {
      color: 'gray',
      children: 'Observer'
    }
  }
};

const isPillProps = (obj: any): obj is PillProps => {
  return obj.color !== undefined;
};

export const UserNameTag: React.FC<Props> = ({
  user,
  index,
  highlightedIndex,
  getItemProps,
  showCurrentlySelected
}) => {
  const errorToDisplay: ErrorPresentation | undefined = user.errors && errorsDisplayMapping[user.errors[0]];
  const tippyContent = errorToDisplay ? <Text>{errorToDisplay.message}</Text> : null;
  const disabled = errorToDisplay?.disable;

  return (
    <TippyOrNot show={!!errorToDisplay} placement='right' arrow={false} content={tippyContent}>
      <li
        key={`${user.id}${index}`}
        className={cn('group w-full px-4 py-2', {
          'bg-indigo-600 text-white': highlightedIndex === index,
          'cursor-pointer hover:bg-indigo-600 hover:text-white': !disabled,
          'cursor-not-allowed hover:bg-gray-50': disabled
        })}
        aria-label={`${user.name} ${user.email}`}
        {...getItemProps({ item: user, index, disabled })}
      >
        <div className='flex items-center py-1'>
          {user.img && <Avatar size='lg' className='mr-4' user={user} />}
          {!user.img && <AvatarFromId size='lg' className='mr-4' userId={user.id} />}
          <div className='flex-grow'>
            <Text h='400'>{user.name}</Text>
            {user && (
              <Text
                className={cn({
                  'text-white': highlightedIndex === index,
                  'text-gray-500': highlightedIndex !== index,
                  'group-hover:text-white': !disabled
                })}
                h='200'
              >
                {user.email}
              </Text>
            )}
          </div>
          {showCurrentlySelected && showCurrentlySelected.email === user.email && (
            <CheckMarkSVG
              data-testid='check-icon'
              className={cn('w-4 h-4 ml-auto', highlightedIndex === index ? 'text-white-600' : 'text-blue-600')}
            />
          )}

          {errorToDisplay && (
            <div className='flex items-center'>
              {isPillProps(errorToDisplay.pill) ? (
                <Pill {...errorToDisplay.pill} className='mr-2' />
              ) : (
                errorToDisplay.pill.error && (
                  <ErrorSvg
                    data-testid='error-icon'
                    className={cn('w-4 h-4', highlightedIndex === index ? 'text-white-600' : 'text-red-600')}
                  />
                )
              )}
            </div>
          )}
        </div>
      </li>
    </TippyOrNot>
  );
};
