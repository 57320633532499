import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Loading } from '@components/common';
import { useToaster } from '@components/stores/toaster';

import { ExternalCandidatesRequestForm } from './components/ExternalCandidatesRequestForm';
import { useFeature } from '@hooks/useFeature';
import { useRecruitingRequest } from '@components/StudiesApp/components/StudyDraft/hooks/useRecruitingRequest';

export const ExternalCandidatesRequest: React.FC<{ study: Study }> = ({ study }) => {
  const studyId = study.id;

  const params = useParams();

  const isNew = params.externalCandidatesRequestId === 'new';
  const navigate = useNavigate();
  const showToast = useToaster();

  const handleUpdatedRequest = (modifiedRequest: ExternalCandidatesRequest) => {
    if (modifiedRequest.valid_request) {
      navigate(`/studies/${studyId}/recruitment_requests`);
    } else if (modifiedRequest.request_errors?.length) {
      showToast({
        heading: `Error submitting request:`,
        text: modifiedRequest.request_errors.join(', '),
        icon: 'error'
      });
    } else {
      showToast({
        heading: `Error submitting request.`,
        text: 'Please review required fields and try again.',
        icon: 'error'
      });
    }
  };

  const { request, handleChange, requestInitialized, isUpdating, isLoading } = useRecruitingRequest({
    studyId: studyId as number,
    handleUpdatedRequest,
    requestId: Number(params.externalCandidatesRequestId),
    skip: isNew
  });

  return (
    <div className='px-page py-gutter'>
      {isLoading && <Loading absolute />}
      {(isNew || requestInitialized) && (
        <ExternalCandidatesRequestForm
          study={study}
          request={request}
          isLoading={isUpdating}
          onSave={handleChange}
          onCancel={() => navigate(`/studies/${studyId}/recruitment_requests`)}
        />
      )}
    </div>
  );
};
