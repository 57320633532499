// state
import * as React from 'react';
import { useRef, useState } from 'react';

// components
import { PopperDropdown, PopperDropdownLink } from '@components/common';
import { SolidKebabSVG } from '@components/svgs';

import { DeleteGuideModal } from './DeleteGuideModal';

type Props = {
  guide: Guide;
  onRemoveGuide: () => void;
};

export const OptionsDropdown: React.FC<Props> = ({ guide, onRemoveGuide }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  
  const canEdit = true; //usePermission<Study>('updateStudy')(study);
  const canCreate = true; //usePermission<Study>('createStudy')(study);

  const onDeleteClick = () => {
    setDeleteModalOpen(true);
  };

  const style = { backgroundColor: `#${guide.bg_color || '374151'}` };

  return (
    <>
      <DeleteGuideModal
        token={guide.token}
        title={guide.title}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onSubmit={onRemoveGuide}
      />

      {(canCreate || canEdit) && (
        <PopperDropdown
          text
          hideCaret
          name='more'
          style={style}
          popperProps={{
            placement: 'bottom-end',
            renderOnBodyRoot: false
          }}
          buttonClassName='border-none rounded-full flex-shrink-0 pt-2 pr-2 pb-2 pl-2 text-white'
          renderIcon={() => <SolidKebabSVG className='w-4 h-4 flex-shrink-0' />}
        >
          <PopperDropdownLink color='red-600' hoverColor='red-700' onClick={onDeleteClick}>
            Delete guide…
          </PopperDropdownLink>
        </PopperDropdown>
      )}
    </>
  );
};
