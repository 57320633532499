import * as React from 'react';

import { Text } from '@components/common';
import { Basic } from '@components/shared/Skeleton';

export const Skeleton: React.FC = () => (
  <div>
    <Text h='700' mb='6'>
      Teams
    </Text>
    <div className='pb-36 flex flex-col space-y-4'>
      <Basic width='100%' />
      <Basic width='100%' />
      <Basic width='100%' />
    </div>
  </div>
);
