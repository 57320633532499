import cn from 'classnames';
import copy from 'copy-to-clipboard';
import React, { forwardRef, HTMLAttributes, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { AIBots, useLazySuggestions } from '@api/chat-gpt';
import { Text, TippyOrNot } from '@components/common';
import { useSessionContext } from '@components/RepoSessionApp/SessionPage';
import { BulkAddToHighlightReel } from '@components/RepositoryApp/components/BulkAddToHighlightReel';
import { BulkAddToInsightDropdown } from '@components/RepositoryApp/components/BulkAddToInsightDropdown';
import {
  AddToInsightSVG,
  ChevronLeftSVG,
  CloseSVG,
  FullPageViewSVG,
  HighlightReelSVG,
  HighlightSVG
} from '@components/svgs';
import { TagListButton, TagListItems, useTagList } from '@components/tags/TagList';
import { track } from '@components/tracking';
import { compact, useOnKeypress } from '@components/utils';
import { useAccount } from '@hooks/useAccount';
import { usePermission } from '@hooks/usePermission';
import { useTags } from '@stores/tags';
import { useToaster } from '@stores/toaster';
import { Editor } from '@tiptap/react';

import {
  useCreateDocumentHighlightMutation,
  useCreateHighlightWithClipMutation,
  useDestroyDocumentHighlightMutation,
  useGetDocumentHighlightQuery,
  useUpdateDocumentHighlightMutation
} from '../api';
import { HighlightAttributes } from '../extensions';
import { TranscriptWordAttributes } from '../extensions/TranscriptWord';
import * as Queries from '../helpers/queries';
import { useTiptapContext } from '../hooks';
import * as Icons from '../icons';
import * as toasts from '../toasts';
import { useFeature } from '@hooks/useFeature';
import { getHighlightContent, getSuggestionIdsFromTags } from '@components/shared/Tiptap/utils';
import { HighlightCreatorInfo } from './HighlightCreatorInfo';
import { HighlightMenuTitle } from './HighlightMenuTitle';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  editor: Editor;
  documentId: number;
  studyId?: number | null;
  isVisible?: boolean;
  hide?: () => void;
  highlightId?: number;
}

export const HighlightMenu = forwardRef<HTMLDivElement, Props>(
  ({ hide, editor, documentId, studyId, isVisible, className, highlightId: initialHighlightId, ...rest }, ref) => {
    const [createDocumentHighlight, { isLoading: highlightCreating }] = useCreateDocumentHighlightMutation();
    const [createHighlightWithClip, { isLoading: highlightWithClipCreating }] = useCreateHighlightWithClipMutation();

    const canCreate = usePermission('canCreate')();

    const nonVideoHlEnabled = useFeature('non_video_highlights');

    const isLoading = highlightCreating || highlightWithClipCreating;

    const highlightId = initialHighlightId ?? (editor.getAttributes('highlight') as HighlightAttributes).highlightId;
    const { sessionUuid, recordingId } = useTiptapContext();
    const { videoPlayerRef } = useSessionContext();
    const { account } = useAccount();
    const { getTag } = useTags();
    const aiEnabled = account.ai_enabled;

    const showToast = useToaster();

    const {
      data: highlight,
      isFetching,
      isLoading: highlightLoading
    } = useGetDocumentHighlightQuery({ documentId, highlightId }, { skip: !highlightId });
    const [updateDocumentHighlight, { isLoading: isUpdating }] = useUpdateDocumentHighlightMutation();
    const [destroyDocumentHighlight, { isError, isSuccess, isLoading: isDeleting }] =
      useDestroyDocumentHighlightMutation();

    const {
      fetch: fetchTagSuggestions,
      value: tagSuggestions,
      accept: acceptSuggestion
    } = useLazySuggestions<string, { text?: string; study_id?: number | null }>({
      id: AIBots.TranscriptTextTags,
      context: { text: '', study_id: studyId }
    });

    const [tags, setTags] = useState<Highlight['tag_ids']>([]);

    const [subMenu, setSubMenu] = useState<'reel' | 'insight' | null>(null);

    useEffect(() => {
      setTags(highlight?.tag_ids ?? []);
    }, [highlight?.tag_ids]);

    useEffect(() => {
      if (isSuccess) {
        showToast(toasts.successDeleteHighlight());
      }
    }, [isSuccess]);

    useEffect(() => {
      if (isError) {
        showToast(toasts.failedDeleteHighlight());
      }
    }, [isError]);

    useEffect(() => {
      if (isVisible) {
        let text = '';

        if (editor.state.selection.empty) {
          const activeMarkRange = Queries.getActiveHighlightRange(editor.state);
          text = Queries.getTextSelection(editor, activeMarkRange);
        } else {
          text = Queries.getTextSelection(editor);
        }

        if (aiEnabled && text) {
          fetchTagSuggestions({ text });
        }
      }
    }, [isVisible]);

    const clipUrl = `${window.location.origin}/${highlight?.clip ? 'clips' : 'h'}/${highlight?.uuid}`;

    const acceptTagSuggestions = (tags: string[]) => {
      const suggestedIds = getSuggestionIdsFromTags(tags, tagSuggestions);
      suggestedIds.forEach((id) => acceptSuggestion(id));
    };

    const handleSaveWithoutTags = () => {
      handleOnFirstTagChange([]);
    };

    const handleOnFirstTagChange = async (newTagIds: number[], newTag?: Tag) => {
      const tempId = Date.now();

      setTags(newTagIds);

      const newTagNames = compact(newTagIds.map(getTag)).map((t) => t.name);

      if (newTag) {
        editor.chain().focus().toggleHighlight({ highlightId: tempId, color: newTag.color }).run();
      } else {
        editor.chain().focus().toggleHighlight({ highlightId: tempId, color: 'default' }).run();
      }

      try {
        const { from, to } = editor.state.selection;
        const tWordMarks = Queries.getMarksInRange(editor, { from, to }, 'transcript_word');

        let highlight: Highlight;

        const text = Queries.getTextSelection(editor);

        if (recordingId) {
          const data = await createHighlightWithClip({
            recordingId,
            documentId,
            sessionUuid,
            text,
            tag_ids: newTagIds,
            from: tWordMarks[0]?.attrs.start_ts,
            to: tWordMarks[tWordMarks.length - 1]?.attrs.end_ts
          }).unwrap();

          highlight = data.highlight;
        } else {
          const content = getHighlightContent({ editor, from, to });

          highlight = await createDocumentHighlight({ documentId, text, tag_ids: newTagIds, content }).unwrap();
        }

        editor.chain().focus().updateHighlight(tempId, { highlightId: highlight.id, color: highlight.color }).run();
        editor.commands.focus();

        acceptTagSuggestions(newTagNames);

        track('created_highlight', { page: 'interview_room', tags: newTagNames });
        track('created_clip', { highlight: highlight.id, tags: newTagNames, recording: recordingId });
      } catch (error) {
        editor.chain().focus().unsetHighlight(tempId).run();
      }

      refocusInput();
    };

    const handleOnTagsChange = async (newTagIds: number[]) => {
      setTags(newTagIds);

      const newTagNames = compact(newTagIds.map(getTag)).map((t) => t.name);

      const updatedHighlight = await updateDocumentHighlight({
        document_id: documentId,
        id: highlightId,
        tag_ids: newTagIds
      }).unwrap();

      editor.commands.toggleHighlight({ highlightId: updatedHighlight.id, color: updatedHighlight.color });

      acceptTagSuggestions(newTagNames);

      refocusInput();
    };

    const handleOnDeleteClick = () => {
      if (highlightId) {
        destroyDocumentHighlight({ documentId, highlightId });
        editor.commands.unsetHighlight(highlightId);
        hide?.();

        track('deleted_highlight', { page: 'interview_room', tab: 'transcript' });
        track('deleted_clip', { highlight: highlightId });
      }
    };

    const handleOnPlayClick = () => {
      if (highlightId) {
        const [{ node }] = Queries.findMarksByHighlightId(editor.state, highlightId);

        if (node) {
          const tWordMark = node.marks.find(({ type }) => type.name === 'transcript_word');

          if (tWordMark && videoPlayerRef.current) {
            const tWordAttrs = tWordMark.attrs as TranscriptWordAttributes;

            videoPlayerRef.current.setCurrentTime(tWordAttrs.start_ts / 1000);
            videoPlayerRef.current.play();
          }
        }
      }
    };

    const handleOnCopyLink = () => {
      copy(clipUrl);
      showToast(toasts.successCopyLink());
    };

    const onTagCreate = (tag?: Tag) => {
      track('created_tag', { page: 'interview_room' });
    };

    const readOnly = isFetching || isLoading;
    const onChange = highlightId ? handleOnTagsChange : handleOnFirstTagChange;

    const { canManage, multiselectTags, inputRef, refocusInput } = useTagList({
      tagIds: tags,
      defaultOpen: true,
      readOnly,
      onChange,
      onTagCreate,
      suggest: tagSuggestions,
      studyId,
      closeOnEsc: false
    });
    const { isOpen, closeMenu } = multiselectTags;

    useOnKeypress('Escape', {}, () => {
      if (isOpen) {
        inputRef.current?.blur?.();
      } else {
        hide?.();
      }
    });

    const getTitle = () => {
      if (isOpen && highlightId) return 'Manage tags';
      if (subMenu === 'reel') return 'Add to Reel';
      if (subMenu === 'insight') return 'Add to Insight';
      return '';
    };

    const loading = isFetching || isUpdating || isDeleting || isLoading || highlightLoading;

    if (!highlightId && !canCreate) {
      return null;
    }

    return (
      <section ref={ref} className={cn('w-80', className)} {...rest}>
        {highlight && (nonVideoHlEnabled || highlight.clip) && (
          <div className='w-full border-b border-gray-200'>
            <div className='flex items-center'>
              <>
                {(isOpen || subMenu) && (
                  <button
                    data-testid='back'
                    className='hover:bg-gray-100 hover:text-indigo-600 flex items-center p-1 -m-1 rounded-full'
                    name='back'
                    aria-label='back'
                    onClick={() => {
                      closeMenu();
                      setSubMenu(null);
                    }}
                  >
                    <ChevronLeftSVG className='w-4 h-4' />
                  </button>
                )}
                <Text className='flex-grow text-sm text-center' bold>
                  {getTitle()}
                </Text>
              </>
            </div>
            {!isOpen && !subMenu && (
              <HighlightMenuTitle
                highlight={highlight}
                onSave={(value) =>
                  updateDocumentHighlight({
                    document_id: documentId,
                    title: value,
                    id: highlightId!
                  })
                }
              />
            )}
          </div>
        )}
        {(isFetching || isLoading) && !highlight && (
          <div className='w-full px-4 py-3 border-b border-gray-200'>
            <Skeleton className='bg-gray-50 h-4 rounded-full' />
          </div>
        )}
        {!isFetching && !isLoading && canCreate && isVisible && !subMenu && (
          <div className={cn('relative')}>
            {!highlight && (
              <div className='flex items-center px-4 py-3'>
                <Text className='flex-grow text-sm' bold>
                  Save highlight
                </Text>
                <button
                  aria-label='Close highlight menu'
                  className='hover:text-indigo-500 text-gray-700'
                  onClick={hide}
                >
                  <CloseSVG className='w-4 h-4' />
                </button>
              </div>
            )}
            <TagListButton
              tagIds={tags}
              multiselectTags={multiselectTags}
              previewShowLimit={2}
              className='focus:bg-gray-50 focus:outline-none hover:bg-gray-50 flex items-center w-full h-10 px-4 space-x-2 border-b border-gray-200'
              readOnly={readOnly}
              inputRef={inputRef}
              triggerRef={undefined}
              studyId={studyId}
              pillClassName={cn('min-w-4', {
                'max-w-1/2': !!highlight?.tag_ids?.length
              })}
            />
            <TagListItems
              alwaysOpen={isOpen || ((nonVideoHlEnabled ? !highlight?.id : !highlight?.clip_id) && !loading)}
              className='TagListDropdown pt-2'
              canManage={canManage}
              multiselectTags={multiselectTags}
              studyId={studyId}
            />
            {!highlight?.id && (
              <div
                onClick={handleSaveWithoutTags}
                className='xx-save-without-tags cursor-pointer hover:bg-gray-50 flex items-center px-4 py-1.5 text-sm text-gray-700 border-t border-gray-200'
              >
                <HighlightSVG className='h4 w-4 mr-2' />
                Save without tags
              </div>
            )}
          </div>
        )}
        {canCreate && subMenu === 'reel' && highlight?.clip_id && (
          <BulkAddToHighlightReel
            hide={hide}
            studyId={studyId}
            selectedArtifacts={[`Clip_${highlight.clip_id}`]}
            onlyContent
          />
        )}
        {canCreate && subMenu === 'insight' && (
          <BulkAddToInsightDropdown
            studyId={studyId}
            hide={hide}
            selectedArtifacts={highlight?.clip_id ? [`Clip_${highlight.clip_id}`] : [`Highlight_${highlight?.id}`]}
            onlyContent
          />
        )}
        {!isOpen && !subMenu && (highlight?.id || nonVideoHlEnabled) && (
          <>
            {(isFetching || isLoading) && !highlight && (
              <>
                <div className='w-full px-4 py-3 border-b border-gray-200'>
                  <Skeleton className='bg-gray-50 h-4 rounded-full' />
                </div>
                <div className='w-full px-4 py-3 border-b border-gray-200'>
                  <Skeleton className='bg-gray-50 h-4 rounded-full' />
                </div>
              </>
            )}

            {highlight?.clip_id && (
              <button
                className='focus:outline-none hover:bg-gray-50 focus:bg-gray-50 flex items-center w-full h-10 px-4 text-sm text-gray-700 border-b border-gray-200'
                onClick={handleOnPlayClick}
              >
                <Icons.Play className='w-4 h-4 mr-2' /> Play clip
              </button>
            )}
            {canCreate && highlightId && (
              <>
                <TippyOrNot show={!highlight?.clip_id} content='Only available for highlights with video'>
                  <button
                    disabled={!highlight?.clip_id}
                    onClick={() => setSubMenu('reel')}
                    data-testid='add-to-reel'
                    className={cn(
                      highlight?.clip_id ? 'text-gray-700' : 'text-gray-400',
                      'focus:outline-none hover:bg-gray-50 focus:bg-gray-50 flex items-center w-full h-10 px-4 text-sm border-b border-gray-200'
                    )}
                  >
                    <HighlightReelSVG className='w-4 h-4 mr-2' /> Add to reel
                  </button>
                </TippyOrNot>
                <button
                  onClick={() => setSubMenu('insight')}
                  data-testid='add-to-insight'
                  className='focus:outline-none hover:bg-gray-50 focus:bg-gray-50 flex items-center w-full h-10 px-4 text-sm text-gray-700 border-b border-gray-200'
                >
                  <AddToInsightSVG className='w-4 h-4 mr-2' /> Add to insight
                </button>
                <button
                  className='focus:outline-none hover:bg-gray-50 focus:bg-gray-50 flex items-center w-full h-10 px-4 text-sm text-gray-700 border-b border-gray-200'
                  onClick={handleOnCopyLink}
                >
                  <Icons.Link className='w-4 h-4 mr-2' /> Copy link
                </button>
                <a
                  href={clipUrl}
                  target='_blank'
                  className='focus:outline-none hover:bg-gray-50 focus:bg-gray-50 hover:text-gray-700 focus:text-gray-700 flex items-center w-full h-10 px-4 text-sm text-gray-700 border-b border-gray-200'
                >
                  <FullPageViewSVG className='w-4 h-4 mr-2' /> View on full page
                </a>
                <button
                  className='focus:outline-none hover:bg-gray-50 focus:bg-gray-50 flex items-center w-full h-10 px-4 text-sm text-red-700'
                  onClick={handleOnDeleteClick}
                >
                  <Icons.Trash className='w-4 h-4 mr-2' /> Delete highlight
                </button>
              </>
            )}
            {(isLoading || highlightId) && (
              <HighlightCreatorInfo
                createdAt={highlight && new Date(highlight.created_at)}
                creatorId={highlight && highlight.creator_id}
                ai={!!highlight?.ai}
              />
            )}
          </>
        )}
      </section>
    );
  }
);

HighlightMenu.displayName = 'HighlightMenu';
