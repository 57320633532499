import * as React from 'react';
import { useEffect, useState } from 'react';

import { ArrowButton } from './ScreenerChart/components/ArrowButton';
import { setHash } from '@components/utils';

interface UseBrowseButtonsHook<T> {
  thing: T | undefined;
  renderBrowseButtons: () => React.ReactNode;
}

interface Params<T> {
  things: T[] | undefined;
  initialIndex?: number;
  hashField?: keyof T;
}

export const useBrowseButtons = <T,>({ things, initialIndex = 0, hashField }: Params<T>): UseBrowseButtonsHook<T> => {
  const [idx, setIdx] = useState(initialIndex);

  useEffect(() => {
    setIdx(initialIndex);
  }, [initialIndex]);

  const thing = things?.[idx];

  const handleOnPrevious = () => {
    if (!things) return;

    let index = idx - 1;

    if (idx === 0) {
      index = things.length - 1;
    }

    setIdx(index);

    if (hashField) {
      setHash(`${things?.[index][hashField]}`);
    }
  };

  const handleOnNext = () => {
    if (!things) return;

    let index = idx + 1;

    if (idx === things.length - 1) {
      index = 0;
    }

    setIdx(index);

    if (hashField) {
      setHash(`${things?.[index][hashField]}`);
    }
  };

  return {
    thing,
    renderBrowseButtons: () =>
      things ? (
        <div className='flex items-center'>
          <ArrowButton disabled={false} type='prev' onClick={handleOnPrevious} />
          <div className='mx-4 text-sm text-gray-500'>
            {idx + 1} of {things?.length}
          </div>
          <ArrowButton disabled={false} type='next' onClick={handleOnNext} />
        </div>
      ) : null
  };
};
