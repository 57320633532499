import * as React from 'react';
import { Link } from 'react-router-dom';
import { track } from '@components/tracking';
import cn from 'classnames';

interface DropdownLinkProps {
  className?: string;
  href?: string;
  target?: '_blank';
  spa?: boolean;
  onClick?: React.EventHandler<any>;
  disabled?: boolean;
  color?: string;
  hoverColor?: string;
  trackEvent?: string;
  trackProps?: Record<string, any>;
  ['aria-label']?: string;
}

export const PopperDropdownLink: React.FC<DropdownLinkProps> = ({
  className = '',
  disabled,
  href,
  spa,
  color = 'gray-700',
  hoverColor = 'indigo-600',
  trackEvent,
  trackProps,
  onClick,
  children,
  ...rest
}) => {
  const isLink = href && spa;
  const isAnchor = href && !spa;

  const onButtonClick = (e?: React.MouseEvent) => {
    if (trackEvent) {
      track(trackEvent, trackProps);
    }

    onClick?.(e);
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onButtonClick?.();
    }
  };

  const buttonClassName = cn(
    `tw-ui-dropdown-link whitespace-nowrap w-full text-left text-${color} ${
      disabled ? 'opacity-50 hover:bg-white' : `hover:text-${hoverColor}`
    }`,
    className
  );

  const buttonProps = {
    tabIndex: 0,
    type: 'button' as const,
    onClick: !disabled ? onButtonClick : undefined,
    onKeyDown: !disabled ? onKeyDown : undefined,
    className: buttonClassName,
    ...rest
  };

  const anchorProps = {
    role: 'button',
    tabIndex: 0,
    href: !disabled ? href : undefined,
    className: buttonClassName,
    ...rest
  };

  const linkProps = {
    role: 'button',
    disabled,
    tabIndex: 0,
    to: href || '',
    className: buttonClassName,
    ...rest
  };

  if (isLink) {
    return <Link {...linkProps}>{children}</Link>;
  } else if (isAnchor) {
    return <a {...anchorProps}>{children}</a>;
  } else {
    return <button {...buttonProps}>{children}</button>;
  }
};
