import React, { useMemo, useState } from 'react';
import { Button, Modal, Pill, Text, Tooltip } from '@components/common';
import { PencilSVG, TrashSVG } from '@components/svgs';
import { DeleteRuleModal, RuleSlideOut } from './components';
import { track } from '@components/tracking';
import { buildCandidateFilterDefs, useTableFilters } from '@components/shared/TableFilters';

import { FilterState } from '@components/shared/TableFilters/types';
import { CORE_ATTRS } from '@components/config';
import { useCandidateAttrs } from '@hooks/useCandidateAttrs';
import { api } from '@api/reduxApi';
import { useFeature } from '@hooks/useFeature';
import { useTeams } from '@hooks/useTeams';
import { useToaster } from '@stores/toaster';
import * as toasts from './toasts';
import { SkeletonLoader } from './components/SkeletonLoader';
import { buildEligibilityRuleFilterDefs } from './components/buildEligibilityRuleDefs';
import { EligibilityRule, EligibilityRulesResp } from '@components/GovernanceApp/components/Eligiblity/types';
import { decodeRules, getFiltersString, updateRulesArray } from '@components/GovernanceApp/components/Eligiblity/utils';
import {
  useGetEligibilityRulesQuery,
  useUpdateEligibilityRulesMutation
} from '@components/GovernanceApp/components/Eligiblity/api';
import { parse } from '@components/shared/TableFilters/utils/encode';
import { PlanUpgradeModal } from '@components/shared/RestrictedAction/PlanUpgradeModal';
import { RestrictedButton } from '@components/shared/RestrictedButton';
import { LimitModal } from '@components/GovernanceApp/components/Eligiblity/components/LimitModal';
import pluralize from 'pluralize';
import Tippy from '@tippyjs/react';

const MAX_RULES = 6;

interface Props {
  canEdit: boolean;
}

export const Eligibility: React.FC<Props> = ({ canEdit }) => {
  const { data, isLoading: rulesLoading } = useGetEligibilityRulesQuery();
  const { data: segments } = api.useGetSegmentsQuery();
  const [updateRules] = useUpdateEligibilityRulesMutation();

  const showToast = useToaster();

  const enableTeams = useFeature('teams');

  const { teams } = useTeams({ skip: !enableTeams });
  const { candidateAttrs, isSuccess: attrsFetched } = useCandidateAttrs();

  const [selected, setSelected] = useState<EligibilityRule | null>(null);
  const [upgradeModal, setUpgradeModal] = useState<null | 'plan_upgrade' | 'request_role_upgrade'>(null);
  const [limitModal, setLimitModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [ruleSlideoutOpen, setRuleSlideoutOpen] = useState(false);

  const definitions = useMemo(() => {
    const eligibilityDefs = buildEligibilityRuleFilterDefs();

    const candidateDefs = buildCandidateFilterDefs({
      coreAttrs: CORE_ATTRS,
      customAttrs: candidateAttrs,
      segments: segments || [],
      enableTeams,
      teams
    });

    return [...eligibilityDefs, ...candidateDefs];
  }, [candidateAttrs, segments, teams]);

  const rulesAsFilterStates = useMemo(() => {
    if (!data) return [];

    return decodeRules<Candidate | EligibilityRule>(data, definitions);
  }, [data, definitions]);

  const filtersHook = useTableFilters<Candidate | EligibilityRule>({
    trackKey: 'eligibilityRules',
    definitions
  });

  const saveRules = async (rules: EligibilityRulesResp[]) => {
    await updateRules(rules).unwrap();
    setRuleSlideoutOpen(false);
    setSelected(null);
    filtersHook.clearFilters();
  };

  const onSave = async (filters: FilterState<EligibilityRule>[], { label, title, description }) => {
    if (!data) return;

    const rule: EligibilityRulesResp = {
      label,
      title,
      description,
      filters: filters.map(parse<EligibilityRule>).filter(Boolean) as string[]
    };

    if (!selected) {
      try {
        await saveRules([...data, rule]);
        track('eligibility_rule_created', { title });
        showToast(toasts.successCreate(title));
      } catch {
        showToast(toasts.failedCreate());
      }
      return;
    }

    const updatedRules = updateRulesArray({ oldArray: data, selected, updatedRule: rule });

    try {
      await saveRules(updatedRules);
      track('eligibility_rule_updated', { title: selected.title });
      showToast(toasts.successUpdate(selected.title));
    } catch {
      showToast(toasts.failedUpdate());
    }
  };

  const onAdd = () => {
    track('add_eligibility_rule_clicked');

    if (rulesAsFilterStates.length < MAX_RULES) {
      setRuleSlideoutOpen(true);
      track('add_eligibility_rule_slideout_opened');
    } else {
      setLimitModal(true);
      track('eligibility_rules_limit_modal_opened');
    }
  };

  const onDelete = async (rule: EligibilityRule) => {
    const newRules = data?.filter((r) => r.label !== rule.name);

    if (newRules) {
      try {
        await saveRules(newRules);
        setDeleteModalOpen(false);
        showToast(toasts.successDelete(rule.title));
        track('eligibility_rule_deleted', { title: rule.title });
      } catch {
        showToast(toasts.failedDelete());
      }
    }

    if (ruleSlideoutOpen) {
      setRuleSlideoutOpen(false);
    }
  };

  return (
    <>
      <div className='flex items-center justify-between w-full flex-wrap'>
        <div className='flex items-center space-x-2 flex-nowrap mr-2 mb-3'>
          <Text as='h1' h='700' className='whitespace-nowrap'>
            Candidate eligibility
          </Text>

          <Tippy
            content='Beta testing is our final round of testing before releasing to a wider audience.  Our objective is to uncover as many bugs or usability issues as possible, so we ask you keep that in mind if you do choose to use beta features.'
            placement='right-end'
            arrow={false}
          >
            <div>
              <Pill className='cursor-pointer' color='blue'>
                Beta
              </Pill>
            </div>
          </Tippy>
        </div>

        <div className='flex items-center whitespace-nowrap space-x-3 mb-3'>
          <Button
            medium
            className='whitespace-nowrap'
            trackEvent='clicked_custom_eligibility_help_link'
            icon='externalLink'
            target='_blank'
            href='https://greatquestion.co/support/governance/candidate-eligibility-settings'
          >
            Read help guide
          </Button>
          {canEdit && (
            <RestrictedButton
              buttonProps={{ icon: 'plus', medium: true, ['aria-label']: 'Add eligibility rule' } as any}
              action='Add eligibility rule'
              setUpgradeModal={setUpgradeModal}
              feature='custom_eligibility'>
              <Button primary aria-label='Add eligibility rule' icon='plus' onClick={onAdd} medium
                      className='whitespace-nowrap'>
                Create rule
              </Button>
            </RestrictedButton>
          )}
        </div>

      </div>

      <Text h='500' color='gray-500' className='mb-4'>
        Candidates will be considered ineligible to participate if any of the following rules apply. In order to
        maintain system performance, you can have up to 6 eligibility rules.
      </Text>
      <div className='bg-white border border-gray-200 rounded-md rounded-b-none'>
        <div className='overflow-auto'>
        {rulesLoading ? (
          <SkeletonLoader />
        ) : (
          <table className='w-full'>
            <thead>
            <tr className='border-b border-gray-200'>
              <th className='px-4 py-3 text-sm text-left'>Title</th>
              <th className='px-4 py-3 text-sm text-left'>Description</th>
              <th className='px-4 py-3 text-sm text-left whitespace-nowrap'>Criteria <Tooltip className='ml-1 mt-1'>Determines
                which
                candidates are affected by
                this rule.</Tooltip></th>
              <th className='px-4 py-3 text-sm text-left whitespace-nowrap'>Label <Tooltip className='ml-1 mt-1'>Eligibility
                status on the
                participant when the criteria applies.</Tooltip></th>

              {canEdit && (<th />)}
            </tr>
            </thead>

            <tbody className='divide-y divide-gray-200'>
            {rulesAsFilterStates.map((rule) => {
              const {
                name: label,
                title,
                description,
                filters: { filters }
              } = rule;

                return (
                  <tr key={label} className='group text-sm text-gray-700'>
                    <td className='px-4 py-3 whitespace-nowra align-top'>{title}</td>
                    <td className='px-4 py-3 min-w-80 align-top'>{description}</td>
                    <td
                      className='px-4 py-3 whitespace-nowrap align-top'>{pluralize('criterias', filters?.length, true)}</td>
                    <td className='px-4 py-3 align-top'>
                      <div className='inline px-2 py-0.5 bg-orange-200 rounded-full whitespace-nowrap h400'>
                        {label}
                      </div>
                    </td>
                    {canEdit && (
                      <td className='px-4 py-1.5 align-top'>
                        <div className='flex justify-end items-center flex-nowrap h-full space-x-2'>
                          <Tippy content='Edit rule' placement='top' arrow={false}>
                            <button
                              className='rounded-full p-2 disabled:opacity-50 focus:ring focus:ring-blue focus:outline-none hover:bg-gray-50 hover:text-indigo-700 active:text-indigo-700 focus:ring-indigo'
                              data-testid='edit'
                              onClick={() => {
                                setSelected(rule);
                                filtersHook.setFilters(filters);
                                setRuleSlideoutOpen(true);
                                track('edit_eligibility_rule_clicked', { title: rule.title });
                              }}
                            >
                              <PencilSVG />
                            </button>
                          </Tippy>
                          <Tippy content='Delete rule' placement='top' arrow={false}>
                            <button
                              className='rounded-full p-2 disabled:opacity-50 focus:ring focus:ring-blue focus:outline-none hover:bg-gray-50 hover:text-indigo-700 active:text-indigo-700 focus:ring-indigo'
                              data-testid='delete'
                              onClick={() => {
                                setSelected(rule);
                                setDeleteModalOpen(true);
                                track('delete_eligibility_rule_clicked', { title: rule.title });
                              }}
                            >
                              <TrashSVG />
                            </button>
                          </Tippy>
                        </div>
                      </td>)}
                  </tr>
                );
            })}
            </tbody>
          </table>
        )}
      </div>
      </div>

      <div className='bg-white p-4 border border-t-0 border-gray-200 rounded-b-md'>
        <Text h='400'>
          If none of the rules above apply candidates will be considered{' '}
          <span className='inline px-2 py-0.5 bg-green-100 rounded-full whitespace-nowrap h400'>eligible</span> to
          participate in research studies.
        </Text>
      </div>
      <RuleSlideOut
        hook={filtersHook}
        rule={selected}
        open={ruleSlideoutOpen}
        onSave={onSave}
        editable
        onClose={() => {
          setRuleSlideoutOpen(false);
          setSelected(null);
          filtersHook.clearFilters();
        }}
      />
      {selected && canEdit && (
        <DeleteRuleModal
          rule={selected}
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onSubmit={onDelete}
        />
      )}
      {upgradeModal === 'plan_upgrade' && (
        <PlanUpgradeModal feature='custom_eligibility' onClose={() => {
          setUpgradeModal(null);
          track('upgrade_modal_opened', { feature: 'custom_eligibility' });
        }} />
      )}

      <LimitModal open={limitModal} onClose={() => setLimitModal(false)} />
    </>
  );
};
