import { useEffect, useState } from 'react';

import { api } from '@api/reduxApi';

import { isEmailCheckFailed } from '../utils';

const POLLING_INTERVAL = 3000;

type Props = {
  studyId: number;
  messageId?: number;
};

type Hook = {
  emailCheck?: EmailCheck;
  isFailed: boolean;
  startPolling: () => void;
};

export const useStudyMessageValidity = ({ studyId, messageId = 0 }: Props): Hook => {
  const [polling, setPolling] = useState(false);

  const { data: emailCheck } = api.useGetStudyMessageValidityQuery(
    { studyId, messageId },
    { skip: !messageId, pollingInterval: polling ? POLLING_INTERVAL : 0 }
  );

  useEffect(() => {
    if (polling && emailCheck && Object.keys(emailCheck).length === 0) {
      setPolling(false);
    }
  }, [polling, emailCheck]);

  return {
    emailCheck,
    isFailed: isEmailCheckFailed(emailCheck),
    startPolling: () => setPolling(true)
  };
};
