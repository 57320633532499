import classNames from 'classnames';
import * as React from 'react';

import { Avatar, Card, DropdownLink, Spinner, Text, TippyOrNot } from '@components/common';
import { CaretDownSVG, ErrorSvg } from '@components/svgs';
import { usePopUp } from '@hooks/usePopUp';

import { EVENT_COLORS } from '../NylasEvent';
import { useUserCalendars } from '../../../hooks/useUserCalendars';
import { useGQCalendarContext } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/hooks/useCalendarContext';

interface Props {
  isOwner: boolean;
  disabled: boolean;
  loading: boolean;
  error?: boolean;
  calendars?: NylasCalendar[] | null;
  user: TeamUser;
  calendarId?: string | null;
  onChangeCalendar?: (cal: NylasCalendar) => void;
  onChangeCalendarId?: (nylas_calendar_id: string) => void;
  onClickRemove: () => void;
  onClickSwitchToCalendly?: () => void;
  type: 'observer' | 'moderator';
  requireCalendar: boolean;
}
export const Moderator: React.FC<Props> = ({
  isOwner,
  user,
  disabled,
  loading,
  calendars: initialCalendars,
  calendarId,
  onChangeCalendar,
  onChangeCalendarId,
  onClickRemove,
  onClickSwitchToCalendly,
  type,
  requireCalendar
}) => {
  const { studyUserColors } = useGQCalendarContext();

  const { ref, togglePopUp, closePopUp, PopUp, open } = usePopUp();
  const { calendars } = useUserCalendars({ calendars: initialCalendars, userId: user.id });

  const handleClickRemove = () => {
    onClickRemove();
    closePopUp();
  };

  const handleClickCalendarId = (cal: NylasCalendar) => {
    onChangeCalendar?.(cal);
    onChangeCalendarId?.(cal.id);
    closePopUp();
  };

  const calendar = calendars?.find(({ id }) => id === calendarId);

  const [color, grade] = user.is_phantom ? ['gray', 400] : studyUserColors?.[user.id] || EVENT_COLORS[0];

  return (
    <div
      key={user.id}
      ref={ref}
      data-testid='moderator'
      className={classNames('xx-moderator cursor-pointer group relative flex items-center px-6 py-3 -mx-6', {
        'hover:bg-gray-50': !disabled
      })}
      onClick={togglePopUp}
    >
      <div className={classNames('mr-4 flex-shrink-0', `border-${color}-${grade} border-2 rounded-full`)}>
        <Avatar size='lg' user={user} />
      </div>
      <div className='flex-grow truncate'>
        <Text h='400'>
          {user.name}
          {user.is_phantom && '*'}
        </Text>
        <div className='flex items-center space-x-1'>
          {requireCalendar && !calendar && <ErrorSvg className='w-4 h-4' />}

          {calendar?.name ? (
            <Text h='400' color='gray-500' truncate>
              {calendar?.name}
            </Text>
          ) : (
            requireCalendar && (
              <Text h='400' color='gray-500' truncate>
                Choose a calendar…
              </Text>
            )
          )}
        </div>
      </div>
      {!loading && !disabled && (
        <button className='group-hover:block hidden' name='more'>
          <CaretDownSVG />
        </button>
      )}
      {loading && <Spinner className='w-4 h-4' />}
      <PopUp open={open} zIndex={30} className='top-full left-0 right-0 px-6'>
        <Card className='max-h-48 overflow-y-scroll shadow-lg' noPadding>
          <div className='p-4'>
            <Text h='400' bold className='mb-1'>
              {user.name}’s calendars
            </Text>
          </div>
          {calendars &&
            calendars
              .filter((cal) => !cal.read_only)
              .map((cal) => (
                <DropdownLink
                  disabled={cal.read_only || disabled}
                  key={cal.id}
                  onClick={() => handleClickCalendarId(cal)}
                >
                  {cal.name}
                </DropdownLink>
              ))}
          {!calendars.length && (
            <div className='mb-4 px-4'>
              <Text h='400' color='gray-500'>
                No calendars found, so events can’t be shown.
              </Text>
            </div>
          )}
          <hr />
          {requireCalendar && onClickSwitchToCalendly && (
            <DropdownLink onClick={onClickSwitchToCalendly}>Switch to Calendly…</DropdownLink>
          )}

          <TippyOrNot
            content={`Please assign another study owner before removing ${user.name} from the moderator rotation`}
            show={isOwner}
          >
            <DropdownLink disabled={isOwner || disabled} onClick={handleClickRemove}>
              Remove as {type}
            </DropdownLink>
          </TippyOrNot>
        </Card>
      </PopUp>
    </div>
  );
};
