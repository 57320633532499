import { Button, Dropdown, DropdownLink, Modal, TeamIcon, Text } from 'components/common';
import React, { useState } from 'react';

import { useDeviceType } from '@hooks/useDeviceType';

import { SurveyPreview } from '@components/BrandingApp/components/SurveyPreview';
import { SchedulingPagePreview } from '@components/BrandingApp/components/SchedulingPagePreview';
import { LandingPagePreview } from '@components/BrandingApp/components/LandingPagePreview';
import { EmailPreview } from '@components/BrandingApp/components/EmailPreview';
import { useAccount } from '@hooks/useAccount';

interface Props {
  edit: boolean;
  setEdit: (v: boolean) => void;
  setIsDirty: (v: boolean) => void;
  onSave: () => void;
  team?: Team;
  brand: Brand;
  disabled?: boolean;
}

type PreviewPage = 'survey' | 'landing_page' | 'scheduling_page' | 'email';

export const Header: React.FC<Props> = ({ disabled, edit, setIsDirty, setEdit, onSave, team, brand }) => {
  const [open, setOpen] = useState<boolean>();
  const [preview, setPreview] = useState<PreviewPage | null>(null);

  const { isMobile } = useDeviceType();

  const {
    account: { name: accountName }
  } = useAccount();

  const onCancel = () => {
    setIsDirty(false);
    setEdit(false);
  };

  const renderPreview = (page: PreviewPage) => {
    switch (page) {
      case 'email':
        return <EmailPreview brand={brand} accountName={accountName} />;
      case 'landing_page':
        return <LandingPagePreview brand={brand} accountName={accountName} />;
      case 'scheduling_page':
        return <SchedulingPagePreview brand={brand} accountName={accountName} />;
      case 'survey':
        return <SurveyPreview brand={brand} accountName={accountName} />;
    }
  };

  return (
    <>
      <section className='desktop:flex-row desktop:space-y-0 flex flex-col items-center space-y-3'>
        {team && <TeamIcon team={team} className='mr-2' />}
        <Text h='600' bold className='flex-1'>
          {edit ? 'Edit branding' : 'Branding'}
        </Text>
        <div className='flex space-x-3'>
          {edit && (
            <Button onClick={onCancel} medium link>
              Cancel changes
            </Button>
          )}
          <Dropdown
            disabled={disabled}
            isOpen={open}
            onClick={() => setOpen(!open)}
            onClose={() => setOpen(false)}
            medium
            position={isMobile ? 'left' : 'right'}
            className='xx-preview-dropdown bg-white'
            text='Preview'
            icon='eye'
          >
            <DropdownLink onClick={() => setPreview('email')}>Email</DropdownLink>
            <DropdownLink onClick={() => setPreview('landing_page')}>Landing page</DropdownLink>
            <DropdownLink onClick={() => setPreview('scheduling_page')}>Scheduling page</DropdownLink>
            <DropdownLink onClick={() => setPreview('survey')}>Survey</DropdownLink>
          </Dropdown>
          {edit ? (
            <Button onClick={onSave} primary medium>
              Save
            </Button>
          ) : (
            <Button disabled={disabled} data-testid='edit' onClick={() => setEdit(true)} medium icon='pencil'>
              Edit
            </Button>
          )}
        </div>
      </section>
      {!edit && (
        <>
          <Text color='gray-500' className='mt-4'>
            {
              'For participant-facing experience including emails & webpages. Only emails sent from your domain will include the branding.'
            }
          </Text>
        </>
      )}
      {preview && (
        <Modal noPadding onClose={() => setPreview(null)} fullScreen>
          <div className='h-full overflow-y-auto brand'>{renderPreview(preview)}</div>
        </Modal>
      )}
    </>
  );
};
