import qs from 'qs';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { Button, Text } from '@components/common';

import { Scheduler } from '../components/Scheduler';
import { browserTimezoneDesc, TimezoneDesc } from '../../../shared/TimezonePicker';
import { buildTimeProposalSlots } from '../utils/buildTimeProposalSlots';
import { addTz } from '../utils/addTz';
import { useTranslation } from 'react-i18next';

type Props = {
  study: Study;
  participation?: Participation;
  researcherView?: boolean;
};
export const NewTimeProposalPage: React.FC<Props> = ({ study, participation, researcherView }) => {
  const { t, i18n } = useTranslation('TimeProposal');
  const [calendarOffset, setCalendarOffset] = useState<number>(0);

  const [selectedAvailabilities, setSelectedAvailabilities] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedTimezone, setSelectedTimezone] = useState<TimezoneDesc>(browserTimezoneDesc());

  const timeslots: Timeslots = useMemo(
    () =>
      buildTimeProposalSlots(
        new Date(),
        new Date(study.schedulable_beginning),
        new Date(study.schedulable_end),
        selectedTimezone
      ),
    [selectedTimezone]
  );

  const handleClick = (day: string, time: string) => () => {
    setSelectedDate(new Date(day + 'T00:00:00'));
    const timeStr = `${day}T${time}`;
    if (selectedAvailabilities.includes(timeStr)) {
      setSelectedAvailabilities(selectedAvailabilities.filter((a) => a !== timeStr));
    } else {
      setSelectedAvailabilities([...selectedAvailabilities, timeStr]);
    }
  };

  const confirmUrl = useMemo(() => {
    const params = {
      tz: selectedTimezone.name,
      time_proposal: selectedAvailabilities.map(addTz).join(','),
      token: participation?.token
    };
    if (!params.token) delete params.token;
    return `/${study.public_path}/time_proposals/new?${qs.stringify(params)}`;
  }, [study.public_path, participation, selectedTimezone.name, selectedAvailabilities]);

  useEffect(() => {
    i18n.changeLanguage(study.language);
  }, []);

  return (
    <div>
      <div className='p-4 border border-gray-200 rounded-md'>
        <Scheduler
          study={study}
          participation={participation}
          researcherView={researcherView}
          isLoading={false}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          timeslots={timeslots}
          selectedTimezone={selectedTimezone}
          setSelectedTimezone={setSelectedTimezone}
          calendarOffset={calendarOffset}
          setCalendarOffset={setCalendarOffset}
          calendarProps={{
            timeslots
          }}
          renderTimeslot={({ time, day, displayText }) => (
            <li key={time} className='group w-full space-x-4 text-center'>
              <label
                htmlFor={`${day}T${time}`}
                className='focus:outline-none hover:text-gray-600 hover:border-gray-600 relative w-full h-full px-4 py-2 text-center truncate border border-gray-200 rounded-md cursor-pointer'
              >
                <div className='left-5 absolute'>
                  <input
                    id={`${day}T${time}`}
                    className='focus:ring-gray-600 w-4 h-4 text-gray-600 border-gray-300'
                    type='checkbox'
                    aria-label={`${day}T${time}`}
                    value={`${day}T${time}`}
                    checked={selectedAvailabilities.includes(`${day}T${time}`)}
                    onChange={handleClick(day, time)}
                  />
                </div>
                <Text as='span' h='400'>
                  {displayText}
                </Text>
              </label>
            </li>
          )}
        />
      </div>
      <div className='py-4 space-x-4'>
        <Button text className='btn-custom-brand-secondary' href={`/${study.public_path}`}>
          {t('cancel')}
        </Button>
        {selectedAvailabilities.length < 1 && (
          <Button secondary className='btn-custom-brand' disabled>
            {t('continue')}
          </Button>
        )}
        {selectedAvailabilities.length > 0 && (
          <Button secondary className='btn-custom-brand' href={confirmUrl}>
            {t('continue')}
          </Button>
        )}
      </div>
    </div>
  );
};
