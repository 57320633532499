import * as React from 'react';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { DropdownItem } from '@components/common/DropdownCombobox';
import { useStudies } from '@hooks/useStudies';
import { SelectDropdown } from '@components/shared/SelectDropdown';

import { StudyItem, studyValid } from './StudyItem';
import { ItemProps } from '@components/shared/SelectDropdown/components/Item';
import { SelectorSkeleton } from './SelectorSkeleton';

interface Props {
  canFund: boolean;
  study?: Study;
  setStudy: (study: Study) => void;
  onNew: (study: Partial<Study>) => void;
}

function studySort(a: PartialStudy, b: PartialStudy, canFund: boolean): number {
  const aValid = studyValid(a, canFund);
  const bValid = studyValid(b, canFund);
  if (aValid === bValid && b.updated_at && a.updated_at) return b.updated_at.getTime() - a.updated_at.getTime();
  if (aValid) return -1;
  if (bValid) return 1;
  return 0;
}

export const StudySelector: React.FC<Props> = ({ onNew, study, setStudy, canFund }) => {
  const { loading, studies } = useStudies();

  // TODO: sort order: valid, updated_at
  const options: DropdownItem[] = useMemo(() => {
    const _studies = [...(studies || [])];

    return _studies
      .sort((a, b) => studySort(a, b, canFund))
      .map(({ id, title }) => ({ label: title || '', value: id.toString() }));
  }, [loading, studies]);

  let selected: { label: string; value: string } | null = null;
  if (study && study.id) {
    selected = { label: study?.title || '', value: study.id?.toString() };
  } else if (study) {
    selected = { label: `New Study`, value: '' };
  }

  if (loading) {
    return <SelectorSkeleton />;
  }

  return (
    <>
      <SelectDropdown<DropdownItem>
        creatable
        onChange={(item) => {
          // TODO: should we actually just fetch the study here - and get the fund into.
          const selectedStudy = studies?.find(({ id }) => id.toString() === item[0].value);

          if (selectedStudy) {
            setStudy(selectedStudy);
          }
        }}
        options={options}
        onCreate={(item) => onNew({ title: item.value })}
        value={selected ? [selected] : []}
        overrides={{
          Input: {
            props: {
              placeholder: 'Enter study title…',
              className: 'xx-combo-input text-sm'
            }
          },
          Item: {
            component: (props: ItemProps) => <StudyItem canFund={canFund} studies={studies} {...props} />
          }
        }}
      />
    </>
  );
};
