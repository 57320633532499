import * as React from 'react';

import { Preview } from '@components/Loom';

import { InputProps } from '../types';

export const WatchLoomInput: React.FC<InputProps> = ({ field: { id, param }, register }) => {
  return (
    <div>
      <Preview id={param as any} />
      <input type='hidden' name={String(id)} ref={register({ required: true })} />
    </div>
  );
};
