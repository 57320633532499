import React, { useEffect, useState } from 'react';
import { Node as ProsemirrorNode } from 'prosemirror-model';
import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { CtaTooltip } from '@components/StudyMessages/components/DocumentPreview/CtaTooltip';
import HeadlessTippy from '@tippyjs/react/headless';
import { useTiptapContext } from '@components/shared/Tiptap/hooks';
import cn from 'classnames';

export const CtaView = (props: NodeViewProps) => {
  const { ctaProps } = useTiptapContext();

  const editable = props.editor.isEditable;

  const isActive = props.editor.isActive('cta');

  const [text, setText] = useState(props.node.textContent);

  useEffect(() => {
    if (props.node.textContent === text) return;

    setText(props.node.textContent);
  }, [props.node.textContent]);

  const onBlur = () => {
    if (!text) return;

    const thisPos = props.getPos();

    props.editor
      .chain()
      .setNodeSelection(thisPos)
      .command(({ tr }) => {
        const newNode = ProsemirrorNode.fromJSON(props.editor.schema, {
          type: props.node.type.name,
          attrs: { ...props.node.attrs },
          content: [
            {
              type: 'text',
              text
            }
          ]
        });

        tr.replaceSelectionWith(newNode);

        return true;
      })
      .run();
  };

  if (!ctaProps) {
    return (
      <NodeViewWrapper>
        <a
          className={cn(
            isActive && 'ring ring-blue',
            'btn whitespace-normal h-auto bg-gray-700 text-white hover:text-white hover:bg-gray-500 active:text-white active:bg-indigo-500 focus:text-white focus:ring-indigo text-sm leading-5 px-4 py-2.5 rounded-md mt-2 mb-6'
          )}
        >
          <NodeViewContent />
        </a>
      </NodeViewWrapper>
    );
  }

  return (
    <NodeViewWrapper contentEditable='false'>
      <HeadlessTippy
        interactive
        placement='top-start'
        onHide={onBlur}
        render={(attrs) => (
          <CtaTooltip
            {...attrs}
            editable={editable}
            {...ctaProps}
            value={text || ''}
            onBlur={onBlur}
            setValue={setText}
          />
        )}
      >
        <a
          contentEditable={false}
          className={cn(
            isActive && 'ring ring-blue',
            'btn whitespace-normal h-auto bg-gray-700 text-white hover:text-white hover:bg-gray-500 active:text-white active:bg-indigo-500 focus:text-white focus:ring-indigo text-sm leading-5 px-4 py-2.5 rounded-md mt-2 mb-6'
          )}
        >
          {text}
        </a>
      </HeadlessTippy>
    </NodeViewWrapper>
  );
};
