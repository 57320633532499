import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { useGetDocumentQuery } from '@components/shared/Tiptap/api';

import { renderNodes } from './renderNodes';
import { CtaProps } from '@components/StudyMessages/components/DocumentPreview/CtaTooltip/helpers';

interface Props {
  id: number;
  renderMergeTag?: (str: string) => JSX.Element;
  evalIfCondition?: (str: string) => boolean;
  ctaUrl?: string;
  ctaAs?: 'button' | 'link';
  ctaProps?: CtaProps;
}

export const DocumentPreview: React.FC<Props> = ({
  id,
  renderMergeTag,
  evalIfCondition,
  ctaAs = 'button',
  ctaProps
}) => {
  const { data: document, isLoading } = useGetDocumentQuery(id);

  if (document && document.doc) {
    return (
      <div className='p-6'>
        {renderNodes(document.doc.content, {
          renderMergeTag,
          evalIfCondition,
          ctaAs: ctaAs,
          ctaProps
        })}
      </div>
    );
  } else {
    return <Skeleton duration={1} className='bg-gray-50 block w-full h-48 rounded-md' />;
  }
};
