import * as React from 'react';
import { HeaderProps } from 'react-table';
import { ParticipationTableItem } from '@components/StudiesApp/components/StudyPublished/ParticipationTable/helpers/buildParticipantsColumns';
import IndeterminateCheckbox from '@components/common/tables/IndeterminateCheckbox';
import { NAME_CHECKBOX_STYLE } from '../constants';
import { ChevronDownSVG } from '@components/svgs';
import { ColumnsActionsDropdown } from '@components/shared/GridTable/components/shared';
import { PopperDropdown } from '@components/common';

interface NameHeaderProps extends React.PropsWithChildren<HeaderProps<Candidate | ParticipationTableItem>> {
  resetOnClick: () => void;
  pageCount: number;
  setShowSelectAll: (value: boolean) => void;
  addFilter?: (value: string) => void;
}

export const NameHeader: React.FC<NameHeaderProps> = ({
  getToggleAllRowsSelectedProps,
  resetOnClick,
  column,
  setSortValue,
  pageCount,
  setShowSelectAll,
  setAllSelected,
  addFilter
}) => {
  const changeSort = (descending: boolean): void => {
    setSortValue(column.id, descending);
    column.toggleSortBy?.(descending);
  };

  const { onChange, checked, ...rest } = getToggleAllRowsSelectedProps();
  const allRowsSelectedProps = {
    ...rest,
    checked,
    onChange: (e) => {
      if (pageCount > 1) {
        if (checked) {
          setShowSelectAll(false);
          setAllSelected(false);
        } else {
          setShowSelectAll(true);
        }
      }
      (onChange as (e) => void)(e);
    }
  };

  return (
    <div className='flex items-center w-full h-full pr-2 overflow-visible'>
      <div className='h400 flex-shrink-0 relative overflow-visible text-center bg-white' style={NAME_CHECKBOX_STYLE}>
        <IndeterminateCheckbox {...allRowsSelectedProps} onClick={resetOnClick} />
      </div>
      <div className='h400 hover:bg-gray-50 overflow-hidden hover:text-indigo-600 flex items-center w-full h-full px-2 font-bold cursor-pointer'>
        <PopperDropdown
          hideCaret
          popperProps={{ placement: 'bottom-end' }}
          className='group flex-1 flex-shrink-0 flex h-full flex-col items-stretch h400 px-2 font-bold cursor-pointer'
          tooltip='Name column options'
          text={
            <div className='flex flex-1 justify-between pl-2'>
              <span className='flex flex-grow truncate font-bold' title='Name'>
                Name
              </span>
              <ChevronDownSVG className='group-hover:block hidden text-gray-500' />
            </div>
          }
        >
          <ColumnsActionsDropdown
            addFilter={addFilter}
            changeSort={changeSort}
            columnAccessor={column.id}
            sortIconType='text'
          />
        </PopperDropdown>
      </div>
    </div>
  );
};
