import tinytime from 'tinytime';
import * as React from 'react';
import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { api } from '@api/reduxApi';
import { Select, Spinner, Text, Tooltip } from '@components/common';
import { track } from '@components/tracking';
import { useFeature } from '@hooks/useFeature';
import { usePermission } from '@hooks/usePermission';
import { useToaster } from '@stores/toaster';

import { ContactAccess } from './ContactAccess';
import * as toasts from './toasts';

const dateTemplate = tinytime('{MMMM} {Do}, {YYYY}');

type Frequency = 'every_day' | 'every_1mo' | 'every_3d' | 'every_3mo' | 'every_6mo' | 'every_1w' | 'every_12mo';

const DEBOUNCE_RATE = 500;

const options = [
  {
    value: 'every_day',
    label: 'day'
  },
  {
    value: 'every_3d',
    label: '3 days'
  },
  {
    value: 'every_1w',
    label: 'week'
  },
  {
    value: 'every_1mo',
    label: 'month'
  },
  {
    value: 'every_3mo',
    label: '3 months'
  },
  {
    value: 'every_6mo',
    label: '6 months'
  },
  {
    value: 'every_12mo',
    label: 'year'
  },
  {
    value: 'anytime',
    label: 'as needed'
  }
];

const getPeriod = (value: Frequency): number => {
  switch (value) {
    case 'every_day':
    case 'every_1mo':
      return 1;
    case 'every_3d':
    case 'every_3mo':
      return 3;
    case 'every_6mo':
      return 6;
    case 'every_1w':
      return 7;
    case 'every_12mo':
      return 12;
  }
};

interface Props {
  candidate: Candidate;
  onUpdate: (res: Candidate) => void;
}

export interface FormValues {
  period?: string;
  frequency?: string;
}

export const Settings: React.FC<Props> = ({ candidate, onUpdate }) => {
  const [frequency, setFrequency] = useState(candidate.contact_frequency || 'anytime');

  const { last_contacted_at, name, id } = candidate;

  const showToast = useToaster();

  const enableTeams = useFeature('teams');
  const canManageTeams = usePermission('manageTeams')();

  const [teamIds, setTeamIds] = useState<number[]>(candidate?.team_ids || []);
  const [contactAccess, setContactAccess] = useState<ContactAccessType>(candidate?.contact_access || 'public');
  const [updateContactAccess, { isLoading: isUpdatingContactAccess }] = api.useUpdateCandidateContactAccessMutation();
  const [updateCandidate] = api.useUpdateCandidateMutation();

  const handleSave = (frequency: string): void => {
    updateCandidate({ id, contact_frequency: frequency })
      .unwrap()
      .then((res) => {
        onUpdate(res);
        showToast(toasts.successUpdate());
      })
      .catch(() => {
        showToast(toasts.failedUpdate());
      });
  };

  const onSelect = (value: string): void => {
    setFrequency(value);
    handleSave(value);
  };

  const getNextContactDate = () => {
    if (!last_contacted_at) return;
    switch (frequency) {
      case 'anytime':
        return null;
      case 'every_day':
      case 'every_3d':
      case 'every_1w':
        return new Date(
          new Date(last_contacted_at).getFullYear(),
          new Date(last_contacted_at).getMonth(),
          new Date(last_contacted_at).getDate() + getPeriod(frequency)
        );
      case 'every_1mo':
      case 'every_3mo':
      case 'every_6mo':
      case 'every_12mo':
        return new Date(
          new Date(last_contacted_at).getFullYear(),
          new Date(last_contacted_at).getMonth() + getPeriod(frequency),
          new Date(last_contacted_at).getDate()
        );
    }
  };

  const { callback: debouncedUpdateContactAccess } = useDebouncedCallback(() => {
    track('updated_candidate_contact_access', {
      count: 1,
      contact_access: contactAccess,
      teams_count: teamIds.length,
      source: 'profile'
    });
    updateContactAccess({
      candidates: { ids: [candidate.id] },
      team_ids: teamIds,
      contact_access: contactAccess
    });
  }, DEBOUNCE_RATE);

  return (
    <div className='my-10'>
      <div className='mb-4'>
        <div className='flex flex-wrap items-center'>
          <p>
            {name} can be contacted {frequency === 'anytime' ? '' : 'no more than once per'}
          </p>

          <div className='inline-block w-48'>
            <Select
              className='my-2 ml-2'
              ulClassName='h-20'
              value={frequency}
              placeholder={frequency}
              onChange={onSelect}
              options={options}
            />
          </div>
        </div>
        {getNextContactDate() && last_contacted_at && (
          <div>
            <p>Next possible contact date {dateTemplate.render(getNextContactDate())}</p>
          </div>
        )}
      </div>

      {enableTeams && (
        <div className='pb-10'>
          <div className='flex items-center mb-4'>
            <Text className='mr-2' bold>
              Contact access
            </Text>
            <Tooltip content='Which teams can contact this candidate' />
            {isUpdatingContactAccess && <Spinner className='w-4 h-4 ml-2' />}
          </div>
          <ContactAccess
            className='max-w-sm'
            disabled={!canManageTeams}
            initialTeamIds={teamIds}
            initialValue={contactAccess}
            onChangeValue={(value) => {
              setContactAccess(value);
              debouncedUpdateContactAccess();
            }}
            onChangeTeamIds={(teamIds) => {
              setTeamIds(teamIds);
              debouncedUpdateContactAccess();
            }}
            selectProps={{
              popperOptions: {
                placement: 'top'
              }
            }}
          />
        </div>
      )}
    </div>
  );
};
