import * as React from 'react';
import { useEffect, useState } from 'react';
import { CellProps } from 'react-table';
import { ParticipationTableItem } from '@components/StudiesApp/components/StudyPublished/ParticipationTable/helpers/buildParticipantsColumns';
import IndeterminateCheckbox from '@components/common/tables/IndeterminateCheckbox';
import { OnHover, OnToggle } from '../../types';
import { NAME_CHECKBOX_STYLE } from '../constants';

interface Props extends React.PropsWithChildren<CellProps<Candidate | ParticipationTableItem>> {
  onClickCandidate: (row: Candidate | ParticipationTableItem) => void;
  onToggle: OnToggle;
  handleMouseOver: OnHover;
  hoveredRows: number[];
}

export const NameCell: React.FC<Props> = (props) => {
  const { row, value, onClickCandidate, onToggle, toggleRowSelected, rows, handleMouseOver, hoveredRows } = props;
  const [active, setActive] = useState(false);

  const index = rows.findIndex((i) => i === row);

  // Handling checkboxes border color changing on shift press
  useEffect(() => {
    if (hoveredRows.includes(index)) {
      setActive(true);
    }
  }, [hoveredRows]);

  const onClick: React.EventHandler<any> = (e) => {
    onToggle({
      index,
      e,
      toggleRowSelected,
      rows,
      row
    });
  };

  const onHover: React.EventHandler<any> = (e) => {
    handleMouseOver({
      index,
      e,
      rows,
      row
    });
  };

  return (
    <div className='xx-candidate-name flex items-center w-full h-full'>
      <div className='h400 flex items-center justify-center h-full bg-white' style={NAME_CHECKBOX_STYLE}>
        <IndeterminateCheckbox
          {...row.getToggleRowSelectedProps()}
          onClick={onClick}
          onMouseEnter={onHover}
          active={active}
        />
      </div>
      <div className='flex items-center flex-1 h-full truncate bg-white'>
        <div
          aria-label={value || 'Unnamed candidate'}
          className='xx-candidate-link h400 pl-2 font-bold text-indigo-600 cursor-pointer'
          onClick={(e) => {
            if (onClickCandidate) {
              e.preventDefault();
              onClickCandidate(row.original);
            }
          }}
        >
          {value || <span className='font-light'>Unnamed candidate</span>}
        </div>
      </div>
    </div>
  );
};
