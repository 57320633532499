import pluralize from 'pluralize';
import React, { useEffect, useState } from 'react';

import { api } from '@api/reduxApi';
import { Button, Dropdown, DropdownLink, Modal } from '@components/common';
import { RestrictedButton } from '@components/shared/RestrictedButton';
import { useToaster } from '@components/stores/toaster';
import { ExportSVG, PencilSVG, ShortlistSVG, TrashSVG } from '@components/svgs';
import { useFeature } from '@hooks/useFeature';
import { usePermission } from '@hooks/usePermission';
import Tippy from '@tippyjs/react';

import { EditContactAccessModal } from './EditContactAccessModal';
import { CandidateSlideOut, FilterQuery } from './types';
import { ExportCsvModal } from '@components/shared/ExportCsvModal';

interface MenuProps {
  selectedIds: number[];
  setSlideOut: (slideOut: CandidateSlideOut) => void;
  allSelected?: boolean;
  selectedCount: number;
  onStartBulkDelete: (ids: number[], backgroundTask: BackgroundTask) => void;
  onEditTeams: () => void;
  currentViewColumns: string[];
  query: FilterQuery;
  addCandidatesModal?: boolean;
  resetSelection: () => void;
  refetchCandidates: () => void;
}

export const BulkActions: React.FC<MenuProps> = ({
  onStartBulkDelete,
  onEditTeams,
  selectedIds,
  allSelected,
  setSlideOut,
  selectedCount,
  currentViewColumns,
  query,
  addCandidatesModal,
  resetSelection,
  refetchCandidates
}) => {
  const showToast = useToaster();
  const [open, setOpen] = useState<boolean>();

  const toggleMenu = () => setOpen(!open);

  const closeMenu = () => setOpen(false);

  const enableTeams = useFeature('teams');

  const canManageTeams = usePermission('manageTeams')();
  const canDelete = usePermission('deleteCandidate')();
  const canExportCsv = usePermission('exportCsv')();
  const canSeeExportButton = usePermission('canCreate')();

  const [
    bulkDeleteCandidates,
    { data, isLoading: isDeleteLoading, isSuccess: deleteSuccess, isError: isDeleteError, error: deleteError }
  ] = api.useBulkCandidateDeleteMutation();

  const [bulkDeleteModalOpen, setBulkDeleteModalOpen] = useState<boolean>(false);
  const [csvExportModalOpen, setCsvExportModalOpen] = useState<boolean>(false);
  const [editContactAccessModalOpen, setEditContactAccessModalOpen] = useState<boolean>(false);

  const candidateParams = { ...(allSelected ? { query } : { ids: selectedIds }) };

  useEffect(() => {
    if (deleteSuccess && data) {
      setBulkDeleteModalOpen(false);
      onStartBulkDelete(selectedIds, data);
      resetSelection();
      showToast({
        icon: 'success',
        heading: 'Done!',
        text: `Deleted ${pluralize('candidate', selectedCount, true)}.`
      });
    }
  }, [deleteSuccess, data]);

  useEffect(() => {
    if (isDeleteError && deleteError) {
      const message =
        (deleteError as any)?.data?.error ||
        'Something went wrong when deleting the candidates. Please try again later.';
      setBulkDeleteModalOpen(false);
      refetchCandidates();
      showToast({
        icon: 'error',
        heading: 'Failed to delete!',
        text: message
      });
    }
  }, [deleteError, isDeleteError]);

  const handleBulkDelete = async () => {
    bulkDeleteCandidates(candidateParams);
  };

  const handleClickDelete = () => {
    if (canDelete) {
      closeMenu();
      setBulkDeleteModalOpen(true);
    }
  };

  const showSlideOut = (slideOut: CandidateSlideOut) => {
    closeMenu();
    setSlideOut(slideOut);
  };

  if (!selectedIds.length) return null;

  const candidate_s = pluralize('candidate', selectedIds.length);

  return (
    <div className='inline-flex items-center space-x-4'>
      <RestrictedButton action='Add candidates' permission='addCandidate'>
        <Dropdown medium className='xx-send-menu' text='Manage' isOpen={open} onClick={toggleMenu} onClose={closeMenu}>
          <DropdownLink className='flex items-center' disabled={!canDelete} onClick={handleClickDelete}>
            <TrashSVG />
            <div className='ml-2'>Delete</div>
          </DropdownLink>

          {canSeeExportButton && (
            <DropdownLink
              data-testid='export-csv'
              disabled={!canExportCsv}
              onClick={() => {
                closeMenu();
                setCsvExportModalOpen(true);
              }}
            >
              <Tippy content='Admin only'>
                <div className='flex items-center'>
                  <ExportSVG width='14' height='14' />
                  <div className='items-center ml-2'>Export CSV</div>
                </div>
              </Tippy>
            </DropdownLink>
          )}
          <DropdownLink className='xx-bulk-shortlist flex items-center' onClick={() => showSlideOut('BulkEdit')}>
            <PencilSVG width='14' height='14' />
            <div className='items-center ml-2'>Edit</div>
          </DropdownLink>
          {enableTeams && canManageTeams && (
            <DropdownLink
              className='xx-bulk-shortlist flex items-center'
              onClick={() => setEditContactAccessModalOpen(true)}
            >
              <PencilSVG width='14' height='14' />
              <div className='items-center ml-2'>Change contact access</div>
            </DropdownLink>
          )}
        </Dropdown>
        {!addCandidatesModal && (
          <Button
            medium
            className='xx-bulk-shortlist flex items-center'
            onClick={() => showSlideOut('AddToStudyShortlist')}
          >
            <ShortlistSVG />
            <div className='items-center ml-2'>Shortlist</div>
          </Button>
        )}
        {editContactAccessModalOpen && (
          <EditContactAccessModal
            open
            {...candidateParams}
            onClose={() => setEditContactAccessModalOpen(false)}
            onSuccess={() => {
              setEditContactAccessModalOpen(false);
              onEditTeams();
              resetSelection();
            }}
          />
        )}
        {bulkDeleteModalOpen && (
          <div className='text-left'>
            <Modal
              size='md'
              icon='danger'
              onClose={() => setBulkDeleteModalOpen(false)}
              renderFooter={() => (
                <div className='flex flex-row justify-end space-x-4'>
                  <Button onClick={() => setBulkDeleteModalOpen(false)} disabled={isDeleteLoading}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => handleBulkDelete()}
                    danger
                    loading={isDeleteLoading}
                    disabled={isDeleteLoading}
                  >
                    Delete {candidate_s}
                  </Button>
                </div>
              )}
            >
              <div>
                <h4 className='mb-3 text-xl font-semibold tracking-tight text-gray-800'>Delete {candidate_s}</h4>
                <p className='mb-2'>
                  You are about to delete {selectedCount} {candidate_s}.
                </p>
                <p className='mb-2'>
                  All information will be deleted including profile data, recordings, and transcripts.
                </p>
                <p className='mb-2'>
                  Candidates who are booked or invited to current studies will automatically be removed from the study.
                </p>
                <p className='mb-2'>
                  Candidates who have pending incentives will automatically be sent their incentives before deleting
                  their data.
                </p>
                <p className='mb-2'>This action is permanent.</p>
              </div>
            </Modal>
          </div>
        )}
        {canExportCsv && csvExportModalOpen && (
          <ExportCsvModal
            query={api.useCreateCandidatesCsvExportMutation as any}
            queryParams={{
              columns: currentViewColumns,
              ...(allSelected ? { query } : { customer_ids: selectedIds })
            }}
            setCsvExportModalOpen={setCsvExportModalOpen}
            pluralizedCount={pluralize('candidate', selectedCount, true)}
          />
        )}
      </RestrictedButton>
    </div>
  );
};
