import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm, UseFormMethods } from 'react-hook-form';

import { getStudyMessageTemplate, previewStudyMessage } from '@api/queries';
import { PreviewButton } from '@components/StudyMessages/hooks/useMessageEditor/components/PreviewButton';
import { savedChanges } from '@components/StudyMessages/toasts';
import { Toast, useToaster } from '@stores/toaster';

import { useGetStudyMessageQuery } from '../../api';
import { EditorRef } from '../../MessageEditor';
import { api } from 'api/reduxApi';
import { useWindowFocus } from 'hooks/useWindowFocus';
import { useFeature } from 'hooks/useFeature';

export interface UseMessageEditorParams {
  event: StudyMessageEvent;
  study: Study;
  hideSender?: boolean;
  customizable?: boolean;
  context?: 'send' | 'editor';
  previewCandidate?: Candidate;
  previewParticipation?: Participation;
}

export type EditorProps = UseMessageEditorParams & {
  message: StudyMessage | null | undefined;
  edit: boolean;
  sender?: EmailSender;
  setSender: (v: EmailSender) => void;
  senders: EmailSender[];
  save: (v?: any) => Promise<undefined | StudyMessage>;
  setMessage: (v: StudyMessage | null | undefined) => void;
  updateModal: boolean;
  setUpdateModal: (v: boolean) => void;
  formMethods: UseFormMethods;
  isDirty: boolean;
  setIsDirty: (v: boolean) => void;
  cancelModal: boolean;
  setCancelModal: (v: boolean) => void;
  setEdit: (v: boolean) => void;
  senderChanged: boolean;
  setSenderChanged: (v: boolean) => void;
  discardChanges: () => void;
};

export interface UseMessageEditorHook {
  editable: boolean;
  PreviewButton: React.FC<{ aboveButton?: boolean }>;
  save: () => Promise<StudyMessage | undefined>;
  message: StudyMessage;
  edit: boolean;
  setEdit: (edit: boolean) => void;
  sender: EmailSender | undefined;
  editorProps: EditorProps;
  editorRef: React.RefObject<EditorRef>;
  updateModal: boolean;
  setUpdateModal: (v: boolean) => void;
  handleSubmit: UseFormMethods['handleSubmit'];
  sendPreview: () => Promise<void>;
  isDirty: boolean;
  setIsDirty: (v: boolean) => void;
  cancelModal: boolean;
  setCancelModal: (v: boolean) => void;
}

const previewSentToast: Toast = {
  icon: 'success',
  heading: 'Preview sent',
  text: 'A preview has been sent to your email.'
};

export const useMessageEditor = (params: UseMessageEditorParams): UseMessageEditorHook => {
  const { study, event, previewCandidate } = params;

  const [edit, setEdit] = useState<boolean>(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const showToast = useToaster();
  const [message, setMessage] = useState<StudyMessage | null>();
  const editorRef = useRef<EditorRef>(null);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [senderChanged, setSenderChanged] = useState<boolean>(false);

  const [cancelModal, setCancelModal] = useState(false);

  const formMethods = useForm();

  const { data: initialStudyMessage } = useGetStudyMessageQuery(
    { studyId: study.id, id: message?.id as number },
    { skip: !message?.id }
  );

  const hasNewSenderSelect = useFeature('new_sender_select');

  const { data: senders, refetch: refetchSenders } = api.useGetStudySendersQuery(study.id, {
    skip: hasNewSenderSelect
  });

  const [sender, setSender] = useState<EmailSender | undefined>();

  useEffect(() => {
    fetch();
  }, [event]);

  useEffect(() => {
    setMessage(null);
  }, [event, study?.id]);

  useEffect(() => {
    if (message?.default_sender_account) {
      setSender(message.default_sender_account);
    }
  }, [message]);

  const focused = useWindowFocus();

  useEffect(() => {
    if (focused && (senders || []).some((s) => s.disabled)) {
      refetchSenders();
    }
  }, [focused]);

  const discardChanges = () => {
    setIsDirty(false);
    fetchMessage();
    if (message?.default_sender_account) {
      setSender(message.default_sender_account);
    }
  };

  async function fetchTemplate() {
    setMessage(await getStudyMessageTemplate(study.id, event));
  }
  function fetchMessage() {
    setMessage(initialStudyMessage);
  }

  async function fetch() {
    // id vs template here....
    message ? fetchMessage() : fetchTemplate();
  }

  const save = async () => {
    const resp = await editorRef.current?.save();
    setEdit(false);
    if (resp) {
      setMessage(resp);
      showToast(savedChanges());
    }
    return resp;
  };

  const sendPreview = useCallback(async () => {
    await previewStudyMessage(
      { studyId: study.id, id: message?.id },
      { candidate_id: previewCandidate?.id, sender: sender }
    );
    showToast(previewSentToast);
  }, [study.id, message?.id, previewCandidate?.id, sender]);

  const MemoPreviewButton = useMemo<React.FC>(
    () => (props: any) =>
      <PreviewButton sendPreview={sendPreview} message={message} previewCandidate={previewCandidate} {...props} />,
    [sendPreview]
  );

  const editorProps: EditorProps = {
    ...params,
    message,
    edit,
    event,
    sender,
    senders: senders || [],
    setSender,
    study,
    save,
    setMessage,
    updateModal,
    setUpdateModal,
    formMethods,
    isDirty,
    setIsDirty,
    cancelModal,
    setCancelModal,
    setEdit,
    setSenderChanged,
    senderChanged,
    discardChanges
  };

  return {
    editorRef,
    editorProps,
    sender,
    save,
    edit,
    editable: !!message,
    message: message as any,
    PreviewButton: MemoPreviewButton,
    setEdit,
    updateModal,
    setUpdateModal,
    handleSubmit: formMethods.handleSubmit,
    sendPreview,
    isDirty,
    setIsDirty,
    cancelModal,
    setCancelModal
  };
};
