import * as React from 'react';
import { useState } from 'react';

import { StarSVG, FullStarSVG } from '@components/svgs';
import Tippy from '@tippyjs/react';

import { CellWrapper } from '../shared';

const possibleRatings: { rating: number; tip: string }[] = [
  { rating: 1, tip: '1-star (poor)' },
  { rating: 2, tip: '2-stars (below average)' },
  { rating: 3, tip: '3-stars (average)' },
  { rating: 4, tip: '4-stars (above average)' },
  { rating: 5, tip: '5-stars (excellent)' }
];

export const RatingCell: React.FC<{ value: number | null; onClick: (rating: number) => void }> = ({
  value: initialValue,
  onClick
}) => {
  const [value, setValue] = useState(initialValue);

  const handleOnClick = (clickedValue) => {
    if (clickedValue != value) {
      setValue(clickedValue);
      onClick(clickedValue);
    }
  };

  return (
    <>
      {possibleRatings.map(({ rating, tip }) => (
        <CellWrapper
          key={rating}
          onClick={() => handleOnClick(rating)}
          wrapperClass='cursor-pointer hover:bg-indigo-50 hover:text-indigo-600 overflow-hidden'
        >
          <Tippy content={tip}>
            <div>{value && value >= rating ? <FullStarSVG /> : <StarSVG />}</div>
          </Tippy>
        </CellWrapper>
      ))}
    </>
  );
};
