import { api } from 'api/reduxApi';
import classNames from 'classnames';
import * as React from 'react';
import { useMemo } from 'react';

import { Text } from '@components/common';
import { CORE_ATTRS } from '@components/config';
import { without } from '@components/utils';
import { useCandidateAttrs } from '@hooks/useCandidateAttrs';

import { TdAttributePicker } from './TdAttributePicker';

const Tr: React.FC = ({ children }) => <tr className='border-b border-gray-200'>{children}</tr>;
const Td: React.FC<{ className?: string }> = ({ className, children }) => (
  <td className={classNames(className, 'w-48 px-4 py-3 border-l border-gray-200')}>{children}</td>
);

interface Props {
  model: CustomerImport;
  save: (model: CustomerImport) => void | Promise<void>;
  erroredColumnIndexes: number[];
}
export const MappingTable: React.FC<Props> = ({ erroredColumnIndexes, model, save }) => {
  const { data: candidateAttrs } = api.useGetCandidateAttrsQuery();

  const attrs = useMemo(
    () => [...CORE_ATTRS, ...(candidateAttrs || []).map((a) => ({ ...a, name: `extra:${a.name}` }))],
    [candidateAttrs]
  );

  const indexofHiddenCols = useMemo(
    () => model.hidden_columns.map((col) => model.headers.indexOf(col)),
    [model.headers, model.hidden_columns]
  );
  const isHidden = (value: string) => model.hidden_columns.includes(value);
  const isIndexHidden = (index: number) => indexofHiddenCols.includes(index);

  const mapping = model.mapping && model.mapping.length > 0 ? model.mapping : model.headers.map(() => '');

  return (
    <div className='w-full flex-grow overflow-x-auto'>
      <table className='whitespace-nowrap mx-auto bg-white border-t border-r border-gray-200'>
        <tbody>
          <Tr>
            <Td className='bg-gray-50'>
              <Text h='400'>Column header</Text>
            </Td>
            {model.headers.map((header, i) => (
              <Td className={isIndexHidden(i) ? 'bg-gray-50' : undefined} key={header}>
                <div className='flex items-center w-full'>
                  <Text h='400' color={isIndexHidden(i) ? 'gray-400' : undefined} bold className='flex-1 mr-2'>
                    {header}
                  </Text>
                  <input
                    className='cursor-pointer'
                    type='checkbox'
                    checked={!isHidden(header)}
                    onChange={(e) => {
                      save({
                        ...model,
                        hidden_columns: isHidden(header)
                          ? without(model.hidden_columns, header)
                          : [...model.hidden_columns, header]
                      });
                    }}
                  />
                </div>
              </Td>
            ))}
          </Tr>
          <Tr>
            <Td className='bg-gray-50'>
              <Text h='400'>Attribute</Text>
            </Td>
            {model.headers.map((header, i) => (
              <TdAttributePicker
                key={header}
                className='w-48 border-l border-gray-200'
                disabled={isIndexHidden(i)}
                defaultLabel={header}
                attrs={attrs}
                value={mapping[i]}
                onChange={async (value) => {
                  const newMapping = [...mapping];
                  newMapping[i] = value;
                  await save({ ...model, mapping: newMapping });
                }}
                errored={erroredColumnIndexes.includes(i)}
              />
            ))}
          </Tr>
          {model.preview.map((row, i) => (
            <Tr key={i}>
              <Td className='bg-gray-50'>
                <Text h='400'>{i + 1}</Text>
              </Td>
              {row.map((cell, i) => (
                <Td className={isIndexHidden(i) ? 'bg-gray-50' : undefined} key={i}>
                  <Text color={isIndexHidden(i) ? 'gray-400' : undefined} h='400'>
                    {cell}
                  </Text>
                </Td>
              ))}
            </Tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
