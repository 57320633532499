import cn from 'classnames';
import { format, isSameDay } from 'date-fns';
import { DateObj } from 'dayzed';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import { Loading } from '@components/common';
import { getScrollableParent } from '@helpers/getScrollableParent';
import { useDeviceType } from '@hooks/useDeviceType';

import { Calendar, CalendarProps } from './Calendar';
import { TimezonePicker, TimezoneDesc } from '../../../shared/TimezonePicker';
import { RenderTimeslot, Timeslots, TimeslotsFormatSelect } from './Timeslots';

interface Props {
  study: Study;
  participation?: Participation;
  isLoading: boolean;
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
  timeslots: Timeslots | undefined;
  calendarOffset: number;
  setCalendarOffset: (offset: number) => void;
  selectedTimezone: TimezoneDesc;
  setSelectedTimezone: (timezone: TimezoneDesc) => void;
  researcherView?: boolean;
  calendarProps?: Partial<CalendarProps>;
  renderTimeslot: RenderTimeslot;
}

const CALENDAR_MAX_HEIGHT = '534px';

export const Scheduler: React.FC<Props> = ({
  study,
  participation,
  isLoading,
  selectedDate,
  setSelectedDate,
  timeslots,
  calendarOffset,
  setCalendarOffset,
  selectedTimezone,
  setSelectedTimezone,
  researcherView,
  calendarProps,
  renderTimeslot
}) => {
  const [wrapperHeight, setWrapperHeight] = useState<number | null>(null);
  const [use24hr, setUse24hr] = useState<boolean>(false);
  const firstPageLoad = useRef(true);

  const scrollToSlot = (slotKey: string) => {
    const slotElement = document.querySelector(`[data-timeslot="${slotKey}"]`) as HTMLElement;
    if (slotElement) {
      const container = getScrollableParent(slotElement) as HTMLElement;
      if (container) {
        container.scrollTop = slotElement.offsetTop - container.offsetTop;
      }
    }
  };

  const { isMobile, isTablet } = useDeviceType();

  const getHeight = () => {
    if (!wrapperHeight) return 'auto';
    if (researcherView && !isMobile) return CALENDAR_MAX_HEIGHT;
    if (!researcherView && !isMobile && !isTablet) return CALENDAR_MAX_HEIGHT;
    return 'auto';
  };

  const handleDateSelected = ({ date, nextMonth, prevMonth }: DateObj) => {
    if (timeslots) {
      setSelectedDate(date);

      if (prevMonth) setCalendarOffset(calendarOffset - 1);

      if (nextMonth) setCalendarOffset(calendarOffset + 1);

      const slotKey = format(date, 'yyyy-MM-dd');
      requestAnimationFrame(() => {
        scrollToSlot(slotKey);
      });
    }
  };

  const handleOffsetChanged = (offset: number) => {
    if (offset >= 0) {
      setCalendarOffset(offset);
    }
  };

  const setActiveDate = () => {
    if (timeslots) {
      const [activeDate] = Object.keys(timeslots).sort();
      if (activeDate) {
        setSelectedDate(new Date(activeDate));
      } else {
        setSelectedDate(new Date());
      }
    }
  };

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (timeslots) {
      if (firstPageLoad.current) {
        // jump to next month if no slots available in current month (only on first load)
        const noSlots = Object.keys(timeslots).length === 0;
        if (noSlots) {
          handleOffsetChanged(calendarOffset + 1);
        }
        firstPageLoad.current = false;
      }

      const isAvailableDate = Object.keys(timeslots).some((d) => isSameDay(new Date(`${d}T00:00`), selectedDate));
      if (!isAvailableDate) {
        setActiveDate();
      }
    }
  }, [timeslots]);

  if (isLoading) {
    return (
      <>
        <div className='tablet:max-w-4xl h-full max-w-md mx-auto overflow-y-auto'>
          <Loading bg='white' absolute />
          <div
            className={cn('h-full', {
              'tablet:grid tablet:grid-cols-2': researcherView,
              'desktop:grid desktop:grid-cols-2': !researcherView
            })}
            ref={wrapperRef}
          >
            <div>
              <Calendar
                handleDateSelected={handleDateSelected}
                handleOffsetChanged={handleOffsetChanged}
                selectedDate={selectedDate}
                timeslots={timeslots}
                calendarOffset={calendarOffset}
                wrapperRef={wrapperRef}
                setWrapperHeight={setWrapperHeight}
                nextMonthCount={0}
              />
              <TimezonePicker
                wrapperClassName='py-5 xx-timezone-picker'
                selectedTimezone={selectedTimezone}
                setSelectedTimezone={setSelectedTimezone}
              />
              <TimeslotsFormatSelect use24hr={use24hr} setUse24hr={setUse24hr} />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className='h-full max-w-full'>
        <div
          className={cn('h-full', {
            'tablet:grid tablet:grid-cols-2': researcherView,
            'desktop:grid desktop:grid-cols-2': !researcherView
          })}
          ref={wrapperRef}
          style={{ height: getHeight() }}
        >
          <div>
            <Calendar
              handleDateSelected={handleDateSelected}
              handleOffsetChanged={handleOffsetChanged}
              selectedDate={selectedDate}
              timeslots={timeslots}
              calendarOffset={calendarOffset}
              wrapperRef={wrapperRef}
              setWrapperHeight={setWrapperHeight}
              nextMonthCount={0}
              {...calendarProps}
            />
            <TimezonePicker
              wrapperClassName='py-5 xx-timezone-picker'
              selectedTimezone={selectedTimezone}
              setSelectedTimezone={setSelectedTimezone}
            />
            <TimeslotsFormatSelect use24hr={use24hr} setUse24hr={setUse24hr} />
          </div>
          {wrapperHeight !== null && (
            <Timeslots
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              timeslots={timeslots}
              selectedTimezone={selectedTimezone}
              use24hr={use24hr}
              nextMonthCount={calendarProps?.nextMonthCount || 0}
              calendarOffset={calendarOffset}
              setCalendarOffset={setCalendarOffset}
              renderTimeslot={renderTimeslot}
            />
          )}
        </div>
      </div>
    </>
  );
};
