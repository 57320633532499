import type { FeatureType } from '@components/shared/FeatureBanner';

type Params = {
  isAdmin: boolean;
  isExternalRecruitmentDisabled: boolean;
  hasReportingFeature: boolean;
  unmoderatedEnabled: boolean;
  aiAccess: boolean;
};

export const buildFeatureBanners = ({
  isAdmin,
  isExternalRecruitmentDisabled,
  hasReportingFeature,
  unmoderatedEnabled,
  aiAccess
}: Params) => {
  const banners: FeatureType[] = [];

  if (!isExternalRecruitmentDisabled) {
    banners.push('external_recruitment');
  }

  banners.push('calendar_v2');

  if (hasReportingFeature && isAdmin) {
    banners.push('reporting');
  }

  if (aiAccess) {
    banners.push('ai');
  }

  if (unmoderatedEnabled) {
    banners.push('unmoderated_internal_launch');
  } else {
    banners.push('unmoderated_upgrade');
  }

  return banners;
};
