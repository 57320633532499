import React, { useState } from 'react';

import { Card, Pill, Text } from '@components/common';
import { SUPPORT_EMAIL } from '@constants/emails';
import { useAccount } from '@hooks/useAccount';

import { AiToggleSetting } from './AiToggleSetting';
import { useFetchAiSettingsQuery } from './api';
import { BackfillsModal } from './BackfillModal';
import { EnableToggle } from './EnableToggle';

export const AiSettingsApp: React.FC = () => {
  const [showBackfillsModal, setShowBackfillsModal] = useState<boolean>(false);

  const {
    account: { ai_enabled }
  } = useAccount();
  const { refetch, data: aiSettings, isSuccess } = useFetchAiSettingsQuery();
  const aiSettingsFetched = isSuccess && aiSettings;

  return (
    <Card>
      <div className='flex items-center mb-6 space-x-2'>
        <Text h='700'>AI Preferences</Text>
        <Pill color='blue'>Beta</Pill>
      </div>
      <Text h='500' mb='2'>
        Level up your synthesis and analysis with the help of AI.
      </Text>
      <Text h='400' color='gray-500' mb='2'>
        Like having your own research assistant, Great Question AI fits seamlessly into your workflow to help you
        summarize and extract insights from entire studies, while meeting high standards for transparency and privacy.
        AI features include Ask AI, instant chapters, summaries, and highlights, and auto-filled titles and tags.
      </Text>
      <Text h='400' color='gray-500'>
        AI features are powered by GPT-4. No PII is sent to the model, and data is not used for cross training.
        Generative AI models can make mistakes, so always check responses.
      </Text>

      <div className='pt-6'>
        <EnableToggle onEnable={() => setShowBackfillsModal(true)} />
      </div>
      <div className='pt-4'>
        {ai_enabled && (
          <>
            <AiToggleSetting
              name='Auto highlighting'
              setting='auto_highlights'
              isEnabled={aiSettings?.auto_highlights}
              isLoading={!aiSettingsFetched}
              onUpdate={refetch}
            >
              <Text className='text-sm'>
                Automatically highlight ~5 most important sections in your interviews when they are transcribed
              </Text>
            </AiToggleSetting>
            {aiSettings?.auto_highlights && (
              <AiToggleSetting
                name='Auto tags'
                setting='auto_tags'
                isEnabled={aiSettings?.auto_tags}
                isLoading={!aiSettingsFetched}
                onUpdate={refetch}
              >
                <Text className='text-sm'>
                  During automatic highlighting, also create new study-level tags if no tags exist
                </Text>
              </AiToggleSetting>
            )}
          </>
        )}
      </div>
      {showBackfillsModal && (
        <BackfillsModal onClose={() => setShowBackfillsModal(false)} onSuccess={() => setShowBackfillsModal(false)} />
      )}
      <Text mt='10' h='400'>
        Have an idea for a great AI automation? Please reach out to{' '}
        <a href={`mailto: ${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
      </Text>
    </Card>
  );
};
