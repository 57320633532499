import classNames from 'classnames';
import { useCombobox } from 'downshift';
import React, { ReactElement, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import mergeRefs from 'react-merge-refs';
import { usePopper } from 'react-popper';

import { composeEventHandlers } from '@helpers/composeEventHandlers';

import { EmptyState, Input, Item, Menu } from './components';
import {
  DefaultOption,
  DefaultOptionMetadata,
  DropdownInputProps,
  DropdownItemProps,
  DropdownMenuProps,
  SelectDropdownProps
} from './types';

const filterItems = <T extends DefaultOption>(items: T[], inputValue: string) => {
  return items.filter((item) => item.label.toLowerCase().includes(inputValue.toLowerCase()));
};

const isSelected = <T extends DefaultOption>(item: T, selectedItems: T[]) => {
  return selectedItems.some((selectedItem) => selectedItem.value === item.value);
};

const isExactMatch = <T extends DefaultOption>(inputValue: string, selectedItems: T[]) => {
  return selectedItems.some((selectedItem) => selectedItem.label.toLowerCase() === inputValue.toLowerCase());
};

const getRemainingItems = <T extends DefaultOption>(items: T[], selectedItems: T[]) => {
  return items.filter((item) => !isSelected(item, selectedItems));
};

const shouldHaveCreatableItem = <T extends DefaultOption>(inputValue: string, items: T[], selectedItems: T[]) => {
  return inputValue.length >= 2 && !isExactMatch(inputValue, items) && !isExactMatch(inputValue, selectedItems);
};

export const SelectDropdown = <M extends DefaultOptionMetadata = DefaultOptionMetadata>({
  options,
  value,
  multi,
  loading,
  creatable,
  clearable,
  defaultIsOpen,
  closeOnSelect = true,
  keepSelectedOptions,
  overrides,
  inputRef: givenInputRef,
  controlRef,
  onChange,
  onCreate,
  onInputValueChange,
  onClose,
  onOpen,
  displayAllItems
}: SelectDropdownProps<M>): ReactElement => {
  const [triggerRef, setTriggerRef] = useState<HTMLElement | null>(null);
  const [menuRef, setMenuRef] = useState<HTMLElement | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [selectedItems, setSelectedItems] = useState<DefaultOption<M>[]>(value);
  const inputRef = useRef<HTMLInputElement>(null);
  const rafRef = useRef<number | null>(null);

  const items = useMemo<DefaultOption<M>[]>(() => {
    let filtered = displayAllItems ? options : filterItems<DefaultOption<M>>(options, inputValue);

    if (creatable && shouldHaveCreatableItem(inputValue, filtered, selectedItems)) {
      filtered = filtered.concat({ value: inputValue, label: inputValue, created: true });
    }

    return multi && !keepSelectedOptions ? getRemainingItems(filtered, selectedItems) : filtered;
  }, [selectedItems, inputValue, options, multi, displayAllItems]);

  const { styles, attributes } = usePopper(triggerRef, menuRef, {
    placement: 'bottom'
  });

  const {
    isOpen,
    highlightedIndex,
    getComboboxProps,
    getMenuProps: getComboboxMenuProps,
    getInputProps: getComboboxInputProps,
    getItemProps: getComboboxItemProps,
    openMenu,
    closeMenu,
    selectItem,
    setHighlightedIndex
  } = useCombobox<DefaultOption<M>>({
    items,
    inputValue,
    selectedItem: selectedItems[0] ?? null,
    itemToString: (item) => item?.label ?? '',
    onIsOpenChange: ({ isOpen, inputValue: newInputValue }) => {
      if (isOpen) onOpen?.(selectedItems, newInputValue);
      else onClose?.(selectedItems, newInputValue);
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        let newSelectedItems: DefaultOption<M>[] = [];

        if (multi) {
          if (isSelected(selectedItem, selectedItems)) {
            newSelectedItems = selectedItems.filter((item) => item.value !== selectedItem.value);
          } else {
            newSelectedItems = selectedItems.concat(selectedItem);
          }
        } else {
          newSelectedItems = [selectedItem];
        }

        if (!selectedItem.created || !onCreate) {
          setSelectedItems(newSelectedItems);
          onChange?.(newSelectedItems);
        }

        if (selectedItem.created && onCreate) {
          onCreate(selectedItem);
        }

        if (multi) {
          setInputValue('');
        }
      }
    },
    onStateChange: ({ inputValue: newInputValue, type }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange: {
          setInputValue(newInputValue ?? '');
          onInputValueChange?.(newInputValue ?? '');
          break;
        }
        default:
          break;
      }
    },
    stateReducer: (_, actionAndChanges) => {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return { ...changes, isOpen: !closeOnSelect };
        default:
          return changes;
      }
    }
  });

  useEffect(() => {
    if (!multi) {
      setInputValue(selectedItems[0]?.label ?? '');
    }
  }, [selectedItems[0]?.label]);

  useEffect(() => {
    setSelectedItems(value);
  }, [value]);

  useEffect(() => {
    // give time for popper to adapt (e.g. on next repaint)
    rafRef.current = requestAnimationFrame(() => {
      if (defaultIsOpen) openMenu();
      else closeMenu();
    });

    return () => {
      if (rafRef.current) cancelAnimationFrame(rafRef.current);
    };
  }, [defaultIsOpen]);

  useImperativeHandle(controlRef, () => ({
    setDropdownOpen: (open: boolean) => {
      if (open) openMenu();
      else closeMenu();
    },
    setValue: (value: DefaultOption<M>[]) => {
      setSelectedItems(value);
    }
  }));

  const defaultProps = useMemo(
    () => ({
      items,
      inputValue,
      highlightedIndex,
      isOpen,
      loading,
      clearable
    }),
    [items, inputValue, highlightedIndex, isOpen, loading]
  );

  const getInputProps = useCallback(
    (): DropdownInputProps<M> => ({
      __dropdownInputProps: {
        ...defaultProps,
        clear: () => {
          setInputValue('');
          setSelectedItems([]);
          closeMenu();
        }
      }
    }),
    [loading, defaultProps, closeMenu, setInputValue, setSelectedItems, inputRef.current]
  );

  const getMenuProps = useCallback(
    (): DropdownMenuProps<M> => ({
      __dropdownMenuProps: defaultProps
    }),
    [defaultProps]
  );

  const getItemProps = useCallback(
    (item: DefaultOption<M>, index: number): DropdownItemProps<M> => ({
      __dropdownItemProps: {
        ...defaultProps,
        item,
        // highlight when selected OR when only "Create..." in the list
        highlighted: highlightedIndex === index || (!!item.created && items.length === 1),
        active: isSelected(item, selectedItems)
      }
    }),
    [defaultProps, selectedItems, highlightedIndex, isSelected]
  );

  const renderDropdownInput = useCallback(() => {
    const props = {
      ...getInputProps(),
      ...getComboboxInputProps({
        ref: mergeRefs([inputRef, ...(givenInputRef ? [givenInputRef] : [])]),
        onChange: (e) => {
          setInputValue(e.currentTarget.value);
          if (items[0]?.created && items.length === 1) {
            selectItem(items[0]);
            setHighlightedIndex(0);
          }
        },
        onKeyDown:
          items[0]?.created && items.length === 1
            ? (e) => {
                if (e.key === 'Enter' && items?.length === 1) {
                  onCreate?.(items[0]);
                }
              }
            : undefined
      }),
      ...overrides?.Input?.props
    };
    const Component = overrides?.Input?.component ?? Input;
    return <Component {...props} />;
  }, [overrides, getInputProps, Input, getComboboxInputProps, openMenu]);

  const renderDropdownMenu = useCallback(
    (children: ReactElement) => {
      const props = {
        ...getMenuProps(),
        ...getComboboxMenuProps({ ref: setMenuRef, style: styles.popper }),
        ...attributes.popper,
        ...overrides?.Menu?.props
      };

      const Component = overrides?.Menu?.component ?? Menu;

      return (
        <Component
          {...props}
          className={classNames(
            {
              // we need this long condition to cover all (currently) possible cases when
              // dropdown has no items in it
              // so we should make it invisible to prevent rendering only wrapper
              invisible: !items.length && ((!inputValue.length && !creatable) || (inputValue.length < 2 && creatable))
            },
            overrides?.Menu?.props?.className
          )}
        >
          {children}
        </Component>
      );
    },
    [overrides, getMenuProps, getComboboxMenuProps, Menu]
  );

  const renderDropdownItem = useCallback(
    (item: DefaultOption<M>, index: number) => {
      const props = {
        ...getItemProps(item, index),
        ...getComboboxItemProps({ item, index }),
        ...overrides?.Item?.props
      };
      const Component = overrides?.Item?.component ?? Item;
      return <Component key={`${item.value}-${index}`} {...props} />;
    },
    [overrides, getItemProps, getComboboxItemProps, Item]
  );

  const renderEmptyState = useCallback(
    (children: ReactElement) => {
      const props = {
        role: 'option',
        'aria-disabled': true,
        'aria-selected': false,
        ...overrides?.Empty?.props
      };
      if (!creatable && inputValue.length > 0 && items.length === 0) {
        const Component = overrides?.Empty?.component ?? EmptyState;
        return <Component {...props}>{children}</Component>;
      }
      return null;
    },
    [overrides, creatable, inputValue, items.length, EmptyState]
  );

  return (
    <section className='relative' {...getComboboxProps()}>
      <div ref={setTriggerRef} onClick={composeEventHandlers(openMenu, () => inputRef.current?.focus())}>
        {renderDropdownInput()}
      </div>
      {renderDropdownMenu(
        <>
          {isOpen && (
            <>
              {items.map((item, index) => renderDropdownItem(item, index))}
              {renderEmptyState(<>No results</>)}
            </>
          )}
        </>
      )}
    </section>
  );
};
