import { mergeDeep } from '@helpers/mergeDeep';
import { Extension, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { ReadonlyTranscriptView, TranscriptView } from '../components';
import * as Queries from '../helpers/queries';
import { TranscriptWord } from './TranscriptWord';

export interface TranscriptAttributes {
  speaker: {
    id: number | null;
    img: string | null;
    name: string;
    source: string;
  };
  block?: {
    field_type: string;
    field_id: number;
    block_start: number;
    block_end: number;
    title: string;
  };
}

export interface TranscriptOptions {
  readonly?: boolean;
  defaultSpeakers?: (TeamUser | Candidate)[];
  onSpeakerChange?: (speaker: Partial<TranscriptAttributes['speaker']>) => void;
  onTimestampClick: (timestamp: number) => void;
  clips?: Clip[];
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    transcript: {
      updateSpeaker: (attributes: Partial<TranscriptAttributes['speaker']>) => ReturnType;
      updateAllSpeakers: (attributes: Partial<TranscriptAttributes['speaker']>) => ReturnType;
    };
  }
}

export const Transcript = Node.create<TranscriptOptions>({
  name: 'transcript',

  group: 'block',

  content: 'inline*',

  selectable: false,

  addOptions() {
    return {
      readonly: false,
      defaultSpeakers: [],
      clip_id: 0,
      onSpeakerChange: () => {
        return;
      },
      onTimestampClick: () => {
        return;
      }
    };
  },

  addAttributes() {
    return {
      clip_id: 0,
      speaker: {
        default: {
          id: null,
          img: null,
          name: '',
          source: ''
        }
      },
      block: {
        default: undefined
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'transcript-component'
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['transcript-component', HTMLAttributes, 0];
  },

  addNodeView() {
    if (this.options.readonly) {
      return ReactNodeViewRenderer(ReadonlyTranscriptView);
    }
    return ReactNodeViewRenderer(TranscriptView);
  },

  addCommands() {
    return {
      updateSpeaker:
        (attributes) =>
        ({ commands, editor }) => {
          const speakerAttrs = mergeDeep(editor.getAttributes('transcript').speaker, attributes);

          return commands.updateAttributes(this.name, { speaker: speakerAttrs });
        },
      updateAllSpeakers:
        (attributes) =>
        ({ editor, tr, dispatch }) => {
          const activeNodeAttrs = editor.getAttributes('transcript') as TranscriptAttributes;

          const nodes = Queries.getAllNodesOfType(
            editor.state,
            'transcript',
            ({ node }) => node.attrs.speaker.source === activeNodeAttrs.speaker.source
          );

          nodes.forEach((node) => {
            tr.setNodeMarkup(node.pos, undefined, mergeDeep(node.node.attrs, { speaker: attributes }));
          });

          dispatch?.(tr);
          return true;
        }
    };
  },

  addExtensions() {
    const extensions: Extension[] = [TranscriptWord];
    return extensions;
  }
});
