import { useEffect, useMemo, useState } from 'react';

import consumer from '@helpers/consumer';

const CHANNEL = 'Users::CalendarsChannel';

interface Args {
  calendars?: NylasCalendar[] | null;
  userId: number;
}

export const useUserCalendars = ({ calendars: initialCalendars, userId }: Args) => {
  const [calendars, setCalendars] = useState<NylasCalendar[]>(initialCalendars ?? []);

  const channel = useMemo(
    () =>
      userId
        ? consumer.subscriptions.create({
            channel: CHANNEL,
            user_id: userId
          })
        : null,
    [consumer, userId]
  );

  useEffect(() => {
    if (channel) {
      channel.received = (data) => {
        setCalendars(data.message);
      };
    }
  }, [channel]);

  useEffect(() => {
    if (initialCalendars && initialCalendars.length) {
      setCalendars(initialCalendars);
    }
  }, [initialCalendars]);

  return { calendars };
};
