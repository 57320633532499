import { Button, InputWithMultiselectDropdown } from '@components/common';
import { CloseIcon } from '@components/common/Modal';
import * as React from 'react';
import {
  Filter,
  FilterStateLookup
} from '@components/StudiesApp/components/StudyPublished/pages/ExternalCandidatesRequest/components/FilterInput';
import { useMemo } from 'react';

interface Props {
  object: ExternalCandidatesFilterListResponse;
  disabled: boolean;
  storeFilterValues: (filters: Filter[]) => void;
  setChanged: (changed: boolean) => void;
  filters: FilterStateLookup;
  storeFilterRange: (range: Filter[]) => void;
  limit?: number;
  allOptionValue?: string;
}

export const Multiselect: React.FC<Props> = ({
  allOptionValue,
  limit,
  storeFilterValues,
  disabled,
  object,
  filters,
  setChanged
}) => {
  const defaultOptions = Object.keys(object.choices).map((c) => ({ label: object.choices[c], value: c }));

  const onChange = (items: string[]) => {
    storeFilterValues([
      {
        filter_id: object.filter_id,
        selected_values: allOptionValue && items.includes(allOptionValue) ? [allOptionValue] : items
      }
    ]);
    setChanged(true);
  };

  const value = useMemo(
    () =>
      filters[object.filter_id]?.selected_values?.map((v) => {
        return { label: object.choices[v], value: v };
      }) || [],
    [filters, object.filter_id, object.choices]
  );

  return (
    <div className='mt-6' key={`filter-${object.filter_id}`}>
      <InputWithMultiselectDropdown
        value={value}
        onChange={onChange}
        allOptionsValue={allOptionValue}
        limit={limit}
        options={defaultOptions}
        disabled={disabled}
        placeholder={`Select ${object.title.toLowerCase()}`}
      />
      <div className='my-1'>
        {!!filters[object.filter_id]?.selected_values?.length &&
          filters[object.filter_id].selected_values.map((value) => (
            <Button
              key={`removeFilter-${object.filter_id}-${value}`}
              className='my-1 mr-2'
              primary
              outline
              medium
              disabled={disabled}
              onClick={() => {
                storeFilterValues([
                  {
                    filter_id: object.filter_id,
                    selected_values: (filters[object.filter_id].selected_values || []).filter(
                      (selected_value) => selected_value !== value
                    )
                  }
                ]);
                setChanged(true);
              }}
            >
              {object.choices[value]}
              <CloseIcon className='ml-2' />
            </Button>
          ))}
      </div>
    </div>
  );
};
