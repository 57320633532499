import cn from 'classnames';
import * as React from 'react';

import { Text } from '@components/common';

export type RadioGroupOption<T extends string = string> = {
  label: string;
  helper?: string;
  value: T;
  extra?: any;
  disabled?: boolean;
  className?: string;
};
interface RadioGroupProps<T extends string = string> {
  id?: string;
  options: RadioGroupOption<T>[];
  selected: T;
  name?: string;
  disabled?: boolean;
  borderColor?: string;
  selectedColor?: string;
  border?: boolean;
  onChange?: (val: T) => void;
}

function optionClass(i, length) {
  if (i === 0) {
    return 'rounded-tl-md rounded-tr-md';
  } else if (i === length - 1) {
    return 'rounded-bl-md rounded-br-md';
  } else {
    return '';
  }
}

interface RadioInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  value?: string;
  disabled?: boolean;
  checked?: boolean;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RadioInput: React.FC<RadioInputProps> = ({ id, value, disabled, checked, name, onChange, ...rest }) => {
  return (
    <input
      id={id}
      type='radio'
      name={name}
      data-testid={String(value)}
      value={String(value)}
      disabled={disabled}
      onChange={(v) => onChange?.(v)}
      checked={checked}
      className={`focus:ring-indigo-500 h-4 w-4 text-indigo-600 ${
        disabled ? 'cursor-not-allowed' : 'cursor-pointer'
      } border-gray-400`}
      {...rest}
    />
  );
};

export const RadioGroup = <T extends string = string>(props: RadioGroupProps<T>): React.ReactElement => {
  const {
    selectedColor = 'indigo-50',
    options,
    selected,
    disabled: allDisabled,
    onChange,
    name,
    border = true,
    borderColor = 'gray-200'
  } = props;

  const handleChange = (e) => {
    onChange?.(e.currentTarget.value);
  };

  return (
    <div id={props.id} className={` rounded-md -space-y-px bg-white ${allDisabled ? 'opacity-75' : ''}`}>
      {options.map(({ label, helper, value, extra, disabled, className = '' }, i) => (
        <div
          key={label}
          className={cn(className, 'relative', optionClass(i, options.length), {
            'opacity-50': disabled,
            [`bg-${selectedColor}`]: selected === value,
            [`p-4 border flex border-${borderColor}`]: border,
            'pb-3': !border && i !== options.length - 1
          })}
        >
          <label
            className={`space-x-3 flex flex-row ${disabled || allDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
          >
            <div className='flex items-center h-5'>
              <RadioInput
                value={String(value)}
                name={name}
                disabled={disabled || allDisabled}
                onChange={handleChange}
                checked={selected === value}
              />
            </div>
            <div className='flex flex-col'>
              <Text medium={!!helper} h='400'>
                {label}
              </Text>
              {helper && (
                <Text h='400' mt='2' color='gray-500'>
                  {helper}
                </Text>
              )}
              {extra}
            </div>
          </label>
        </div>
      ))}
    </div>
  );
};
