import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AvatarFromId, Button, Heading } from '@components/common';
import { StudyDetailsList, STYLE_ICONS } from '../StudyDetailsList';
import * as svgs from '../svgs';
import { useAccount } from '@hooks/useAccount';

interface Props {
  landing_page: LandingPage;
  study: Study;
  brand?: Brand;
}

export const PreviewSignupPage: React.FC<Props> = ({ landing_page, study, brand }) => {
  const {
    account: { name: accountName }
  } = useAccount();

  const { t, i18n } = useTranslation('PreviewSignupPage');

  useEffect(() => {
    i18n.changeLanguage(study.language);
  }, []);

  const showNew = study.style === 'panel';

  const relevantScreener: Screener | null = study.pre_screener || study.survey_screener;

  return (
    <>
      <div className='brand flex space-x-4'>
        <div className='flex-1'>
          <Heading className='pb-4'>{landing_page.title}</Heading>
          <span className='h600 block pb-12'>
            {landing_page.description.split('\n').map((p, i) => (
              <p className='my-2' key={i}>
                {p}
              </p>
            ))}
          </span>

          <div className='pb-8 space-y-4'>
            <Button className='btn-custom-brand hover:bg-gray-700' secondary>
              {landing_page.button_text}
            </Button>
            {relevantScreener?.fields && relevantScreener.fields.length > 0 && (
              <p className='text-custom-brand-secondary block text-gray-500'>
                {t('fields', { count: relevantScreener.fields.length })}
              </p>
            )}
          </div>
          {landing_page.personal_note && (
            <div className='flex space-x-4'>
              <div className='flex-shrink-0'>
                <AvatarFromId userId={study.owner?.id} size='xl' />
              </div>
              <div className='bg-gray-50 max-w-md p-4 text-gray-700 border border-gray-200 rounded-lg'>
                {study.owner && (
                  <>
                    <span className='h500-bold block'>{study.owner.name}</span>
                    <span className='h400 block pb-4 text-gray-500'>
                      {t('job_title', { job_title: study.owner.job_title, company: accountName })}
                    </span>
                  </>
                )}
                <div className='h400'>
                  {landing_page.personal_note.split('\n').map((p, i) => (
                    <div key={i}>
                      <p className='my-1'>{p}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='flex-shrink-0 w-1/3'>
          <div className='p-6 text-gray-700 border border-gray-200 rounded-md'>
            {showNew ? (
              <>
                <div>
                  <h2 className='h600-bold py-2'>{landing_page.sidebar_title}</h2>
                  <p className='h400 py-3'>{landing_page.sidebar_subtitle}</p>
                  <ul className='space-y-3'>
                    {landing_page.sidebar_items.map((i: string) => {
                      const Icon = STYLE_ICONS[i];
                      return (
                        <li key={i} className='flex items-center'>
                          {Icon && (
                            <div className='flex justify-center w-10'>
                              <Icon />
                            </div>
                          )}
                          <p>{t(`sidebar_items.${i}`)}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </>
            ) : (
              <>
                <h2 className='h600-bold block pb-4'>{landing_page.sidebar_title}</h2>
                <StudyDetailsList study={study} brand={brand} />
                <h2 className='h600-bold py-6 mt-6 border-t border-gray-200'>{landing_page.sidebar_subtitle}</h2>
                <ul className='h400 space-y-3'>
                  {landing_page.requirements.map((r) => (
                    <li key={r} className='flex items-center'>
                      <div className='flex justify-center w-10'>
                        <svgs.checkBox />
                      </div>
                      {r.replace(/^\s*-\s*/, '')}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
