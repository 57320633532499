import * as React from 'react';
import { Button } from 'components/common';

export const authButtonProps = (provider) => ({
  'data-method': 'post',
  href: `/users/auth/${provider}`
});

interface Props {
  provider: string;
  primary?: boolean;
  small?: boolean;
  className?: string;
  children: React.ReactNode;
}

export const AuthButton: React.FC<Props> = ({ provider, primary, small, className, children }) => {
  return (
    <Button
      primary={primary}
      small={small}
      className={className}
      {...authButtonProps(provider)}
      target='auth_popup'
      onClick={() => {
        window.open('about:blank', 'auth_popup', 'width=1000,height=800');
      }}
    >
      {children}
    </Button>
  );
};
