import React, { useRef, useState, VoidFunctionComponent as VFC } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { AIBots } from '@api/chat-gpt';
import { TitleHandle } from '@components/shared/AI';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { usePermission } from '@hooks/usePermission';
import { useAiTitle } from '@components/shared/AI/hooks/useAiTitle';
import { composeEventHandlers } from '@helpers/composeEventHandlers';

import { TITLE_HELPER } from '../constants';
import { ResearchGoalModal } from '../ResearchGoalModal';

interface Props {
  study: Study;
  updateStudy: (study: { id: number } & Partial<Study>) => void;
}

export const StudyTitle: VFC<Props> = ({ study, updateStudy }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const inputHandle = useRef<TitleHandle<{ text: string }>>();

  const [storedRejectedSuggestions, setStoredRejectedSuggestions] = useLocalStorage<number[]>(
    'study-title-rejected-suggestions'
  );
  const canUpdate = usePermission<Study>('updateStudy')(study);

  const { callback: debouncedSave } = useDebouncedCallback(updateStudy, process.env.NODE_ENV === 'test' ? 10 : 300);

  const disableSuggestions = !study.research_goal;
  const defaultTitleRegex = /Untitled\s(.*)\sstudy\s\(\d{1,2}\/\d{1,2}\/\d{1,4}\)/;
  const shouldFetchOnMount =
    !storedRejectedSuggestions?.includes(study.id) && defaultTitleRegex.test(study.title) && !!study.research_goal;

  const onInputSave = (value: string) => {
    if (value !== study.title) {
      updateStudy({ id: study.id, title: value });
    }
  };

  const onInputGetSuggestions = () => {
    if (disableSuggestions) {
      setShowModal(true);
    }
  };

  const onResearchGoalUpdate = (research_goal: string) => {
    if (research_goal) {
      inputHandle.current?.getSuggestions({ text: research_goal });
      updateStudy({ id: study.id, research_goal });
    }

    setShowModal(false);
  };

  const { value, handleOnChange, AiActions } = useAiTitle({
    bot: AIBots.StudyTitle,
    context: { text: study.research_goal },
    value: study.title,
    disabled: !study.research_goal,
    handle: inputHandle,
    readOnly: !canUpdate,
    onSave: onInputSave,
    onGetSuggestions: onInputGetSuggestions,
    fetchOnMount: shouldFetchOnMount,
    onReject: () => setStoredRejectedSuggestions((storedRejectedSuggestions ?? []).concat(study.id)),
    resetValueOnUpdate: false
  });

  return (
    <div className='w-full pt-6'>
      <label htmlFor='study_title' className='h500-bold block mb-2'>
        Study title
      </label>
      <span className='h400 block mb-2 text-gray-500'>{TITLE_HELPER}</span>

      <div className='relative w-full mt-1'>
        <input
          name='study_title'
          aria-label='Study title'
          placeholder='e.g onboarding discovery research'
          value={value}
          disabled={!canUpdate}
          className='tablet:text-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full h-10 p-2 pl-3 pr-20 placeholder-gray-400 border border-gray-200 rounded-md'
          onChange={composeEventHandlers(handleOnChange, (e) => debouncedSave({ id: study.id, title: e.target.value }))}
        />
        <div className='absolute inset-y-0 right-0 flex items-center w-auto pr-1'>
          <AiActions />
        </div>
      </div>
      {showModal && <ResearchGoalModal onClose={() => setShowModal(false)} onSubmit={onResearchGoalUpdate} />}
    </div>
  );
};
