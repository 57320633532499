import { useTeams } from 'hooks/useTeams';
import React, { useState } from 'react';

import { ConfirmationModal } from '@components/BrandingApp/components/ConfirmationModal';
import { track } from '@components/tracking';
import { useParams } from 'react-router-dom';
import { Editor, Header } from './components';
import { useBrand } from '@hooks/useBrand';
import { useBeforeunload } from 'react-beforeunload';
import { Basic } from '@components/shared/Skeleton';

export const BrandingApp = () => {
  const { id: team_id } = useParams<{ id?: string }>();

  const { updateBrand, isLoading, isFetching, deleteBrand, initialBrand, brand, setBrand, setLogoFile, logoFile } =
    useBrand(team_id);

  const { findTeam } = useTeams();
  const team = team_id ? findTeam(parseInt(team_id)) : undefined;

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  useBeforeunload((e) => {
    if (isDirty) {
      e.preventDefault();
    }
  });

  const change = (field, value) => {
    setIsDirty(true);
    setBrand({ ...brand, [field]: value });
  };

  const onSave = () => {
    setConfirmationOpen(true);
  };
  const onConfirm = () => {
    setIsDirty(false);
    updateBrand();
    setEdit(false);
    track('brand_settings_updated', { has_team: !!team_id });
    setConfirmationOpen(false);
  };

  return (
    <section className='flex flex-col items-center'>
      <div className='w-full max-w-xl'>
        <Header
          disabled={isFetching}
          setIsDirty={setIsDirty}
          brand={brand}
          edit={edit}
          setEdit={setEdit}
          onSave={onSave}
          team={team}
        />

        {isFetching ? (
          <Basic width='100%' height='80vh' />
        ) : (
          <Editor
            edit={edit}
            brand={brand}
            change={change}
            deleteBrand={deleteBrand}
            logoFile={logoFile}
            setLogoFile={setLogoFile}
          />
        )}
      </div>
      <ConfirmationModal
        isLoading={isLoading}
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={onConfirm}
      />
    </section>
  );
};
