import { List, Text } from 'components/common';
import * as React from 'react';
import { useEffect } from 'react';

import { api } from '@api/reduxApi';
import { useToaster } from '@stores/toaster';

import * as toasts from '../../../toasts';
import { StudyActionModal, StudyActionModalProps } from '../StudyActionModal/StudyActionModal';

export const DeleteStudyModal: React.FC<StudyActionModalProps> = (props) => {
  const showToast = useToaster();
  const [deleteStudy, { isSuccess, isError, isLoading }] = api.useDeleteStudyMutation();

  useEffect(() => {
    if (isSuccess) {
      showToast(toasts.successDelete(props.title));
      props.onAfterSubmit?.();
      props.onClose();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedDelete());
    }
  }, [isError]);

  return (
    <StudyActionModal
      {...props}
      icon='danger'
      heading='Delete study'
      loading={isLoading}
      primaryButtonProps={{
        danger: true,
        children: 'Delete'
      }}
      onSubmit={async () => {
        deleteStudy({ id: props.id });
      }}
    >
      <Text>Are you sure you want to permanently delete “{props.title}“?</Text>
      <Text mt='4' mb='2'>
        The following will also be deleted:
      </Text>
      <List>
        {props.style === 'video_call' && <li>Interview recordings</li>}
        {props.style === 'survey' && <li>Survey results</li>}
        {props.style === 'unmoderated_test' && <li>Test results</li>}
        <li>Notes and reports</li>
        {props.style === 'video_call' && <li>Highlights</li>}
      </List>
    </StudyActionModal>
  );
};
