import { customAttrToCollectionAttribute } from 'components/shared/CollectionAttributes/customAttrToCollectionAttribute';
import React from 'react';

import { Avatar, Text } from '@components/common';
import {
  buildCollectionAttributes,
  CollectionAttributes
} from '@components/shared/CollectionAttributes/buildCollectionAttributes';
import * as dropdowns from '@components/shared/TableFilters/components/dropdowns';
import * as svgs from '@components/shared/TableFilters/components/svgs';
import { StudyStatus } from '@components/StudiesApp/components/StudyPublished/components/StudyStatus';
import { STYLES } from '@components/StudiesApp/constants';

import { filterByType } from './utils';

type DropdownOption<T> = { label: string; value: T };

// should be singluar.
export const STATUSES: Record<Exclude<Study['state'], 'cancelled' | 'completed' | 'archived'>, string> = {
  active: 'Active',
  draft: 'Draft',
  paused: 'Paused',
  pending: 'Pending',
  closed: 'Closed'
};

const INCENTIVE_METHODS: Record<Exclude<Study['incentive_method'], null>, string> = {
  manual: 'Manual payment',
  tremendous: 'Money or gift card',
  coupon: 'Coupons',
  product: 'Product gift',
  other: 'Other',
  '': 'None'
};

const labelsToOptions = (labels: Record<string, string>): DropdownOption<any>[] => {
  return Object.entries(labels).map(([value, label]) => ({ value, label }));
};

const groupByTypeOptions = Object.keys(
  Object.fromEntries(Object.entries(STYLES).sort(([, a], [, b]) => (a as string).localeCompare(b as string)))
);

const STYLE_OPTIONS = labelsToOptions(STYLES);
const STATUSES_OPTIONS = labelsToOptions(STATUSES);
const INCENTIVE_METHOD_OPTIONS = labelsToOptions(INCENTIVE_METHODS);

const StudyStatusHeader: React.FC<{ value: string }> = ({ value }) => {
  return <StudyStatus status={value as Study['state']} noTip />;
};

type Props = {
  onlyBackendFilters?: boolean;
  team: TeamUser[];
  studyAttrs: Attr_[];
};

export const buildStudiesAttributes = ({
  onlyBackendFilters,
  team,
  studyAttrs
}: Props): CollectionAttributes<Study> => {
  const teamMembers = [...team];

  const byOwnerOptions = teamMembers
    .sort(function (a, b) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    })
    .map((t) => t.id);

  return buildCollectionAttributes<Study>([
    {
      id: 'title',
      type: 'free_text'
    },
    {
      id: 'style',
      name: 'Type',
      type: 'text',
      Icon: svgs.type,
      groupable: true,
      renderValue: (value) => STYLES[value],
      filter: {
        operators: ['includes_any', 'includes_none'],
        renderValue: (value) => value.map((v) => STYLES[v]).join(', '),
        Component: (props) => <dropdowns.Choose {...props} options={STYLE_OPTIONS} />,
        func: filterByType
      },
      group: {
        options: groupByTypeOptions
      }
    },
    {
      id: 'state',
      type: 'text',
      name: 'Status',
      Icon: svgs.status,
      groupable: true,
      renderValue: (value) => STATUSES[value],
      filter: {
        operators: ['includes_any', 'includes_none'],
        Icon: svgs.status,
        renderValue: (value) => value.map((v) => STATUSES[v]).join(', '),
        Component: (props) => <dropdowns.Choose {...props} options={STATUSES_OPTIONS} />
      },
      group: {
        options: Object.keys(STATUSES),
        Header: StudyStatusHeader
      }
    },
    {
      id: 'owner_id',
      name: 'Owner',
      type: 'team_members',
      Icon: svgs.owner,
      renderValue: (value) => {
        const userName = team.find((user) => user.id === value)?.name;
        return userName ? userName : 'None';
      },
      groupable: true,
      filter: {
        Component: dropdowns.TeamMember,
        defaultOperator: 'includes_any',
        func: ({ owner_id }, { operator, value }) => {
          if (!value) return true;
          return value.includes(owner_id);
        }
      },
      group: {
        options: byOwnerOptions,
        Header: ({ value }) => {
          const user = team.find((user) => user.id === value);
          return (
            <div className='flex items-center space-x-2'>
              {user && <Avatar user={user} noTippy />}
              <Text bold h='400'>
                {user?.name || 'None'}
              </Text>
            </div>
          );
        }
      }
    },
    { id: 'created_at', type: 'date' },
    { id: 'updated_at', type: 'date' },
    {
      id: 'has_external_candidates_requests',
      type: 'boolean',
      name: 'Recruitment requests',
      sortable: !onlyBackendFilters
    },
    {
      id: 'incentive',
      type: 'number',
      name: 'Incentive amount'
    },
    {
      id: 'incentive_method',
      type: 'text',
      groupable: true,
      renderValue: (value) => (value ? INCENTIVE_METHODS[value] : 'None'),
      filter: {
        operators: ['includes_any', 'includes_none'],
        renderValue: (value) => (Array.isArray(value) ? value.map((v) => INCENTIVE_METHODS[v]).join(', ') : ''),
        Component: (props) => <dropdowns.Choose {...props} options={INCENTIVE_METHOD_OPTIONS} />
      },
      group: {
        options: Object.keys(INCENTIVE_METHODS)
      }
    },
    ...studyAttrs.map((c) => customAttrToCollectionAttribute<Study>(c, !onlyBackendFilters))
  ]);
};
