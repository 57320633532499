import cn from 'classnames';
import * as React from 'react';
import { useRef, useState } from 'react';

import { Option, Text } from '@components/common';
import { ChevronDownSVG } from '@components/svgs';
import { CheckSVG } from '@components/svgs';
import { track } from '@components/tracking';
import { useOnClickOutside } from '@components/utils';
import Tippy from '@tippyjs/react';

interface Props {
  value?: string | null;
  onChange: (args: Partial<CollectionView['sort']>) => void;
  options: Option[];
  trackKey?: string;
  type?: 'sort' | 'group';
  wrapperClass?: string;
}

export const SortDropdown: React.FC<Props> = ({ value, wrapperClass, type = 'sort', onChange, options, trackKey }) => {
  const [open, setOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const selectedOption = options.find((o) => o.value === value);

  const sortedOptions = [...options];
  sortedOptions.sort((a, b) => {
    return a.label.localeCompare(b.label);
  });

  useOnClickOutside(ref, () => open && setOpen(false));

  return (
    <div className={cn('relative', wrapperClass)}>
      <Tippy
        placement='top'
        arrow={false}
        disabled={!selectedOption?.label}
        content={
          <Text h='400' className='inline' color='white'>
            Sort by: {selectedOption?.label}
          </Text>
        }
      >
        <button
          className='whitespace-nowrap focus:outline-none hover:border-indigo-600 flex items-center justify-between rounded-md px-4 py-1.5 space-x-2 bg-white border-gray-200 border w-full'
          name={`${type}-dropdown`}
          data-testid={`${type}-dropdown`}
          onClick={() => setOpen(true)}
        >
          <Text h='400' className='truncate'>
            {selectedOption?.label || 'None'}
          </Text>
          <ChevronDownSVG className='flex flex-shrink-0' />
        </button>
      </Tippy>
      {open && (
        <div ref={ref} className='absolute right-0 z-50'>
          <div
            className='whitespace-nowrap p-4 overflow-y-auto bg-white rounded-md shadow-lg'
            style={{ maxHeight: 400 }}
          >
            <div className='w-40 space-y-3'>
              {sortedOptions.map((option) => (
                <button
                  key={option.value}
                  name={option.value}
                  data-testid={option.value}
                  className='whitespace-nowrap focus:outline-none hover:text-indigo-600 flex items-center w-full p-1 text-left truncate'
                  onClick={() => {
                    onChange({ value: option.value });
                    open && setOpen(false);
                    if (trackKey) {
                      track('sorted', { page: trackKey });
                    }
                  }}
                >
                  <Text
                    color={option.value === value ? 'indigo-600' : undefined}
                    className='whitespace-nowrap flex-1 mr-1'
                    h='400'
                  >
                    {option.label}
                  </Text>

                  {option.value === value && <CheckSVG />}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
