import * as React from 'react';

import { BackArrowSVG } from '@components/svgs';

const BackArrow: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <div tabIndex={0} role='button' className='inline-block p-2' onClick={onClick}>
    <BackArrowSVG className='text-indigo-600' />
  </div>
);
export default BackArrow;
