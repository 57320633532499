import React, { useEffect, useRef, useState } from 'react';

import { api } from '@api/reduxApi';
import { Alert, Loading } from '@components/common';
import { AuthButton } from '../AuthButton';

interface Props {
  user: User;
}

export const NylasLoadedBlocker: React.FC<Props> = ({ user }) => {
  const [tries, setTries] = useState(1);
  const { data, isError, refetch, isFetching, isLoading } = api.useGetUserNylasLoadedQuery(user.id, {
    skip: user.nylas_loaded || !user.id
  });
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (!isError && !data?.nylas_loaded && !isLoading && tries < 4) {
      timeoutRef.current = setTimeout(() => {
        if (!user.nylas_loaded) {
          refetch();
        }
        setTries(tries + 1);
      }, 2000);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [data, isFetching, isError, isLoading]);

  if (data?.nylas_loaded || user.nylas_loaded) {
    return null;
  }

  if (isError || tries >= 4) {
    return (
      <div className='desktop:px-12 my-3'>
        <Alert type='error' heading='Failed to load calendar'>
          Please retry or contact support
          <div className='mt-2'>
            <AuthButton small provider='nylas' className='mt-2'>
              Retry
            </AuthButton>
          </div>
        </Alert>
      </div>
    );
  }

  return <Loading />;
};
