import { useCancelMutation } from 'components/StudiesApp/components/StudyPublished/api';
import * as React from 'react';
import { useState } from 'react';

import { sendStudyMessage } from '@api/queries';
import { Loading } from '@components/common';
import { Toast, useToaster } from '@components/stores/toaster';

import { StudyMessageSlideOut } from './StudyMessageSlideOut';

interface Props {
  study: Study;
  participations: Participation[];
  ids: number[];
  onSuccess: (backgroundTask: BackgroundTask) => void;
  onClose: () => void;
}

const toasts: { [key: string]: Toast } = {
  sent: {
    heading: 'Cancel emails sent!',
    text: `All participants were canceled and emailed.`,
    icon: 'success'
  },
  canceled: {
    heading: `Successfully canceled!`,
    text: 'All participants were marked as canceled.',
    icon: 'success'
  }
};

export const CancelSlideOut: React.FC<Props> = ({ study, ids, participations, onSuccess, onClose }) => {
  const showToast = useToaster();
  const [loading, setLoading] = useState<boolean>(false);
  const [cancel] = useCancelMutation();

  const [send, setSend] = useState(true);

  const parties = participations.filter(
    ({ customer_id, status }) => ids.includes(customer_id) && ['booked', 'started', 'completed'].includes(status)
  );

  if (!parties) return null;

  async function handleSend({ message, send, sender }) {
    if (!parties) return;
    setLoading(true);
    const selectedIds = parties.map(({ id }) => id);
    const backgroundTask = await cancel({ studyId: study.id, ids: selectedIds }).unwrap();

    if (backgroundTask) {
      onSuccess(backgroundTask);

      if (send) {
        await sendStudyMessage(
          { id: message.id, studyId: study.id },
          { participation_ids: parties.map(({ id }) => id), sender }
        );
        showToast(toasts.sent);
      } else {
        showToast(toasts.canceled);
      }
    }

    setLoading(false);
  }

  return (
    <StudyMessageSlideOut
      optional={{ send, setSend }}
      customizable
      title='Mark as canceled'
      subtitle={
        parties.find((p) => p.interview_at && new Date(p.interview_at) < new Date())
          ? 'Note: Use "Mark as no-showed" for participants that did not attend'
          : undefined
      }
      cta='Confirm & Send'
      study={study}
      event='cancel_interview'
      previewCandidate={parties[0]?.customer}
      previewParticipation={parties[0]}
      inviteableCount={parties.length}
      onClose={onClose}
      onSend={handleSend}
    >
      <>{loading && <Loading absolute />}</>
    </StudyMessageSlideOut>
  );
};
