import { api } from 'api/reduxApi';
import { RestrictedAction } from 'components/shared/RestrictedAction';
import { RestrictedButton } from 'components/shared/RestrictedButton';
import { usePlan } from 'hooks/usePlan';
import { useTrackDuration } from 'hooks/useTrackDuration';
import * as React from 'react';
import { useState } from 'react';

import { Button } from '@components/common';
import { useToaster } from '@stores/toaster';

import { StudyPublishValidator } from '../hooks/useStudyPublishValidator';
import { useDraftStudyContext } from '../StudyDraft';
import * as toasts from '../toasts';
import { PublishModal } from './PublishModal';

interface Props {
  validator: StudyPublishValidator;
  study: Study;
  saving: boolean;
  setSaving: (value: boolean) => void;
}

// TODO: this should use the same as study
type EditStudyTab = 'calendar' | 'task' | 'survey';

function getErrorMessage(study: Study, data: Record<string, any>): string | null {
  if (data == null) return null;

  const errKeys: EditStudyTab[] = [];
  if (data.start_url) {
    switch (study.style) {
      case 'video_call':
        errKeys.push('calendar');
        break;
      case 'online_task':
      case 'unmoderated_test':
        errKeys.push('task');
        break;
      case 'survey':
        errKeys.push('survey');
        break;
    }
  }
  return errKeys.length > 0 ? `Please check your ${errKeys.join(' and ')} settings.` : null;
}

export const PublishStudyButton: React.FC<Props> = ({ validator, study, saving, setSaving }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<'funding' | 'not_selected' | null>(null);

  const showToast = useToaster();

  const { funder, prefundLimit } = useDraftStudyContext();
  const trackPublishStudy = useTrackDuration({ action: 'publish_study' });

  const [transitionStudy] = api.useTransitionStudyMutation();

  const createButtonText = funder.total > 0 ? `Create & Fund ${funder.displayTotal}` : 'Create';

  async function openPublishModal() {
    setSaving(true);
    await funder.prepare({ participant_limit: prefundLimit });
    setModalOpen(true);
    setSaving(false);
  }

  async function handleSubmit(delayFunding: boolean) {
    trackPublishStudy.start();
    setSaving(true);

    let activate = false;
    if (!delayFunding) {
      // trying to fund first
      const res = await funder.process({ participant_limit: prefundLimit });

      if (res === 'error') {
        setSaving(false);
        // if error - stop execution and show error
        if (funder.selectedMethod.kind === 'external_incentive') {
          showToast(toasts.failedPublish('incentive'));
          return;
        } else {
          setError('funding');
          return;
        }
      }
      if (res === 'funded') {
        activate = true;
      }
    } else {
      activate = true;
    }

    if (activate) {
      try {
        // if funding is ok - try to publish
        await transitionStudy({ id: study.id, transition: 'activate' }).unwrap();
      } catch (err) {
        setSaving(false);
        // if error - stop execution and show error
        showToast(toasts.failedPublish('activate', getErrorMessage(study, err.data as any) as any));
        return;
      }
    }
    setModalOpen(false);

    const path = study.external_candidates_enabled
      ? `/studies/${study.id}/recruitment_requests`
      : `/studies/${study.id}`;

    // is it ok that we don't call trackPublishStudy.stop() if funding or publish request fails?
    trackPublishStudy.stop();
    window.location.href = path;
  }

  React.useEffect(() => {
    funder.prepare({ participant_limit: prefundLimit });
  }, [prefundLimit]);

  return (
    <>
      <RestrictedButton action='Publish study' limit='studies' permission='createStudy'>
        {!validator.canPublish && <Button inactive>{createButtonText}</Button>}
        {validator.canPublish && !study.external_candidates_enabled && study.incentive_method !== 'tremendous' && (
          <Button onClick={openPublishModal} primary>
            {createButtonText}
          </Button>
        )}
        {validator.canPublish && study.external_candidates_enabled && (
          <RestrictedAction feature='external_recruitment'>
            {({ may }) => (
              <Button onClick={may ? openPublishModal : undefined} primary>
                {createButtonText}
              </Button>
            )}
          </RestrictedAction>
        )}
        {validator.canPublish && study.incentive_method === 'tremendous' && !study.external_candidates_enabled && (
          <RestrictedAction feature='incentives'>
            {({ may }) => (
              <Button onClick={may ? openPublishModal : undefined} primary>
                {createButtonText}
              </Button>
            )}
          </RestrictedAction>
        )}
      </RestrictedButton>
      {modalOpen && (
        <PublishModal
          study={study} //clean up
          funder={funder}
          onClose={() => setModalOpen(false)}
          saving={saving}
          error={error}
          setError={setError}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
};
