import cn from 'classnames';
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { AATeamMembers } from '@components/AccountTeamsApp/components/icons';
import { AvatarFromId, Pill, TeamIcon } from '@components/common';
import { Compliance, Permissions } from '@components/GovernanceApp/components';
import { PageNavbar } from '@components/shared/PageNavbar/PageNavbar';
import { BuildingSVG, ChevronRightSVG } from '@components/svgs';
import { capitalize, compact, humanize, without } from '@components/utils';
import { useAccount } from '@hooks/useAccount';
import { useFeature } from '@hooks/useFeature';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { usePermission } from '@hooks/usePermission';
import { usePlan } from '@hooks/usePlan';
import { useTeams } from '@hooks/useTeams';
import { useUser } from '@hooks/useUser';

import { IS_PRODUCTION } from '../../../config/env';

interface TabSections {
  account?: boolean;
  user?: boolean;
  teams?: boolean;
  governance?: boolean;
}

interface TabLinkProps {
  to: string;
  isActive?: boolean;
  name: string;
  isNew?: boolean;
  onClick?: React.MouseEventHandler;
}

const TabLink: React.FC<TabLinkProps> = ({ onClick, to, isActive, name, isNew }) => (
  <Link
    to={to}
    role='tab'
    onClick={onClick}
    className={cn(
      { 'bg-indigo-50 text-indigo-600': isActive },
      'flex space-x-3 items-center text-gray-700 w-full focus:outline-none focus:text-indigo-600 hover:text-indigo-600 group px-10 py-1.5 text-sm relative'
    )}
  >
    <span>{humanize(capitalize(name))}</span>
    {isNew && <Pill color='indigo'>new</Pill>}
  </Link>
);

const newTabs: string[] = ['Wallets', 'Governance'];

export const SettingsNavbar = () => {
  const user = useUser();
  const { account } = useAccount();
  const { pathname } = useLocation();
  const { data: accounts } = api.useGetAccountsQuery();
  const { hasFeature } = usePlan();
  const isAdmin = usePermission('isAdmin')();

  const apiAccessEnabled = useFeature('api_access');
  const teamsEnabled = useFeature('teams');
  const mfaEnabled = useFeature('mfa');
  const hasAIAccess = account.ai_access;
  const teamWalletsEnabled = useFeature('team_wallets');

  const [hiddenNewLabels, setHiddenNewLabels] = useLocalStorage<string[]>('settings-hidden-new-labels');

  const [hiddenTabs, setHiddenTabs] = useLocalStorage<TabSections>('settings-hidden-tabs');

  const [hiddenTeams, setHiddenTeams] = useLocalStorage<number[]>('settings-hidden-teams');

  const userTabs = compact([
    { label: 'Profile', href: `/users/edit` },
    mfaEnabled && { label: 'Security', href: `/user/security` },
    { label: 'Password', href: `/account/password/edit` },
    { label: 'Integrations', href: `/user/connected_accounts` }
  ]);

  const accountTabs = compact([
    isAdmin && { label: 'Profile', href: `/accounts/${account.id}/edit` },
    isAdmin && hasFeature('basic_branding') && { label: 'Branding', href: `/branding` },
    { label: 'Members', href: `/accounts/${user.id}` },
    isAdmin && hasAIAccess && { label: 'AI preferences', href: `/ai_preferences` },
    isAdmin && { label: 'Notifications', href: `/notifications` },
    teamsEnabled && isAdmin && { label: 'Teams', href: `/teams` },
    isAdmin && { label: 'Billing', href: `/subscription` },
    isAdmin && { label: 'Fundings', href: `/wallet/transactions` },
    teamWalletsEnabled && isAdmin && { label: 'Wallets', href: `/wallet/wallets` },
    { label: 'Custom attributes', href: `/custom_attrs` },
    { label: 'Candidate segments', href: `/candidate_segments` },
    { label: 'Governance', href: `/governance`, isNew: !hiddenNewLabels?.includes('Governance') },
    IS_PRODUCTION && isAdmin && { label: 'CSV imports', href: `/customer_imports` },
    { label: 'Tags', href: `/tags` },
    apiAccessEnabled && isAdmin && { label: 'API tokens', href: `/api_tokens` },
    hasFeature('data_retention') && isAdmin && { label: 'Data retention', href: `/data_retention` },
    hasFeature('custom_consent') && isAdmin && { label: 'Legal', href: `/legal` }
  ]);

  const governanceTabs = compact([
    { label: 'Candidate eligibility', href: `/governance/eligibility` },
    isAdmin && { label: 'Data retention', href: `/governance/data_retention` },
    isAdmin && { label: 'Permissions', href: `/governance/permissions` },
    { label: 'Compliance', href: `/governance/compliance` }
  ]);

  const enableTeams = useFeature('teams');
  const canManageTeams = usePermission('manageTeams')();
  const { teams } = useTeams();
  const toggleShowTeam = (teamId: number) =>
    setHiddenTeams(hiddenTeams?.includes(teamId) ? without(hiddenTeams, teamId) : [...(hiddenTeams || []), teamId]);

  return (
    <PageNavbar>
      <button
        className='focus:outline-none no-outline flex items-center w-full px-4 py-1 mt-4 space-x-2 text-xs font-bold text-left text-gray-500'
        onClick={() => setHiddenTabs({ ...hiddenTabs, user: !hiddenTabs?.user })}
      >
        <AvatarFromId size='sm' userId={user.id} />
        <span>{user.name}</span>
        <ChevronRightSVG
          className={cn('inline-block ml-2 h-2.5 w-2.5 transform transition ease-in-out duration-200', {
            ['rotate-90']: !hiddenTabs?.user
          })}
        />
      </button>

      {!hiddenTabs?.user &&
        !!userTabs.length &&
        userTabs.map(({ label, href }) => {
          return <TabLink key={label} to={href} name={label} isActive={pathname.includes(href)} />;
        })}
      <button
        className='focus:outline-none no-outline flex items-center w-full px-4 py-1 mt-4 space-x-2 text-xs font-bold text-left text-gray-500'
        onClick={() => setHiddenTabs({ ...hiddenTabs, account: !hiddenTabs?.account })}
      >
        <BuildingSVG />
        <div>{account.workspace_name || account.name}</div>
        <ChevronRightSVG
          className={cn('inline-block ml-2 h-2.5 w-2.5 transform transition ease-in-out duration-200', {
            ['rotate-90']: !hiddenTabs?.account
          })}
        />
      </button>
      {!hiddenTabs?.account &&
        !!accountTabs.length &&
        accountTabs.map(({ label, href, isNew }) => {
          if (label === 'Governance') {
            return (
              <div key={label}>
                <button
                  className='flex space-x-3 pl-10 items-center text-gray-700 w-full focus:outline-none group px-10 py-1.5 text-sm relative'
                  onClick={() => {
                    if (newTabs.includes(label)) {
                      setHiddenNewLabels([...(hiddenNewLabels || []), label]);
                    }
                    setHiddenTabs({ ...hiddenTabs, governance: !hiddenTabs?.governance });
                  }}
                >
                  <div>{label}</div>
                  <ChevronRightSVG
                    className={cn('inline-block ml-2 h-2.5 w-2.5 transform transition ease-in-out duration-200', {
                      ['rotate-90']: !hiddenTabs?.governance
                    })}
                  />
                  {isNew && (
                    <Pill className='mr-0' color='indigo'>
                      new
                    </Pill>
                  )}
                </button>
                <div className='pl-6'>
                  {!hiddenTabs?.governance &&
                    governanceTabs.map(({ label, href }) => (
                      <TabLink
                        key={label}
                        to={href}
                        name={label}
                        onClick={() => {
                          if (newTabs.includes('Governance')) {
                            setHiddenNewLabels([...(hiddenNewLabels || []), 'Governance']);
                          }
                        }}
                        isActive={pathname.includes(href) && !pathname.includes('/teams/')}
                        isNew={newTabs.includes(label) && !hiddenNewLabels?.includes(label)}
                      />
                    ))}
                </div>
              </div>
            );
          }

          return (
            <TabLink
              key={label}
              to={href}
              name={label}
              onClick={() => {
                if (newTabs.includes(label)) {
                  setHiddenNewLabels([...(hiddenNewLabels || []), label]);
                }
              }}
              isActive={pathname.includes(href) && !pathname.includes('/teams/')}
              isNew={newTabs.includes(label) && !hiddenNewLabels?.includes(label)}
            />
          );
        })}

      {enableTeams && canManageTeams && teams && teams?.length > 0 && (
        <div>
          <button
            className='focus:outline-none no-outline flex items-center w-full px-4 py-1 mt-4 space-x-2 text-xs font-bold text-left text-gray-500'
            onClick={() => setHiddenTabs({ ...hiddenTabs, teams: !hiddenTabs?.teams })}
          >
            <AATeamMembers />
            <div>Teams</div>
            <ChevronRightSVG
              className={cn('inline-block ml-2 h-2.5 w-2.5 transform transition ease-in-out duration-200', {
                ['rotate-90']: !hiddenTabs?.teams
              })}
            />
          </button>
          <div className='pl-6'>
            {!hiddenTabs?.teams &&
              teams?.map((team) => (
                <div key={team.id}>
                  <button
                    name={team.name}
                    aria-label={team.name}
                    className='focus:outline-none no-outline flex items-center w-full px-4 py-1 space-x-2 text-xs font-bold text-left text-gray-500'
                    onClick={() => toggleShowTeam(team.id)}
                  >
                    <TeamIcon team={team} />
                    <div className='truncate'>{team.name}</div>
                    <ChevronRightSVG
                      className={cn('inline-block ml-2 h-2.5 w-2.5 transform transition ease-in-out duration-200', {
                        ['rotate-90']: !hiddenTeams?.includes(team.id)
                      })}
                    />
                  </button>
                  {!hiddenTeams?.includes(team.id) && (
                    <>
                      <TabLink
                        to={`/teams/${team.id}/profile`}
                        name='Profile'
                        isActive={pathname.includes(`/teams/${team.id}/profile`)}
                      />
                      {hasFeature('basic_branding') && (
                        <TabLink
                          to={`/teams/${team.id}/branding`}
                          name='Branding'
                          isActive={pathname.includes(`/teams/${team.id}/branding`)}
                        />
                      )}
                      <TabLink
                        to={`/teams/${team.id}/legal`}
                        name='Legal'
                        isActive={pathname.includes(`/teams/${team.id}/legal`)}
                      />
                    </>
                  )}
                </div>
              ))}
          </div>
        </div>
      )}

      <div className='self-stretch m-4 border-b border-gray-200' />
      {!!accounts?.length && (
        <Link
          to='accounts'
          role='tab'
          className={cn(
            { 'bg-indigo-50 text-indigo-600': pathname === '/accounts' },
            'flex space-x-3 items-center text-gray-700 w-full focus:outline-none focus:text-indigo-600 hover:text-indigo-600 group px-4 py-1.5 text-sm relative'
          )}
        >
          <span>Other workspaces</span>
        </Link>
      )}
    </PageNavbar>
  );
};
