import React, { useState } from 'react';
import { Navigate, Route, Routes, useMatch, useNavigate } from 'react-router';

import { Button, Tabs } from '@components/common';
import { PageHeader } from '@components/shared/PageHeader';
import { SortDropdown } from '@components/shared/SortDropdown';
import { useCollectionView } from '@components/stores/view';

import { AttrSlideout } from './AttrSlideout';
import { AttrsTable } from './AttrsTable';
import { RestrictedAction } from 'components/shared/RestrictedAction';

export type AttrScope = 'study' | 'candidate';
const TAB_LABELS: Record<AttrScope, string> = { candidate: 'Candidates attributes', study: 'Study attributes' };

export const AccountAttrsIndex: React.FC = () => {
  const match = useMatch('/custom_attrs/:tab');

  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [edit, setEdit] = useState<Attr_ | null>(null);
  const [create, setCreate] = useState<boolean>(false);

  const {
    view: { sort },
    setView
  } = useCollectionView();

  const onSortChange = ({ value }) => setView({ sort: { value, desc: false } });

  const SORT_OPTIONS = [
    { value: 'label', label: 'Name' },
    { value: 'is_pii', label: 'Is PII' },
    { value: 'attr_type', label: 'Attr type' }
  ];

  return (
    <div className='flex flex-col p-10 bg-white border border-gray-200 rounded-lg'>
      <PageHeader
        transparent
        shortPage
        h1='Custom attributes'
        renderSortBy={() => (
          <SortDropdown wrapperClass='w-full' options={SORT_OPTIONS} value={sort.value} onChange={onSortChange} />
        )}
        searchProps={{
          placeholder: 'Search…',
          onSearch: setSearch,
          value: search
        }}
        renderCta={() => (
          <RestrictedAction permission='manageAttrs'>
            {({ can }) => (
              <Button disabled={!can} className='whitespace-nowrap' onClick={() => setCreate(true)} primary medium>
                New {match?.params.tab} attribute
              </Button>
            )}
          </RestrictedAction>
        )}
      />
      <Tabs
        current={(match?.params.tab as AttrScope) || undefined}
        labels={TAB_LABELS}
        tabs={['candidate', 'study']}
        onSelect={(v) => navigate(`/custom_attrs/${v}`)}
      />
      <Routes>
        <Route path='*' element={<Navigate replace to='candidate' />} />
        <Route path='/candidate' element={<AttrsTable search={search} setEdit={setEdit} scope='candidate' />} />
        <Route path='/study' element={<AttrsTable search={search} setEdit={setEdit} scope='study' />} />
      </Routes>

      {edit && (
        <AttrSlideout scope={match?.params.tab as AttrScope} mode='edit' onClose={() => setEdit(null)} attr={edit} />
      )}
      {create && <AttrSlideout mode='create' scope={match?.params.tab as AttrScope} onClose={() => setCreate(false)} />}
    </div>
  );
};
