import cn from 'classnames';
import React, { HTMLAttributes, useEffect, useMemo, useState } from 'react';

import { composeEventHandlers } from '@helpers/composeEventHandlers';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';

import { Cell, Checkbox, ColumnHeader } from './components';
import { getPreviousCellSize, updateCellData } from './helpers';
import { Provider } from './hooks';
import { Context, GetKeyOf, OnUpdateCellParams, Props } from './types';

const TableComponent = <D extends Record<string, any> = Record<string, any>>({
  data: initialData,
  columns,
  stickyColumns = [],
  editableColumns,
  columnOrder: initialColumnOrder = [],
  visibleColumns: initialVisibleColumns,
  isCompact,
  onUpdateCell,
  onSort,
  onSelectRow,
  ...rest
}: Props<D> & HTMLAttributes<HTMLTableElement>) => {
  const [data, setData] = useState<D[]>(initialData);
  const [errors, setErrors] = useState<Context<D>['errors']>([]);
  const [visibleColumns, setVisibleColumns] = useState<Props<D>['visibleColumns']>(initialVisibleColumns);
  const [columnOrder, setColumnOrder] = useState<Props<D>['columnOrder']>(initialColumnOrder);
  const [key, setKey] = useState(0);

  const context: Context<D> = useMemo(
    () => ({
      errors,
      visibleColumns,
      stickyColumns,
      editableColumns,
      columnOrder,
      isCompact,
      setErrors,
      setVisibleColumns,
      setColumnOrder
    }),
    [errors, visibleColumns, stickyColumns, editableColumns, isCompact, setErrors, setVisibleColumns, setColumnOrder]
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columnResizeMode: 'onChange',
    meta: {
      updateCellData: composeEventHandlers(
        (params: OnUpdateCellParams<GetKeyOf<D, 'extra'>>) => onUpdateCell?.({ ...params, errors, setErrors }),
        (params: OnUpdateCellParams<GetKeyOf<D, 'extra'>>) => setData(updateCellData<D>(data, params))
      ),
      onSort
    },
    state: {
      columnVisibility: visibleColumns as Record<GetKeyOf<D, 'extra'>, boolean>,
      columnOrder
    },
    manualPagination: true
  });

  const { rows: selectedRows } = table.getSelectedRowModel();

  const isSticky = (columnId: string) => stickyColumns.includes(columnId as GetKeyOf<D, 'extra'>);

  useEffect(() => {
    setVisibleColumns(initialVisibleColumns);
  }, [initialVisibleColumns]);

  useEffect(() => {
    onSelectRow?.(selectedRows);
  }, [selectedRows]);

  useEffect(() => {
    setData(initialData);
    table.setSorting([]);
    setKey((prev) => prev + 1);
  }, [initialData]);

  return (
    <Provider<D> value={context}>
      <table {...rest} key={key}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{ width: header.getSize(), left: getPreviousCellSize<D>(headerGroup.headers, header) }}
                  className={cn({ 'sticky z-10': isSticky(header.column.id) }, 'p-0')}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  style={{ width: cell.column.getSize(), left: getPreviousCellSize<D>(row.getVisibleCells(), cell) }}
                  className={cn({ sticky: isSticky(cell.column.id) }, 'p-0')}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Provider>
  );
};

export const Table = TableComponent as typeof TableComponent & {
  Cell: typeof Cell;
  ColumnHeader: typeof ColumnHeader;
  Checkbox: typeof Checkbox;
};

Table.Cell = Cell;
Table.ColumnHeader = ColumnHeader;
Table.Checkbox = Checkbox;
