import { ColumnDef, Table } from 'components/shared/Table';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { format as timeagoFormat } from 'timeago.js';

import { AvatarFromId, Text } from '@components/common';
import { compact, humanize } from '@components/utils';

import { StudyStatus } from '../../StudyPublished/components/StudyStatus';
import { getStudyProgress } from '../hooks/getStudyProgress';
import { OptionsDropdown } from './OptionsDropdown';

export const Th: React.FC = (props) => <th className='px-6 py-3 text-left' {...props} />;
export const Td: React.FC<{ className?: string }> = ({ className = '', ...props }) => (
  <td className={`px-6 py-3 ${className}`} {...props} />
);

interface Props {
  onlyBackendFilters?: boolean;
  records: Study[];
  groupBy?: 'owner' | 'status' | 'type';
  borderColor?: string;
}

export const Header: React.FC<any> = ({ column }) => (
  <Text h='400' className='text-left' bold>
    {humanize(column.id)}
  </Text>
);

export const ListViewTable: React.FC<Props> = ({
  onlyBackendFilters,
  records: studies,
  groupBy,
  borderColor = 'gray-200'
}) => {
  const columns = React.useMemo<ColumnDef<any>[]>(() => {
    return compact([
      groupBy !== 'status' && {
        id: 'state',
        minSize: 50,
        size: 100,
        accessorKey: 'state',
        header: (props) => (
          <Table.ColumnHeader disableHide {...props} disableSort={onlyBackendFilters}>
            Status
          </Table.ColumnHeader>
        ),
        cell: (props) => <Table.Cell {...props} render={({ row }) => <StudyStatus status={row.original.state} />} />
      },
      {
        id: 'title',
        accessorKey: 'title',
        size: 200,
        minSize: 150,
        header: (props) => (
          <Table.ColumnHeader disableHide {...props} disableSort={onlyBackendFilters}>
            Title
          </Table.ColumnHeader>
        ),
        cell: (props) => (
          <Table.Cell
            {...props}
            render={({ row }) => {
              const url =
                row.original.state === 'draft' ? `/studies/${row.original.id}/edit` : `/studies/${row.original.id}`;

              return (
                <Link to={url} title={row.original.title || 'Untitled'}>
                  {row.original.title || 'Untitled'}
                </Link>
              );
            }}
          />
        )
      },
      groupBy !== 'type' && {
        id: 'label',
        minSize: 50,
        size: 100,
        accessorKey: 'label',
        header: (props) => (
          <Table.ColumnHeader disableHide {...props} disableSort={onlyBackendFilters}>
            Type
          </Table.ColumnHeader>
        ),
        cell: (props) => <Table.Cell {...props} render={({ row }) => row.original.label} />
      },
      {
        id: 'last updated',
        minSize: 50,
        size: 100,
        accessorKey: 'updated_at',
        header: (props) => (
          <Table.ColumnHeader sortIconType='date' disableHide {...props} disableSort={onlyBackendFilters}>
            Last updated
          </Table.ColumnHeader>
        ),
        cell: (props) => <Table.Cell {...props} render={({ row }) => timeagoFormat(row.original.updated_at as Date)} />
      },
      {
        id: 'created at',
        minSize: 50,
        size: 100,
        accessorKey: 'created_at',
        header: (props) => (
          <Table.ColumnHeader sortIconType='date' disableHide {...props} disableSort={onlyBackendFilters}>
            Created
          </Table.ColumnHeader>
        ),
        cell: (props) => <Table.Cell {...props} render={({ row }) => timeagoFormat(row.original.created_at as Date)} />
      },
      groupBy !== 'owner' && {
        id: 'owner',
        size: 60,
        minSize: 40,
        accessorFn: (originalRow) => `${originalRow.owner.name}`,
        header: (props) => (
          <Table.ColumnHeader disableHide {...props} disableSort={onlyBackendFilters}>
            Owner
          </Table.ColumnHeader>
        ),
        cell: (props) => (
          <Table.Cell {...props} render={({ row }) => <AvatarFromId noTippy userId={row.original.owner_id} />} />
        )
      },
      {
        id: 'completed',
        minSize: 50,
        size: 100,
        accessorFn: (originalRow) => `${originalRow.id}${originalRow.completed_participations_count}`,
        header: (props) => (
          <Table.ColumnHeader disableHide disableSort {...props} isStatic>
            Completed
          </Table.ColumnHeader>
        ),

        cell: (props) => (
          <Table.Cell
            {...props}
            render={({ row }) => {
              const progress = getStudyProgress(row.original as Study);
              if (progress.show) {
                return (
                  <div className='flex items-center'>
                    <div className='w-full mr-2'>{progress.renderProgressBar?.()}</div>
                    <Text h='200' color='gray-500' uppercase className='flex-1 text-right'>
                      {progress.text}
                    </Text>
                  </div>
                );
              }
              return <></>;
            }}
          />
        )
      },
      {
        id: 'options',
        accessorFn: (originalRow) => `${originalRow.id}${originalRow.id}`,
        size: 50,
        header: (props) => (
          <Table.ColumnHeader disableHide disableSort {...props} isStatic>
            Options
          </Table.ColumnHeader>
        ),
        cell: (props) => (
          <Table.Cell
            {...props}
            render={({ row }) => (
              <div className='flex justify-end w-full p-1'>
                <OptionsDropdown study={row.original} />
              </div>
            )}
          />
        )
      }
    ]);
  }, [studies]);

  return (
    <div className={`relative max-w-full overflow-auto border-l border-r border-${borderColor}`}>
      <Table
        data={studies}
        columns={columns}
        className={`w-full bg-white border-t border-${borderColor} table-fixed`}
      />
    </div>
  );
};
