import api from './client';

const isSuccess = ({ status }) => status.toString().match(/^2\d*/);

export const updateParticipation = async (
  token: string,
  params: { screener_id: number; transition: string; consent_info?: any }
) => {
  const { screener_id, transition, consent_info } = params;
  const url = `/public/participations/${token}`;
  const resp = await api.put(url, { screener_id, transition, consent_info });
  if (isSuccess(resp)) {
    const json = await resp.json();
    return json.data.attributes;
  } else {
    return false;
  }
};

export const getPublicConsentForm = async (token) => {
  const url = `/public/participations/${token}/consent_forms`;
  const resp = await api.get(url);
  if (isSuccess(resp)) {
    const json = await resp.json();
    return json.data ? json.data.attributes : {};
  } else {
    return false;
  }
};

export async function createSignedConsent(token, consent_form) {
  const URL = `/public/participations/${token}/consent_forms`;
  // /screeners/${id}/responses`
  const resp = await api.post(URL, { consent_form });
  if (isSuccess(resp)) {
    // const json = await resp.json();
    return true;
  } else {
    return false;
  }
}

export async function createScreenerResponse(
  screener_id: number,
  participation_id: number | undefined,
  attrs: any
): Promise<any> {
  const URL = `/public/screener_responses`;
  // /screeners/${id}/responses`
  const resp = await api.post(URL, { screener_id, participation_id, screener_response: attrs });
  if (isSuccess(resp)) {
    const json = await resp.json();
    return json.data.attributes;
  } else {
    const json = await resp.json();
    return Promise.reject(json);
  }
}

export async function updateCandidateConsent(token, params) {
  const URL = `/public/candidate_consent/${token}`;
  const resp = await api.put(URL, { contact_preferences: params });
  if (isSuccess(resp)) {
    return true;
  }
}

export async function getCalendarAvailabilities(slug, workspace, start_date, end_date, timezone) {
  const URL = `/public/calendar/availability?slug=${slug}&workspace=${workspace}&start_date=${start_date}&end_date=${end_date}&timezone=${timezone}`;
  const resp = await api.get(URL);
  if (isSuccess(resp)) {
    const json = await resp.json();
    return json;
  } else {
    return Promise.reject({});
  }
}
