import pluralize from 'pluralize';
import React, { useEffect, useMemo, useState } from 'react';

import { api } from '@api/reduxApi';
import { Alert, AlertLink, Button, LayoutToggle, Tabs, Text, Toggle } from '@components/common';
import { Grid } from '@components/common/Grid';
import { DashboardLayout, DashboardLayoutBody } from '@components/layouts/DashboardLayout';
import { PageHeader } from '@components/shared/PageHeader';
import { RestrictedButton } from '@components/shared/RestrictedButton';
import { SortDropdown } from '@components/shared/SortDropdown';
import { AnyAllToggle, SegmentActions, TableFilters, useTableFilters } from '@components/shared/TableFilters';
import { ZDSPage } from '@components/shared/ZDS/components/ZDSPage';
import { useCollectionView } from '@components/stores/view';
import { track } from '@components/tracking';
import { useAccount } from '@hooks/useAccount';
import { usePlan } from '@hooks/usePlan';
import { useUser } from '@hooks/useUser';

import { NewStudyAction } from '../StudyNew';
import { GroupedTable } from './components/GroupedTable';
import { ListViewTable } from './components/ListViewTable';
import { NoResults } from './components/NoResults';
import { RowViewTable } from './components/RowViewTable';
import { StudyCard } from './components/StudyCard';
import { buildStudiesAttributes } from './filters/buildStudiesAttributes';
import { sortStudies } from './utils';

const TAB_LABELS = {
  my: 'My studies',
  all: 'All studies'
};

interface Props {
  studies: Study[];
  team?: Team | null;
}

export const OldStudiesListPage: React.FC<Props> = ({ studies, team }) => {
  const user = useUser();

  const {
    account: { status, team: accountMembers }
  } = useAccount();

  const { limits, hasQuota } = usePlan();

  const viewHook = useCollectionView();
  const {
    view: { sort, layout, groupBy, tab, toggle },
    setView
  } = viewHook;

  function setLayout(layout) {
    setView({ layout });
  }

  function setSort(sort) {
    setView({ sort });
  }

  function setGroupBy(groupBy) {
    setView({ groupBy });
  }

  function setToggle(toggle) {
    setView({ toggle });
  }

  const [filteredBySearch, setFilteredBySearch] = useState(studies);
  const [filteredStudies, setFilteredStudies] = useState<Study[]>(filteredBySearch);

  const [value, setValue] = useState<string>('');

  const { data: studyAttrs, isSuccess, isError } = api.useGetStudyAttrsQuery();

  const attrs = useMemo(
    () => buildStudiesAttributes({ team: accountMembers, studyAttrs: studyAttrs || [] }),
    [studyAttrs, accountMembers]
  );

  const filtersHook = useTableFilters<Study>({
    definitions: attrs.filters,
    trackKey: 'studies',
    syncWithURL: isSuccess || isError
  });

  const filterStudies = (studies: Study[]) => {
    const sortAttr = attrs.find(({ id }) => id === sort.value);
    const filteredStudies = filtersHook.rawFilter(sortStudies(studies, sortAttr) || []);
    setFilteredStudies(filteredStudies);
  };

  const onSortChange = ({ value }) => {
    setSort({ value, desc: false });
  };

  const onSearchChange = (value: string) => {
    setValue(value);
  };

  const searchStudies = (value: string) => {
    const initialStudies = toggle ? studies : studies.filter(({ state }) => state !== 'archived');

    if (!value) {
      setFilteredBySearch(initialStudies);
      return;
    }
    const filteredStudies = initialStudies.filter(({ title }) => {
      const lowerCaseTitle = title.toLowerCase();
      const lowerCaseValue = value.toLowerCase();

      return lowerCaseTitle.includes(lowerCaseValue);
    });

    setFilteredBySearch(filteredStudies);
  };

  const onGroupByChange = ({ value }: { value: string }): void => {
    setGroupBy(value);
  };

  const filteredStudiesDeps = filteredStudies.map(({ id }) => id).join(',');

  useEffect(() => {
    if (tab === 'my') {
      const userStudies = filteredBySearch.filter(({ owner_id }) => owner_id === user.id);
      filterStudies(userStudies);
    } else {
      filterStudies(filteredBySearch);
    }
  }, [tab, filteredBySearch, filtersHook.filters, filtersHook.op, sort]);

  useEffect(() => {
    searchStudies(value);
  }, [studies, toggle]);

  const renderStudies = useMemo(() => {
    if (filteredStudies.length === 0) {
      return <NoResults />;
    }

    if (layout === 'grid') {
      return (
        <GroupedTable<Study>
          Table={({ records: studies }) => (
            <Grid gap={6} monitor={4} desktop={3} tablet={2} mobile={1}>
              {studies?.map((s) => s && <StudyCard key={s.id} study={s} />)}
            </Grid>
          )}
          groupBy={groupBy || ''}
          groupByOptions={attrs.groups}
          records={filteredStudies}
        />
      );
    }

    if (layout === 'row') {
      return (
        <GroupedTable<Study>
          Table={RowViewTable}
          groupBy={groupBy || ''}
          groupByOptions={attrs.groups}
          records={filteredStudies}
        />
      );
    }

    return (
      <GroupedTable<Study>
        Table={ListViewTable}
        groupBy={groupBy || ''}
        groupByOptions={attrs.groups}
        records={filteredStudies}
      />
    );
  }, [
    filteredStudiesDeps,
    JSON.stringify(filteredStudies),
    attrs.groups,
    groupBy,
    layout,
    ListViewTable,
    RowViewTable
  ]);

  const renderFilters = () => (
    <div className='px-page flex items-center border-t border-gray-200'>
      <div className='flex-1'>
        <TableFilters<Study> hook={filtersHook} defaultShowInput={!filtersHook.filters.length} />
      </div>
      <div className='flex py-3 pl-3 space-x-3 border-l border-gray-200'>
        <AnyAllToggle value={filtersHook.op} onChange={filtersHook.setOp} />
        <SegmentActions onClear={filtersHook.clearFilters} anyFilters={filtersHook.filters.length > 0} />
      </div>
    </div>
  );

  const renderTabs = () => (
    <Tabs
      current={tab || 'all'}
      labels={TAB_LABELS}
      onSelect={(tab) => {
        track('changed_study_owner_tab', { tab });
        setView({ tab });
      }}
      className='px-page flex items-center border-t border-gray-200'
      tabs={['my', 'all']}
    />
  );

  if (!studies.length) {
    return <ZDSPage types={['studies']} teamId={team?.id} />;
  }

  return (
    <DashboardLayout>
      <PageHeader
        h1='Studies'
        renderSortBy={() => (
          <SortDropdown wrapperClass='w-full' options={attrs.sortOptions} value={sort.value} onChange={onSortChange} />
        )}
        renderLayout={() => <LayoutToggle withRow value={layout} onChange={setLayout} />}
        renderGroupBy={() => (
          <SortDropdown type='group' options={attrs.groupOptions} value={groupBy} onChange={onGroupByChange} />
        )}
        searchProps={{
          placeholder: 'Search…',
          addDebounce: true,
          onSearch: onSearchChange,
          onEnd: searchStudies,
          value
        }}
        renderTabs={renderTabs}
        renderFilters={renderFilters}
        filtersApplied={!!filtersHook.filters.length}
        renderCta={() => (
          <NewStudyAction>
            {({ openSlideout }) => (
              <RestrictedButton
                limit='studies'
                permission='createStudy'
                action='New study'
                className='xx-new-study-cta'
              >
                <Button onClick={() => openSlideout({ teamId: team?.id })} primary medium>
                  New study
                </Button>
              </RestrictedButton>
            )}
          </NewStudyAction>
        )}
        renderToggle={() => (
          <div className='flex items-center justify-between'>
            <div className='text-sm'>Show archived studies</div>
            <Toggle onToggle={setToggle} on={toggle} />
          </div>
        )}
        team={team}
      />

      <DashboardLayoutBody>
        {!hasQuota('studies') && ['active', 'trial'].includes(status) && (
          <Alert className='mb-4' type='warning'>
            <div className='h400'>
              You’ve reached your active study limit of <span className='font-bold'>{limits.studies}</span>. Please
              upgrade your plan for unlimited access. <AlertLink href='/plans'>View membership plans</AlertLink>
            </div>
          </Alert>
        )}
        <div className='tablet:flex-row tablet:items-center flex flex-col-reverse items-start mb-4'>
          <div className='tablet:mt-0 flex-grow mt-4'>
            <Text color='gray-500' h='400'>
              {pluralize('studies', filteredStudies.length, true)}
            </Text>
          </div>
        </div>
        {!!studies.length && renderStudies}
      </DashboardLayoutBody>
    </DashboardLayout>
  );
};
