import cn from 'classnames';
import { format as formatTz, getTimezoneOffset } from 'date-fns-tz';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import tinytime from 'tinytime';

import { Loading, Pill, Tabs } from '@components/common';
import { ConnectedAccountSync } from '@components/shared/ConnectedAccountSync';
import { addDaysToDate, startOfWeek } from '@components/utils';
import { useUser } from '@hooks/useUser';
import { MAX_WEEKS_TO_RENDER, SCHEDULING_INCREMENT, TODAY } from '../constants';
import {
  buildInitialCalAvailableSlots,
  fixSlots,
  getActiveWeekIndex,
  getDateFromString,
  getHourMin
} from './NylasCalendar/utils';
import { useStudyUsers } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/hooks/useStudyUsers';
import { NylasLoadedBlocker } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/NylasLoadedBlocker';
import { StepHelper, StepTitle } from '../../shared';
import { ViewSettings } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/ViewSettings';
import { BookingPage } from '@components/Public/Scheduler/pages/BookingPage';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { useGetStudyCalendarQuery } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/api';
import { getInitialTimeSlot } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/AdditionalOptionsPanel/utils';
import { AttendeesCalendars } from './AttendeesCalendars';
import { CalendarPanels } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/CalendarPanels';
import { timezoneFromName } from '@components/shared/TimezonePicker/utils';
import { api } from '@api/reduxApi';
import { useTrackDuration } from '@hooks/useTrackDuration';
import { track } from '@components/tracking';
import { useDebouncedCallback } from 'use-debounce';
import { GQCalendarProvider } from '../hooks/useCalendarContext';
import { EVENT_COLORS } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/NylasEvent';

const dateTemplate = tinytime('{MMMM} {DD}');

type WeekObject = { label: string; startOfWeek: Date };

export type CalendarSettings = {
  moderatorsDisabled?: boolean;
  observersDisabled?: boolean;
  hiddenIds?: number[];
  hiddenEvents?: ('busy' | 'free')[];
};

export type Props = Pick<PageProps, 'study' | 'onSave'> & {
  isLoading?: boolean;
  onBack: () => void;
  studyShowTitle?: boolean;
  readOnly?: boolean;
};

export type Data = {
  icon: React.ReactElement;
  description: string;
};

export const GQCalendar: React.FC<Props> = ({ study, onSave, onBack, readOnly, studyShowTitle, isLoading }) => {
  const user = useUser();

  const { data: updatedStudy } = api.useGetSimpleStudyQuery(study.id);

  const initialSlots = study.cal_available_slots || buildInitialCalAvailableSlots(study);

  const [calAvailableSlots, setCalAvailableSlots] = useState<CalenderAvailableSlots>(initialSlots);

  const { data: calendar } = useGetStudyCalendarQuery({ studyId: study.id }, { refetchOnMountOrArgChange: true });

  const [timeSlot, setTimeSlot] = useState<RecurringTimeSlot | OneOffTimeSlot>(
    getInitialTimeSlot(study.time_slot_settings, user.time_zone) as OneOffTimeSlot
  );

  const [calSettings, setCalSettings] = useLocalStorage<CalendarSettings>(`study-${study.id}-cal-visibility-settings`);

  const [currentTab, setCurrentTab] = useState<'preview' | 'calendars'>('calendars');

  const calendarPageOpened = useTrackDuration({ action: 'calendar_page_opened' });

  const changeTimeSlot = useCallback(
    (attr: string, val: string | number | number[]) => {
      const newVal = { ...timeSlot, [attr]: val };
      setTimeSlot(newVal);
      onSave({ id: study.id, time_slot_settings: newVal });
    },
    [timeSlot, study]
  );

  useEffect(() => {
    calendarPageOpened.start();

    return () => {
      calendarPageOpened.stop({ studyId: study.id });
    };
  }, []);

  useEffect(() => {
    if (!calSettings) {
      setCalSettings({ observersDisabled: true, hiddenEvents: ['free'] });
    } else if (!calSettings.hiddenEvents) {
      setCalSettings({ ...calSettings, hiddenEvents: ['free'] });
    }
  }, []);

  const startDate = useMemo(() => getDateFromString(calAvailableSlots.start_date), [calAvailableSlots.start_date]);

  const endDate = useMemo(() => {
    if (calAvailableSlots.end_date) {
      return getDateFromString(calAvailableSlots.end_date);
    } else if (!study.continuous) {
      return addDaysToDate(startDate, timeSlot.future_limit * 7);
    }

    return null;
  }, [calAvailableSlots.end_date, startDate, timeSlot.future_limit, study.continuous]);

  const weeks = useMemo<WeekObject[]>(() => {
    const weekStr = (monday: Date) =>
      `Week of ${dateTemplate.render(monday)}—${dateTemplate.render(addDaysToDate(monday, 6))}`;

    let monday = startOfWeek(startDate);
    const weeks: WeekObject[] = [];

    for (let i = 0; i < MAX_WEEKS_TO_RENDER; i++) {
      if (!endDate || (endDate && monday <= endDate)) {
        weeks.push({ label: weekStr(monday), startOfWeek: monday });
      }
      monday = addDaysToDate(monday, 7);
    }

    return weeks;
  }, [startDate, endDate]);

  const [weekIdx, setWeekIdx] = useState(getActiveWeekIndex(weeks));

  const disablePrevWeekButton = weekIdx <= 0;
  const disableNextWeekButton = weekIdx >= weeks.length - 1;

  const { timezone } = timeSlot;

  const studyUsersHook = useStudyUsers({
    studyId: study.id,
    ownerId: study.owner_id as any,
    isRoundRobin: study.moderation_style === 'round_robin'
  });

  const currentModerators: { teamUser: TeamUser; studyUser: StudyUser }[] = useMemo(() => {
    let moderators: { teamUser: TeamUser; studyUser: StudyUser }[] = [];

    if ((study.moderation_style === 'collective' || study.moderation_style === 'round_robin') && study.owner) {
      const currentModerators = studyUsersHook.allStudyUsers?.filter(
        (u) => u.studyUser.role === 'moderator' && (!u.teamUser.deactivated || !study.user_ids.includes(u.teamUser.id))
      );

      if (currentModerators) {
        moderators = currentModerators;
      }
    } else {
      const currentModerator = studyUsersHook.allStudyUsers?.find((m) => m.studyUser.role === 'moderator');

      if (currentModerator) {
        moderators = [currentModerator];
      }
    }

    return moderators;
  }, [study.moderation_style, study.owner_id, study.user_ids, studyUsersHook.allStudyUsers]);

  const moderators = useMemo(() => currentModerators?.map((m) => m.teamUser), [currentModerators]);

  const currentObservers = useMemo(() => {
    const currentObservers = studyUsersHook.allStudyUsers?.filter(
      (u) => u.studyUser.role === 'observer' && !u.teamUser.deactivated
    );

    return currentObservers || [];
  }, [studyUsersHook.allStudyUsers]);

  const observers = useMemo(() => currentObservers.map((o) => o.teamUser), [currentObservers]);

  // combine all user ids with hidden calendar events into one array
  const userIdsWithHiddenEvents: number[] = useMemo(
    () => [
      ...(calSettings?.hiddenIds || []),
      ...((calSettings?.moderatorsDisabled && moderators?.map((m) => m.id)) || []),
      ...((calSettings?.observersDisabled && observers?.map((m) => m.id)) || [])
    ],
    [calSettings, moderators, observers]
  );

  const [viewSettings, setViewSettings] = useState<CalenderViewSettings>({
    start_date: +startOfWeek(TODAY),
    start_time: '07:00',
    end_time: '19:00',
    week_days_only: false,
    ...(study.cal_view_settings as any)
  });

  const changeViewSettings = (v: Partial<CalenderViewSettings>) => {
    setViewSettings({ ...viewSettings, ...v });
    onSave({ id: study.id, cal_view_settings: { ...viewSettings, ...v } });
  };

  const wrapperClass = cn({ 'py-gutter': studyShowTitle, '-mt-2': !studyShowTitle });

  const { callback: debouncedSave } = useDebouncedCallback(onSave, process.env.NODE_ENV === 'test' ? 5 : 300);

  const onChange = (events: SlotInstance[], debounce: boolean = false) => {
    const slots = { ...calAvailableSlots, slots: fixSlots(events) };

    setCalAvailableSlots(slots);
    debounce
      ? debouncedSave({ id: study.id, cal_available_slots: slots })
      : onSave({
          id: study.id,
          cal_available_slots: slots
        });
  };

  const timeZone = useMemo(
    () => ({
      name: timezone,
      abbr: formatTz(startDate, 'zzz', { timeZone: timezone }),
      offset: new Date().getTimezoneOffset() * -60 * 1000 - getTimezoneOffset(timezone, startDate)
    }),
    [timezone, startDate]
  );

  useEffect(() => {
    if (!study.nylas_calendar_id && study.owner?.default_nylas_calendar_id) {
      onSave({
        id: study.id,
        nylas_calendar_id: study.owner.default_nylas_calendar_id,
        booking_url: null,
        calendly_event_type_uuid: null
      });
    }
  }, []);

  useEffect(() => {
    studyUsersHook.fetchStudyUsers();
  }, [updatedStudy?.owner_id]);

  useEffect(() => {
    if (study.state === 'active') {
      track('calendar_v2_viewed_post_publish', { studyId: study.id, userId: user.id });
    }
  }, []);

  const studyUserColors: Record<number, UserColor | undefined> = useMemo(() => {
    const colors: Record<number, UserColor | undefined> = {};

    [...(moderators || []), ...(observers || [])].forEach((teamUser, i) => {
      const userColor: UserColor = teamUser.is_phantom
        ? (['gray', 400, 100, false] as UserColor)
        : EVENT_COLORS[i % EVENT_COLORS.length];

      colors[teamUser.id] = userColor;
    });

    return colors;
  }, [studyUsersHook.allStudyUsers]);

  return (
    <GQCalendarProvider value={{ studyUserColors, calSettings, setCalSettings }}>
      <div className={wrapperClass}>
        {study.owner && <NylasLoadedBlocker user={study.owner} />}

        {!studyShowTitle && (
          <div className='text-center mb-4'>
            <div className='inline-flex space-x-2 items-center'>
              <StepTitle>Set up your calendar</StepTitle>
              <Pill color='blue' className='mb-2'>
                Beta
              </Pill>
            </div>
            <StepHelper mb='2'>Set your availability for when participants can schedule time with you.</StepHelper>
          </div>
        )}

        <ConnectedAccountSync provider='nylas' />

        <div className='px-page flex items-end justify-between'>
          {studyShowTitle && (
            <div className='mb-4 flex space-x-2 items-center'>
              <h3>Calendar</h3>
              <Pill color='blue'>Beta</Pill>
            </div>
          )}
        </div>
        <div className='px-page desktop:flex justify-center'>
          <CalendarPanels
            startDate={startDate}
            endDate={endDate}
            onBack={onBack}
            study={study}
            onSave={onSave}
            setCalAvailableSlots={setCalAvailableSlots}
            readOnly={readOnly}
            studyUsersHook={studyUsersHook}
            setWeekIdx={setWeekIdx}
            weekIdx={weekIdx}
            calAvailableSlots={calAvailableSlots}
            disablePrevWeekButton={disablePrevWeekButton}
            disableNextWeekButton={disableNextWeekButton}
            viewSettings={viewSettings}
            calendar={calendar}
            timeSlot={timeSlot}
            changeTimeSlot={changeTimeSlot}
            moderators={moderators}
            observers={observers}
          />
          <div className='desktop:my-0 flex-1 overflow-auto'>
            <div className='w-full min-w-160 relative h-full bg-white border desktop:border-l-0 rounded border-gray-200 desktop:rounded-tl-none desktop:rounded-bl-none'>
              {isLoading && <Loading absolute />}
              <div className='flex justify-between items-center pt-1.5 px-6 border-b border-gray-200'>
                <Tabs
                  labels={{ calendars: 'Attendees calendars', preview: 'Participant preview' }}
                  current={currentTab}
                  tabs={['calendars', 'preview']}
                  onSelect={(tab) => {
                    setCurrentTab(tab);
                    track('calendar_v2_tab_switched', { tab });
                  }}
                />
                {currentTab === 'calendars' && (
                  <div className='ml-2 tablet:space-y-0 space-x-3 items-center justify-end flex flex-1'>
                    <div className='flex items-center'>
                      <div className='w-4 h-4 mr-2 rounded-sm border border-indigo-600 border-dashed bg-indigo-50' />
                      <span className='h400'>Available</span>
                    </div>
                    <div className='flex items-center'>
                      <div className='w-4 h-4 mr-2 rounded-sm calendar-event-conflict-styling-v2' />
                      <span className='h400'>Conflict</span>
                    </div>
                    <ViewSettings
                      viewSettings={viewSettings}
                      changeViewSettings={changeViewSettings}
                      moderators={moderators}
                      observers={observers}
                      disabled={readOnly}
                      calSettings={calSettings}
                      setCalSettings={setCalSettings}
                      hasObservers={!!observers?.length}
                    />
                  </div>
                )}
              </div>

              <AttendeesCalendars
                currentUsers={[...currentObservers, ...currentModerators]}
                hidden={currentTab !== 'calendars'}
                userIdsWithHiddenEvents={userIdsWithHiddenEvents}
                showConflicts={study.nylas_calendar_type === 'user'}
                onChange={onChange}
                initialDuration={study.duration_in_minutes || 30}
                frozen={readOnly}
                endDate={endDate}
                startHour={getHourMin(viewSettings.start_time || '7:00').hour}
                endHour={getHourMin(viewSettings.end_time || '19:00').hour}
                changeTimeSlot={changeTimeSlot}
                timeSlot={timeSlot}
                startDate={startDate}
                weekIdx={weekIdx}
                weekDaysOnly={viewSettings.week_days_only}
                events={calAvailableSlots.slots || null}
                timezone={timeZone}
                schedulingIncrement={SCHEDULING_INCREMENT}
                setWeekIdx={setWeekIdx}
                disableNextWeekButton={disableNextWeekButton}
                disablePrevWeekButton={disablePrevWeekButton}
              />
              <div aria-hidden={currentTab !== 'preview'} className={cn('p-6', currentTab !== 'preview' && 'hidden')}>
                <BookingPage
                  isHidden={currentTab !== 'preview'}
                  study={study}
                  deps={[
                    updatedStudy?.cal_available_slots,
                    updatedStudy?.moderation_style,
                    updatedStudy?.duration_in_minutes,
                    updatedStudy?.time_slot_settings,
                    updatedStudy?.minimum_booking_notice_in_minutes,
                    calendar?.maximum_scheduling_notice,
                    calendar?.booking_limit_daily,
                    calendar?.booking_limit_weekly
                  ]}
                  researcherView
                  researcherTimezone={timezoneFromName(timezone)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </GQCalendarProvider>
  );
};
