import cn from 'classnames';
import { useCombobox } from 'downshift';
import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';

type Props = {
  minHour: number;
  maxHour: number;
  increment: number;
  initialValue?: TimeOption;
  onChange: (value: TimeOption) => void;
  disabled?: boolean;
  label?: string;
  inputWidth?: string;
  addOneMoreOption?: boolean;
};

export type TimeOption = {
  hour: number;
  min: number;
};
export const itemToString = (option?: TimeOption | null): string =>
  option
    ? `${(option.hour === 12 ? option.hour : option.hour % 12).toString()}:${option.min.toString().padStart(2, '0')}${
        option.hour >= 12 ? 'pm' : 'am'
      }`
    : '';

export const TimeSelect: React.FC<Props> = ({
  minHour,
  maxHour,
  increment,
  initialValue,
  onChange,
  disabled,
  label,
  inputWidth = '24',
  addOneMoreOption
}) => {

  const inputRef = useRef<HTMLInputElement | null>(null);

  const allTimeOptions: TimeOption[] = useMemo(
    () =>
      Array.from({ length: (maxHour - minHour) * (60 / increment) + (addOneMoreOption ? 1 : 0) }, (_, i) => {
        const hour = Math.floor(i / (60 / increment)) + minHour;
        const min = (i % (60 / increment)) * increment;
        return { hour, min };
      }),
    [minHour, maxHour, increment]
  );

  const [items, setItems] = useState<TimeOption[]>([]);

  useEffect(() => {
    setItems(allTimeOptions);
  }, [allTimeOptions]);

  const combobox = useCombobox<TimeOption>({
    items,
    selectedItem: allTimeOptions.find((o) => itemToString(o) === itemToString(initialValue)),
    onInputValueChange: ({ inputValue = '' }) => {
      setItems(allTimeOptions.filter((i) => itemToString(i).includes(inputValue)));
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem && !disabled) {
        inputRef.current?.blur();
        onChange(selectedItem);
      }
    },
    itemToString
  });

  const onUpdate = () => {
    const valueToSelect = items.find((item) => itemToString(item) === combobox.inputValue);

    if (valueToSelect) {
      onChange(valueToSelect);
    }
  };

  const onBlur = () => {
    const selected = allTimeOptions.find((o) => itemToString(o) === combobox.inputValue);
    if (selected) {
      onChange(selected);
    } else {
      combobox.setInputValue(itemToString(combobox.selectedItem));
    }
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (combobox.highlightedIndex > -1) {
        onChange(items[combobox.highlightedIndex]);
      } else {
        onUpdate();

        combobox.closeMenu();
        inputRef.current?.blur();
      }
    }
  };

  const onFocus = () => {
    if (!combobox.isOpen) {
      inputRef.current?.select();
      setItems(allTimeOptions);
      combobox.openMenu();
    }
  };

  return (
    <div {...combobox.getComboboxProps()}>
      <input
        {...combobox.getInputProps({
          disabled,
          ref: inputRef,
          onFocus,
          onBlur,
          onKeyDown
        })}
        placeholder='HH:MM'
        className={`h-8 border-gray-200 focus:ring-1 focus:outline-none border rounded-md w-${inputWidth} placeholder-gray-400 py-1.5 px-2 tablet:px-4 tablet:text-sm focus:ring-indigo-500 focus:border-indigo-500`}
      />
      {label && <label {...combobox.getLabelProps({ hidden: true })}>{label}</label>}
      <div className='relative'>
        <ul
          {...combobox.getMenuProps({
            className: cn({
              'w-full rounded-b-md max-h-60 absolute z-40 overflow-y-auto bg-white mt-2 py-2 border border-gray-200 shadow-lg':
                combobox.isOpen,
              invisible: !items.length
            })
          })}
        >
          {combobox.isOpen &&
            !disabled &&
            items.map((item, index) => (
              <li
                key={`${item.hour}-${item.min}`}
                {...combobox.getItemProps({
                  item,
                  index,
                  onMouseDown: () => {
                    onChange(item);
                    combobox.closeMenu();
                  },
                  className: cn('px-1.5 py-1', {
                    'cursor-pointer bg-indigo-600 text-white transition duration-150 ease-in-out':
                      index === combobox.highlightedIndex
                  })
                })}
              >
                {itemToString(item)}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
