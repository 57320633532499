import React, { HTMLAttributes, forwardRef, useEffect, useMemo, useState } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { useForm } from 'react-hook-form';

import { DraggableCardProvider } from '@components/common/DraggableCard';
import { useParticipantRecording } from '@components/ScreenSharingProvider/hooks/useParticipantRecording';
import { Models, Enums } from '@components/SurveyBuilder';
import { merge } from '@helpers/merge';
import { useLocalStorage } from '@hooks/useLocalStorage';

import { CardSortTask } from './components/CardSortTask/CardSortTask';
import { Question } from './components/Question';
import { Permissions } from './components/Permissions';
import { WrongDevice } from './components/WrongDevice';
import { PrototypeTestTask } from './components/PrototypeTestTask';
import { WebsiteTestTask } from './components/WebsiteTestTask';
import { useCreateResponse } from './hooks/useCreateResponse';
import { useIsCorrectDevice } from './hooks/useIsCorrectDevice';
import { useTaskTimings } from './hooks/useTaskTimings';
import { UnmoderatedContext } from './hooks/useUnmoderatedContext';

import { UnmoderatedStorageKeys, parseStorageKey } from './utils';

import * as Types from './types';

export interface Props extends HTMLAttributes<HTMLFormElement> {
  blocks: Models.Block[];
  deviceType?: Study['device_type'];
  participation?: Participation;
  surveyId: number;
  onSubmitAwnsers?: (answers: Types.Answer[]) => void;
}

// this ID references the "full screen" element that will be used to display cartain tasks
const FULL_SCREEN_ELEMENT_ID = 'fullScreenTask';

export const Unmoderated = forwardRef<HTMLFormElement, Props>(
  ({ blocks, deviceType = 'desktop', participation, surveyId, onSubmitAwnsers, ...rest }, ref) => {
    const [activeId, setActiveId] = useState<Models.Block['id']>();
    const [answers, setAnswers] = useState<Types.Answer[]>([]);

    const { control, formState, handleSubmit, reset } = useForm<Types.FormData>({
      defaultValues: { value: '' },
      mode: 'onChange'
    });

    const activeBlock = useMemo(() => blocks?.find(({ id }) => id === activeId), [blocks, activeId]);

    const [storedBlock, setStoredBlock] = useLocalStorage<Models.Block>(
      parseStorageKey({ key: UnmoderatedStorageKeys.ACTIVE_BLOCK, participation })
    );
    const [storedAnswers, setStoredAnswers] = useLocalStorage<Types.Answer[]>(
      parseStorageKey({ key: UnmoderatedStorageKeys.ANSWERS, participation })
    );

    const {
      enableRecording,
      endRecording,
      isRecording,
      isEnabled: isRecordingEnabled,
      isCanceled: isRecordingCanceled
    } = useParticipantRecording();

    const [createResponse, { isLoading: isLoadingCreateResponse }] = useCreateResponse();
    const { hasError: hasMismatchedDeviceError } = useIsCorrectDevice(deviceType);
    const { startAt, start, stop } = useTaskTimings();

    const onSubmit = (formData: Types.FormData) => {
      if (!blocks || blocks.length === 0 || !activeBlock) {
        return;
      }

      if (activeBlock.kind === Enums.Kind.thankYou) {
        location.reload();
        return;
      }

      const position = activeBlock.position + 1;
      const nextBlock = blocks.find((block) => block.position === position);
      const { duration, endAt } = stop();

      if (nextBlock) {
        let newAnswers = [...answers];

        if (participation && storedAnswers) {
          // get anwsers from storage if needed
          newAnswers = merge<Types.Answer>(newAnswers, storedAnswers, (a, b) => a.block.id === b.block.id);
        }

        const timings: Partial<Types.Timings> = {
          start_at: startAt,
          end_at: endAt,
          duration: duration ?? 0
        };

        newAnswers.push({
          block: activeBlock,
          value: formData.value,
          prototype_test_task: formData.prototype_test_task,
          card_sort_task: formData.card_sort_task,
          timings
        });

        setAnswers(newAnswers);
        setActiveId(nextBlock.id);

        if (participation) {
          // not having a participation means we are in preview mode
          // and we don't want to store the block and answers
          setStoredBlock(nextBlock);
          setStoredAnswers(newAnswers);
        }

        if (nextBlock.kind === Enums.Kind.thankYou) {
          onSubmitAwnsers?.(newAnswers);

          if (isRecording) {
            endRecording(false);
          }

          if (participation) {
            createResponse(newAnswers, participation);
          }

          setStoredBlock(null);
          setStoredAnswers(null);
        }

        start();
      }

      reset({ value: '' });
    };

    const renderer = () => {
      if (!activeBlock) return <></>;

      switch (activeBlock.kind) {
        case Enums.Kind.prototypeTest:
          return (
            <PrototypeTestTask
              key={activeBlock.id}
              block={activeBlock}
              control={control}
              iframePortalSelector={`#${FULL_SCREEN_ELEMENT_ID}`}
            />
          );

        case Enums.Kind.permissions:
          return <Permissions block={activeBlock} isPreview={!participation} onSubmit={handleSubmit(onSubmit)} />;

        case Enums.Kind.websiteTest:
          return (
            <WebsiteTestTask
              key={activeBlock.id}
              block={activeBlock}
              iframePortalSelector={`#${FULL_SCREEN_ELEMENT_ID}`}
            />
          );

        case Enums.Kind.cardSort:
          return (
            <CardSortTask
              key={activeBlock.id}
              block={activeBlock}
              control={control}
              fullScreenPortalSelector={`#${FULL_SCREEN_ELEMENT_ID}`}
            />
          );

        case Enums.Kind.thankYou:
          return <Question block={activeBlock} control={control} isValid={!isRecording && !isLoadingCreateResponse} />;

        default:
          return <Question block={activeBlock} control={control} isValid={formState.isValid} />;
      }
    };

    useEffect(() => {
      if (activeBlock && activeBlock.kind === Enums.Kind.welcome) {
        start();
      }
    }, [activeBlock]);

    useEffect(() => {
      if (blocks && blocks.length > 0 && !activeId) {
        if (storedBlock) {
          setActiveId(storedBlock.id);
        } else {
          setActiveId(blocks[0].id);
        }
      }
    }, [blocks, activeId, storedBlock]);

    useEffect(() => {
      if (!isRecordingEnabled && activeBlock?.kind === Enums.Kind.permissions) {
        enableRecording();
      }
    }, [isRecordingEnabled, activeBlock]);

    useBeforeunload((event) => {
      if (isRecording && !isRecordingCanceled) {
        event.preventDefault();
      }
    });

    if (!blocks || !blocks.length || !activeBlock) {
      return null;
    }

    if (hasMismatchedDeviceError) {
      return <WrongDevice surveyDevice={deviceType} />;
    }

    return (
      <DraggableCardProvider>
        <UnmoderatedContext.Provider value={{ blocks, deviceType }}>
          <form className='tablet:py-32 flex-1' ref={ref} onSubmit={handleSubmit(onSubmit)} {...rest}>
            <div className='fixed inset-0 p-6 overflow-hidden' id='unmoderatedCanvas'>
              {activeBlock.image_url && (
                <img src={activeBlock.image_url} alt='Question attachment' className='absolute inset-0 object-cover' />
              )}

              <div id={FULL_SCREEN_ELEMENT_ID} className='fixed inset-0' />

              {activeBlock && renderer()}
            </div>
          </form>
        </UnmoderatedContext.Provider>
      </DraggableCardProvider>
    );
  }
);
