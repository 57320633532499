import { add, endOfDay, endOfMonth, format, isAfter, startOfDay, startOfMonth } from 'date-fns';
import { useEffect, useRef, useState } from 'react';

import { getCalendarAvailabilities } from '@api/public_endpoints';

type DateRange = [string, string];

export interface Args {
  slug: string;
  workspace: string;
  tz: string;
  offset: number;
  limit: string;
}

interface Options {
  skip?: boolean;
  /** minutes to expire cache */
  expireIn?: number;
  dependencies?: any[];
}

interface Hook {
  data: Timeslots | undefined;
  nextMonthCount: number;
  isLoading: boolean;
  isLoadingNextOffset: boolean;
  hasError: boolean;
  cache: Cache;
}

interface Cache {
  data: Record<number, Timeslots>;
  at: Date;
}

type FetchRequest = (
  slug: Args['slug'],
  workspace: Args['workspace'],
  from: string,
  to: string,
  tz: Args['tz']
) => Promise<void | Timeslots>;

type APIResponse = { slots: Timeslots };

const DATE_FORMAT = 'yyyy-MM-dd';
const DEFAULT_CACHE_EXPIRE = 5;

export const useGetCalendarAvailabilities = (
  { slug, workspace, tz, offset = 0, limit }: Args,
  options?: Options
): Hook => {
  const [timeslots, setTimeslots] = useState<Timeslots>();
  const [nextMonthCount, setNextMonthCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNextOffset, setIsLoadingNextOffset] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [availabilitiesUpdated, setAvailabilitiesUpdated] = useState(false);

  const cache = useRef<Cache>({ data: {}, at: new Date() });
  const isInitialMountRef = useRef(true);


  const getWeeklyBlocks: (from: Date, offset?: number) => DateRange[] = (from) => {
    let start = startOfDay(from);
    const end = endOfDay(endOfMonth(start));
    const ranges: DateRange[] = [];

    while (start < end) {
      const week = add(start, { weeks: 1 });
      const until = isAfter(week, end) ? end : week;
      ranges.push([format(start, DATE_FORMAT), format(until, DATE_FORMAT)]);
      start = until;
    }

    return ranges;
  };

  const compactWeeklySlots: (slots: (void | Timeslots)[]) => Timeslots = (slots) =>
    Object.assign({}, ...slots.filter((t) => t && Object.keys(t).length));

  const fetch: FetchRequest = async (...args) => {
    try {
      const { slots }: APIResponse = await getCalendarAvailabilities(...args);
      return slots;
    } catch (e) {
      setHasError(true);
      setIsLoading(false);
      setIsLoadingNextOffset(false);
    }
  };

  const inCache = (ofs: number): boolean => Object.keys(cache.current.data).includes(String(ofs));

  const cacheNextMonth = async () => {
    setIsLoadingNextOffset(true);
    const settledSlots = await Promise.allSettled(
      getWeeklyBlocks(startOfMonth(add(new Date(), { months: offset + 1 }))).map(([from, to]) =>
        fetch(slug, workspace, from, to, tz)
      )
    );
    const slots = compactWeeklySlots(settledSlots.map((r) => (r.status === 'fulfilled' ? r.value : undefined)));
    if (slots) {
      cache.current.data[offset + 1] = slots;
    }
    setIsLoadingNextOffset(false);
  };

  const cacheExpired = () =>
    isAfter(new Date(), add(cache.current.at, { minutes: options?.expireIn ?? DEFAULT_CACHE_EXPIRE }));

  const clearCache = () => {
    cache.current.data = {};
    cache.current.at = new Date();
  };

  const isOutOfRange = () => {
    if (limit) {
      return isAfter(add(startOfMonth(new Date()), { months: offset }), new Date(limit));
    }
    return false;
  };

  const nowWithOffset = () => add(new Date(), { months: offset });

  const getSlots = async () => {
    const settledSlots = await Promise.allSettled(
      getWeeklyBlocks(offset > 0 ? startOfMonth(nowWithOffset()) : nowWithOffset()).map(([from, to]) =>
        fetch(slug, workspace, from, to, tz)
      )
    );

    const slots = compactWeeklySlots(settledSlots.map((r) => (r.status === 'fulfilled' ? r.value : undefined)));

    if (slots) {
      setTimeslots(slots);
      cache.current.data[offset] = slots;
    }
  };

  useEffect(() => {
    if (isInitialMountRef.current) return;

    // TODO: remove timeout when optimistic update is gone
    setTimeout(() => setAvailabilitiesUpdated(true), 500);
  }, [...(options?.dependencies || [])]);

  useEffect(clearCache, [tz]);


  useEffect(() => {
    isInitialMountRef.current = false;
  }, []);

  useEffect(() => {
    if (options?.skip) {
      return;
    }

    if (isOutOfRange()) {
      setTimeslots(undefined);
      return;
    }

    if (cacheExpired()) {
      clearCache();
    }

    (async () => {
      setIsLoading(true);
      setNextMonthCount(0);

      if (cache.current.data[offset] && !availabilitiesUpdated) {
        setTimeslots(cache.current.data[offset]);
      } else {
        await getSlots();
      }

      setIsLoading(false);

      // cache 1 month ahead
      if (!inCache(offset + 1)) {
        await cacheNextMonth();
      }

      if (cache.current.data[offset + 1]) {
        setNextMonthCount(
          Object.keys(cache.current.data[offset + 1]).reduce(
            (acc, d) => acc + cache.current.data[offset + 1][d].length,
            0
          )
        );
      }

      availabilitiesUpdated && setAvailabilitiesUpdated(false);
    })();
  }, [slug, workspace, tz, offset, options?.skip, availabilitiesUpdated]);

  return {
    data: timeslots,
    nextMonthCount: isLoadingNextOffset ? 0 : nextMonthCount,
    isLoading,
    isLoadingNextOffset,
    hasError,
    cache: cache.current
  };
};
