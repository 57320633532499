import cn from 'classnames';
import { fixSkipLogicQuestions } from 'components/Public/GQSurvey/skipLogic';
import { NameQuestionPreview } from 'components/Public/GQSurvey/SurveyQuestion';
import { Survey as SurveyAI } from 'components/shared/AI';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { Button, Portal, Spinner } from '@components/common';
import { StepHelper, StepTitle } from '@components/StudiesApp/components/StudyPublished/pages/shared';
import { track } from '@components/tracking';
import { last, uid } from '@components/utils';
import { useAccount } from '@hooks/useAccount';
import { useCandidateAttrs } from '@hooks/useCandidateAttrs';

import { AddQuestionBar } from './AddQuestionBar';
import { QuestionBank } from './QuestionBank';
import { QuestionCardList } from './QuestionCardList';
import { createDuplicate, getPortalId, getTrackProps, preparePayload, reposition, scrollTo } from './utils';
import { ScreenerReview } from '@components/GQSurveyBuilder/components';
import { attrMappings, DEFAULT_QUESTION, SCREENER_TITLE, SURVEY_TITLES } from '@components/GQSurveyBuilder/constants';
import { api } from '@api/reduxApi';

const DEBOUNCE_RATE = process.env.NODE_ENV === 'test' ? 500 : 1000;

interface Props {
  study?: Study;
  editable: boolean;
  screener: Screener;
  save: (fields: ScreenerField[]) => Promise<Screener | null>;
  afterSave?: (fields: Screener) => void;
  builderTitle?: boolean;
  noImgUpload?: boolean;
  page: 'study' | 'template' | 'studyBuilder' | 'templateBuilder';
  onSave?: (v: Study) => void;
}

export const GQSurveyBuilder: React.FC<Props> = ({
  screener,
  save,
  page,
  builderTitle = false,
  editable,
  noImgUpload = false,
  onSave,
  study
}) => {
  const [duplicateStudyScreenerField, { isLoading: isDuplicating }] = api.useDuplicateStudyScreenerFieldMutation();

  const { candidateAttrs, create: createCustomAttr } = useCandidateAttrs();
  const { account } = useAccount();

  const trackProps = getTrackProps(screener);

  const [questions, setQuestions] = useState(screener.fields.length === 0 ? [DEFAULT_QUESTION] : screener.fields);
  const [addedQuestions, setAddedQuestions] = useState<ScreenerField[]>([]);
  const [bankShown, setBankShown] = useState(false);
  const [isLoadingAI, setIsLoadingAI] = useState<boolean>(false);

  useEffect(() => {
    track('viewed_survey_builder', trackProps);
  }, []);

  const toggleBank = () => setBankShown((prev) => !prev);

  const closeBank = () => bankShown && setBankShown(false);

  const handleDelete = (question: ScreenerField) => {
    const newAddedQ = addedQuestions.filter((q) => q.label !== question.label);

    setAddedQuestions(newAddedQ);
  };

  const debouncedSave = useDebouncedCallback(save, DEBOUNCE_RATE);

  useEffect(() => {
    return () => {
      debouncedSave.flush();
    };
  }, []);

  const handleChange = async (fields: ScreenerField[]) => {
    const questions = fixSkipLogicQuestions(fields);

    const newQuestions = await save(preparePayload(questions));

    if (newQuestions) {
      setQuestions(newQuestions.fields);
    }
  };

  const handleDebouncedChange = (fields: ScreenerField[]) => {
    const questions = fixSkipLogicQuestions(fields);
    setQuestions(questions);

    debouncedSave.callback(preparePayload(questions));
  };

  const handleDuplicate = async (q: ScreenerField) => {
    if (q.field_type === 'info' && q.image_url) {
      const resp = await duplicateStudyScreenerField(q.id).unwrap();

      handleInsert(q.position, resp);
      return;
    }

    const newQs: ScreenerField[] = questions.reduce<ScreenerField[]>((newQs, question) => {
      if (question.id == q.id) {
        return newQs.concat([question, createDuplicate(q)]);
      } else {
        return newQs.concat([question]);
      }
    }, []);

    const screener = await save(preparePayload(reposition(newQs)));

    if (screener) {
      setQuestions(screener.fields);
    }
  };

  const buildNewQuestion = (): ScreenerField => ({
    ...DEFAULT_QUESTION,
    id: uid(),
    position: questions.length + 1,
    field_type: 'short_text'
  });

  const handleAdd = async () => {
    const newQuestion = buildNewQuestion();

    track('added_question', {
      ...trackProps,
      source: 'manual'
    });

    let newQuestions = fixSkipLogicQuestions([...questions, newQuestion]);

    const newScreener = await save(preparePayload(newQuestions));
    newQuestions = newScreener?.fields || screener.fields;

    if (newQuestions) {
      setQuestions(newQuestions);
      setImmediate(() => scrollTo(last(newQuestions).id));
    }
  };

  const handleAcceptAISuggestion = async (question: ScreenerField) => {
    const newScreener = await save(preparePayload([...questions, question]));
    const newQuestions = newScreener?.fields || screener.fields;

    if (newQuestions) {
      setQuestions(newQuestions);
    }
  };

  const addBankQuestion = async (newQuestion: ScreenerField) => {
    // TODO: create the custom attr
    const attr = newQuestion.candidate_attr;
    if (attr) {
      const hasAttr = candidateAttrs?.some(({ name }) => name === newQuestion.candidate_attr);
      if (!hasAttr) {
        await createCustomAttr({
          name: newQuestion.candidate_attr as any,
          attr_type: attrMappings[newQuestion.field_type]
        });
      }
    }

    let newQuestions = fixSkipLogicQuestions(reposition([...questions, newQuestion]));

    const newScreener = await save(preparePayload(newQuestions));
    newQuestions = newScreener?.fields || screener.fields;

    if (newQuestions) {
      setQuestions(newQuestions);
      setImmediate(() => scrollTo(last(newQuestions).id));
    }
  };

  const handleInsert = async (index: number, q?: ScreenerField) => {
    const newQuestion = q || buildNewQuestion();
    const qs = [...questions];

    qs.splice(index, 0, newQuestion);

    if (q) {
      setQuestions(reposition(qs));
    } else {
      const newScreener = (await save(preparePayload(reposition(qs)))) as any;
      const newQuestions = newScreener?.fields || screener.fields;
      if (newQuestions) {
        setQuestions(newQuestions);
      }
    }
  };

  const handleReplaceQuestion = (id: number, question: ScreenerField) => {
    const origQuestion = questions.find((q) => q.id === id);
    if (!origQuestion) {
      return;
    }

    const newQuestion = { ...question, position: origQuestion.position };
    const newQuestions = questions.map((q) => (q.id === id ? newQuestion : q));
    setQuestions(newQuestions);
  };

  const getPreviewUrl = () => {
    if (study) {
      let prefix = `/studies/${study.id}`;

      if (study.state === 'draft') {
        prefix += '/edit';
      }

      if (screener.screener_type === 'survey') {
        return `${prefix}/preview/survey`;
      } else {
        return `${prefix}/preview/screener`;
      }
    }
  };

  useEffect(() => {
    if (!screener.fields.length) {
      setQuestions([DEFAULT_QUESTION]);
    }
  }, [screener.fields.length]);

  useEffect(() => {
    if (screener.fields.length > 0) {
      setQuestions(screener.fields);
    }
  }, [screener.screener_type]);

  const isRecorded = screener.style === 'unmoderated_test';

  const portalId = React.useMemo(() => getPortalId(page), []);

  const previewUrl = getPreviewUrl();

  return (
    <div className={cn('max-w-3xl pb-12 mx-auto pt-gutter')}>
      <div className={cn('mb-8')}>
        {builderTitle && (
          <div className='text-center'>
            <StepTitle>
              {screener.screener_type === 'survey' ? SURVEY_TITLES[screener.style] : SCREENER_TITLE}
            </StepTitle>
            <StepHelper>
              If you’re not ready, you can skip ahead to complete other parts of the setup process.
            </StepHelper>
          </div>
        )}

        {screener.screener_type === 'pre' && study && (
          <ScreenerReview wrapperClassName='mb-6' study={study} onSave={onSave} />
        )}

        {account.ai_enabled && (
          <SurveyAI
            onSave={handleAcceptAISuggestion}
            fields={questions}
            studyGoal={study?.research_goal}
            onLoadingChange={setIsLoadingAI}
          />
        )}

        <QuestionCardList
          recordingZone={isRecorded}
          hasImgUpload={!noImgUpload}
          screener_review={study?.screener_review || 'manual'}
          questions={questions}
          editable={editable}
          screener={screener}
          style={screener.style}
          isUpdating={isDuplicating}
          onInsert={handleInsert}
          onDebouncedChange={handleDebouncedChange}
          onReplace={handleReplaceQuestion}
          onDelete={handleDelete}
          onDuplicate={handleDuplicate}
          onChange={handleChange}
        />

        {isLoadingAI && (
          <div className='flex justify-center mt-8'>
            <Spinner className='w-6 h-6' />
          </div>
        )}
      </div>

      {editable && <AddQuestionBar onClick={handleAdd} disabled={debouncedSave.pending()} />}

      <div className='pt-8 mt-16 border-t border-gray-200'>
        <div className=' pb-4 font-bold text-center text-gray-500'>
          Candidate information and consent will be collected automatically
        </div>
        <div className='bg-gray-50 p-10 border border-gray-200 rounded-sm opacity-75'>
          <NameQuestionPreview company_name='{{Your company}}' />
        </div>
      </div>

      <Portal id={portalId}>
        <div className='flex items-center space-x-2'>
          {previewUrl && (
            <Button icon='eye' aria-label='Preview button' href={previewUrl} target='_blank'>
              Preview
            </Button>
          )}

          <Button icon='questionBank' name='question_bank' onClick={toggleBank}>
            Library
          </Button>
        </div>
      </Portal>

      <QuestionBank
        screener={screener}
        addedQuestions={addedQuestions}
        isOpen={bankShown}
        addBankQuestion={addBankQuestion}
        setAddedQuestions={setAddedQuestions}
        trackProps={trackProps}
        onClose={closeBank}
        onSave={(study) => {
          if (screener.id === study.pre_screener?.id) {
            setQuestions(study.pre_screener.fields);
          } else if (screener.id === study.survey_screener?.id) {
            setQuestions(study.survey_screener.fields);
          }
        }}
      />
    </div>
  );
};
