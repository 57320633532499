import { Avatar, Button, Text } from '@components/common';
import * as React from 'react';
import { useMemo, useState } from 'react';
import {
  PanelButton,
  PanelType
} from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/PanelButton';
import { EventDetailsPanel } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/EventDetailsPanel';
import { AttendeesPanel } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/AttendeesPanel';
import { AvailabilityPanel } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/AvailabilityPanel';
import { AdditionalOptionsPanel } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/AdditionalOptionsPanel';
import {
  LOCATION,
  MODERATION_STYLES,
  SUFFIXES
} from '@components/StudiesApp/components/StudyDraft/pages/Calendar/constants';
import pluralize from 'pluralize';
import tinytime from 'tinytime';
import { addDaysToDate, getDatesBetween, startOfWeek } from '@components/utils';
import { UseStudyUsersHook } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/hooks/useStudyUsers';
import { TIME_ZONE_OPTIONS } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/options';
import { track } from '@components/tracking';

const dateStrTemplate = tinytime('{MM} {DD}');

interface Props {
  changeTimeSlot: (attr: string, val: string | number | number[]) => void;
  readOnly?: boolean;
  timeSlot: RecurringTimeSlot | OneOffTimeSlot;
  startDate: Date;
  endDate: Date | null;
  setWeekIdx: (v: number) => void;
  disablePrevWeekButton?: boolean;
  disableNextWeekButton?: boolean;
  study: Study;
  onBack: () => void;
  onSave: (study: Study) => void;
  studyUsersHook: UseStudyUsersHook;
  weekIdx: number;
  calAvailableSlots: CalenderAvailableSlots;
  viewSettings: CalenderViewSettings;
  calendar?: StudyCalendar;
  moderators: TeamUser[] | undefined;
  observers: TeamUser[] | undefined;
  setCalAvailableSlots: (v: CalenderAvailableSlots) => void;
}

export const CalendarPanels: React.FC<Props> = ({
  startDate,
  endDate,
  onBack,
  study,
  onSave,
  readOnly,
  studyUsersHook,
  setWeekIdx,
  weekIdx,
  calAvailableSlots,
  disablePrevWeekButton,
  disableNextWeekButton,
  viewSettings,
  calendar,
  timeSlot,
  changeTimeSlot,
  moderators,
  observers,
  setCalAvailableSlots
}) => {
  const [activePanel, setActivePanel] = useState<PanelType | null>(null);

  const minSchedNoticeSuffix = SUFFIXES.filter(
    (s) => ((study.minimum_booking_notice_in_minutes || 0) / s.multiplier) % 1 === 0
  )[0];

  const dateStr = useMemo(() => {
    const separator = study.continuous ? 'until' : '-';
    const start = dateStrTemplate.render(startDate);
    const end = endDate ? dateStrTemplate.render(endDate) : 'canceled';

    return `${start} ${separator} ${end}`;
  }, [startDate, endDate, study.continuous]);

  const dates = useMemo<Date[]>(() => {
    const monday = startOfWeek(addDaysToDate(startDate, weekIdx * 7));
    return getDatesBetween(monday, addDaysToDate(monday, 6));
  }, [startDate, weekIdx]);

  const currentTimezone = useMemo(() => {
    const selectedTZ = TIME_ZONE_OPTIONS.find((i) => i.data?.name === timeSlot.timezone);

    return selectedTZ?.label;
  }, [timeSlot.timezone]);

  const openPanel = (panel: PanelType) => {
    setActivePanel(panel);
    track('clicked_calendar_panel', { panel });
  };

  const closePanel = () => {
    setActivePanel(null);
  };

  const renderActivePanel = () => {
    switch (activePanel) {
      case 'event_details':
        return (
          <EventDetailsPanel
            study={study}
            onSave={onSave}
            onClose={closePanel}
            readOnly={readOnly}
            calendar={calendar}
          />
        );
      case 'attendees':
        return (
          <AttendeesPanel
            study={study}
            onBack={onBack}
            onSave={onSave}
            onClose={closePanel}
            readOnly={readOnly}
            studyUsersHook={studyUsersHook}
          />
        );
      case 'availability':
        return (
          <AvailabilityPanel
            timeSlot={timeSlot}
            changeTimeSlot={changeTimeSlot}
            onClose={closePanel}
            study={study}
            setCalAvailableSlots={setCalAvailableSlots}
            onSave={onSave}
            setWeekIdx={setWeekIdx}
            weekIdx={weekIdx}
            calAvailableSlots={calAvailableSlots}
            disablePrevWeekButton={disablePrevWeekButton}
            disableNextWeekButton={disableNextWeekButton}
            startDate={startDate}
            endDate={endDate}
            viewSettings={viewSettings}
            dates={dates}
            readOnly={readOnly}
          />
        );
      case 'additional_options':
        return (
          <AdditionalOptionsPanel
            calendar={calendar}
            timeSlot={timeSlot}
            changeTimeSlot={changeTimeSlot}
            onClose={closePanel}
            study={study}
            onSave={onSave}
            readOnly={readOnly}
          />
        );
      default:
        return (
          <div className='flex flex-col'>
            <PanelButton
              className='xx-event-details'
              onClick={() => openPanel('event_details')}
              type='event_details'
            >
              <Text h='400'>{`${study.duration_in_minutes} minutes`}</Text>
              <Text h='400'>{LOCATION[study.video_url_type]}</Text>
              {calendar && study.video_url_type !== 'in_person' && (
                <Text h='400'>{`Observer live stream ${calendar.live_stream_enabled ? 'enabled' : 'disabled'}`}</Text>
              )}
            </PanelButton>
            <PanelButton
              className='xx-attendees'
              onClick={() => openPanel('attendees')}
              type='attendees'
            >
              <Text h='400'>{study.moderation_style ? MODERATION_STYLES[study.moderation_style] : 'single'}</Text>
              {moderators?.length === 1 ? (
                <div key={moderators[0]?.id} className='h400 flex items-center space-x-1'>
                  <Avatar user={moderators[0]} size='sm' />
                  <Text h='400' color='gray-700'>
                    {moderators[0].name}
                  </Text>
                  <Text color='gray-500' h='400'>
                    (Moderator)
                  </Text>
                </div>
              ) : (
                <Text h='400' color='gray-700'>
                  {pluralize('moderator', moderators?.length || 0, true)}
                </Text>
              )}
              <Text h='400' color='gray-700'>
                {pluralize('observer', observers?.length || 0, true)}
              </Text>
            </PanelButton>
            <PanelButton
              className='xx-availability'
              onClick={() => openPanel('availability')}
              type='availability'
            >
              <Text h='400'>{`${endDate ? 'Date Range' : 'Continuous'}, ${dateStr}`}</Text>
              <Text h='400'>{currentTimezone}</Text>
            </PanelButton>
            <PanelButton
              className='xx-additional-options'
              onClick={() => openPanel('additional_options')}
              type='additional_options'
            >
              <Text h='400'>
                {calendar?.booking_limit_daily && calendar.booking_limit_daily > 0
                  ? `${calendar.booking_limit_daily} daily booking limit`
                  : `No daily booking limit`}
              </Text>

              <Text h='400'>
                {calendar?.booking_limit_weekly && calendar.booking_limit_weekly > 0
                  ? `${calendar.booking_limit_weekly} weekly booking limit`
                  : `No weekly booking limit`}
              </Text>
              <Text h='400'>
                {timeSlot.call_buffer
                  ? `${pluralize('minute', timeSlot.call_buffer, true)} buffer between events`
                  : 'No buffer between events'}
              </Text>
              <Text h='400'>
                {study.minimum_booking_notice_in_minutes
                  ? `${pluralize(
                      minSchedNoticeSuffix.label,
                      study.minimum_booking_notice_in_minutes / minSchedNoticeSuffix.multiplier,
                      true
                    )} minimum scheduling notice`
                  : 'No minimum scheduling notice'}
              </Text>
              <Text h='400'>
                {calendar?.maximum_scheduling_notice
                  ? `${pluralize('day', calendar?.maximum_scheduling_notice, true)} maximum scheduling notice`
                  : 'No maximum scheduling notice'}
              </Text>
              <Text h='400'> {study.waitlist_enabled ? 'Allow waitlists' : 'No waitlists'}</Text>
              <Text h='400'>{study.time_proposals_enabled ? 'Allow time proposals' : 'No time proposals'}</Text>
            </PanelButton>
          </div>
        );
    }
  };

  return (
    <div className='desktop:mb-0 desktop:rounded-tr-none desktop:rounded-br-none desktop:w-113 flex-shrink-0 w-full mb-4 bg-white border border-gray-200 rounded'>
      {renderActivePanel()}
      {!activePanel && (
        <Button
          className='mt-2 ml-6'
          trackEvent='clicked_calendar_help_link'
          link
          small
          icon='externalLink'
          target='_blank'
          href='https://greatquestion.co/support/studies/scheduling-tool'
        >
          Read our calendar guide
        </Button>
      )}
    </div>
  );
};
