import classNames from 'classnames';
import * as React from 'react';
import { useRef, useState } from 'react';

import { useOnClickOutside } from '@components/utils';

type Props = React.HTMLProps<HTMLDivElement> & {
  open: boolean | undefined;
  zIndex?: number;
  style?: React.CSSProperties;
  renderOffScreen?: boolean;
};
export const PopUp = React.forwardRef<HTMLDivElement, Props>(
  ({ children, className, open, zIndex, style, renderOffScreen, ...props }, ref) =>
    open ? (
      <div
        ref={ref}
        className={classNames('whitespace-nowrap absolute', className)}
        {...props}
        style={{ zIndex: zIndex || 10, ...style }}
      >
        {children}
      </div>
    ) : renderOffScreen ? (
      <div className='hidden'>{children}</div>
    ) : null
);

interface Params {
  defaultOpen?: boolean;
  open?: boolean;
  setOpen?: (open: boolean) => void;
}
interface Hook<T> {
  ref: React.MutableRefObject<T>;
  open: boolean | undefined;
  togglePopUp: () => void;
  openPopUp: () => void;
  closePopUp: () => void;
  PopUp: React.FC<Props>;
}
export const usePopUp = <T extends any = HTMLDivElement>(params?: Params): Hook<T> => {
  const [_open, _setOpen] = useState(params?.defaultOpen || false);

  const open = params?.open !== undefined ? params.open : _open;
  const setOpen = params?.setOpen ? params.setOpen : _setOpen;

  const ref = useRef<T>();

  useOnClickOutside(ref, () => {
    if (open) setOpen(false);
  });

  return {
    ref: ref as any,
    open,
    togglePopUp: () => setOpen(!open),
    openPopUp: () => setOpen(true),
    closePopUp: () => setOpen(false),
    PopUp
  };
};

export { Hook as UsePopUp };
