import { api } from 'api/reduxApi';
import pluralize from 'pluralize';

import { api as surveyBuilderApi, useBlockErrors } from '@components/SurveyBuilder';
import { compact } from '@components/utils';

interface Params {
  study: Study;
}

const INCENTIVE_LABELS: { [id: string]: string } = {
  tremendous: '',
  manual: '',
  coupon: 'Coupon: ',
  product: 'Product gift: ',
  other: 'Other: '
};

function incentivePublishText(study: Study): string {
  const prefix = INCENTIVE_LABELS[study.incentive_method as any];
  switch (study.incentive_method) {
    case 'tremendous':
    case 'manual':
      if (study.incentive === null || study.incentive <= 0) {
        return 'You haven’t set up an incentive';
      }
      return `${prefix}${study.currencySymbol}${study.incentive} incentive each`;
    case 'product':
      if (!study.incentive_title) {
        return 'Product gift details not set';
      }
    // falls through
    case 'coupon':
      if (!study.incentive_title) {
        return 'Coupon details not set';
      }
    // falls through
    case 'other':
      if (!study.incentive_title) {
        return 'Incentive details not set';
      }
      return prefix + study.incentive_title;
    default:
      return 'No incentive';
  }
}

function peopleOrPerson(slots: number) {
  if (slots > 1) {
    return 'people';
  } else {
    return 'person';
  }
}

function peopleSuffix(study: Study) {
  if (study.focus_group) {
    return 'per session';
  } else if (study.continuous) {
    return 'per week';
  }

  return 'maximum';
}

function planStepText(study: Study): string {
  const amountOfPeople: number = study.continuous ? (study.recurring_participant_limit as number) : study.maximum_slots;
  return compact([
    `${amountOfPeople} ${peopleOrPerson(amountOfPeople)} ${peopleSuffix(study)}`,
    study.incentive_method ? 'offering an incentive' : 'no incentive',
    study.has_screener ? 'requiring a screener' : ''
  ]).join(', ');
}

const planStateErrorMessage = (study: Study, incentiveFailing: boolean) => {
  if (incentiveFailing) {
    return incentivePublishText(study);
  }

  if (!study.participant_limit) {
    return 'You need to set a participant limit';
  }

  return 'You need to set up your plan';
};

const planStateFailedKeys = (study, incentiveFailing: boolean): PublishStepItem['failedKeys'] => {
  const keys: PublishStepItem['failedKeys'] = [];

  if (!study.participant_limit) {
    keys.push('participation_limit');
  }

  if (incentiveFailing) {
    keys.push('incentives');
  }

  return keys;
};

const isIncentiveFailing = (study: Study) => {
  if (study.incentive_method) {
    if (['manual', 'tremendous'].includes(study.incentive_method)) {
      return !study.incentive || study.incentive <= 0;
    }

    return !study.incentive_title;
  }

  return false;
};

export interface StudyPublishValidator {
  items: PublishStepItem[];
  canPublish: boolean;
}

export const useStudyPublishValidator = (params: Params): StudyPublishValidator => {
  const { study } = params;
  const { pre_screener, survey_screener } = study;
  const baseUrl = `/studies/${study.id}/edit`;
  const {
    data: sessions,
    isLoading: sessionsIsLoading,
    isSuccess: sessionsIsSuccess,
    isError: sessionsIsError
  } = api.useGetRepoSessionsQuery(
    { studyId: study.id },
    { skip: !study.focus_group || !['draft', 'pending'].includes(study.state) }
  );

  const { data: blocks = [] } = surveyBuilderApi.useGetSurveyBuilderBlocksQuery(study.id, {
    skip: !study.focus_group || !['draft', 'pending'].includes(study.state)
  });

  const { errors } = useBlockErrors(blocks);

  const hasTimeConflict = sessions?.find((current, i) => {
    return sessions?.some((session, j) => {
      if (!session.session_at || !current.session_at) return false;
      return i !== j && session.session_at.toString() === current.session_at.toString();
    });
  });

  let planState: PublishStepItem['state'] = study.participant_limit ? 'completed' : 'failed';
  const incentiveFailing = isIncentiveFailing(study);
  if (incentiveFailing) planState = 'failed';

  let taskState: PublishStepItem['state'] = null;
  if (study.style === 'online_task') {
    taskState = study.external_url ? 'completed' : 'failed';
  }

  let calendarError: string | null = null;
  let calendarState: PublishStepItem['state'] = null;

  if (study.style === 'video_call' && study.focus_group) {
    if (sessionsIsLoading) {
      calendarState = 'loading';
    } else if (sessionsIsSuccess && hasTimeConflict) {
      calendarState = 'failed';
      calendarError = 'Sessions cannot overlap. Please check your calendar.';
    } else if (sessionsIsSuccess && sessions?.length > 0 && sessions?.every((session) => !!session.session_at)) {
      calendarState = 'completed';
    } else if (sessionsIsSuccess && !sessions?.length) {
      calendarState = 'failed';
      calendarError = 'You need to set up at least one session.';
    } else if (sessionsIsSuccess) {
      calendarState = 'failed';
      calendarError = 'You need to set up all the sessions you have added.';
    } else if (sessionsIsError) {
      calendarState = 'failed';
      calendarError = 'Something went wrong. Please try again later.';
    }
  } else if (study.style === 'video_call') {
    if (study.nylas_calendar_id) {
      const hasSlot = !!(
        (study.time_slot_settings['days'] || []).length > 0 &&
        study.time_slot_settings['end_time'] &&
        study.time_slot_settings['start_time'] &&
        study.time_slot_settings['timezone']
      );
      const hasSlots = study.cal_available_slots?.slots?.length > 0;
      if (!(hasSlot || hasSlots)) {
        calendarState = 'failed';
        calendarError = 'Your calendar has no available time configured.';
      } else if (!study.video_url && study.video_url_type === 'manual') {
        calendarState = 'failed';
        calendarError = 'You need to provide a URL for the video call.';
      } else if (!study.location.address && study.video_url_type === 'in_person') {
        calendarState = 'failed';
        calendarError = 'You need to provide a location for the in-person interview.';
      } else if (!study.duration_in_minutes) {
        calendarState = 'failed';
        calendarError = 'You need to specify a call duration.';
      } else {
        calendarState = 'completed';
      }
    } else {
      const isBookable = study.calendly_event_type_uuid || study.booking_url;
      calendarState = isBookable && study.duration_in_minutes ? 'completed' : 'failed';
    }
  }

  let calendarSubheading = '';
  if (calendarState === 'failed') {
    calendarSubheading = calendarError || 'You haven’t set up the calendar yet.';
  } else if (calendarState === 'loading') {
    calendarSubheading = 'Checking...';
  } else if (study.moderation_style === 'round_robin') {
    calendarSubheading = `You’ve set up calendars for ${pluralize(
      'moderators',
      study.user_ids.length,
      true
    )}, round-robin style`;
  } else {
    calendarSubheading = 'You’ve set up the calendar.';
  }

  const requiredFieldsCount = study.external_candidates_enabled ? 2 : 1;
  let preScreenerSubheading = `You need to set up your screener with at least ${pluralize(
    'questions',
    requiredFieldsCount,
    true
  )}.`;
  let preScreenerState: PublishStepItem['state'] = null;
  if (pre_screener?.fields && pre_screener.fields.length >= requiredFieldsCount) {
    preScreenerSubheading = `${pre_screener.fields.length} question screener with ${
      study.screener_review === 'auto' ? 'automatic' : 'manual'
    } review.`;

    if (study.screener_review === 'auto' && !pre_screener.fields.find((f) => !!f.ideal_answer?.value)) {
      preScreenerSubheading = 'With automatic review, you need to specify ideal answers for at least 1 question.';
      preScreenerState = 'failed';
    } else {
      preScreenerState = 'completed';
    }
  } else if (study.has_screener) {
    preScreenerState = 'failed';
  }

  let surveyScreenerSubheading = 'You need to set up your survey with at least 1 question.';
  let surveyScreenerState: PublishStepItem['state'] = null;
  let unmoderatedState: PublishStepItem['state'] = null;

  if (survey_screener?.fields && survey_screener.fields.length >= 1) {
    surveyScreenerSubheading = `${survey_screener.fields.length} question survey.`;
    surveyScreenerState = 'completed';
  } else if (study.style === 'panel') {
    surveyScreenerState = 'failed';
  } else if (study.style === 'survey') {
    surveyScreenerState = 'failed';

    if (!study.on_gq && study.external_url) {
      surveyScreenerSubheading = 'You’ve set up your survey link.';
      surveyScreenerState = 'completed';
    }
  }

  if (study.style === 'unmoderated_test') {
    unmoderatedState = Object.keys(errors).length === 0 ? 'completed' : 'failed';
  }

  const {
    data: candidatesRequests,
    isLoading: candidatesRequestsIsLoading,
    isSuccess: candidatesRequestsIsSuccess,
    isError: candidatesRequestsIsError
  } = api.useGetExternalCandidatesRequestsQuery(study.id, {
    skip: !study.external_candidates_enabled || !['draft', 'pending'].includes(study.state)
  });

  let recruitingState: PublishStepItem['state'] = null;
  let recruitingSubheading: string | null = null;
  if (candidatesRequestsIsLoading) {
    recruitingState = 'loading';
    recruitingSubheading = 'Checking...';
  } else if (candidatesRequestsIsSuccess && !!candidatesRequests?.[0]) {
    if (candidatesRequests[0].valid_request) {
      recruitingState = 'completed';
      recruitingSubheading = `You’ve set up your ${candidatesRequests[0].attrs.market_type.toUpperCase()} recruitment request to be submitted ${
        candidatesRequests[0].state === 'submitted_publish' ? 'immediately' : 'later'
      }.`;
    } else {
      recruitingState = 'failed';
      recruitingSubheading = candidatesRequests[0].request_errors?.length
        ? candidatesRequests[0].request_errors.join('; ')
        : 'Please check all required fields in your recruiting.';
    }
  } else if (candidatesRequestsIsSuccess) {
    recruitingState = 'failed';
    recruitingSubheading = 'You need to set up your recruiting.';
  } else if (candidatesRequestsIsError) {
    recruitingState = 'failed';
    recruitingSubheading = 'Something went wrong. Please try again later.';
  }

  const anyFailures = [
    planState,
    preScreenerState,
    surveyScreenerState,
    recruitingState,
    calendarState,
    taskState,
    unmoderatedState
  ].some((s) => s === 'failed' || s === 'loading');

  const items: PublishStepItem[] = compact<PublishStepItem>([
    ['survey', 'online_task', 'video_call', 'unmoderated_test', 'panel'].includes(study.style)
      ? {
          state: planState,
          heading: 'Plan',
          subheading: planState === 'completed' ? planStepText(study) : planStateErrorMessage(study, incentiveFailing),
          href: `${baseUrl}/plan`,
          ctaText: 'Edit plan',
          id: 'plan',
          failedKeys: planState === 'failed' ? planStateFailedKeys(study, incentiveFailing) : []
        }
      : false,
    study.has_screener
      ? {
          state: preScreenerState,
          heading: 'Screener',
          subheading: preScreenerSubheading,
          href: `${baseUrl}/screener`,
          ctaText: 'Set up screener',
          id: 'screener'
        }
      : false,
    study.external_candidates_enabled
      ? {
          state: recruitingState,
          heading: 'Recruiting',
          subheading: recruitingSubheading,
          href: `${baseUrl}/recruiting`,
          ctaText: 'Set up recruiting',
          id: 'recruiting'
        }
      : false,
    study.style === 'video_call'
      ? {
          state: calendarState,
          heading: 'Calendar',
          subheading: calendarSubheading,
          href: `${baseUrl}/calendar`,
          ctaText: 'Set up calendar',
          id: 'calendar'
        }
      : false,
    study.style === 'online_task'
      ? {
          state: taskState,
          heading: 'Task',
          subheading: taskState === 'failed' ? 'You haven’t set up your task yet.' : 'You’ve added your task link.',
          href: `${baseUrl}/task`,
          ctaText: 'Set up task',
          id: 'task'
        }
      : false,
    study.style === 'unmoderated_test'
      ? {
          state: unmoderatedState,
          heading: 'Unmoderated test',
          subheading: unmoderatedState === 'failed' ? 'You haven’t set up your test yet.' : 'You’ve set up your test.',
          href: `${baseUrl}/test`,
          ctaText: 'Set up test',
          id: 'unmoderated'
        }
      : false,
    study.style === 'panel'
      ? {
          state: surveyScreenerState,
          heading: 'Signup form',
          subheading:
            surveyScreenerState === 'failed' ? 'You haven’t set up your signup form yet.' : 'You’ve set up your test.',
          href: `${baseUrl}/survey`,
          ctaText: 'Set up form',
          id: 'signup_form'
        }
      : false,
    study.style === 'survey'
      ? {
          state: surveyScreenerState,
          heading: 'Survey',
          subheading: surveyScreenerSubheading,
          href: `${baseUrl}/survey`,
          ctaText: 'Set up survey',
          id: 'survey'
        }
      : false
  ]);

  return {
    items,
    canPublish: !anyFailures
  };
};
