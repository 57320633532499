import { Toast } from '@stores/toaster';

export const successDuplicate = (title: string): Toast => ({
  heading: `Created “${title}”`,
  icon: 'success',
  text: 'Your study was duplicated.'
});

export const failedDuplicate = (): Toast => ({
  heading: 'Failed to duplicate!',
  icon: 'error',
  text: 'Something went wrong saving your study. Please try again later.'
});

export const successDelete = (title: string): Toast => ({
  heading: 'Study deleted',
  icon: 'success',
  text: `The study “${title}” was deleted`
});

export const failedDelete = (): Toast => ({
  heading: 'Failed to delete!',
  icon: 'error',
  text: 'Something went wrong deleting that study. Please try again later.'
});

export const successApproved = (title: string): Toast => ({
  heading: 'Study approved',
  icon: 'success',
  text: `The study “${title}” was published`
});

export const failedApproved = (): Toast => ({
  heading: 'Failed to approve study',
  icon: 'error',
  text: 'Something went wrong publishing that study. Please try again later.'
});

export const successRequestedApproval = (title: string): Toast => ({
  heading: 'Request sent!',
  icon: 'success',
  text: `Your request to approve “${title}” was sent`
});

export const failedRequestedApproval = (): Toast => ({
  heading: 'Failed to send request',
  icon: 'error',
  text: 'Something went wrong. Please try again later or contact support.'
});

export const successReactivate = (): Toast => ({
  heading: 'Study reactivated',
  icon: 'success',
  text: 'The study has been reactivated.'
});

export const failedReactivate = (): Toast => ({
  heading: 'An error occurred!',
  icon: 'error',
  text: 'Something went wrong reactivating that study. Please try again later.'
});

export const successArchive = (): Toast => ({
  heading: 'Study archived',
  icon: 'success',
  text: 'The study has been archived.'
});

export const failedArchive = (): Toast => ({
  heading: 'An error occurred!',
  icon: 'error',
  text: 'Something went wrong archiving that study. Please try again later.'
});

export const successPause = (): Toast => ({
  heading: 'Study paused',
  icon: 'success',
  text: 'The study has been paused.'
});

export const failedUnpause = (): Toast => ({
  heading: 'An error occurred!',
  icon: 'error',
  text: 'Something went wrong unpausing that study. Please try again later.'
});

export const successUnpause = (): Toast => ({
  heading: 'Study unpaused',
  icon: 'success',
  text: 'The study has been unpaused.'
});

export const failedPause = (): Toast => ({
  heading: 'An error occurred!',
  icon: 'error',
  text: 'Something went wrong pausing that study. Please try again later.'
});

export const successClose = (): Toast => ({
  heading: 'Study closed',
  icon: 'success',
  text: 'The study has been closed.'
});

export const failedClose = (): Toast => ({
  heading: 'An error occurred!',
  icon: 'error',
  text: 'Something went wrong closing that study. Please try again later.'
});

export const failedChangeModerator = (errors: string[]): Toast => {
  let text = 'Please check they have all necessary permissions and integrations';
  if (errors.length > 0) {
    text += ': ' + errors.join(', ');
  }
  text += '.';
  return {
    heading: 'We couldn’t make that user the moderator',
    icon: 'error',
    text
  };
};

export const failedAcceptTimeProposal = (): Toast => ({
  heading: 'We couldn’t schedule that time',
  icon: 'error',
  text: 'Please try again in a few moments, or contact support.'
});

export const failedUpdate = (): Toast => ({
  heading: 'Failed to update!',
  icon: 'error',
  text: 'Something went wrong saving the study. Try again?'
});

export const successUpdate = (): Toast => ({
  heading: 'Updated successfully!',
  icon: 'success',
  text: 'Your study was updated.'
});
