import classNames from 'classnames';
import { format } from 'date-fns';
import { DateObj } from 'dayzed';
import * as React from 'react';

export const DateButton: React.FC<{
  todayKey: string;
  dateObj: DateObj | '';
  timeslots?: Timeslots;
  weekIndex: number;
  getDateProps: any;
  withDivider?: boolean;
}> = ({ todayKey, dateObj, timeslots, weekIndex, getDateProps, withDivider = false }) => {
  if (!dateObj) {
    return (
      <div className={classNames(weekIndex > 0 && withDivider && 'border-t border-custom-brand-secondary', 'py-2')}>
        <span className='flex items-center justify-center w-8 h-8 mx-auto rounded-full'>&nbsp;</span>
      </div>
    );
  }

  const { date, selected } = dateObj;
  const dateKey = format(date, 'yyyy-MM-dd');

  let slots: string[] | undefined;
  if (timeslots) {
    slots = timeslots[dateKey];
  }

  return (
    <div className={classNames(weekIndex > 0 && withDivider && 'border-t border-custom-brand-secondary', 'py-2')}>
      {slots && slots.length > 0 && (
        <button
          className={classNames(
            selected && 'text-white bg-custom-brand-button',
            'border border-custom-brand font-bold',
            'focus:outline-none mx-auto flex h-8 w-8 items-center justify-center rounded-full'
          )}
          {...getDateProps({ dateObj })}
        >
          <time dateTime={date.toISOString()}>{format(date, 'd')}</time>
        </button>
      )}
      {!slots && (
        <span
          className={classNames(
            dateKey >= todayKey ? 'text-custom-brand' : 'text-custom-brand-secondary',
            'mx-auto flex h-8 w-8 items-center justify-center rounded-full'
          )}
        >
          <time dateTime={date.toISOString()}>{format(date, 'd')}</time>
        </span>
      )}
    </div>
  );
};
