import { bulkParticipationsAction, sendStudyMessage } from 'api/queries';
import { Button } from 'components/common';
import { useToaster } from 'components/stores/toaster';
import { SendMessageParams, StudyMessageSlideOut } from 'components/StudyMessages';
import { compact } from 'components/utils';
import pluralize from 'pluralize';
import React, { useState } from 'react';

import { UpdateSessionModal } from '../UpdateSessionModal';
import { Session } from './components';

const MESSAGES = {
  cancel_interview: 'a cancelation notice',
  reschedule_request: 'a reschedule request'
};

const TITLES = {
  cancel: 'Cancellation notice',
  reschedule: 'Reschedule request'
};

interface Props {
  sessions: RepoSession[];
  duration: number;
  createSession: (data?: Partial<RepoSession>) => void;
  deleteSession: (uuid: string) => void;
  updateSession: (data: Partial<RepoSession>) => void;
  study: Study;
}

const scheduledParticipationIds = (session: RepoSession) =>
  session.participations?.filter((p) => p.status === 'booked' || p.status === 'completed')?.map(({ id }) => id) || [];

export const Sessions: React.FC<Props> = ({
  study,
  sessions,
  duration,
  createSession,
  deleteSession,
  updateSession
}) => {
  const timestamps = compact(sessions.map((session) => session.session_at?.getTime()));

  const [modal, setModal] = useState<'reschedule' | 'cancel' | null>(null);
  const [slideout, setSlideout] = useState<'reschedule' | 'cancel' | null>(null);
  const [selected, setSelected] = useState<RepoSession | null>(null);
  const partyIds = selected ? scheduledParticipationIds(selected) : [];
  const scheduledCount = partyIds.length || 0;

  const showToast = useToaster();

  const checkForConflicts = (index: number): boolean => {
    // Iterate over timestamps and check if any of them overlap with timestamps[i] over the duration
    const sessionStart = timestamps[index];
    const sessionEnd = sessionStart + duration * 60 * 1000;

    for (let i = 0; i < timestamps.length; i++) {
      if (i === index) {
        continue;
      }

      const otherSessionStart = timestamps[i];
      const otherSessionEnd = otherSessionStart + duration * 60 * 1000;

      if (sessionStart > otherSessionStart && sessionStart < otherSessionEnd) {
        return true;
      }

      if (sessionEnd > otherSessionStart && sessionEnd < otherSessionEnd) {
        return true;
      }
    }

    return false;
  };

  const onDelete = (session: RepoSession) => {
    if (scheduledParticipationIds(session).length > 0) {
      setSelected(session);
      setModal('cancel');
    } else {
      deleteSession(session.uuid);
    }
  };

  const onChange = (session: RepoSession, date: Date | null) => {
    if (scheduledParticipationIds(session).length > 0) {
      setSelected({ ...session, session_at: date });
      setModal('reschedule');
    } else {
      updateSession({ uuid: session.uuid, session_at: date });
    }
  };
  const onSuccess = () => {
    if (!selected) return;
    slideout === 'reschedule'
      ? updateSession({ uuid: selected.uuid, session_at: selected.session_at })
      : deleteSession(selected?.uuid);
  };

  const handleSend = async ({ message, sender }: SendMessageParams) => {
    const action: ParticipationAction = message.event === 'cancel_interview' ? 'cancel' : 'reschedule_request';
    await bulkParticipationsAction(study.id, {
      action,
      ids: partyIds,
      fields: []
    });

    const resp = await sendStudyMessage({ studyId: study.id, id: message.id }, { participation_ids: partyIds, sender });

    if (resp) {
      onSuccess();

      showToast({
        heading: 'Emails sent!',
        text: `${pluralize('candidates', resp.participation_ids.length, true)} sent ${MESSAGES[message.event]}.`,
        icon: 'success'
      });
    } else {
      showToast({
        heading: 'An error occurred!',
        text: 'Please try again later.',
        icon: 'error'
      });
    }
    setSelected(null);
    setSlideout(null);
  };

  return (
    <div className='flex flex-col items-start mt-2 mb-4 space-y-4'>
      {!sessions.length && study.state === 'draft' && (
        <Session
          checkForConflicts={checkForConflicts}
          index={0}
          onDelete={onDelete}
          onChange={onChange}
          duration={duration}
          createSession={createSession}
          isDraft
        />
      )}
      {sessions.map((session, index) => (
        <Session
          isDraft={study.state === 'draft'}
          key={session.uuid}
          checkForConflicts={checkForConflicts}
          session={session}
          index={index}
          onDelete={onDelete}
          onChange={onChange}
          duration={duration}
          cancelEnabled={study.state === 'active' || sessions.length > 1}
        />
      ))}
      {!(study.state === 'draft' && sessions.length === 0) && (
        <Button icon='plus' onClick={() => createSession()} link>
          Add session
        </Button>
      )}

      {modal && selected && (
        <UpdateSessionModal
          type={modal}
          onClose={() => {
            setSelected(null);
            setModal(null);
          }}
          onConfirm={() => {
            setModal(null);
            setSlideout(modal);
          }}
          participantsNumber={scheduledCount}
        />
      )}
      {slideout && selected && (
        <StudyMessageSlideOut
          event={slideout === 'cancel' ? 'cancel_interview' : 'reschedule_request'}
          onClose={() => {
            setSlideout(null);
            setSelected(null);
          }}
          inviteableCount={scheduledCount}
          study={study}
          previewCandidate={selected.participations?.[0]?.customer}
          previewParticipation={selected.participations?.[0]}
          cta='Send email'
          onSend={handleSend}
          title={TITLES[slideout]}
          subtitle='This asks participants to re-schedule to a new available session.'
        />
      )}
    </div>
  );
};
