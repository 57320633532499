import { MarkType, Node } from 'prosemirror-model';
import { Editor } from '@tiptap/core';
import * as Queries from '@components/shared/Tiptap/helpers/queries';
import { AI } from '@api/chat-gpt';

export const getTimestamp = (node: Node, markType: MarkType): number => {
  const firstChild = node.content.firstChild;

  if (firstChild) {
    const mark = firstChild.marks.find(({ type }) => type === markType);

    if (mark) {
      return mark.attrs.start_ts / 1000;
    }
  }

  return 0;
};

export const getSuggestionIdsFromTags = (tags: string[], suggested?: AI.UseQueryResult<string>): number[] => {
  if (suggested && suggested.length) {
    return suggested.filter(({ result }) => tags.includes(result)).map(({ id }) => id);
  }

  return [];
};

type GetHighlightContent = (params: { editor: Editor; from: number; to: number }) => HighlightContentElement[];

export const getHighlightContent: GetHighlightContent = ({ editor, from, to }) => {
  const content: HighlightContentElement[] = [];

  Queries.getNodesSelection(
    editor,
    (node) => {
      if (node.type.name === 'text') {
        content.push({ type: 'text', text: node.text });
      } else if (node.type.name === 'image') {
        content.push({ type: 'image', src: node.attrs.src });
      }
    },
    { from, to }
  );

  return content;
};

export const isEmptyDocument = (data: ApiDocument): boolean => {
  return !data.doc || !data.doc.content || (data.doc.content.length === 1 && !data.doc.content[0].content);
};
