import cn from 'classnames';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { createScreenerResponse } from '@api/public_endpoints';
import { Alert, Button, Loading, Modal } from '@components/common';
import { useRecorder } from '@components/Loom/useRecorder';
import { compact, uniq } from '@components/utils';
import { SUPPORT_EMAIL } from '@constants/emails';
import { useStopwatch } from '@hooks/useStopWatch';

import { ConfirmationPage } from './ConfirmationPage';
import { StartLoom } from './fields/StartLoom';
import { getFormKey, hasFormKey } from './helpers/getFormKey';
import { InsertLoom } from './InsertLoom';
import { UnsupportedLoomPage } from './pages/UnsupportedLoomPage';
import { evaluateSkipLogic } from './skipLogic';
import { NameAndIdQuestion, Question, NotQualifiedQuestion } from './SurveyQuestion';
import {
  END_OF_SURVEY,
  END_OF_SURVEY_WITH_AUTO_REVIEW,
  END_OF_SURVEY_WITHOUT_SUBMIT,
  endOfSurveyIds,
  isEndOfSurvey
} from '@components/GQSurveyBuilder/components/SkipLogicSlideOut';

interface Props {
  study: PublicStudy;
  screener: Screener;
  hidden: any;
  prefill?: Record<any, any>;
  participation?: Participation;
  account: PublicGQAccount;
  preview?: boolean;
}

interface Step {
  id: number;
  number?: number;
  field: ScreenerField | null;
  to: number | null;
}

type FieldValues = Record<number, string | { label: string | string[] }>;

const scrollTo = (id: number | string) => {
  document.getElementById(`step-${id}`)?.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
};

// TODO: This will have to incoproate the start / stop recording.
const buildSteps = (fields: ScreenerField[], params: { isRecorded: boolean }): Step[] => {
  const { isRecorded } = params;
  const screenerIds = fields.map((s) => s.id);

  const getNextId = (id: number) => {
    const idx = screenerIds.indexOf(id);
    const isLast = idx === screenerIds.length - 1;
    if (!isLast) {
      return screenerIds[idx + 1];
    } else {
      return END_OF_SURVEY;
    }
  };

  return compact([
    ...fields.map((field, idx) => ({ id: field.id, number: idx + 1, field, to: getNextId(field.id) })),
    {
      id: END_OF_SURVEY,
      number: fields.length + (isRecorded ? 2 : 1),
      field: null,
      to: null
    },
    {
      id: END_OF_SURVEY_WITH_AUTO_REVIEW,
      number: fields.length + (isRecorded ? 2 : 1) + 1,
      field: null,
      to: null
    },
    {
      id: END_OF_SURVEY_WITHOUT_SUBMIT,
      number: fields.length + (isRecorded ? 2 : 1) + 2,
      field: null,
      to: null
    }
  ]);
};

const buildPrefill = (prefill: Record<any, any>, steps: Step[]): Record<number, any> => {
  return Object.keys(prefill).reduce((memo, pId) => {
    const step = steps.find(({ id }) => id.toString() === pId);
    if (step) {
      if (step.field && ['single_choice', 'multiple_choice'].includes(step.field.field_type)) {
        memo[`${step.id}.label`] = prefill[pId];
      } else {
        memo[step.id] = prefill[pId];
      }
    }
    return memo;
  }, {});
};

type SURVEY_PAGES = 'survey' | 'success' | 'unsupported';

export const QuestionWrapper: React.FC<{ id: any }> = ({ id, children }) => (
  <div
    id={`step-${id}`}
    key={id}
    aria-live='polite'
    className='tablet:p-6 flex p-4 my-6 border border-gray-200 rounded-sm'
  >
    {children}
  </div>
);

export const GQSurvey: React.FC<Props> = ({
  study,
  screener,
  hidden,
  prefill = {},
  account,
  participation: initialParticipation,
  preview
}) => {
  const isRecorded = study.style == 'unmoderated_test' && screener.screener_type === 'survey';
  const steps = useMemo<Step[]>(() => buildSteps(screener.fields, { isRecorded: isRecorded }), []);
  const [participation, setParticipation] = useState<Participation | null>(initialParticipation || null);
  const [activeStepId, setActiveStepId] = useState<number | null>(steps[0].id);
  const [shownIds, setShownIds] = useState<number[]>([steps[0].id]);
  const [submitting, setSubmitting] = useState(false);
  const [globalError, setGlobalError] = useState<boolean>();
  const [page, setPage] = useState<SURVEY_PAGES>('survey');
  const [resetModal, setShowResetModal] = useState<'early' | 'canceled' | null>(null);
  const [currentStepId, setCurrentStepId] = useState(steps[0].id);

  const startStep = steps.find((s) => s.field && s.field.field_type === 'start_loom');
  const stopStep = steps.find((s) => s.field && s.field.field_type === 'stop_loom');

  const countableShown = uniq(shownIds).filter((id) => !compact([startStep?.id, stopStep?.id]).includes(id)).length;
  const countableTotal = steps.filter(
    (s) => !compact([startStep?.id, stopStep?.id, ...endOfSurveyIds]).includes(s.id)
  ).length;

  const currentStep = useMemo(() => steps.filter((s) => s.id === currentStepId)[0], [currentStepId]);

  const [totalSteps, setTotalSteps] = useState<number>(countableTotal);

  const { t, i18n } = useTranslation('GQSurvey');

  useEffect(() => {
    i18n.changeLanguage(study.language);
  }, []);

  useEffect(() => {
    const to = currentStepId;
    setActiveStepId(to);
    if (to && !shownIds.includes(to)) {
      setShownIds(to ? [...(shownIds || []), to] : [...(shownIds || [])]);
    }
    window.setImmediate && window.setImmediate(() => scrollTo(to as number));
  }, [currentStepId]);

  const defaultValues = useMemo(
    () => ({
      name: participation?.name || prefill?.name,
      email: participation?.email || prefill?.email,
      phone_number: participation?.phone_number || prefill?.phone_number,
      consent: participation?.consent,
      ...buildPrefill(prefill, steps)
    }),
    []
  );

  const { register, handleSubmit, watch, errors, setValue, getValues, setError, control, reset } = useForm({
    defaultValues
  });

  const { start: startTimer, time, stop: stopTimer, reset: resetTimer } = useStopwatch();
  const {
    status,
    button,
    reset: resetRecorder,
    recordingId: loomRecordingId
  } = useRecorder({
    onCancel: () => {
      setShowResetModal('canceled');
    },
    onStart: () => {
      startTimer();
      goToStep((startStep || currentStep).to);
    },
    onComplete: (id) => {
      stopTimer();
    }
  });

  useEffect(() => {
    if (currentStep.field?.field_type === 'stop_loom' && loomRecordingId) {
      goToStep(currentStep.to);
    }
  }, [currentStepId, loomRecordingId]);

  useEffect(() => {
    if (status === 'completed' && currentStep.field?.field_type !== 'stop_loom') {
      setShowResetModal('early');
    }
  }, [status]);

  useEffect(() => {
    if (isRecorded && status === 'error') {
      setPage('unsupported');
    }
  }, [status]);

  const submitAnswers = async (answers: FieldValues) => {
    setSubmitting(true);
    const attrs: Record<string, any> = {
      ...answers,
      hidden
    };
    if (isRecorded) {
      attrs.loom_recording_id = loomRecordingId;
    }

    createScreenerResponse(screener.id, participation?.id, attrs)
      .then((resp) => {
        const { participation: part } = resp;
        setParticipation(part);
        // TODO: Refirect to the party page once we have a better page for it.
        // window.location.href = `/go/${part.token}`;
        window.scrollTo(0, 0);
        setPage('success');
      })
      .catch((err) => {
        if (err.participation_id && err.participation_id[0] === 'has already been taken') {
          setError('email', { message: 'This email has already been used.', type: 'validate' });
        } else {
          setGlobalError(true);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const gotoNext = (answers: FieldValues) => {
    let to = currentStep.to;

    try {
      if (currentStep.field) {
        const field = currentStep.field;
        const skipLogics = currentStep.field.skip_logic;
        const skipLogic = skipLogics?.find((sl) => evaluateSkipLogic(field, answers[currentStepId], sl));

        if (skipLogic?.to) {
          to = skipLogic.to;
        } else if (currentStep.field.skip_logic) {
          to = currentStep.field.next_screener_field_id ?? currentStep.to;
        }
      }
    } catch (err) {
      // should we do something here?
      // its very bad if evaluateSkipLogic errors and we let the page just break right here
      // at least just ignore the skip logic and continue them along the survey
    }

    goToStep(to);
  };

  function goToStep(to: number | null) {
    setCurrentStepId(to as number);
  }

  // TODO: this could slice the shownIds to befor the recording, and only reset those values...
  function restartSurvey() {
    reset(defaultValues);
    resetTimer();
    resetRecorder();
    setActiveStepId(steps[0].id);
    setCurrentStepId(steps[0].id);
    setShownIds([steps[0].id]);
  }

  const isRecordingStep = currentStep.field && ['start_loom', 'stop_loom'].includes(currentStep.field.field_type);

  const onSubmit = async (answers: FieldValues) => {
    setGlobalError(undefined);
    if (isEndOfSurvey(currentStep.id)) {
      if (preview || currentStep.id === END_OF_SURVEY_WITHOUT_SUBMIT) {
        setPage('success');
      } else {
        await submitAnswers(answers);
      }
    } else if (!isRecordingStep) {
      gotoNext(answers);
    }
  };

  const onFocus = (id: number) => {
    if (activeStepId !== id) {
      setActiveStepId(id);
      scrollTo(id);
    }
  };

  useEffect(() => {
    const currentIndex = steps.findIndex(({ id }) => id === currentStep.id);
    setTotalSteps(countableTotal - currentIndex + shownIds.length - 1);
  }, [shownIds, currentStep]);

  const renderFields = (step: Step, idx: number): React.ReactElement | null => {
    const stepId = step.id;
    const isActive = activeStepId === stepId;

    return (
      <div key={idx} className={cn(preview && 'brand')}>
        {(() => {
          if (step.field?.field_type === 'start_loom') {
            return (
              <div id={`step-${stepId}`} key={stepId}>
                <StartLoom
                  position={step.field.position}
                  status={status}
                  button={button}
                  register={register}
                  isUnlimited={account.plan_features.unlimited_unmoderated || false}
                />
              </div>
            );
          } else if (step.field?.field_type === 'stop_loom') {
            return (
              <QuestionWrapper id={stepId}>
                <InsertLoom
                  status={status}
                  number={idx + 1}
                  button={button}
                  recordingId={loomRecordingId}
                  isActive={isActive}
                  onClick={isActive ? undefined : () => onFocus(stepId)}
                />
              </QuestionWrapper>
            );
          } else if (stepId === END_OF_SURVEY_WITHOUT_SUBMIT) {
            return (
              <QuestionWrapper id={stepId}>
                <NotQualifiedQuestion />
              </QuestionWrapper>
            );
          } else if (isEndOfSurvey(stepId)) {
            return (
              <QuestionWrapper id={stepId}>
                <NameAndIdQuestion
                  study={study}
                  number={idx + 1}
                  token={participation?.token as any}
                  external={!!participation?.external as boolean}
                  hasPrefillId={
                    (account.unique_id_attr == 'email' && (defaultValues.email || '').trim().length > 0) ||
                    (account.unique_id_attr == 'phone_number' && (defaultValues.phone_number || '').trim().length > 0)
                  }
                  hasOptedIn={!!participation?.opted_in}
                  account={account}
                  register={register}
                  watch={watch}
                  errors={errors}
                  onClick={!isActive ? () => onFocus(stepId) : undefined}
                  onFocus={!isActive ? () => onFocus(stepId) : undefined}
                  isActive={isActive}
                />
              </QuestionWrapper>
            );
          } else if (step.field) {
            return (
              <QuestionWrapper id={stepId}>
                <Question
                  number={idx + 1}
                  field={step.field}
                  register={register}
                  watch={watch}
                  setValue={(val) => step.field && setValue(step.field.id.toString(), val)}
                  errors={errors}
                  onClick={!isActive ? () => onFocus(stepId) : undefined}
                  onFocus={!isActive ? () => onFocus(stepId) : undefined}
                  isActive={isActive}
                  autofocus
                  control={control}
                />
              </QuestionWrapper>
            );
          }

          return null;
        })()}
      </div>
    );
  };

  const fields = steps.filter((s) => shownIds.includes(s.id)).map(renderFields);

  const currentStepValue =
    currentStep.field && hasFormKey(currentStep.field) ? watch(getFormKey(currentStep.field)) : null;

  const hideFooter = isRecordingStep || currentStep.id === END_OF_SURVEY_WITHOUT_SUBMIT;
  const hideContinue = currentStep.field?.field_type === 'task' && currentStep.field?.required && !currentStepValue;

  return (
    <div className='tablet:py-6 tablet:px-8 max-w-4xl pb-12 mx-auto'>
      {submitting && <Loading />}
      {page === 'unsupported' && <UnsupportedLoomPage />}
      {preview && page === 'survey' && (
        <Alert
          heading={`You are in preview mode, no data will be stored on submission`}
          cta={<Button onClick={restartSurvey}>Restart</Button>}
        />
      )}
      {preview && page === 'success' && <Alert heading='You are previewing the confirmation page' />}

      {page === 'survey' && (
        <form onSubmit={handleSubmit(onSubmit)}>
          {fields}

          {/* <ScreenSharingProvider>{fields}</ScreenSharingProvider> */}

          {!hideFooter && (
            <div className='tablet:flex-row tablet:p-0 flex flex-col items-center justify-end px-10'>
              <div className='flex-1'>
                {/* this is a little misleading if skip logic is employed... */}
                {!isEndOfSurvey(currentStep.id) && (
                  <span className='text-custom-brand-secondary'>
                    {t('step_count', { current: countableShown, total: totalSteps })}
                  </span>
                )}
              </div>
              {preview && isEndOfSurvey(currentStep.id) && (
                <span className='justify-self-end text-custom-brand-secondary mr-4'>No data will be stored</span>
              )}
              {!hideContinue && (
                <Button
                  className='tablet:w-auto tablet:mb-0 btn-custom-brand w-full mb-4'
                  type='submit'
                  noStyle
                  primary={isEndOfSurvey(currentStep.id)}
                  secondary={!isEndOfSurvey(currentStep.id)}
                  aria-label='Continue'
                >
                  {isEndOfSurvey(currentStep.id) ? t('submit') : t('continue')}
                </Button>
              )}
            </div>
          )}
          {globalError && (
            <div role='alert' aria-live='assertive' className='text-sm text-center text-red-600'>
              Something went wrong and your answers were not submitted.
              <br />
              Please try again later or contact {SUPPORT_EMAIL}.
            </div>
          )}
        </form>
      )}
      {page === 'success' && <ConfirmationPage screener={screener} participation={participation} account={account} />}
      {resetModal == 'early' && (
        <Modal
          title={t('stopped_early_modal_title')}
          hideClose
          icon='danger'
          size='md'
          renderFooter={() => (
            <Button
              onClick={() => {
                setShowResetModal(null);
                restartSurvey();
              }}
              noStyle
              className='btn-custom-brand'
            >
              {t('restart_test')}
            </Button>
          )}
        >
          {t('stopped_early_modal_text')}
        </Modal>
      )}
      {resetModal == 'canceled' && (
        <Modal
          title={t('canceled_recording_modal_title')}
          hideClose
          size='md'
          icon='danger'
          renderFooter={() => (
            <Button
              primary
              onClick={() => {
                setShowResetModal(null);
                restartSurvey();
              }}
            >
              {t('restart_test')}
            </Button>
          )}
        >
          {t('canceled_recording_modal_text')}
        </Modal>
      )}
    </div>
  );
};
