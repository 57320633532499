import { Text, Toggle } from 'components/common';
import React from 'react';
import pluralize from 'pluralize';

import { Batching } from '../types';

type BatchFieldsProps = {
  batch: Batching;
  event: string;
  setBatch: (batch: Batching) => void;
  target?: boolean;
  title?: string;
};

export const BatchFields: React.FC<BatchFieldsProps> = ({ batch, event, setBatch, target = false, title = 'messages' }) => (
  <div className='form-group flex flex-col mt-6 mb-6'>
    <div className='flex flex-row space-x-2'>
      <Toggle name='batch_on' on={batch.on} onToggle={(v) => setBatch({ ...batch, on: v })} />
      <div className='flex items-center mt-1 mb-2'>
        <Text h='400' bold>
          Send {title} in batches
        </Text>
      </div>
    </div>
    {batch.on && (
      <>
        <div className='ml-14 flex items-center w-full mb-2'>
          <input
            type='number'
            name='batch_size'
            value={batch.size}
            onChange={(e) => setBatch({ ...batch, size: parseInt(e.currentTarget.value) })}
            className='focus:ring-indigo-500 focus:border-indigo-500 no_arrows w-12 px-0 text-center border-gray-300 rounded-md shadow-sm'
          />
          <span className='px-1.5'>{title} sent every</span>
          <input
            type='number'
            name='batch_wait_hours'
            value={batch.wait_hours}
            onChange={(e) => setBatch({ ...batch, wait_hours: parseInt(e.currentTarget.value) })}
            className='focus:ring-indigo-500 focus:border-indigo-500 no_arrows w-12 px-0 text-center border-gray-300 rounded-md shadow-sm'
          />
          <span className='px-1.5'>hours</span>
        </div>
        {target && (
          <div className='ml-14 flex items-center w-full'>
            <span className='px-1.5'>Until</span>
            <input
              type='number'
              name='batch_target'
              value={batch.target}
              onChange={(e) => setBatch({ ...batch, target: parseInt(e.currentTarget.value) })}
              className='focus:ring-indigo-500 focus:border-indigo-500 no_arrows w-12 px-0 text-center border-gray-300 rounded-md shadow-sm'
            />
            <span className='px-1.5'>{pluralize(event)} are completed</span>
          </div>
        )}
        <div className='ml-14 flex w-full'>
          <p className='mt-2 text-sm text-gray-600'>Note: Participants will be selected at random.</p>
        </div>
      </>
    )}
  </div>
);
