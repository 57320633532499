import { useToaster } from 'components/stores/toaster';
import * as toasts from 'components/StudiesApp/components/StudyDraft/toasts';
import consumer from 'helpers/consumer';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { VideoUrl } from '@lib/video-url/VideoUrl';
import { getConnectZoomButtonProps } from '@components/getConnectZoomButtonProps';

import { api } from '@api/reduxApi';
import { Dropdown, Input, Radio, Text, Tooltip, Modal, Button } from '@components/common';
import { StudyLocationInput } from '@components/StudyLocationInput';
import { ErrorStateSVG } from '@components/svgs';
import { useDisabledFeatures } from '@hooks/useDisabledFeatures';

import { useUpdateStudyCalendarMutation, useGetMeetingProvidersQuery } from '../api';

import { MeetingProviderOption } from './MeetingProviders/MeetingProviderOption';
import { MeetingProviderDropdownContent } from './MeetingProviders/MeetingProviderDropdownContent';
import { useFeature } from 'hooks/useFeature';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { EmailCheckAlerts } from 'components/shared/BookabilityAlerts';

interface Props {
  disabled?: boolean;
  user: User;
  study: Study;
  onChange: (value: Partial<Study>) => void;
  addDebounce?: boolean;
  calendar?: StudyCalendar;
}

const DEBOUNCE_RATE = process.env.NODE_ENV === 'test' ? 10 : 300;

const ConnectedAccountsChannel = (user_id) =>
  consumer.subscriptions.create(
    {
      channel: 'Users::ConnectedAccountsChannel',
      user_id: user_id
    },
    {}
  );

const ConnectedAccountCreatedChannel = (user_id) =>
  consumer.subscriptions.create(
    {
      channel: 'Users::ConnectedAccountCreatedChannel',
      user_id: user_id
    },
    {}
  );

export const VideoURLInput: React.FC<Props> = ({ calendar, disabled, user, study, onChange, addDebounce }) => {
  const { owner } = study;
  const [open, setOpen] = useState(false);
  const [videoUrlType, setVideoUrlType] = useState<Study['video_url_type'] | null>(
    study.video_url_type === 'manual' && !study.video_url ? null : study.video_url_type
  );
  const [videoUrl, setVideoUrl] = useState<VideoUrl>(new VideoUrl(study.video_url));
  const [liveStreamEnabled, setLiveStreamEnabled] = useState<boolean>(calendar?.live_stream_enabled || false);
  const [liveStreamModal, setLiveStreamModal] = useState<boolean>(false);
  const [updateStudyCalendar, { isError: calendarUpdateError }] = useUpdateStudyCalendarMutation();
  const { data: meetingProviderOptions, refetch } = useGetMeetingProvidersQuery({ studyId: study.id });
  const debouncedOnChange = useDebouncedCallback(onChange, DEBOUNCE_RATE);

  const hasStudyEmailAlerts = useFeature('call_url_alerts');

  const { data: conferenceCheck, refetch: recheck } = api.useGetStudyConferenceValidityQuery(
    { studyId: study.id },
    { skip: !hasStudyEmailAlerts }
  );

  const showToast = useToaster();

  const [selectedMeetingProvider, setSelectedMeetingProvider] = useState<MeetingProvider | undefined>();
  const isOwner = owner?.id === user.id;

  const disabledFeatures = useDisabledFeatures();

  useEffect(() => {
    if (meetingProviderOptions) {
      setSelectedMeetingProvider(meetingProviderOptions?.find((o) => o.video_url_type === videoUrlType));
    }
  }, [meetingProviderOptions]);

  useEffect(() => {
    if (calendarUpdateError) {
      showToast(toasts.failedUpdate());
    }
  }, [calendarUpdateError]);

  useEffect(() => {
    setLiveStreamEnabled(calendar?.live_stream_enabled || false);
    if (hasStudyEmailAlerts) {
      recheck();
    }
  }, [calendar?.live_stream_enabled]);

  useEffect(() => {
    if (hasStudyEmailAlerts) {
      recheck();
    }
  }, [study.video_url, study.video_url_type]);

  useEffect(() => {
    ConnectedAccountCreatedChannel(owner?.id).received = (data) => {
      if (data.message) {
        refetch();
        if (hasStudyEmailAlerts) {
          recheck();
        }
      }
    };

    ConnectedAccountsChannel(owner?.id).received = (data) => {
      if (data.message) {
        refetch();
        if (hasStudyEmailAlerts) {
          recheck();
        }
      }
    };
  }, []);

  const onUrlChange = (v) => {
    setVideoUrl(new VideoUrl(v));
    addDebounce ? debouncedOnChange.callback({ video_url: v }) : onChange({ video_url: v });
  };

  const handleOptionClick = (option) => {
    return (e) => {
      e.preventDefault();

      updateLiveStreamEnabled(false);
      setVideoUrlType(option.video_url_type);

      let videoUrl: string | null = null;

      if (option.video_url_type === 'manual') {
        videoUrl = study.video_url || owner?.default_video_url || null;
      }

      onChange({ video_url_type: option.video_url_type, video_url: videoUrl });

      setSelectedMeetingProvider(option);

      setOpen(false);
    };
  };

  const updateLiveStreamEnabled = (v: boolean) => {
    updateStudyCalendar({
      studyId: study.id,
      calendar: { live_stream_enabled: v }
    });

    setLiveStreamEnabled(v);
  };

  const liveStreamAvailable = useMemo(() => {
    return (
      videoUrlType === 'zoom' ||
      videoUrlType === 'webex' ||
      videoUrlType === 'microsoft_teams' ||
      videoUrlType === 'google_meet' ||
      (videoUrlType === 'manual' && videoUrl.isSupported())
    );
  }, [videoUrlType, videoUrl]);

  const dropdownContent = <MeetingProviderDropdownContent option={selectedMeetingProvider} />;

  return (
    <>
      <Dropdown
        disabled={disabled || !meetingProviderOptions}
        isOpen={open}
        primary={!videoUrlType}
        text={dropdownContent}
        className='w-full'
        menuClassName='w-full'
        buttonClassName='justify-self-start w-full'
        onClick={() => setOpen(!open)}
        onClose={() => setOpen(false)}
      >
        {meetingProviderOptions &&
          meetingProviderOptions.length > 0 &&
          meetingProviderOptions.map((option) => (
            <MeetingProviderOption
              key={option.name}
              option={option}
              onClick={handleOptionClick(option)}
              isOwner={isOwner}
            />
          ))}
      </Dropdown>

      {videoUrlType === 'manual' && (
        <Input
          disabled={disabled}
          name='video_url'
          className='mt-2'
          placeholder='e.g Zoom, Hangouts'
          value={videoUrl.toString() || ''}
          onChange={onUrlChange}
          size='lg'
        />
      )}
      {videoUrlType === 'in_person' && <StudyLocationInput study_id={study.id} />}
      {owner?.zoom_invalid && owner.id === user.id && (
        <>
          <ErrorStateSVG className='inline w-4 h-4 mr-1' />
          <p className='relative inline text-sm' style={{ top: '2px', left: '2px' }}>
            Please&nbsp;
            <a {...getConnectZoomButtonProps()}>re-authorize</a>
            &nbsp;your Zoom account
          </p>
        </>
      )}

      {liveStreamModal && (
        <Modal
          size='md'
          onClose={() => setLiveStreamModal(false)}
          title={`${liveStreamEnabled ? 'Disable' : 'Enable'} Observer live streams`}
          renderFooter={() => (
            <>
              <Button onClick={() => setLiveStreamModal(false)}>
                Don't {liveStreamEnabled ? 'disable' : 'enable'}
              </Button>
              <Button
                primary
                onClick={() => {
                  setLiveStreamEnabled(!liveStreamEnabled);
                  updateLiveStreamEnabled(!liveStreamEnabled);
                  setLiveStreamModal(false);
                }}
              >
                Confirm & {liveStreamEnabled ? 'disable' : 'enable'}
              </Button>
            </>
          )}
        >
          This will apply for all new interviews that get scheduled on this study.
        </Modal>
      )}

      {liveStreamAvailable && !study.focus_group && !disabledFeatures.livestream && (
        <div className='mt-4'>
          <Radio
            type='checkbox'
            checked={liveStreamEnabled}
            id='live_stream_enabled'
            name='live_stream_enabled'
            disabled={study.focus_group}
            onChange={() => {
              if (study.state == 'active') {
                setLiveStreamModal(true);
              } else {
                updateLiveStreamEnabled(!liveStreamEnabled);
              }
            }}
            label='Live stream for Observers'
          >
            <Tooltip>
              <>
                <p className='mb-2'>
                  When checked, the Great Question Bot will join your call with the participant and it will be live
                  streamed in a separate room for observers to watch. The participant won't be able to see the observers
                  viewing the live stream.
                </p>
                <p>
                  The bot will join the call at the interview time and wait 15 minutes, if the call does not start the
                  bot will leave the call.
                </p>
              </>
            </Tooltip>
          </Radio>
        </div>
      )}

      {hasStudyEmailAlerts && conferenceCheck && (
        <ErrorBoundary>
          <div className='mt-4'>
            <EmailCheckAlerts study={study} emailCheck={conferenceCheck} />
          </div>
        </ErrorBoundary>
      )}
    </>
  );
};
