import { Input, Select } from 'components/common';
import getSymbolFromCurrency from 'currency-symbol-map';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';

import { CURRENCY_OPTIONS } from '../constants';
import { toPositiveNumber } from '@components/utils';

interface InputWithDropdownProps {
  amount: number;
  setAmount: (amount: number) => void;
  disableCurrency?: boolean;
  currency: Study['currency']; // We
  setCurrency: (currency: Study['currency']) => void;
  error?: boolean;
  disabled?: boolean;
  onBlur?: () => void;
  onEnter?: () => void;
}
export const InputWithDropdown: React.FC<InputWithDropdownProps> = ({
  amount,
  setAmount,
  disableCurrency,
  currency,
  setCurrency,
  error,
  disabled,
  onBlur,
  onEnter
}) => {
  const symbol = getSymbolFromCurrency(currency) || '$';
  const currencyOptions = CURRENCY_OPTIONS.map(({ code, label, countryCode }) => ({
    label,
    value: code,
    data: { countryCode }
  }));

  return (
    <div className='border-200 relative rounded-md'>
      <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
        <span className='h400 text-gray-500'>{symbol}</span>
      </div>
      <Input
        disabled={disabled}
        error={error}
        type='number'
        onWheel={(e) => e.currentTarget.blur()}
        name='incentive_amount'
        data-testid='incentive-amount'
        onKeyDown={(e) => e.key === 'Enter' && onEnter?.()}
        onBlur={() => onBlur?.()}
        value={amount ? amount.toString() : ''}
        onChange={(v) => setAmount(toPositiveNumber(v))}
        className='focus:ring-indigo-500 focus:border-indigo-500 pl-7 h400 no_arrows block w-full h-10 pr-12 border-gray-200 rounded-md'
      />
      <div className='absolute inset-y-0 right-0 flex items-center'>
        {disableCurrency ? (
          <div className='text-sm py-2.5 px-4'>{currency}</div>
        ) : (
          <Select
            noBorder
            className='hover:border-gray-200 bg-transparent border border-l-0 border-transparent rounded-l-none'
            syncWidths={false}
            disabled={disableCurrency || disabled}
            ulClassName='w-60 right-0'
            value={currency}
            onChange={setCurrency}
            options={currencyOptions}
            renderSelected={(option) => option.value}
            popperOptions={{ placement: 'bottom-end' }}
            renderLabel={(option) => (
              <span className='h600 flex flex-row items-center'>
                <ReactCountryFlag countryCode={option.data?.countryCode} className='flex-0' />
                <span className='h400 flex-1 ml-3'> {option.label}</span>
                <span className='group-hover:text-white h400 font-bold text-gray-500'>
                  {getSymbolFromCurrency(option.value)} {option.value}
                </span>
              </span>
            )}
          />
        )}
      </div>
    </div>
  );
};
