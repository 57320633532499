import { useState } from 'react';

type Hook<T> = [T | null, (value: T | null) => void];

export const useLocalStorage = <T = any>(key: string): Hook<T> => {
  const [storedValue, setStoredValue] = useState<T | null>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item as any) : null;
    } catch (error) {
      return null;
    }
  });
  const setValue = (value: T | null) => {
    setStoredValue(value);
    try {
      if (value === null) {
        window.localStorage.removeItem(key);
      } else {
        window.localStorage.setItem(key, JSON.stringify(value));
      }
    } catch (e) {
      //
    }
  };
  return [storedValue, setValue];
};
