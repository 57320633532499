import React, { useState } from 'react';
import { Button, Text } from '@components/common';
import { ChevronDownSVG } from '@components/svgs';
import cn from 'classnames';
import { slugify } from '@components/utils';
import { Filter, FilterInput, FilterStateLookup } from '../components/FilterInput';

interface Props {
  renderInput?: () => React.ReactNode;
  icon: React.FC;
  title: string;
  subtitle: string;
  renderError?: () => React.ReactNode;
  object?: ExternalCandidatesFilterListResponse;
  newRequest: boolean;
  disabled: boolean;
  storeFilterValues: (filters: Filter[]) => void;
  setChanged: (changed: boolean) => void;
  filters: FilterStateLookup;
  storeFilterRange: (range: Filter[]) => void;
  limit?: number;
  allOptionValue?: string;
}

const getFilterValues = ({ filters, filtersObject, filter_id }) => {
  const totalSelectedValues = filters[filter_id]?.selected_values?.length;
  const totalOptions = Object.keys(filtersObject?.choices || []).length;

  if (filtersObject?.all_option && (totalSelectedValues === totalOptions || !totalSelectedValues)) {
    return filtersObject.all_option;
  }

  return filters[filter_id]?.selected_values?.map((v) => filtersObject?.choices?.[v]).join(', ') || '';
};

const DEFAULT_EXPANDED_FILTERS: FilterId[] = ['targetJobTitles', 'targetTopics', 'marketType', 'targetIndustries'];

export const AudienceField: React.FC<Props> = ({
  object,
  filters,
  newRequest,
  disabled,
  storeFilterValues,
  storeFilterRange,
  setChanged,
  subtitle,
  renderError,
  renderInput,
  icon: Icon,
  title,
  limit,
  allOptionValue
}) => {
  const couldBeOpenByDefault = DEFAULT_EXPANDED_FILTERS.includes(object?.filter_id as FilterId);
  const defaultOpen =
    (newRequest || filters[object?.filter_id as FilterId]?.selected_values?.length === 0) && couldBeOpenByDefault;

  const [open, setOpen] = useState(defaultOpen);

  const values = getFilterValues({ filters, filtersObject: object, filter_id: object?.filter_id });

  if (!object) return null;

  return (
    <div className='flex items-start py-4 border-b border-gray-200'>
      <div className='flex flex-shrink-0 items-center justify-center p-1'>
        <Icon />
      </div>
      <div className='ml-4 flex-1 flex-shrink-0'>
        <div className='flex justify-between items-center flex-1'>
          <div>
            <Text bold>{title}</Text>
            {!open && values && (
              <Text h='400' className='mt-1'>
                {values}
              </Text>
            )}
            {open && (
              <Text h='400' className='mt-1'>
                {subtitle}
              </Text>
            )}
          </div>
          <Button
            className={`xx-toggle-${slugify(title)} rounded-full pt-3 pr-3 pb-3 pl-3`}
            text
            aria-label={title}
            onClick={() => setOpen(!open)}
          >
            <ChevronDownSVG className={cn('w-4 h-4 transform', { ['rotate-180']: open })} />
          </Button>
        </div>
        {!open && renderError?.()}
        {open && renderInput && <div className='mt-2'>{renderInput()}</div>}
        {open && !renderInput && object && (
          <FilterInput
            limit={limit}
            disabled={disabled}
            filters={filters}
            storeFilterValues={storeFilterValues}
            storeFilterRange={storeFilterRange}
            setChanged={setChanged}
            object={object}
            allOptionValue={allOptionValue}
          />
        )}
        {open && renderError?.()}
      </div>
    </div>
  );
};
