import cn from 'classnames';
import { useCombobox } from 'downshift';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { api } from '@api/reduxApi';
import { Button, Input, Spinner } from '@components/common';
import { CaretDownSVG, PersonSVG } from '@components/svgs';
import { usePopUp } from '@hooks/usePopUp';
import Tippy from '@tippyjs/react';

interface Props {
  disabled?: boolean;
  editable: boolean;
  candidate?: Candidate | null;
  onCandidateClick?: () => void;
  onSetCandidate: (candidate: Candidate) => void;
}

const DEBOUNCE_TIME = 300;

export const Participant: React.FC<Props> = ({ disabled, editable, candidate, onCandidateClick, onSetCandidate }) => {
  const { PopUp, ref, open, togglePopUp } = usePopUp();

  const [value, setValue] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const { data: fetchedData, isLoading } = api.useGetServerSideCandidatesQuery({
    items: 50,
    page: 1,
    searchQuery
  });
  const { callback: debouncedSetSearchQuery } = useDebouncedCallback(setSearchQuery, DEBOUNCE_TIME);
  useEffect(() => {
    if (value === '') {
      setSearchQuery('');
    } else {
      debouncedSetSearchQuery(value);
    }
  }, [value]);
  const shouldShowSpinner = value !== searchQuery || isLoading;

  const results: Candidate[] = fetchedData ? fetchedData.data : [];

  const items = useMemo(
    () => results.map(({ name, id }) => ({ label: name || 'Unnamed candidate', value: id })) || [],
    [results]
  );

  const {
    highlightedIndex,
    selectedItem,
    getToggleButtonProps,
    getComboboxProps,
    getMenuProps,
    getInputProps,
    getItemProps
  } = useCombobox({
    isOpen: true,
    items,
    itemToString: (item) => (item ? item.label : ''),
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        const candidate = results.find((i) => i.id === selectedItem.value);
        if (candidate) {
          onSetCandidate(candidate);
          togglePopUp();
        }
      }
    }
  });

  const shouldInputBeInline = !candidate;

  const inputEl = (
    <Input
      {...getInputProps({ disabled }, { suppressRefError: true })}
      autoFocus
      icon={shouldInputBeInline ? undefined : 'search'}
      className={cn('h400 w-full', {
        'border-0 py-0 ring-0': shouldInputBeInline
      })}
      value={value}
      onChange={setValue}
      placeholder='Search…'
    />
  );

  return (
    <div ref={ref} className='relative flex items-center space-x-2'>
      <div {...getComboboxProps({ disabled })}>
        {editable && (
          <div className='flex items-center'>
            <button
              {...getToggleButtonProps()}
              className={cn('flex items-center space-x-2 px-2.5 py-1.5 h400 whitespace-nowrap', {
                'border-gray-200 border rounded-md': editable && !shouldInputBeInline
              })}
              onClick={togglePopUp}
            >
              <PersonSVG className='flex-shrink-0' />
              {candidate && <span className='truncate'>{candidate.name || 'Unnamed candidate'}</span>}
              {!candidate && !open && <span className='text-gray-400 truncate'>Add participant</span>}
              {open && shouldInputBeInline && inputEl}
              {!shouldInputBeInline && <CaretDownSVG className='flex-shrink-0' />}
            </button>
            {shouldShowSpinner && <Spinner className='w-4 h-4' />}
          </div>
        )}
        {!editable && (
          <div className='flex items-center space-x-2 px-2.5 py-1.5 h400 whitespace-nowrap'>
            <PersonSVG />
            {candidate && (
              <Tippy content='View profile' arrow={false}>
                <span
                  onClick={onCandidateClick}
                  className='xx-session-candidate cursor-pointer whitespace-nowrap hover:text-indigo-500'
                >
                  {candidate.name || 'Unnamed candidate'}
                </span>
              </Tippy>
            )}
            {!candidate && <span className='text-gray-200'>No candidate set</span>}
          </div>
        )}

        <PopUp open={open} className='w-80 top-8 left-0 mt-2 bg-white border border-gray-200 rounded-md shadow-lg'>
          <div>
            {!shouldInputBeInline && (
              <>
                <div className='p-4'>{inputEl}</div>
                <div className='border-b border-gray-200' />
              </>
            )}
            <div className='p-4'>
              <ul {...getMenuProps({ disabled, className: 'max-h-60 overflow-y-scroll' }, { suppressRefError: true })}>
                {items.map((item, index) => (
                  <li
                    key={`${item.label}${index}`}
                    {...getItemProps({ disabled, item, index })}
                    className={cn('p-1 h400', {
                      'bg-gray-50': highlightedIndex === index,
                      'text-indigo-600': selectedItem === item
                    })}
                  >
                    {item.label}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </PopUp>
      </div>
    </div>
  );
};
