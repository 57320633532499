import { objectAssign } from '@helpers/objectAssign';
import { Cell, Header } from '@tanstack/react-table';

import { GetKeyOf, OnUpdateCellParams } from '../types';

export const updateCellData = <D extends Record<string, any>>(
  data: D[],
  { rowIndex, columnId, value }: OnUpdateCellParams<GetKeyOf<D, 'extra'>>
): D[] => data.map((row, index) => (index === rowIndex ? objectAssign<D>(data[rowIndex], columnId, value) : row));

export const isEmpty = (value: any) => value == null || value === '';

export const getPreviousCellSize = <D extends Record<string, any>>(
  cells: Cell<D, unknown>[] | Header<D, unknown>[],
  cell: Cell<D, unknown> | Header<D, unknown>
) => {
  const index = cells.findIndex(({ id }) => id === cell.id);

  if (index === 0) return 0;

  return cells[index - 1].column.getSize();
};
