import cn from 'classnames';
import * as React from 'react';
import { useState } from 'react';

import { Text } from '@components/common';
import { DropdownCombobox, DropdownItem, RenderDropdownItem } from '@components/common/DropdownCombobox';
import { NewAttrModal } from '@components/shared/NewAttrModal';
import { CaretDownSVG } from '@components/svgs';
import { usePermission } from '@hooks/usePermission';

interface Props {
  className?: string;
  disabled?: boolean;
  defaultLabel: string;
  isErrored?: boolean;
  attrs: Attr_[];
  value: string;
  onChange: (value: string) => Promise<void>;
}

const ComboboxItem: RenderDropdownItem = (item, isHighlighted) => (
  <div className='flex flex-col space-y-1'>
    {item?._isNew ? (
      <>
        <span
          className={cn({ 'text-white': isHighlighted, 'text-indigo-600': !isHighlighted })}
        >{`”${item?.value}”`}</span>
        <span className='text-xs'>Save this as a new attribute</span>
      </>
    ) : (
      <span className='text-sm'>{item?.label}</span>
    )}
  </div>
);

const Input = (inputProps: React.HTMLProps<HTMLInputElement>): React.ReactElement => (
  <input
    {...inputProps}
    className='min-w-48 w-full h-8 px-3 text-sm text-gray-700 placeholder-gray-400 border border-gray-200 rounded-md'
    type='text'
    placeholder='Select or create…'
    autoFocus
    autoComplete='off'
    name='dropdown_combobox'
    style={{ outline: 'none', outlineOffset: 'none', boxShadow: 'none' }}
  />
);

export const SelectAttribute: React.FC<Props> = ({
  className = '',
  defaultLabel: initialDefaultLabel,
  disabled,
  isErrored,
  attrs,
  value,
  onChange
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selecting, setSelecting] = useState(false);
  const [defaultLabel, setDefaultLabel] = useState(initialDefaultLabel);

  const canCreate = usePermission('manageAttrs')();

  const options = attrs
    .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1))
    .map(({ name, label }) => ({ label, value: name }));

  const selectedItem = options.find((o) => o.value === value);

  const onSelect = (attr: DropdownItem) => {
    if (attr._isNew) {
      setDefaultLabel(attr.value);
      setModalOpen(true);
    } else {
      onChange(attr.value);
    }
    setSelecting(false);
  };

  return (
    <div className={cn(className, { 'bg-gray-50': disabled })}>
      {selecting ? (
        <DropdownCombobox
          allowCreate={canCreate}
          unlimitedItems
          items={options}
          selectedItem={selectedItem}
          placeholder='Select or create…'
          onClickOutside={() => setSelecting(false)}
          onSelect={onSelect}
          renderInput={Input}
          renderItem={ComboboxItem}
        />
      ) : (
        <button
          disabled={disabled}
          className={cn(
            'flex h400 w-full truncate min-w-48 justify-between items-center rounded-md px-3 h-8 border border-gray-200 outline-none hover:bg-gray-50',
            {
              'text-gray-400 cursor-default': disabled,
              'border-red-600': isErrored
            }
          )}
          onClick={() => setSelecting(true)}
        >
          <Text as='span' color={selectedItem ? undefined : 'gray-400'} h='400'>
            {selectedItem?.label || 'Select or create…'}
          </Text>
          <CaretDownSVG className='ml-2' />
        </button>
      )}

      <NewAttrModal
        defaultLabel={defaultLabel}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSubmit={async (attr: Attr_) => {
          await onChange(`extra:${attr.name}`);
        }}
      />
    </div>
  );
};
