import { NO_OUTLINE } from 'components/CandidateAttrs';
import * as React from 'react';
import { createContext, forwardRef, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';

import { Text } from '@components/common';
import Tippy from '@tippyjs/react';
import { LinkSVG } from '@components/svgs';
import { useOnClickOutside, useOnEscape } from '@components/utils';
import cn from 'classnames';
import copy from 'copy-to-clipboard';
import { useToaster } from '@stores/toaster';
import { track } from '@components/tracking';

type Size = 'md' | 'lg' | 'xl' | '2xl';

interface FrameProps {
  size: Size;
  zIndex: string;
}
export const SlideOutFrame = React.forwardRef<HTMLDivElement, PropsWithChildren<FrameProps>>(
  ({ size, zIndex, children }, ref) => (
    <div
      data-testid='slideout'
      className={`xx-slideout-frame fixed inset-0 z-${zIndex} overflow-hidden transition-all bg-gray-700 bg-opacity-50`}
    >
      <div ref={ref} className='absolute inset-0 overflow-hidden'>
        <section className='tablet:pl-16 absolute inset-y-0 right-0 flex max-w-full pl-10'>
          <div className={`w-screen h-screen max-w-${size}`}>{children}</div>
        </section>
      </div>
    </div>
  )
);

const NonBlockingSlideoutFrame = React.forwardRef<HTMLDivElement, PropsWithChildren<FrameProps>>(
  ({ size, children }, ref) => (
    <div
      data-testid='slideout'
      className={`xx-slideout-frame fixed inset-y-0 right-0 shadow-lg max-w-${size} w-full z-40 transition-all bg-opacity-50`}
    >
      <section ref={ref} className='absolute inset-y-0 right-0 flex max-w-full'>
        <div className={`w-screen max-w-${size}`}>{children}</div>
      </section>
    </div>
  )
);

const SlideOutFooter: React.FC<{ size: Size }> = ({ children, size }) => (
  <div className={`px-6 py-4 flex flex-shrink-0 w-full max-w-${size} bg-white border-t border-gray-200`}>
    {children}
  </div>
);

interface InnerProps {
  noHeader?: boolean;
  children?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: string;
  onClose?: () => void;
  className?: string;
  showCopyLink?: boolean;
  copyLink?: string;
}

const SlideOutBody = forwardRef<HTMLDivElement, InnerProps>(
  ({ className = 'overflow-y-auto', noHeader, title, subtitle, onClose, children, showCopyLink, copyLink }, ref) => {
    const showToast = useToaster();
    return (
      <div style={NO_OUTLINE} className={cn('flex flex-col flex-grow bg-white shadow-xl', className)} ref={ref}>
        {!noHeader && (
          <header className='px-6 py-4'>
            <div className='flex items-start justify-between space-x-3'>
              <div className=''>
                {typeof title === 'string' ? (
                  <h2 className='text-lg font-bold leading-7 text-gray-900'>{title}</h2>
                ) : (
                  title
                )}
                {subtitle && (
                  <Text h='400' className='mt-1' color='gray-500'>
                    {subtitle}
                  </Text>
                )}
              </div>
              <div className='h-7 flex items-center'>
                {showCopyLink && (
                  <Tippy content='Copy link' arrow={false}>
                    <button
                      className='hover:bg-indigo-100 hover:text-indigo-600 focus:outline-none p-1 bg-white rounded-full'
                      disabled={copyLink == null}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (copyLink != null) {
                          copy(copyLink);
                          showToast({
                            heading: 'Success!',
                            text: 'Link copied to clipboard',
                            icon: 'success'
                          });
                          track('copied_link_to_artifact', { copyLink });
                        }
                      }}
                    >
                      <LinkSVG />
                    </button>
                  </Tippy>
                )}
                <button
                  onClick={onClose}
                  aria-label='Close panel'
                  className='xx-close-slideout hover:text-gray-500 ml-1 text-gray-400 cursor-pointer'
                >
                  <svg className='w-6 h-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12' />
                  </svg>
                </button>
              </div>
            </div>
          </header>
        )}
        {noHeader && onClose && (
          <button
            onClick={onClose}
            aria-label='Close panel'
            className='xx-close-slideout hover:text-gray-500 top-5 right-5 absolute z-10 text-gray-400 cursor-pointer'
          >
            <svg className='w-6 h-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12' />
            </svg>
          </button>
        )}
        <div className='flex flex-col flex-grow'>{children}</div>
      </div>
    );
  }
);

interface Context {
  childModalOpen: boolean;
  setChildModalOpen: any;
}

const SlideOutContext = createContext<Context>({} as Context);

export const useSlideOutContext = () => {
  const slideoutContext = useContext<Context>(SlideOutContext);

  if (slideoutContext === undefined) throw new Error();

  return slideoutContext;
};

interface Props {
  noHeader?: boolean;
  title?: React.ReactNode;
  subtitle?: string;
  closeOnEsc?: boolean;
  nonBlocking?: boolean;
  renderFooter?: () => React.ReactNode;
  size?: Size;
  onClose?: () => void;
  zIndex?: string;
  autoFocus?: boolean;
  className?: string;
  showCopyLink?: boolean;
  copyLink?: string;
}
export const SlideOut: React.FC<Props> = ({
  noHeader,
  children,
  title,
  subtitle,
  renderFooter,
  nonBlocking = false,
  closeOnEsc = true,
  size = 'md',
  zIndex = '40',
  onClose,
  autoFocus,
  className,
  showCopyLink,
  copyLink
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const frameRef = useRef<HTMLDivElement>(null);

  const [childModalOpen, setChildModalOpen] = useState(false);

  useOnClickOutside(ref, (e) => {
    if (e.target === frameRef.current) {
      onClose?.();
    }
  });

  const handler = ({ key }) => {
    if (closeOnEsc && key === 'Escape' && !childModalOpen) {
      onClose?.();
    }
  };

  useEffect(() => {
    if (autoFocus) {
      bodyRef.current?.focus();
    }
  }, []);

  useOnEscape(handler, [childModalOpen, onClose]);

  const Frame = nonBlocking ? NonBlockingSlideoutFrame : SlideOutFrame;

  return (
    <Frame size={size} zIndex={zIndex} ref={frameRef}>
      <div className='relative flex flex-col h-full' ref={ref}>
        <SlideOutBody
          className={className}
          ref={bodyRef}
          noHeader={noHeader}
          title={title}
          subtitle={subtitle}
          onClose={onClose}
          showCopyLink={showCopyLink}
          copyLink={copyLink}
        >
          <SlideOutContext.Provider value={{ childModalOpen, setChildModalOpen }}>{children}</SlideOutContext.Provider>
        </SlideOutBody>

        {renderFooter && <SlideOutFooter size={size}>{renderFooter()}</SlideOutFooter>}
      </div>
    </Frame>
  );
};
