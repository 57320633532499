import * as React from 'react';

import { Alert, Button, Text } from '@components/common';
import { ButtonProps } from '@components/common/Button';

type Props = {
  level: BookabilityCase['level'];
  heading: string;
  message: string;
  ctas: ButtonProps[];
};

export const BookabilityRuleAlert: React.VFC<Props> = ({ level, heading, message, ctas }) => (
  <Alert type={level}>
    <Text bold mb='2'>
      {heading}
    </Text>
    <Text h='400' mb={ctas.length > 0 ? '2' : '0'}>
      {message}
    </Text>
    <div className='flex space-x-4'>
      {ctas.map((buttonProps, i) => (
        <Button key={i} small className='h400' icon='externalLink' target='_blank' {...buttonProps} />
      ))}
    </div>
  </Alert>
);
