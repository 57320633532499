import cn from 'classnames';
import { RestrictedAction } from 'components/shared/RestrictedAction';
import * as React from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Avatar, PopperDropdown, PopperDropdownLink, Text } from '@components/common';
import { CTA } from '@components/Sidebar/CTA';
import { AskAiSVG, CaretDownSVG, CaretSVG, CogSVG, LogoExpandSVG } from '@components/svgs';
import { SignOutSVG } from '@components/svgs/SignOutSVG';
import { SwitchAccountsSVG } from '@components/svgs/SwitchAccountsSVG';
import { useAccount } from '@hooks/useAccount';
import { useDeviceType } from '@hooks/useDeviceType';
import { useFeature } from '@hooks/useFeature';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { usePermission } from '@hooks/usePermission';
import { usePlan } from '@hooks/usePlan';
import { useUser } from '@hooks/useUser';
import Tippy from '@tippyjs/react';

import { NAV_SHORTCUT_DEFINITIONS as SHORTCUTS } from './consts';
import { useSidebar } from './context';
import { NavItem } from './NavItem';
import * as svgs from './svgs';
import { Teams } from './Teams';

const newTabs: string[] = ['Reporting'];

export const Sidebar: React.FC = () => {
  const [mouseOver, setMouseOver] = useState(false);
  const { isMobile, isTablet } = useDeviceType();
  const sidebar = useSidebar();
  const { account } = useAccount();
  const user = useUser();
  const canCreate = usePermission('createStudy');
  const isAdmin = usePermission('isAdmin')();
  const hasSwitcher = useFeature('account_switcher');
  const { hasFeature, subscribed } = usePlan();
  const { pathname } = useLocation();
  const enableTeams = useFeature('teams');
  const hasAiChat = account.ai_enabled;

  const [hiddenNewLabels, setHiddenNewLabels] = useLocalStorage<string[]>('sidebar-hidden-new-labels');

  const className = cn('bg-indigo-1000 flex-col w-full h-full', {
    'absolute transition-left': sidebar.fixed && !isMobile,
    hidden: sidebar.hidden,
    'desktop:flex flex': !sidebar.hidden,
    'tablet:w-60': sidebar.open,
    'tablet:w-12': !sidebar.open
  });

  React.useEffect(() => {
    setMouseOver(false);
  }, [sidebar.open]);

  return (
    <>
      {sidebar.open && sidebar.fixed && !isMobile && <div className='fixed inset-0' onClick={sidebar.toggle} />}
      <div
        className={className}
        id='app-nav'
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
        style={{ left: sidebar.open ? 0 : -60 }}
      >
        {!sidebar.open && (
          <>
            <div
              className={cn('relative cursor-pointer flex flex-col flex-1', isMobile ? 'py-3' : 'pt-4')}
              onClick={sidebar.toggle}
            >
              <Tippy
                disabled={isMobile}
                className='whitespace-nowrap'
                placement='right'
                arrow={false}
                content='Show Navigation'
              >
                <button
                  name='nav_toggle'
                  aria-label='Show Navigation'
                  className='hover:bg-gray-700 tablet:flex items-center self-center justify-center hidden w-8 h-8 rounded-lg'
                  onClick={sidebar.toggle}
                  aria-expanded={sidebar.open}
                >
                  <div className='flex items-center justify-center w-full h-full mx-auto' aria-expanded='false'>
                    {mouseOver ? <svgs.navExpand /> : <LogoExpandSVG className='text-white' />}
                  </div>
                </button>
              </Tippy>
              <div className='desktop:hidden tablet:mb-4 relative flex items-center justify-between flex-shrink-0 px-4'>
                {(!isTablet || sidebar.open) && (
                  <Link aria-label='Great Question logo' to='/'>
                    <svgs.logo />
                  </Link>
                )}

                <button
                  type='button'
                  aria-label='Show Navigation'
                  className='tablet:hidden focus:outline-none focus:text-white mobile-nav-toggle flex items-center text-white'
                  onClick={sidebar.toggle}
                  aria-expanded={sidebar.open}
                >
                  {isMobile ? <svgs.hamburger /> : <LogoExpandSVG className='text-white' />}
                </button>
              </div>
            </div>
            {!subscribed && (
              <Tippy
                disabled={isMobile}
                className='whitespace-nowrap'
                placement='right'
                arrow={false}
                content='Upgrade plans'
              >
                <a
                  aria-label='Upgrade plans'
                  href='/plans'
                  className='hover:bg-indigo-700 hover:text-white visited:text-white focus:outline-none focus:text-white group place-content-center flex items-center px-2 py-2 text-sm font-medium text-white bg-indigo-600'
                  data-target='collapse.expanded'
                >
                  <svgs.upgrade className='w-6 h-6 text-white' />
                </a>
              </Tippy>
            )}
          </>
        )}
        {sidebar.open && (
          <>
            <div className={cn('no-scrollbar flex flex-col flex-1 py-3 overflow-y-auto', isMobile ? 'py-3' : 'pt-4')}>
              <div className='relative flex items-center justify-between flex-shrink-0 px-4 mb-4'>
                <Link to='/' aria-label='Great Question logo'>
                  <svgs.logo />
                </Link>
                <Tippy
                  disabled={isMobile}
                  className='whitespace-nowrap'
                  placement='right'
                  arrow={false}
                  content='Hide Navigation'
                >
                  <button
                    aria-expanded={sidebar.open}
                    aria-label='Hide navigation'
                    className='hover:bg-gray-700 desktop:flex items-center justify-center hidden w-8 h-8 rounded-lg'
                    onClick={sidebar.toggle}
                  >
                    <div className='flex items-center justify-center w-full h-full mx-auto'>
                      <svgs.navCollapse />
                    </div>
                  </button>
                </Tippy>
                <button
                  type='button'
                  aria-expanded={sidebar.open}
                  aria-label='Hide navigation'
                  className='desktop:hidden focus:outline-none focus:text-white mobile-nav-toggle flex items-center text-white'
                  onClick={sidebar.toggle}
                >
                  <svgs.hamburger />
                </button>
              </div>
              <div className='hover:bg-gray-700 bg-indigo-1000 flex flex-col flex-shrink-0 border-t border-b border-gray-500'>
                <PopperDropdown
                  medium
                  className='group border-y flex-1 flex-shrink-0 block mx-2 border-gray-500'
                  aria-label={`${user.name} User menu`}
                  name='account_nav_toggle'
                  dropdownClass='w-full'
                  hideCaret
                  popperProps={{
                    closeOnClickOutside: true,
                    renderOnBodyRoot: false,
                    placement: 'bottom',
                    zIndex: 50,
                    className: 'px-2',
                    syncWidth: true
                  }}
                  text={
                    <div className='flex items-center justify-between flex-1 py-2.5 text-left'>
                      <div>
                        <Avatar size='lg' user={user} />
                      </div>
                      <div className='flex-1 ml-3 overflow-hidden'>
                        <Text h='400' truncate medium color='white'>
                          {user.name}
                        </Text>
                        <Text h='200' truncate medium color='gray-300' className='group-hover:text-gray-200'>
                          {account.workspace_name || account.name}
                        </Text>
                      </div>
                      <CaretDownSVG className='text-white' />
                    </div>
                  }
                >
                  {hasAiChat && (
                    <PopperDropdownLink
                      spa
                      className='flex items-center space-x-2 text-gray-700 hover:text-indigo-600 h400 block w-full px-4 pt-1 pb-1.5 cursor-pointer'
                      href='/ai/chats'
                      onClick={sidebar.userInfo.toggle}
                    >
                      <AskAiSVG className='w-4 h-4' />
                      <span>Ask AI History</span>
                    </PopperDropdownLink>
                  )}
                  <PopperDropdownLink
                    className={`flex items-center space-x-2 text-gray-700 hover:text-indigo-600 h400 block w-full px-4 pt-1 ${
                      hasSwitcher ? 'pb-1.5' : 'pb-3'
                    } cursor-pointer`}
                    href='/users/edit'
                  >
                    <CogSVG width='14' height='14' />
                    <span>Settings</span>
                  </PopperDropdownLink>
                  {hasSwitcher && (
                    <PopperDropdownLink
                      spa
                      className='flex items-center space-x-2 text-gray-700 hover:text-indigo-600 h400 block w-full px-4 pt-1.5 pb-3 cursor-pointer'
                      href='/accounts/choose'
                      onClick={sidebar.userInfo.toggle}
                    >
                      <SwitchAccountsSVG />
                      <span>Switch workspaces</span>
                    </PopperDropdownLink>
                  )}
                  <PopperDropdownLink
                    className={`border-t border-gray-200 flex items-center space-x-2 text-gray-700 hover:text-indigo-600 h400 block w-full px-4 pt-3 ${
                      user.gq_admin ? 'pb-3' : 'pb-1'
                    } cursor-pointer`}
                    // rel='nofollow'
                    data-method='delete'
                    href='/users/sign_out'
                    aria-label='Sign out'
                  >
                    <SignOutSVG /> <span>Sign out</span>
                  </PopperDropdownLink>
                  {user.gq_admin && (
                    <>
                      <div className='border-t border-gray-200 pb-1.5 pt-3 pl-4 text-xs font-semibold text-gray-500'>
                        GQ INTERNAL
                      </div>
                      <PopperDropdownLink
                        className='text-gray-700 hover:text-indigo-600 h400 block w-full px-4 py-1.5 cursor-pointer'
                        href='/admin'
                      >
                        Super Admin
                      </PopperDropdownLink>
                      <PopperDropdownLink
                        className='text-gray-700 hover:text-indigo-600 h400 block w-full px-4 py-1.5 cursor-pointer'
                        target='_blank'
                        href='/admin/sidekiq'
                      >
                        Sidekiq
                      </PopperDropdownLink>
                    </>
                  )}
                </PopperDropdown>
              </div>
              <div className='p-4'>
                <CTA permission={canCreate()} />
              </div>
              <nav className='bg-indigo-1000 flex-1' aria-label='Sidebar'>
                <NavItem
                  name='Dashboard'
                  isActiveNav={pathname === '/'}
                  spa={SHORTCUTS.dashboard.spa}
                  shortcut={SHORTCUTS.dashboard.keys}
                  href={SHORTCUTS.dashboard.href}
                >
                  <svgs.dashboard />
                  Dashboard
                </NavItem>
                <NavItem
                  name='Candidates'
                  isActiveNav={pathname.startsWith('/candidates')}
                  spa={SHORTCUTS.candidates.spa}
                  shortcut={SHORTCUTS.candidates.keys}
                  href={SHORTCUTS.candidates.href}
                >
                  <svgs.candidates />
                  Candidates
                </NavItem>
                <div>
                  <NavItem
                    name='Studies'
                    isActiveNav={pathname.startsWith('/studies')}
                    spa={SHORTCUTS.studies.spa}
                    shortcut={SHORTCUTS.studies.keys}
                    href={SHORTCUTS.studies.href}
                  >
                    <svgs.studies />
                    Studies
                  </NavItem>
                </div>
                {hasFeature('incentives') && isAdmin && (
                  <NavItem
                    name='Incentives'
                    isActiveNav={pathname.startsWith('/incentives')}
                    spa={SHORTCUTS.incentives.spa}
                    shortcut={SHORTCUTS.incentives.keys}
                    href={SHORTCUTS.incentives.href}
                  >
                    <svgs.incentives />
                    Incentives
                  </NavItem>
                )}
                <NavItem
                  name='Repository'
                  spa={SHORTCUTS.repository.spa}
                  shortcut={SHORTCUTS.repository.keys}
                  href={SHORTCUTS.repository.href}
                  isActiveNav={pathname.startsWith('/repository')}
                >
                  <svgs.repository />
                  Repository
                </NavItem>
                <div className='hover:bg-gray-700 bg-indigo-1000 flex flex-col flex-shrink-0 mt-4'>
                  <button
                    name='toggle'
                    className='group hover:bg-gray-700 hover:text-white visited:text-white focus:outline-none focus:text-white flex-shrink-0 block w-full px-4 py-3 text-xs font-semibold leading-4 text-white'
                    onClick={sidebar.resources.toggle}
                  >
                    <div className='flex items-center space-x-3'>
                      <div>Resources</div>
                      {sidebar.resources.open && <CaretDownSVG className='text-white' height='10' width='10' />}
                      {!sidebar.resources.open && <CaretSVG className='text-white' height='10' width='10' />}
                    </div>
                  </button>
                  {sidebar.resources.open && (
                    <div className='text-white'>
                      <ul className=' bg-indigo-1000 pt-2 pb-2'>
                        {hasFeature('reporting') && isAdmin && (
                          <li>
                            <NavItem
                              onClick={() => {
                                if (newTabs.includes('Reporting')) {
                                  setHiddenNewLabels([...(hiddenNewLabels || []), 'Reporting']);
                                }
                              }}
                              isNew={newTabs.includes('Reporting') && !hiddenNewLabels?.includes('Reporting')}
                              spa
                              name='Reporting'
                              href='/reporting'
                              isActiveNav={pathname === '/reporting'}
                            >
                              <svgs.reporting />
                              Reporting
                            </NavItem>
                          </li>
                        )}

                        <li>
                          <NavItem
                            name='Getting started'
                            spa={SHORTCUTS.getting_started.spa}
                            shortcut={SHORTCUTS.getting_started.keys}
                            href={SHORTCUTS.getting_started.href}
                            isActiveNav={pathname === '/getting_started'}
                          >
                            <svgs.gettingStarted />
                            Getting started
                          </NavItem>
                        </li>
                        <li>
                          <NavItem
                            name='Guides'
                            spa={SHORTCUTS.guides.spa}
                            shortcut={SHORTCUTS.guides.keys}
                            href={SHORTCUTS.guides.href}
                            isActiveNav={pathname === '/guides'}
                          >
                            <svgs.guides />
                            Guides
                          </NavItem>
                        </li>
                        <li>
                          <NavItem
                            name='Templates'
                            spa={SHORTCUTS.templates.spa}
                            shortcut={SHORTCUTS.templates.keys}
                            href={SHORTCUTS.templates.href}
                            isActiveNav={pathname === '/study_templates'}
                          >
                            <svgs.templates />
                            Templates
                          </NavItem>
                        </li>
                        <li>
                          <NavItem
                            name='Help center'
                            spa={SHORTCUTS.help_center.spa}
                            shortcut={SHORTCUTS.help_center.keys}
                            href={SHORTCUTS.help_center.href}
                            targetBlank
                          >
                            <svgs.helpCenter />
                            Help center
                          </NavItem>
                        </li>
                        <li>
                          <NavItem
                            name='Chat with us'
                            href='#'
                            onClick={() => {
                              const pylon = (window as any).Pylon;
                              if (pylon) {
                                pylon('show');
                              }
                            }}
                          >
                            <svgs.chat />
                            Chat with us
                          </NavItem>
                        </li>
                        <li>
                          <NavItem
                            name='Changelog'
                            spa={SHORTCUTS.changelog.spa}
                            shortcut={SHORTCUTS.changelog.keys}
                            href={SHORTCUTS.changelog.href}
                            targetBlank
                          >
                            <svgs.changelog />
                            Changelog
                          </NavItem>
                        </li>

                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                  )}
                </div>
                {enableTeams && <Teams />}
              </nav>
              <div className='flex flex-col justify-end flex-1'>
                <RestrictedAction permission='createAccountInvitation'>
                  {({ may, can }) => (
                    <NavItem name='Invite teammate' href={may && can ? '/invitations/new' : '#'} shortcut='Mod+Shift+I'>
                      <svgs.invite />
                      Invite teammate
                    </NavItem>
                  )}
                </RestrictedAction>
              </div>
            </div>
            {!subscribed && (
              <a
                href='/plans'
                className='hover:bg-indigo-700 hover:text-white visited:text-white focus:outline-none focus:text-white group flex items-center px-2 py-2 text-sm font-medium text-white bg-indigo-600'
                data-target='collapse.expanded'
              >
                <svgs.upgrade className='w-6 h-6 mr-3 text-white' />
                Upgrade plans
              </a>
            )}
          </>
        )}
      </div>
    </>
  );
};
