import * as React from 'react';

import { Button, Radio, Text } from '@components/common';

type Props = {
  label: string;
  value: string;
  checked: boolean;
  onChange: () => void;
};
export const TimeSlotRadioButton: React.FC<Props> = ({ label, value, checked, onChange }) => {
  return (
    <Button className='flex items-center w-full space-x-2' onClick={onChange}>
      <Radio name='time' value={value} checked={checked} onChange={onChange} />
      <Text className='flex-1 text-center'>{label}</Text>
    </Button>
  );
};
