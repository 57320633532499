import cn from 'classnames';
import Tippy from '@tippyjs/react';
import * as React from 'react';
import { useMemo, useState } from 'react';

import { api } from '@api/reduxApi';
import { Button, Checkbox, Text, Tooltip } from '@components/common';
import { CORE_ATTRS } from '@components/config';
import { NewAttrModal } from '@components/shared/NewAttrModal';
import { ArrowRightSVG, ChevronLeftSVG, ChevronRightSVG } from '@components/svgs';
import { without } from '@components/utils';
import { Error } from '@components/shared/AI';

import { SelectAttribute } from './SelectAttribute';

export type MappingTableProps = {
  model: CustomerImport;
  save: (model: CustomerImport) => void | Promise<void>;
  erroredColumnIndexes: number[];
};

export const MappingTable: React.FC<MappingTableProps> = ({ erroredColumnIndexes, model, save }) => {
  const { data: candidateAttrs } = api.useGetCandidateAttrsQuery();

  const [createAttrModalOpen, setCreateAttrModalOpen] = useState(false);
  const [previewIndex, setPreviewIndex] = useState(0);
  const maxPreviewIndex = model.preview?.length - 1 || 0;

  const attrs = useMemo(
    () => [...CORE_ATTRS, ...(candidateAttrs || []).map((a) => ({ ...a, name: `extra:${a.name}` }))],
    [candidateAttrs]
  );
  const indexofHiddenCols = useMemo(
    () => model.hidden_columns.map((col) => model.headers.indexOf(col)),
    [model.headers, model.hidden_columns]
  );
  const isHidden = (value: string) => model.hidden_columns.includes(value);
  const isIndexHidden = (index: number) => indexofHiddenCols.includes(index);

  const mapping = model.mapping && model.mapping.length > 0 ? model.mapping : model.headers.map(() => '');

  const onToggleHidden = (header: string) => {
    save({
      ...model,
      hidden_columns: isHidden(header) ? without(model.hidden_columns, header) : [...model.hidden_columns, header]
    });
  };

  const areAllSelected = model.headers.every((header) => !isHidden(header));
  const onToggleSelectAll = () => {
    save({
      ...model,
      hidden_columns: areAllSelected ? model.headers : []
    });
  };

  return (
    <div className='max-w-5xl bg-white border border-gray-200 rounded'>
      <div className='p-4 border-b border-gray-200'>
        <div className='flex items-center'>
          <div className='inline-flex items-center w-1/3 pr-4 space-x-2'>
            <Checkbox value='Select all' selected={areAllSelected} onChange={onToggleSelectAll} />
            <Text h='400' bold>
              CSV column
            </Text>
          </div>
          <div className='inline-flex items-center w-1/3 pr-4'>
            <Text h='400' bold className='mr-2'>
              Attribute
            </Text>
            <Tooltip content='Attributes are various types of data that get stored against each person.' />
            <Button className='ml-auto' icon='plus' small link onClick={() => setCreateAttrModalOpen(true)}>
              Create
            </Button>
          </div>
          <div className='inline-flex items-center w-1/3 space-x-2'>
            <Text h='400' bold>
              Example
            </Text>
            <Tooltip content='Example of data from your CSV file' />
            <div className='flex justify-end flex-1 space-x-2'>
              <Tippy content='See previous example'>
                <button
                  aria-label='Previous example'
                  disabled={previewIndex === 0}
                  className={cn('p-1 rounded-full', {
                    'hover:text-indigo-600 hover:bg-gray-50': previewIndex > 0,
                    'text-gray-400': previewIndex === 0
                  })}
                  onClick={() => setPreviewIndex(previewIndex - 1)}
                >
                  <ChevronLeftSVG />
                </button>
              </Tippy>
              <Tippy content='See next example'>
                <button
                  aria-label='Next example'
                  disabled={previewIndex >= maxPreviewIndex}
                  className={cn('p-1 rounded-full', {
                    'hover:text-indigo-600 hover:bg-gray-50': previewIndex < maxPreviewIndex,
                    'text-gray-400': previewIndex >= maxPreviewIndex
                  })}
                  onClick={() => setPreviewIndex(previewIndex + 1)}
                >
                  <ChevronRightSVG />
                </button>
              </Tippy>
            </div>
          </div>
        </div>
      </div>
      <div className='px-4 py-2'>
        <div className='divide-y divide-gray-200'>
          {model.headers.map((header, i) => (
            <div key={header} className='flex items-center py-2'>
              <div className='inline-flex items-center w-1/3 pr-4 space-x-2'>
                <Checkbox value={header} selected={!isHidden(header)} onChange={() => onToggleHidden(header)} />
                <Text className='flex-grow' h='400' truncate>
                  {header}
                </Text>
                <ArrowRightSVG className='flex-shrink-0' />
              </div>
              <div className='w-1/3 pr-4'>
                <SelectAttribute
                  isErrored={erroredColumnIndexes.includes(i)}
                  disabled={isIndexHidden(i)}
                  defaultLabel={header}
                  attrs={attrs}
                  value={mapping[i]}
                  onChange={async (value) => {
                    const newMapping = [...mapping];
                    newMapping[i] = value;
                    await save({ ...model, mapping: newMapping });
                  }}
                />
                {erroredColumnIndexes.includes(i) && (
                  <div className='mt-2'>
                    <Error>Select attribute or uncheck the row</Error>
                  </div>
                )}
              </div>
              <div className='w-1/3 pr-4 truncate'>
                <Text as='span' h='400'>
                  {model.preview?.[previewIndex]?.[i] || '—'}
                </Text>
              </div>
            </div>
          ))}
        </div>
      </div>
      <NewAttrModal
        open={createAttrModalOpen}
        onClose={() => setCreateAttrModalOpen(false)}
        onSubmit={() => setCreateAttrModalOpen(false)}
      />
    </div>
  );
};
