import classNames from 'classnames';
import { isSameDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import qs from 'qs';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Text } from '@components/common';

import { browserTimezoneDesc, TimezoneDesc } from '../../../shared/TimezonePicker';
import { Scheduler } from '../components/Scheduler';
import { useGetCalendarAvailabilities } from '../hooks/useGetCalendarAvailabilities';

type Props = {
  study: Study;
  participation?: Participation;
  researcherView?: boolean;
  researcherTimezone?: TimezoneDesc;
  isHidden?: boolean;
  deps?: any[];
};
export const BookingPage: React.FC<Props> = ({
  isHidden,
  researcherTimezone,
  study,
  participation,
  researcherView,
  deps
}) => {
  const { t, i18n } = useTranslation('BookingPage');
  const { slug, workspace, schedulable_end } = study;
  const [dayString, setDayString] = useState<string>('');
  const [calendarOffset, setCalendarOffset] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);

  const [bookedUrl, setBookedUrl] = useState<string>();
  const [startTime, setStartTime] = useState<number>();
  const [endTime, setEndTime] = useState<number>();

  const [selectedAvailability, setSelectedAvailability] = useState<string>();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedTimezone, setSelectedTimezone] = useState<TimezoneDesc>(researcherTimezone || browserTimezoneDesc());

  const searchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  const {
    data: timeslots,
    nextMonthCount,
    isLoading: isLoadingCurrentOffset,
    isLoadingNextOffset,
    cache
  } = useGetCalendarAvailabilities(
    { slug, workspace, tz: selectedTimezone.name, offset: calendarOffset, limit: schedulable_end },
    {
      skip: !slug || calendarOffset < 0 || isHidden,
      dependencies: deps
    }
  );

  const handleOnClick = (day: string, time: string) => {
    setSelectedDate(new Date(day + 'T00:00:00'));
    setSelectedAvailability(time);
    setDayString(day);
  };

  const isActive = (day: string, time: string) => {
    return (
      selectedAvailability && selectedAvailability === time && isSameDay(selectedDate, new Date(day + 'T00:00:00'))
    );
  };

  useEffect(() => {
    if (selectedAvailability && study.duration_in_minutes) {
      const date = new Date(dayString + 'T00:00:00');
      const [h, m] = selectedAvailability.split(':');
      const start = new Date(date.setHours(parseInt(h), parseInt(m)));
      const end = new Date(start.getTime() + study.duration_in_minutes * 60000);

      const startUTC = zonedTimeToUtc(start, selectedTimezone.name);
      const endUTC = zonedTimeToUtc(end, selectedTimezone.name);

      setStartTime(startUTC.getTime() / 1000);
      setEndTime(endUTC.getTime() / 1000);
    }
  }, [selectedAvailability]);

  useEffect(() => {
    if (startTime && endTime) {
      const params = {
        ...searchParams,
        start_time: startTime,
        end_time: endTime,
        tz: selectedTimezone.name
      };
      if (participation?.token) {
        setBookedUrl(`/go/${participation.token}/book?${qs.stringify(params)}`);
      } else {
        setBookedUrl(`/${study.public_path}/bookings/new?${qs.stringify(params)}`);
      }
    }
  }, [startTime, endTime]);

  useEffect(() => {
    setIsLoading(isLoadingCurrentOffset);
  }, [isLoadingCurrentOffset]);

  useEffect(() => {
    if (researcherTimezone) {
      setSelectedTimezone(researcherTimezone);
    }
  }, [researcherTimezone]);

  useEffect(() => {
    if (selectedDate && selectedAvailability && !isSameDay(selectedDate, new Date(dayString + 'T00:00:00'))) {
      setSelectedAvailability(undefined);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (calendarOffset >= 0) {
      setIsLoading(isLoadingNextOffset);
    }
  }, [calendarOffset]);

  useEffect(() => {
    i18n.changeLanguage(study.language);
  }, []);

  return (
    <div>
      <Scheduler
        study={study}
        participation={participation}
        researcherView={researcherView}
        isLoading={isLoading}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        timeslots={timeslots}
        selectedTimezone={selectedTimezone}
        setSelectedTimezone={setSelectedTimezone}
        calendarOffset={calendarOffset}
        setCalendarOffset={setCalendarOffset}
        calendarProps={{
          timeslots: { ...timeslots, ...cache.data[calendarOffset + 1], ...cache.data[calendarOffset - 1] },
          nextMonthCount
        }}
        renderTimeslot={({ time, day, displayText }) => (
          <li key={time} className='group flex items-center w-full space-x-4 text-center'>
            <button
              className={classNames(
                {
                  'w-full': !isActive(day, time),
                  'w-1/2': isActive(day, time)
                },
                'truncate h-full rounded-md border border-custom-brand py-2 px-4 hover-bg-custom-brand-button hover-text-custom-brand-button focus:outline-none transition-all duration-150'
              )}
              onClick={() => handleOnClick(day, time)}
            >
              {displayText}
            </button>

            <a
              href={researcherView ? undefined : bookedUrl}
              className={classNames(
                {
                  'w-0 hidden': !isActive(day, time),
                  'w-1/2': isActive(day, time)
                },
                'h-full rounded-md focus:text-white border border-custom-brand py-2 px-4 bg-custom-brand-button text-custom-brand-button hover-text-custom-brand-button hover:font-bold overflow-hidden transition-all duration-150'
              )}
            >
              {t('confirm')}
            </a>
          </li>
        )}
      />
      {study.time_proposals_enabled && !study.focus_group && (
        <div className='border-custom-brand-secondary pt-4 mt-4 text-center border-t'>
          <Text mb='4' bold>
            {t('time_proposal_title')}
          </Text>
          <Button
            href={
              researcherView
                ? undefined
                : `/${study.public_path}/time_proposal?${qs.stringify(
                    participation?.token ? { token: participation?.token } : {}
                  )}`
            }
            disabled={researcherView}
            icon='proposeTime'
            noStyle
            className='btn-custom-brand-secondary'
            small
          >
            {t('time_proposal_cta')}
          </Button>
        </div>
      )}
    </div>
  );
};
