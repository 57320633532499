import cn from 'classnames';
import React from 'react';

import { AlignmentDropdown } from '@components/BrandingApp/components/AlignmentDropdown';
import { BrandColor } from '@components/BrandingApp/components/BrandColor';
import { BlockHeader, Description, Label } from '@components/BrandingApp/components/shared';
import { Text, Button } from '@components/common';
import { UploadButton } from '@components/common/UploadButton';

interface Props {
  edit: boolean;
  brand: Brand;
  change: (field: string, value: string) => void;
  logoFile: File | undefined;
  setLogoFile: (file: File) => void;
  deleteBrand: () => void;
}

export const Editor: React.FC<Props> = ({ edit, change, brand, setLogoFile, logoFile, deleteBrand }) => {
  const { button_color, button_text_color, secondary_text_color, text_color, logo_alignment } = brand;

  return (
    <>
      <div className={cn('p-6 pt-0 w-full bg-white rounded-md border border-gray-200', edit ? 'mt-6' : 'mt-4')}>
        <div className='pb-6 border-b border-gray-200'>
          <BlockHeader>Logo</BlockHeader>
          <div className='flex items-center'>
            {logoFile ? (
              <img
                className='w-20 h-auto mt-4 mr-4 rounded-md'
                src={window.URL.createObjectURL(logoFile)}
                alt='brand logo'
              />
            ) : (
              brand.logo && <img className='w-20 h-auto mt-4 mr-4 rounded-md' src={brand.logo} alt='brand logo' />
            )}
            {edit && (
              <>
                <UploadButton
                  medium
                  className='whitespace-nowrap'
                  supportedFileTypes={[
                    'image/apng',
                    'image/gif',
                    'image/jpeg',
                    'image/png',
                    'image/svg+xml',
                    'image/webp'
                  ]}
                  onUploadFiles={([file]) => setLogoFile(file)}
                >
                  {brand.logo ? 'Change logo' : 'Upload logo'}
                </UploadButton>
                <Text h='200' className='ml-1'>
                  We support PNG, JPEG, GIF, SVG, WEBP files of any size.
                </Text>
              </>
            )}
          </div>

          <AlignmentDropdown
            edit={edit}
            alignment={logo_alignment}
            setAlignment={(v: 'left' | 'center' | 'right') => change('logo_alignment', v)}
          />
        </div>

        <div className='pb-6 border-b border-gray-200'>
          <BlockHeader>Text</BlockHeader>
          <Label>Primary color</Label>
          <Description>Use for header and paragraph text.</Description>
          <BrandColor ariaLabel='text_color' edit={edit} color={text_color} onChange={(v) => change('text_color', v)} />
          <Label>Secondary color</Label>
          <Description>Used for support text.</Description>
          <BrandColor
            ariaLabel='secondary_text_color'
            edit={edit}
            color={secondary_text_color}
            onChange={(v) => change('secondary_text_color', v)}
          />
        </div>

        <div className={cn({ 'pb-6 border-b border-gray-200': edit })}>
          <BlockHeader>Buttons</BlockHeader>
          <Label>Primary color</Label>
          <Description>Use for the primary call-to-action buttons in emails and webpages.</Description>
          <BrandColor edit={edit} color={button_color} onChange={(v) => change('button_color', v)} />
          <Label>Primary text color</Label>
          <Description>Use for the text included in the primary buttons in emails and webpages.</Description>
          <BrandColor edit={edit} color={button_text_color} onChange={(v) => change('button_text_color', v)} />
        </div>
        {edit && (
          <Button onClick={deleteBrand} medium className='mt-6'>
            Reset to default
          </Button>
        )}
      </div>
    </>
  );
};
