import cn from 'classnames';
import React, { PropsWithChildren } from 'react';

import { Item, Menu, MenuProps } from '@components/common/Menu';
import { move } from '@helpers/move';

import { useTableContext } from '../hooks';
import * as Icons from '../icons';
import { ColumnHeaderProps, GetKeyOf } from '../types';
import { SORT_ICONS } from '@components/shared/GridTable/components/OtherHeader/constants';
import { Text } from '@components/common';

export const ColumnHeader = <D extends Record<string, any>>({
  isStatic = false,
  header,
  table,
  children,
  disableHide = false,
  disableSort = false,
  sortIconType = 'text'
}: PropsWithChildren<ColumnHeaderProps<D>>) => {
  const { visibleColumns, columnOrder, setVisibleColumns, setColumnOrder } = useTableContext<D>();
  const columnId = header.column.id;
  const sortCopy = SORT_ICONS[sortIconType];
  const getDefaultColumns = () => table.getAllFlatColumns().map(({ id }) => id as GetKeyOf<D, 'extra'>);

  const handleOnItemSelect: MenuProps['onItemSelect'] = (value) => {
    switch (value) {
      case 'hide':
        setVisibleColumns({ ...visibleColumns, [columnId]: false });
        break;
      case 'sort_asc':
        header.column.toggleSorting(false);
        table.options.meta?.onSort?.({ value: columnId, desc: false });
        break;
      case 'sort_desc':
        header.column.toggleSorting(true);
        table.options.meta?.onSort?.({ value: columnId, desc: true });
        break;
      case 'move_left':
      case 'move_right': {
        const order = columnOrder?.length ? columnOrder : getDefaultColumns();
        const index = order.findIndex((id) => id === columnId);

        if ((value === 'move_left' && index === 0) || (value === 'move_right' && index === order.length - 1)) {
          break;
        }

        setColumnOrder(move<GetKeyOf<D, 'extra'>>(order, index, index + (value === 'move_left' ? -1 : 1)));
        break;
      }
      default:
        break;
    }
  };

  return (
    <Menu
      onItemSelect={handleOnItemSelect}
      className='w-60 overflow-hidden text-sm bg-white border border-gray-200 rounded-md shadow-lg'
      popperProps={{ placement: 'bottom-end', zIndex: 10, isDisabled: isStatic }}
      renderTrigger={({ isOpen }) => (
        <div
          className={cn('group h-11 relative flex items-center justify-between bg-white border-b border-gray-200', {
            'cursor-pointer': !isStatic
          })}
        >
          <span className='h400 ml-2 font-bold truncate'>{children}</span>
          {!isStatic && (
            <>
              <Icons.ChevronDown className={cn('group-hover:opacity-100 opacity-0 mr-2', { 'opacity-100': isOpen })} />
              <div
                className={cn(
                  'absolute w-1 h-full top-0 right-0 select-none cursor-col-resize opacity-0 hover:opacity-100 bg-gray-500'
                )}
                onMouseDown={header.getResizeHandler()}
              />
            </>
          )}
        </div>
      )}
    >
      {!disableHide && (
        <Item value='hide' className='hover:bg-indigo-600 hover:text-white flex items-center px-4 py-2'>
          <Icons.Hide className='mr-2' /> Hide
        </Item>
      )}
      {!disableSort && (
        <>
          <Item value='sort_asc' className='hover:bg-indigo-600 hover:text-white flex items-center px-4 py-2'>
            <sortCopy.ascending.Icon className='mr-4' />
            <Text h='400'>{sortCopy.ascending.text}</Text>
          </Item>
          <Item value='sort_desc' className='hover:bg-indigo-600 hover:text-white flex items-center px-4 py-2'>
            <sortCopy.descending.Icon className='mr-4' />
            <Text h='400'>{sortCopy.descending.text}</Text>
          </Item>
        </>
      )}
      <Item value='move_left' className='hover:bg-indigo-600 hover:text-white flex items-center px-4 py-2'>
        <Icons.MoveLeft className='mr-2' /> Move to left
      </Item>
      <Item value='move_right' className='hover:bg-indigo-600 hover:text-white flex items-center px-4 py-2'>
        <Icons.MoveRight className='mr-2' /> Move to right
      </Item>
    </Menu>
  );
};
