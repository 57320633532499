import React, { createContext, useContext, useMemo, useState } from 'react';

import { api } from '@api/reduxApi';
import { ActivityTable } from '@components/DashboardApp/ActivityTable';
import { DashboardZDS } from '@components/DashboardApp/DashboardZDS';
import { StatsTable } from '@components/DashboardApp/StatsTable';
import { RecentArtifacts } from '@components/RepositoryApp/RecentArtifacts';
import { RequestAlerts } from '@components/RoleRequests/RequestAlerts';
import { PageTitle } from '@components/common/helmets';
import { ActiveStudies } from '@components/DashboardApp/ActiveStudies';
import { DashboardLayoutBody } from '@components/layouts/DashboardLayout';
import { FeatureBannerStack } from '@components/shared/FeatureBanner/FeatureBannerStack';
import { PageHeader } from '@components/shared/PageHeader';
import { StudySlideOut } from '@components/StudiesApp/types';
import {
  NewStudySlideout,
  NewRecruitingStudySlideout
} from '@components/StudiesApp/components/StudyNew/components/NewStudySlideout';
import { useAccount } from '@hooks/useAccount';
import { usePermission } from '@hooks/usePermission';
import { useUser } from '@hooks/useUser';
import { usePlan } from '@hooks/usePlan';
import { useFeature } from '@hooks/useFeature';
import { useDisabledFeatures } from '@hooks/useDisabledFeatures';
import { useShowAiChatBubble } from '@stores/aiChat';
import configStore from '@stores/config';

import { OnboardingChecklist } from './components/OnboardingChecklist';
import { UpcomingInterviews } from './UpcomingInterviews';
import { buildFeatureBanners } from './buildFeatureBanners';

const ACTIVITIES_TO_SHOW = 4;

interface Context {
  hitsNumber?: number;
  setHitsNumber?: React.Dispatch<React.SetStateAction<number>>;
}

export const dashboardContext = createContext<Context>({} as Context);

export const DashboardIndex = () => {
  useShowAiChatBubble();

  const isAdmin = usePermission('isAdmin')();

  const { hasFeature } = usePlan();

  const hasReportingFeature = hasFeature('reporting');

  const {
    state: {
      config: { hide_dashboard_stats }
    }
  } = useContext(configStore);

  const user = useUser();
  const {
    account: { ai_access: aiAccess }
  } = useAccount();

  const unmoderatedEnabled = hasFeature('unmoderated');

  const [hitsNumber, setHitsNumber] = useState<number>();

  const [slideOut, setSlideOut] = useState<StudySlideOut | null>();

  const { data: interviews, isSuccess: interviewsFetched } = api.useGetUpcomingInterviewsQuery({});
  const { data: activities, isSuccess: activitiesFetched } = api.useGetActivitiesQuery({
    limit: ACTIVITIES_TO_SHOW + 1
  });
  const { data: stats, isSuccess: statsFetched } = api.useGetDashboardStatsQuery();

  const disabledFeatures = useDisabledFeatures();

  const d = stats
    ? Object.values(stats).reduce((acc, curr) => [...(acc as any[]), ...Object.values((curr as any).table)], [])
    : null;

  const statsAreEmpty = d ? (d as any[]).every((i) => i === 0) : true;

  const dataIsFetched = interviewsFetched && activitiesFetched && (statsFetched || hide_dashboard_stats);

  const shouldShowOnboardingChecklist = !user.source;
  const shouldShowZDS = dataIsFetched && statsAreEmpty && !activities?.length && !interviews?.length && !hitsNumber;

  const banners = useMemo(
    () =>
      buildFeatureBanners({
        isAdmin,
        isExternalRecruitmentDisabled: disabledFeatures.external_recruitment,
        hasReportingFeature,
        unmoderatedEnabled,
        aiAccess
      }),
    [disabledFeatures.external_recruitment, hasReportingFeature, isAdmin, unmoderatedEnabled, aiAccess]
  );

  const renderSlideOut = () => {
    switch (slideOut) {
      case 'NewStudySlideout':
        return <NewStudySlideout isVisible onClose={closeSlideOut} />;
      case 'NewRecruitingStudySlideout':
        return <NewRecruitingStudySlideout isVisible onClose={closeSlideOut} />;
      default:
        return null;
    }
  };

  const closeSlideOut = () => {
    setSlideOut(null);
  };

  if (shouldShowZDS) {
    return (
      <>
        {renderSlideOut()}
        <DashboardZDS setSlideOut={setSlideOut} />
      </>
    );
  }

  return (
    <>
      <PageTitle>Dashboard</PageTitle>
      <PageHeader h1='Dashboard' hideDefaultButtons>
        {!hide_dashboard_stats && <StatsTable />}
      </PageHeader>
      <DashboardLayoutBody>
        {renderSlideOut()}
        {shouldShowOnboardingChecklist && <OnboardingChecklist />}
        {isAdmin && <RequestAlerts />}
        <FeatureBannerStack setSlideOut={setSlideOut} types={banners} source='dashboard' />

        <ActiveStudies setHitsNumber={setHitsNumber} />
        <UpcomingInterviews />
        <dashboardContext.Provider value={{ hitsNumber, setHitsNumber }}>
          <RecentArtifacts kind='Story' />
          <RecentArtifacts kind='Repo::Session' />
          <RecentArtifacts kind='HighlightReel' />
        </dashboardContext.Provider>

        <ActivityTable />
      </DashboardLayoutBody>
    </>
  );
};
