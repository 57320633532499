import { PageTitle } from 'components/common/helmets';
import React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { Loading, SlideOut } from '@components/common';
import { DashboardHeader, DashboardLayout } from '@components/layouts/DashboardLayout';
import { useToaster } from '@components/stores/toaster';
import { usePermission } from '@hooks/usePermission';

import { CandidatesInviteSlideOut } from '../CandidatesIndex/CandidatesInviteSlideOut';
import { CandidateInfo } from './components/CandidateInfo';
import { ConfirmDeleteModal } from './components/ConfirmDeleteModal';
import { ConfirmUnsubscribeModal } from './components/ConfirmUnsubscribeModal';
import { HeaderButtons } from './components/HeaderButtons';
import { PageSkeleton } from './components/PageSkeleton';
import Shortlist from './components/Shortlist';
import { successShortlisted } from './components/toasts';
import { CandidatesScreenerSlideOut } from '@components/StudyMessages/CandidatesScreenerSlideOut';
import { useStudies } from '@hooks/useStudies';

type Actions = 'invite' | 'shortlist' | 'sendScreener' | 'inviteToPanel' | 'unsubscribe' | 'delete' | null;

export const CandidatesShow: React.FC = () => {
  const [action, setAction] = useState<Actions>();
  const params = useParams() as { id: string };
  const id = parseInt(params.id);

  const showToast = useToaster();

  const { panelStudies } = useStudies();

  const { data: candidate, isSuccess: candidateFetched, refetch: refetchCandidate } = api.useGetCandidateQuery({ id });
  const { data: studies } = api.useGetStudiesQuery();
  const { data: participations, refetch: refetchParticipations } = api.useGetCandidateParticipationsQuery({ id });

  const canUpdate = usePermission<Candidate>('updateCandidate')(candidate);

  const title = candidate?.name || 'Unnamed Candidate';

  function handleShortlistSuccess(participation: Participation) {
    setAction(null);
    refetchParticipations();
    showToast(successShortlisted({ candidate, participation }));
  }

  if (!candidateFetched || !candidate) {
    return <PageSkeleton />;
  }

  return (
    <DashboardLayout>
      <PageTitle>{title}</PageTitle>
      <DashboardHeader
        h1={title}
        breadcrumb={['Candidates', '/candidates']}
        renderHeaderButtons={() => canUpdate && <HeaderButtons setAction={setAction} />}
      />
      <CandidateInfo
        key={candidate.updated_at}
        candidate={candidate}
        participations={participations}
        onUpdate={refetchCandidate}
      />
      {action === 'delete' && (
        <ConfirmDeleteModal id={id} onCancel={() => setAction(null)} onSuccess={() => setAction(null)} />
      )}
      {action === 'unsubscribe' && (
        <ConfirmUnsubscribeModal id={id} onCancel={() => setAction(null)} onSuccess={() => setAction(null)} />
      )}
      {action === 'invite' && !studies && (
        <SlideOut title='Participation invite'>
          <Loading absolute />
        </SlideOut>
      )}
      {action === 'invite' && studies && (
        <CandidatesInviteSlideOut
          ids={[candidate.id]}
          studies={studies}
          onClose={() => setAction(null)}
          onSuccess={() => setAction(null)}
          selectedCount={1}
          previewCandidate={candidate}
        />
      )}
      {action === 'sendScreener' && (
        <CandidatesScreenerSlideOut
          ids={[candidate.id]}
          onClose={() => setAction(null)}
          onSuccess={() => setAction(null)}
          selectedCount={1}
          previewCandidate={candidate}
        />
      )}
      {action === 'inviteToPanel' && (
        <CandidatesInviteSlideOut
          ids={[candidate.id]}
          study={panelStudies?.[0]}
          studies={panelStudies}
          onClose={() => setAction(null)}
          onSuccess={() => setAction(null)}
          selectedCount={1}
          previewCandidate={candidate}
        />
      )}
      {action === 'shortlist' && (
        <SlideOut title='Shortlist to Study' closeOnEsc={false} onClose={() => setAction(null)}>
          <div className='px-4'>
            {!participations || !studies ? (
              <Loading absolute />
            ) : (
              <Shortlist
                candidate={candidate}
                studies={studies}
                participations={participations}
                onSuccess={handleShortlistSuccess}
              />
            )}
          </div>
        </SlideOut>
      )}
    </DashboardLayout>
  );
};
