import {
    displayUrl
} from 'components/StudiesApp/components/StudyPublished/ParticipationTable/components';
import format from 'date-fns/format';
import React from 'react';
import { Link } from 'react-router-dom';

import { AvatarFromId } from '@components/common';
import { InterviewsZDS } from '@components/DashboardApp/InterviewsZDS';
import { CopyURLButton } from '@components/shared/CopyURLButton';
import { ClockSVG, LiveSVG } from '@components/svgs';
import { compact, truncate } from '@components/utils';
import { usePermission } from '@hooks/usePermission';

interface Props {
  participations?: (Participation & { attendees: User[] })[];
  header: string;
}

const isToday = (someDate: Date) => {
  const today = new Date();
  return (
    someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  );
};

export const InterviewsTable: React.FC<Props> = ({ participations, header }) => {
  if (!participations) {
    return <InterviewsZDS />;
  }
  return (
    <div>
      <h2 className='h600 mt-12 mb-4 font-bold'>{header}</h2>
      <div className='w-full px-6 bg-white border border-gray-200 rounded-md'>
        <div className='overflow-x-auto'>
          {!participations?.length ? (
            <InterviewsZDS />
          ) : (
            <table className='xx-participations-table w-full divide-y divide-gray-200'>
              <thead>
                <tr>
                  <th className='py-4 px-0.5 text-sm font-bold text-left text-gray-700'>Date & Time</th>
                  <th className='py-4 px-0.5 text-sm font-bold text-left text-gray-700'>Participant</th>
                  <th className='py-4 px-0.5 text-sm font-bold text-left text-gray-700'>Study</th>
                  <th className='py-4 px-0.5 text-sm font-bold text-left text-gray-700'>Moderator</th>
                  <th className='py-4 px-0.5 text-sm font-bold text-left text-gray-700'>Call Link</th>
                  <th className='py-4 px-0.5 text-sm font-bold text-left text-gray-700'></th>
                  <th className='py-4 px-0.5 text-sm font-bold text-left text-gray-700'></th>
                </tr>
              </thead>
              <tbody className='overflow-x-auto divide-y divide-gray-200'>
                {participations.map((p) => (
                  <tr key={p.id}>
                    <td className={`py-4 px-0.5 flex flex-col  whitespace-nowrap text-sm text-gray-700`}>
                      {p.interview_at ? (
                        <>
                          <span className='font-bold'>{format(p.interview_at, 'E, MMM d')}</span>
                          <span className='font-normal'>{format(p.interview_at, 'h:mm a O')}</span>
                        </>
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                    <td className='whitespace-nowrap py-4 px-0.5 text-sm text-gray-700'>
                      <div
                        className={`flex flex-col items-start ${
                          p.name ? 'text-gray-700 font-medium' : 'text-gray-400'
                        }`}
                      >
                        <Link
                          className='w-40 font-medium text-gray-700 truncate'
                          to={`/studies/${p.project_id}#candidates/${p.customer_id}`}
                        >
                          {p.name ? p.name : 'Unnamed candidate'}
                        </Link>
                      </div>
                    </td>
                    <td className='whitespace-nowrap py-4 px-0.5 text-sm text-gray-700'>
                      <Link to={`/studies/${p.study?.id}`} className='block w-40 max-w-xs text-gray-700 truncate'>
                        {p.study?.title}
                      </Link>
                    </td>
                    <td className='whitespace-nowrap py-4 px-0.5 text-sm text-gray-700'>
                      <div className='flex items-center w-full h-full'>
                        <AvatarFromId userId={p?.moderator_id} />
                      </div>
                    </td>
                    <td className='whitespace-nowrap py-4 pl-0.5 text-sm text-gray-700 truncate'>
                      {p.join_url ? (
                        <div className='flex items-center'>
                          <span className='tablet:inline hidden'>{truncate(displayUrl(p.join_url), 18)}</span>
                          <CopyURLButton link className='p-6 ml-2' text={displayUrl(p.join_url)} icon='link' />
                        </div>
                      ) : (
                        <span className='text-gray-400'>Not set</span>
                      )}
                    </td>
                    <td className='whitespace-nowrap py-4 px-0.5 text-sm text-gray-700'>
                      {p.live_stream_status === 'scheduled' && (
                        <div className='relative flex flex-row-reverse justify-center'>
                          <div className='flex items-center px-2 py-1 font-bold uppercase border border-gray-200 rounded-full'>
                            <ClockSVG className='w-3.5 h-3.5' />
                            <span className='ml-1 text-xs'>LIVE UPCOMING</span>
                          </div>
                        </div>
                      )}
                      {p.live_stream_status === 'live' && (
                        <div className='relative flex flex-row-reverse justify-center'>
                          <div className='bg-red-450 flex items-center px-2 py-1 font-bold text-white uppercase rounded-full'>
                            <LiveSVG className='w-3.5 h-3.5' />
                            <span className='ml-1 text-xs'>LIVE</span>
                          </div>
                        </div>
                      )}
                    </td>
                    <td className='text-sm text-right'>
                      <Link
                        className='whitespace-nowrap hover:text-indigo-700 text-indigo-600'
                        to={p.interview_path || ''}
                      >
                        <span className='whitespace-nowrap'>Interview room</span>
                        <svg
                          className='icon-sm inline-block fill-current'
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 20 20'
                        >
                          <path
                            fill='currentColor'
                            d='M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z'
                          />
                        </svg>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        {participations.length > 4 && (
          <div className='py-4 px-0.5 border-t border-gray-200'>
            <a href='participations/booked' target='_blank' className='text-sm font-medium'>
              Show all
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
