import pluralize from 'pluralize';
import ReactDatePicker from 'react-datepicker';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { api } from '@api/reduxApi';
import { bulkParticipationsAction } from '@api/queries';
import { UserSelect } from '@components/shared/UserSelect';
import { TimezoneIndicator } from '@components/shared/TimezoneIndicator';
import { Error } from '@components/shared/AI';
import { Checkbox, Input, Loading, Text, TippyOrNot } from '@components/common';
import { compact, uniq } from '@components/utils';
import { Toast, useToaster } from '@components/stores/toaster';
import { useAccount } from '@hooks/useAccount';
import { useUser } from '@hooks/useUser';

import { StudyMessageSlideOut } from './StudyMessageSlideOut';
import { SendMessageParams } from './types';
import { getReasonsManualBookingCantCreateEvents, isDateInPast } from './utils';

const successToast: Toast = {
  heading: 'Successfully marked as scheduled!',
  text: 'All participants were marked as scheduled.',
  icon: 'success'
};
const failedToast: Toast = {
  heading: 'Something went wrong.',
  text: 'We couldn’t mark all participants as scheduled. Please try again later.',
  icon: 'error'
};
const successWithEventsToast: Toast = {
  heading: `Successfully marked as scheduled!`,
  text: 'Calendar events were sent to participants.',
  icon: 'success'
};
const successWithEventsAndSendToast: Toast = {
  heading: `Successfully marked as scheduled!`,
  text: 'Emails were sent to participants.',
  icon: 'success'
};

interface Props {
  study: Study;
  parties: Participation[];
  onSuccess?: () => void;
  onClose: () => void;
}

export const ManualScheduleSlideOut: React.FC<Props> = ({ study, parties, onSuccess, onClose }) => {
  const cantCreateEventsReasons = getReasonsManualBookingCantCreateEvents(study, parties);
  const canCreateEvents = cantCreateEventsReasons.length === 0;
  const [loading, setLoading] = useState(false);
  const [createEvents, setCreateEvents] = useState(canCreateEvents);
  const [send, setSend] = useState(canCreateEvents);
  const [moderatorId, setModeratorId] = useState<number | null>(parties[0]?.moderator_id || study.owner_id);
  const [interviewAt, setInterviewAt] = useState<Date | null>(parties[0]?.interview_at || null);
  const showToast = useToaster();
  const {
    account: { team }
  } = useAccount();
  const { id: currentUserId } = useUser();
  const userIds = uniq([currentUserId, study.owner_id, ...study.user_ids]);
  const users = compact(userIds.map((id) => team.find((u) => u.id === id)));

  const participantsLabel =
    parties.length === 1 ? parties[0].name || 'Unnamed candidate' : pluralize('participants', parties.length, true);

  const sendDisabled = createEvents && (!moderatorId || !interviewAt);

  const [createManualBooking, { isLoading, isSuccess, isError }] = api.useCreateParticipationManualBookingMutation();

  const bulkMarkScheduled = async () => {
    setLoading(true);
    try {
      await bulkParticipationsAction(study.id, { action: 'book', ids: parties.map((p) => p.id), fields: [] });
      onSuccess?.();
      showToast(successToast);
    } catch (e) {
      showToast(failedToast);
    }
    setLoading(false);
  };

  const manualSchedule = (params: SendMessageParams) => {
    createManualBooking({
      id: parties[0].id,
      message_id: send ? params.message.id : null,
      moderator_id: moderatorId,
      start_time: interviewAt
    });
  };

  const handleSend = async (params: SendMessageParams) => {
    if (parties.length === 1 && createEvents) {
      manualSchedule(params);
    } else {
      await bulkMarkScheduled();
      onClose();
    }
  };

  useEffect(() => {
    if (isSuccess) {
      showToast(send ? successWithEventsAndSendToast : successWithEventsToast);
      onSuccess?.();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      showToast(failedToast);
    }
  }, [isError]);

  return (
    <>
      <StudyMessageSlideOut
        customizable
        disabled={sendDisabled}
        optional={{ type: 'checkbox', disabled: !createEvents, send: send && createEvents, setSend }}
        title='Mark as scheduled'
        cta='Confirm & send'
        study={study}
        event='booked'
        previewCandidate={parties[0]?.customer}
        previewParticipation={parties[0]}
        inviteableCount={parties.length}
        onClose={onClose}
        onSend={handleSend}
      >
        <>
          {(loading || isLoading) && <Loading absolute />}

          <div className='mb-6'>
            <Text>Marking {participantsLabel} as scheduled.</Text>
          </div>

          <div className='mb-6'>
            <TippyOrNot show={!canCreateEvents} content={cantCreateEventsReasons.join(' and ')}>
              <div className='flex items-center space-x-2'>
                <Checkbox
                  disabled={!canCreateEvents}
                  selected={canCreateEvents && createEvents}
                  onChange={(v) => setCreateEvents(v)}
                />
                <Text color={canCreateEvents ? 'gray-700' : 'gray-500'}>Create calendar events</Text>
              </div>
            </TippyOrNot>
            {createEvents && (
              <>
                <div className='mt-4'>
                  <Text bold mb='1'>
                    Moderator
                  </Text>
                  <UserSelect selectedId={moderatorId} users={users || []} onSelect={setModeratorId} />
                </div>
                <div className='mt-6'>
                  <Text bold mb='1'>
                    Date & time
                  </Text>
                  <ReactDatePicker
                    name='interview_at'
                    customInput={<Input size='full' error={!interviewAt} />}
                    selected={interviewAt}
                    onChange={(date) => setInterviewAt(date)}
                    showTimeSelect
                    placeholderText='Choose a date & time…'
                    minDate={new Date()}
                    filterTime={isDateInPast}
                    className='w-full text-left border border-gray-300 rounded-md'
                    dateFormat='Pp'
                  />
                  <TimezoneIndicator />
                  {!interviewAt && <Error className='mt-3'>Please select a date & time in the future.</Error>}
                </div>
              </>
            )}
          </div>
        </>
      </StudyMessageSlideOut>
    </>
  );
};
